import { RenderElementProps } from "slate-react/dist/components/editable";
import { ReactEditor, useSlate } from "slate-react";
import styles from "./Image.module.scss";
import { Transforms } from "slate";
import { ImageElement } from "../../../type/slate-types";
import Button from "antd/es/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

const Image: React.FC<RenderElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      {children}
      <div className={styles.container} contentEditable={false}>
        <img className={styles.image} src={(element as ImageElement).url} />
        <Button
          className={styles.delete}
          onClick={() => Transforms.removeNodes(editor, { at: path })}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </div>
  );
};

export default Image;
