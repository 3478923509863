import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

class FirstNameField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.REQUIRED,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      customGridProperties: {
        cellClass: ["cursor-pointer"],
      },
      formProperties: {
        fullWidth: true,
      },
      importProperties: { required: true },
      ...data,
    });
  }

  get isReadable() {
    // system-required fields are always readable, except Name which can be masked
    return this._schemaDetails?.accessStatus.read !== false;
  }

  get doesSupportPhi() {
    return true;
  }
}

export default FirstNameField;
