import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import styles from "./SearchButton.module.scss";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";
import { isMacOS } from "@mapmycustomers/shared/util/browser";

interface Props {
  onClick: () => void;
}

const SearchButton: React.FC<Props> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <nav>
      <Tooltip
        title={
          <div>
            <div>
              {intl.formatMessage({
                id: "navbar.findIcon.tooltip",
                defaultMessage: "Search anything",
                description: "Title of navbar search icon",
              })}
            </div>
            <div>{isMacOS() ? "⇧⌘F" : "Ctrl+Shift+F"}</div>
          </div>
        }
      >
        <FontAwesomeIcon className={styles.container} icon={faSearch} onClick={onClick} />
      </Tooltip>
    </nav>
  );
};

export default SearchButton;
