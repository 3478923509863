import { GeoPoint } from "@mapmycustomers/shared/types";
import Address from "@mapmycustomers/shared/types/Address";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import GeoAddress from "@mapmycustomers/shared/types/GeoAddress";

export const getAddress = (
  formValues: any,
  geoManagementState?: GeoManagementState
): Address & {
  geoAddress: GeoAddress;
  geoManagementState: GeoManagementState;
  geoPoint: GeoPoint;
} => {
  const lat = parseFloat(formValues.latitude);
  const lon = parseFloat(formValues.longitude);
  const address = formValues.addressDetails
    ? {
        ...formValues.addressDetails,
        region: formValues.addressDetails.region,
        regionCode: formValues.addressDetails.regionCode,
        geoManagementState: formValues.addressDetails.geoManagementState
          ? formValues.addressDetails.geoManagementState
          : geoManagementState ?? GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
        geoPoint:
          isNaN(lat) || isNaN(lon)
            ? null
            : { type: "Point", coordinates: [lon, lat] as [number, number] },
      }
    : {};
  return address;
};
