import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import User from "@mapmycustomers/shared/types/User";
import RoleEnum from "@mapmycustomers/shared/enum/Role";
import cn from "classnames";
import UserSelectField from "component/input/UserSelectField";
import TextWithInfo from "component/typography/TextWithInfo";
import type Role from "@mapmycustomers/shared/types/Role";
import type { RootState } from "store/rootReducer";
import { getRole } from "store/iam";
import { connect } from "react-redux";
import styles from "./ViewAsFilterComponent.module.scss";

interface Props {
  className?: string;
  currentUserRole?: Role;
  onChange: (user: User | undefined) => void;
  users: User[];
  value: User | undefined;
}

const ViewAsFilterComponent: React.FC<Props> = ({
  className,
  currentUserRole,
  onChange,
  users,
  value,
}) => {
  const intl = useIntl();

  const availableUsers = useMemo(() => {
    return users.filter(
      (user) =>
        // owner can see everybody
        currentUserRole?.key === RoleEnum.OWNER ||
        // managers can see other managers and reps
        (currentUserRole?.key === RoleEnum.MANAGER && user.role.key !== RoleEnum.OWNER) ||
        // reps are visible to everybody
        user.role.key === RoleEnum.MEMBER
    );
  }, [currentUserRole, users]);

  return (
    <div className={cn(styles.container, className)}>
      <UserSelectField
        className={styles.selectField}
        defaultUsersOption={false}
        label={false}
        onChange={onChange}
        size="middle"
        users={availableUsers}
        value={value}
      />
      <TextWithInfo
        info={intl.formatMessage({
          id: "filters.viewAs.tooltip",
          defaultMessage:
            "Viewing as another user shows only the records they are permitted to see",
          description: "Tooltip in the view as filter",
        })}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUserRole: getRole(state),
});

export default connect(mapStateToProps, {})(ViewAsFilterComponent);
