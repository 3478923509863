import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import {
  Actions,
  fetchInvitationInfo,
  fetchUserDetailsSso,
  forgotPassword,
  requestAccountSso,
  resetErrors,
  resetPassword,
  signUp,
  signUpMember,
  ssoLogin,
  tryLogin,
  updateUserStatus,
  userExists,
} from "./actions";
import { createReducer } from "typesafe-actions";
import InvitationInfo from "../../types/auth/InvitationInfo";
import { defineMessage } from "react-intl";
import i18nService from "config/I18nService";
import getValidationErrors from "util/errorHandling/getValidationErrors";
import UserDetails from "types/auth/UserDetails";
import UserRequestStatus from "enum/sso/UserRequestStatus";

export type AuthState = {
  error: ApiError | undefined;
  forgotPasswordLoading: boolean;
  forgotPasswordSuccess: boolean;
  invalidUser: boolean;
  invitationInfo: InvitationInfo | undefined;
  invitationInfoLoading: boolean;
  linkExpired: boolean;
  loading: boolean;
  resetPasswordLinkExpired: boolean;
  resetPasswordLoading: boolean;
  resetPasswordSuccess: boolean;
  signupLoading: boolean;
  token: string | undefined; // also stored in the AuthService
  userDetails: UserDetails | undefined;
  userExists: boolean;
  userExistsLoading: boolean;
  userLimitExceeded: boolean;
  userRequestStatus: UserRequestStatus | undefined;
};

const initialState: AuthState = {
  error: undefined,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  invalidUser: false,
  invitationInfo: undefined,
  invitationInfoLoading: true, // loading by default to avoid "something went wrong" error blink
  linkExpired: false,
  loading: false,
  resetPasswordLinkExpired: false,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  signupLoading: false,
  token: undefined,
  userDetails: undefined,
  userExists: false,
  userExistsLoading: false,
  userLimitExceeded: false,
  userRequestStatus: undefined,
};

enum PasswordResetErrorCodes {
  LINK_EXPIRED = "v0326",
  USER_INVALID = "v0327",
}

enum SsoErrorCodes {
  USER_LIMIT_EXCEEDED = "v0755",
}

const unknownErrorMessage = defineMessage({
  id: "auth.api.error",
  defaultMessage: "Unknown error, please try again later",
  description: "Unknown error message",
});

const auth = createReducer<AuthState, Actions>(initialState)
  .handleAction(resetErrors, (state) => ({
    ...state,
    error: undefined,
    invalidUser: false,
    resetPasswordLinkExpired: false,
    resetPasswordSuccess: false,
    resetPasswordLoading: false,
    userLimitExceeded: false,
  }))
  .handleAction(tryLogin.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
    token: undefined,
  }))
  .handleAction(tryLogin.success, (state, action) => ({
    ...state,
    loading: false,
    token: action.payload,
  }))
  .handleAction(tryLogin.failure, (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError
        ? payload
        : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
    loading: false,
  }))
  .handleAction(forgotPassword.request, (state) => ({
    ...state,
    error: undefined,
    forgotPasswordLoading: true,
    forgotPasswordSuccess: false,
  }))
  .handleAction(forgotPassword.success, (state) => ({
    ...state,
    forgotPasswordLoading: false,
    forgotPasswordSuccess: true,
  }))
  .handleAction(forgotPassword.failure, (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError
        ? payload
        : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
    forgotPasswordLoading: false,
    forgotPasswordSuccess: false,
  }))
  .handleAction(resetPassword.request, (state) => ({
    ...state,
    error: undefined,
    resetPasswordLoading: true,
    resetPasswordSuccess: false,
  }))
  .handleAction(resetPassword.success, (state) => ({
    ...state,
    resetPasswordLoading: false,
    resetPasswordSuccess: true,
  }))
  .handleAction(resetPassword.failure, (state, { payload }) => {
    const validationErrors = getValidationErrors(payload);
    return {
      ...state,
      error:
        payload instanceof ApiError
          ? payload
          : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
      invalidUser: validationErrors.some(
        ({ code }) => code === PasswordResetErrorCodes.USER_INVALID
      ),
      resetPasswordLinkExpired: validationErrors.some(
        ({ code }) => code === PasswordResetErrorCodes.LINK_EXPIRED
      ),
      resetPasswordLoading: false,
    };
  })
  .handleAction([signUp.request, signUpMember.request], (state) => ({
    ...state,
    error: undefined,
    signUpLoading: true,
  }))
  .handleAction([signUp.success, signUpMember.success], (state) => ({
    ...state,
    signUpLoading: false,
  }))
  .handleAction([signUp.failure, signUpMember.failure], (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError
        ? payload
        : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
    signUpLoading: false,
  }))
  .handleAction(userExists.request, (state) => ({
    ...state,
    error: undefined,
    userExistsLoading: true,
  }))
  .handleAction(userExists.success, (state, action) => ({
    ...state,
    userExists: action.payload,
    userExistsLoading: false,
  }))
  .handleAction(userExists.failure, (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError
        ? payload
        : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
    userExistsLoading: false,
  }))
  .handleAction(fetchInvitationInfo.request, (state) => ({
    ...state,
    invitationInfoError: undefined,
    invitationInfoLoading: true,
  }))
  .handleAction(fetchInvitationInfo.success, (state, action) => ({
    ...state,
    invitationInfo: action.payload,
    invitationInfoLoading: false,
  }))
  .handleAction(fetchInvitationInfo.failure, (state) => ({
    ...state,
    invitationInfoLoading: false,
  }))
  .handleAction(ssoLogin.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(ssoLogin.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(ssoLogin.failure, (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError
        ? payload
        : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
    loading: false,
  }))
  .handleAction(requestAccountSso.failure, (state, { payload }) => {
    const validationErrors = getValidationErrors(payload);

    return {
      ...state,
      error:
        payload instanceof ApiError
          ? payload
          : new ApiError(i18nService.formatMessage(unknownErrorMessage)),
      userLimitExceeded: validationErrors.some(
        ({ code }) => code === SsoErrorCodes.USER_LIMIT_EXCEEDED
      ),
    };
  })
  .handleAction(fetchUserDetailsSso.success, (state, { payload }) => ({
    ...state,
    userDetails: payload,
  }))
  .handleAction(fetchUserDetailsSso.failure, (state) => ({
    ...state,
    linkExpired: true,
  }))
  .handleAction(updateUserStatus.success, (state, { payload }) => ({
    ...state,
    userDetails: payload.userDetails,
    userRequestStatus: payload.status,
  }))
  .handleAction(updateUserStatus.failure, (state, { payload }) => ({
    ...state,
    linkExpired: true,
  }));

export * from "./selectors";
export type AuthActions = Actions;
export default auth;
