import FieldModel from "./impl/FieldModel";
import DateTimeField from "./impl/DateTimeField";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import GroupsField from "./impl/GroupsField";
import MapLocationField from "./impl/MapLocationField";
import NotesField from "./impl/NotesField";
import { createField } from "./impl/fieldModelUtil";
import NameField from "./impl/NameField";
import UniqueIdField from "./impl/UniqueIdField";
import ColorField from "./impl/ColorField";
import UserField from "./impl/UserField";
import CountryField from "./impl/CountryField";
import DateField from "./impl/DateField";
import FrequencyStatusField from "./impl/FrequencyStatusField";
import NoContactField from "./impl/NoContactField";
import TeamField from "./impl/TeamField";
import ViewAsField from "./impl/ViewAsField";
import FrequencyIntervalField from "./impl/FrequencyIntervalField";
import NestedAddressField from "./impl/NestedAddressField";
import SourceField from "./impl/SourceField";
import { moneyFormatter } from "util/fieldModel/impl/fieldUtil";
import { Company, EntityType } from "@mapmycustomers/shared/types/entity";
import getEntityFilter, { ENTITY_FILTER_OPERATORS } from "../filters/EntityFilter";
import { defineMessages } from "react-intl";
import RoutesField from "./impl/RoutesField";
import TerritoryField from "util/fieldModel/impl/TerritoryField";
import FrequencyDaysLeft from "./impl/FrequencyDaysLeft";
import AddressField from "util/fieldModel/impl/AddressField";

export enum CompanyFieldName {
  NAME = "name",
  ADDRESS = "address",
  CITY = "city",
  REGION = "region",
  POSTAL_CODE = "postalCode",
  COUNTRY = "countryCode",
  PHONE = "phone",
  WEBSITE = "website",
  CRM_LINK = "crmLink",
  EMAIL = "email",
  FILES = "files",
  GROUPS = "groups",
  FREQUENCY_INTERVAL = "cadenceInterval",
  COLOR = "color",
  ANNUAL_REVENUE = "annualRevenue",
  NUM_EMPLOYEES = "numEmployees",
  CREATOR = "creator",
  CREATOR_NAME = "creatorName",
  CREATOR_EMAIL = "creator.username",
  USER = "user",
  OWNER_NAME = "ownerName",
  OWNER_EMAIL = "user.username",
  NEXT_ACTIVITY = "upcomingActivity.startAt",
  LAST_ACTIVITY = "lastCompletedActivity.completedAt",
  FREQUENCY_STATUS = "cadenceDaysOut",
  DAYS_LEFT_IN_FREQUENCY = "daysLeftInFrequency",
  NO_CONTACT_IN = "noContactDaysOut",
  NOTES = "notes",
  UNIQUE_ID = "importId",
  GEOPOINT = "geoPoint",
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  SOURCE = "sourceCreated",
  PARENT_ACCOUNT = "parentAccount",
  PARENT_ACCOUNT_ADDRESS = "parentAccount.address",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  TEAM = "team",
  TERRITORIES = "territories",
  VIEW_AS = "viewAs",
  ROUTES = "routes",
  PARENT_ACCOUNT_IMPORT_ID = "parentAccount.importId",
}

const displayNames = defineMessages<CompanyFieldName>({
  [CompanyFieldName.NAME]: {
    id: "company.field.name",
    defaultMessage: "Name",
    description: "Name field of company",
  },
  [CompanyFieldName.ADDRESS]: {
    id: "company.field.address",
    defaultMessage: "Street",
    description: "Street field of company",
  },
  [CompanyFieldName.CITY]: {
    id: "company.field.city",
    defaultMessage: "City",
    description: "City field of company",
  },
  [CompanyFieldName.REGION]: {
    id: "company.field.region",
    defaultMessage: "State/Region",
    description: "State/Region field of company",
  },
  [CompanyFieldName.POSTAL_CODE]: {
    id: "company.field.postalCode",
    defaultMessage: "Postal Code",
    description: "Postal Code field of company",
  },
  [CompanyFieldName.COUNTRY]: {
    id: "company.field.country",
    defaultMessage: "Country",
    description: "Country field of company",
  },
  [CompanyFieldName.PHONE]: {
    id: "company.field.phone",
    defaultMessage: "Phone",
    description: "Phone field of company",
  },
  [CompanyFieldName.WEBSITE]: {
    id: "company.field.website",
    defaultMessage: "Website",
    description: "Website field of company",
  },
  [CompanyFieldName.CRM_LINK]: {
    id: "company.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM Link field of company",
  },
  [CompanyFieldName.EMAIL]: {
    id: "company.field.email",
    defaultMessage: "Email",
    description: "Email field of company",
  },
  [CompanyFieldName.FILES]: {
    id: "company.field.files",
    defaultMessage: "Files",
    description: "Files field of company",
  },
  [CompanyFieldName.GROUPS]: {
    id: "company.field.groups",
    defaultMessage: "Group",
    description: "Groups field of company",
  },
  [CompanyFieldName.FREQUENCY_INTERVAL]: {
    id: "company.field.frequencyInterval",
    defaultMessage: "Frequency Interval",
    description: "Frequency Interval field of company",
  },
  [CompanyFieldName.COLOR]: {
    id: "company.field.color",
    defaultMessage: "Color",
    description: "Color field of company",
  },
  [CompanyFieldName.ANNUAL_REVENUE]: {
    id: "company.field.annualRevenue",
    defaultMessage: "Annual Revenue",
    description: "Annual Revenue field of company",
  },
  [CompanyFieldName.NUM_EMPLOYEES]: {
    id: "company.field.numEmployees",
    defaultMessage: "Number of Employees",
    description: "Number of Employees field of company",
  },
  [CompanyFieldName.CREATOR]: {
    id: "company.field.creator",
    defaultMessage: "Created by",
    description: "Creator field of company",
  },
  [CompanyFieldName.CREATOR_NAME]: {
    id: "company.field.creatorName",
    defaultMessage: "Creator Name",
    description: "Creator Name field of company",
  },
  [CompanyFieldName.CREATOR_EMAIL]: {
    id: "company.field.creatorEmail",
    defaultMessage: "Creator Email",
    description: "Creator Email field of company",
  },
  [CompanyFieldName.USER]: {
    id: "company.field.user",
    defaultMessage: "Owner",
    description: "Owner field of company",
  },
  [CompanyFieldName.OWNER_NAME]: {
    id: "company.field.ownerName",
    defaultMessage: "Owner Name",
    description: "Owner Name field of company",
  },
  [CompanyFieldName.OWNER_EMAIL]: {
    id: "company.field.ownerEmail",
    defaultMessage: "Owner Email",
    description: "Owner Email field of company",
  },
  [CompanyFieldName.NEXT_ACTIVITY]: {
    id: "company.field.nextActivity",
    defaultMessage: "Date of Next Activity",
    description: "Next Activity field of company",
  },
  [CompanyFieldName.LAST_ACTIVITY]: {
    id: "company.field.lastActivity",
    defaultMessage: "Date of Last Done Activity",
    description: "Date of Last Done Activity field of company",
  },
  [CompanyFieldName.FREQUENCY_STATUS]: {
    id: "company.field.frequencyStatus",
    defaultMessage: "Frequency Status",
    description: "Frequency Status field of company",
  },
  [CompanyFieldName.NO_CONTACT_IN]: {
    id: "company.field.noContactIn",
    defaultMessage: "No Contact In",
    description: "No Contact In field of company",
  },
  [CompanyFieldName.NOTES]: {
    id: "company.field.notes",
    defaultMessage: "Notes",
    description: "Notes field of company",
  },
  [CompanyFieldName.UNIQUE_ID]: {
    id: "company.field.uniqueId",
    defaultMessage: "Unique ID",
    description: "Unique Id field of company",
  },
  [CompanyFieldName.GEOPOINT]: {
    id: "company.field.geopoint",
    defaultMessage: "Location",
    description: "Location field of company",
  },
  [CompanyFieldName.LATITUDE]: {
    id: "company.field.latitude",
    defaultMessage: "Latitude",
    description: "Latitude field of company",
  },
  [CompanyFieldName.LONGITUDE]: {
    id: "company.field.longitude",
    defaultMessage: "Longitude",
    description: "Longitude field of company",
  },
  [CompanyFieldName.SOURCE]: {
    id: "company.field.source",
    defaultMessage: "Source",
    description: "Source field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT]: {
    id: "company.field.parentCompany",
    defaultMessage: "Parent Company",
    description: "Parent Company field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT_ADDRESS]: {
    id: "company.field.parentCompanyAddress",
    defaultMessage: "Parent Company Address",
    description: "Parent Company Address field of company",
  },
  [CompanyFieldName.CREATED_AT]: {
    id: "company.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created At field of company",
  },
  [CompanyFieldName.UPDATED_AT]: {
    id: "company.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of company",
  },
  [CompanyFieldName.TEAM]: {
    id: "company.field.team",
    defaultMessage: "Team",
    description: "Team field of company",
  },
  [CompanyFieldName.TERRITORIES]: {
    id: "company.field.territories",
    defaultMessage: "Territories",
    description: "Territories field of company",
  },
  [CompanyFieldName.VIEW_AS]: {
    id: "company.field.viewAs",
    defaultMessage: "View As",
    description: "View As field of company",
  },
  [CompanyFieldName.ROUTES]: {
    id: "company.field.routes",
    defaultMessage: "Routes",
    description: "Routes field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT_IMPORT_ID]: {
    id: "company.field.parentAccount.importId",
    defaultMessage: "Parent Company ID",
    description: " Parent Company Id field of company",
  },
  [CompanyFieldName.DAYS_LEFT_IN_FREQUENCY]: {
    id: "company.field.daysLeftInFrequency",
    defaultMessage: "Days Left In Frequency",
    description: " Days Left In Frequency field of company",
  },
});

const additionalDisplayNames = defineMessages({
  address: {
    id: "company.field.street.fieldManagementName",
    defaultMessage: "Address",
    description: "Field Management name for the street field of company",
  },
  parentCompanyId: {
    id: "company.field.parentAccount.importName",
    defaultMessage: "Parent Company - ID",
    description: "Import name for the parentAccount field of company",
  },
});

const companyFieldModel = new FieldModel(
  [
    new NameField({
      name: CompanyFieldName.NAME,
      displayName: displayNames[CompanyFieldName.NAME],
      importProperties: { required: true },
      formProperties: { fullWidth: false },
    }),
    new AddressField({
      name: CompanyFieldName.ADDRESS,
      displayName: displayNames[CompanyFieldName.ADDRESS],
      displayOrder: 1,
      fieldManagementDisplayName: additionalDisplayNames.address,
    }),
    {
      name: "address_bulk",
      displayName: displayNames[CompanyFieldName.ADDRESS],
      type: FieldType.STRING,
      platformFilterName: CompanyFieldName.ADDRESS,
      features: [
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.BULK_EDIT,
        FieldFeature.BULK_ADDRESS,
      ],
    },
    {
      name: CompanyFieldName.CITY,
      displayName: displayNames[CompanyFieldName.CITY],
      displayOrder: 2,
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
    },
    {
      name: CompanyFieldName.REGION,
      displayName: displayNames[CompanyFieldName.REGION],
      displayOrder: 3,
      type: FieldType.STRING,
      features: [
        FieldFeature.REGION_FIELD,
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
    },
    {
      name: CompanyFieldName.POSTAL_CODE,
      displayName: displayNames[CompanyFieldName.POSTAL_CODE],
      displayOrder: 5,
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
    },
    new CountryField({
      name: CompanyFieldName.COUNTRY,
      displayName: displayNames[CompanyFieldName.COUNTRY],
      platformFilterName: "countryCode",
      displayOrder: 4,
    }),
    {
      name: CompanyFieldName.PHONE,
      displayName: displayNames[CompanyFieldName.PHONE],
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      customGridProperties: {
        cellRenderer: "phoneCellRenderer",
      },
    },
    {
      name: CompanyFieldName.WEBSITE,
      displayName: displayNames[CompanyFieldName.WEBSITE],
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      customGridProperties: {
        cellRenderer: "websiteCellRenderer",
      },
    },
    {
      name: CompanyFieldName.CRM_LINK,
      displayName: displayNames[CompanyFieldName.CRM_LINK],
      type: FieldType.STRING,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.SORTABLE,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DETAILS,
      ],
      customGridProperties: {
        cellRenderer: "crmLinkCellRenderer",
      },
    },
    {
      name: CompanyFieldName.EMAIL,
      displayName: displayNames[CompanyFieldName.EMAIL],
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      customGridProperties: {
        cellRenderer: "emailCellRenderer",
      },
    },
    {
      name: CompanyFieldName.FILES,
      displayName: displayNames[CompanyFieldName.FILES],
      type: FieldType.LIST,
      features: [
        FieldFeature.FILE_FIELD,
        // not visible anywhere, except in forms
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
      ],
      formProperties: {
        fullWidth: true,
      },
    },
    new GroupsField({
      name: CompanyFieldName.GROUPS,
      displayName: displayNames[CompanyFieldName.GROUPS],
    }),
    new FrequencyIntervalField({
      name: CompanyFieldName.FREQUENCY_INTERVAL,
      displayName: displayNames[CompanyFieldName.FREQUENCY_INTERVAL],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.ENGAGEMENT,
        FieldFeature.BULK_EDIT,
        FieldFeature.FREQUENCY_INTERVAL,
        FieldFeature.FILTERABLE,
        FieldFeature.NUMERIC,
        FieldFeature.FILTERABLE_ON_MAP,
      ],
    }),
    new FrequencyStatusField({
      name: CompanyFieldName.FREQUENCY_STATUS,
      displayName: displayNames[CompanyFieldName.FREQUENCY_STATUS],
      displayOrder: 13,
    }),
    new ColorField({
      name: CompanyFieldName.COLOR,
      displayName: displayNames[CompanyFieldName.COLOR],
    }),
    {
      name: CompanyFieldName.ANNUAL_REVENUE,
      displayName: displayNames[CompanyFieldName.ANNUAL_REVENUE],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NUMERIC,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.MONETARY_VALUE,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      valueFormatter: moneyFormatter,
    },
    {
      name: CompanyFieldName.NUM_EMPLOYEES,
      displayName: displayNames[CompanyFieldName.NUM_EMPLOYEES],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NUMERIC,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      formProperties: {
        numericOptions: { min: 1 },
      },
    },
    new UserField({
      name: CompanyFieldName.CREATOR,
      displayName: displayNames[CompanyFieldName.CREATOR],
      platformFilterName: "createdBy",
      platformName: "createdBy",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.DATA_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    new UserField({
      name: CompanyFieldName.CREATOR_NAME,
      filterName: "creator",
      exportColumnName: "creator",
      displayName: displayNames[CompanyFieldName.CREATOR_NAME],
      platformFilterName: "createdBy",
      valueGetter: (entity: unknown) => (entity as Company).creator,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    {
      name: CompanyFieldName.CREATOR_EMAIL,
      displayName: displayNames[CompanyFieldName.CREATOR_EMAIL],
      type: FieldType.STRING,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new UserField({
      name: CompanyFieldName.USER,
      displayName: displayNames[CompanyFieldName.USER],
      platformFilterName: "userId",
      platformName: "userId",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    new UserField({
      name: CompanyFieldName.OWNER_NAME,
      filterName: "user",
      exportColumnName: "user",
      displayName: displayNames[CompanyFieldName.OWNER_NAME],
      platformFilterName: "userId",
      valueGetter: (entity: unknown) => (entity as Company).user,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    {
      name: CompanyFieldName.OWNER_EMAIL,
      displayName: displayNames[CompanyFieldName.OWNER_EMAIL],
      type: FieldType.STRING,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    {
      name: "parentAccount.importId",
      displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT_IMPORT_ID],
      type: FieldType.STRING,
      valueGetter: ["parentAccount", "importId"],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new DateField({
      name: CompanyFieldName.NEXT_ACTIVITY,
      displayName: displayNames[CompanyFieldName.NEXT_ACTIVITY],
      displayOrder: 11,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
    }),
    new DateField({
      name: CompanyFieldName.LAST_ACTIVITY,
      displayName: displayNames[CompanyFieldName.LAST_ACTIVITY],
      displayOrder: 12,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
    }),
    new NoContactField({
      name: CompanyFieldName.NO_CONTACT_IN,
      displayName: displayNames[CompanyFieldName.NO_CONTACT_IN],
      displayOrder: 14,
    }),
    new NotesField({
      name: CompanyFieldName.NOTES,
      displayName: displayNames[CompanyFieldName.NOTES],
    }),
    new UniqueIdField({
      name: CompanyFieldName.UNIQUE_ID,
      displayName: displayNames[CompanyFieldName.UNIQUE_ID],
    }),
    new MapLocationField({
      name: CompanyFieldName.GEOPOINT,
      displayName: displayNames[CompanyFieldName.GEOPOINT],
      displayOrder: 6,
    }),
    {
      name: CompanyFieldName.LATITUDE,
      displayName: displayNames[CompanyFieldName.LATITUDE],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    {
      name: CompanyFieldName.LONGITUDE,
      displayName: displayNames[CompanyFieldName.LONGITUDE],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new SourceField({
      name: CompanyFieldName.SOURCE,
      displayName: displayNames[CompanyFieldName.SOURCE],
    }),
    {
      name: CompanyFieldName.PARENT_ACCOUNT,
      displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT],
      platformName: "parentAccountId",
      type: FieldType.STRING,
      filterType: FieldType.ID,
      platformFilterName: "parentAccount.id",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SORTABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.RELATIONSHIPS,
      ],
      valueGetter: ["parentAccount", "name"],
      importProperties: {
        bottomPosition: true,
        displayName: additionalDisplayNames.parentCompanyId,
        required: false,
      },
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.COMPANY) },
        operators: ENTITY_FILTER_OPERATORS,
      },
    },
    new NestedAddressField(
      {
        name: CompanyFieldName.PARENT_ACCOUNT_ADDRESS,
        displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT_ADDRESS],
      },
      ["parentAccount"]
    ),
    new DateTimeField({
      name: CompanyFieldName.CREATED_AT,
      displayName: displayNames[CompanyFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
    new DateTimeField({
      name: CompanyFieldName.UPDATED_AT,
      displayName: displayNames[CompanyFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
    new TeamField({
      name: CompanyFieldName.TEAM,
      displayName: displayNames[CompanyFieldName.TEAM],
      platformFilterName: "teamId",
    }),
    new TerritoryField({
      name: CompanyFieldName.TERRITORIES,
      displayName: displayNames[CompanyFieldName.TERRITORIES],
    }),
    // filter-only fields:
    new ViewAsField({
      name: CompanyFieldName.VIEW_AS,
      displayName: displayNames[CompanyFieldName.VIEW_AS],
    }),
    new RoutesField({
      name: CompanyFieldName.ROUTES,
      displayName: displayNames[CompanyFieldName.ROUTES],
    }),
    new FrequencyDaysLeft({
      name: CompanyFieldName.DAYS_LEFT_IN_FREQUENCY,
      displayName: displayNames[CompanyFieldName.DAYS_LEFT_IN_FREQUENCY],
    }),
  ].map(createField)
);

export default companyFieldModel;
