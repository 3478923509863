import { useSlate } from "slate-react";
import { useCallback, useMemo } from "react";
import { Editor, Text, Transforms } from "slate";
import LinkEditModal from "./LinkEditModal";

interface Props {
  defaultText?: string;
  onHide?: () => void;
}

const CreateLink: React.FC<Props> = ({ defaultText, onHide }) => {
  const editor = useSlate();

  const isMultiLine = useMemo(() => {
    return !!(
      defaultText &&
      editor.selection?.anchor.path[0] !== editor.selection?.focus.path[0]
    );
  }, [defaultText, editor.selection]);

  const handleOk = useCallback(
    (name: string, url: string) => {
      Editor.addMark(editor, "link", url);
      const [link] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Text.isText(n) && !!n.link,
      });
      const textNode = link?.[0] ?? { text: name, link: url };
      if (Text.isText(textNode)) {
        Transforms.insertNodes(editor, [
          { ...textNode, text: name },
          { text: "" },
        ]);
      }
      onHide?.();
    },
    [onHide, editor]
  );

  return (
    <LinkEditModal
      onHide={onHide}
      onConfirm={handleOk}
      defaultText={
        editor.selection ? Editor.string(editor, editor.selection) : ""
      }
      textDisabled={isMultiLine}
    />
  );
};

export default CreateLink;
