import React from "react";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import getCountryFlag from "@mapmycustomers/shared/util/countries/getCountryFlag";
import styles from "./CountryWithFlag.module.scss";
import { useConfigProvider } from "../ConfigProvider";

interface Props {
  countryCode: CountryCode;
}

const CountryWithFlag: React.FC<Props> = ({ countryCode }) => {
  const configProvider = useConfigProvider();
  return (
    <div className={styles.container}>
      <div className={styles.flag}>{getCountryFlag(countryCode)}</div>
      <div>{configProvider.formatCountryName(countryCode)}</div>
    </div>
  );
};

export default CountryWithFlag;
