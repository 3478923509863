import React from "react";
import Select from "antd/es/select";
import { Labeled, SelectField } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import { SizeType } from "antd/es/config-provider/SizeContext";
import styles from "./CommonStyles.module.scss";

export type VisitType = "optimized" | "ordered";

interface Props {
  onChange: (visitOrder: VisitType) => void;
  size?: SizeType;
  value: VisitType;
}

const VisitOrder: React.FC<Props> = ({ onChange, size, value }) => {
  const intl = useIntl();

  return (
    <Labeled
      label={intl.formatMessage({
        id: "createRouteForm.field.visitOrder",
        defaultMessage: "* Visit Order",
        description: "Visit order field on the Create Route form",
      })}
    >
      <SelectField<VisitType>
        className={styles.fullWidth}
        dropdownMatchSelectWidth={false}
        onChange={onChange}
        size={size}
        value={value}
      >
        <Select.Option value="optimized">
          {intl.formatMessage({
            id: "createRouteForm.field.visitOrder.option.optimize",
            defaultMessage: "Optimize Route",
            description: "Optimize Route option of the Visit order field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value="ordered">
          {intl.formatMessage({
            id: "createRouteForm.field.visitOrder.option.ordered",
            defaultMessage: "Visit Route in order",
            description:
              "Visit Route in order option of the Visit order field on the Create Route form",
          })}
        </Select.Option>
      </SelectField>
    </Labeled>
  );
};

export default VisitOrder;
