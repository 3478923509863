import { EntityPin } from "@mapmycustomers/shared/types/map";
import ColorDefinition from "./types/ColorDefinition";
import colors from "styles/_colors.module.scss";
import anyColorToHex from "util/colors/anyColorToHex";
import getContrastColor from "util/colors/getContrastColor";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";

export const defaultColorBuilder = (color: AnyColor | null | undefined): ColorDefinition => {
  const fillColor = color ? anyColorToHex(color) : colors.slate;
  return {
    color: getContrastColor(fillColor) === "black" ? colors.slate : colors.white,
    fillColor,
  };
};

const defaultColorGetter = (entityPin: EntityPin): ColorDefinition =>
  defaultColorBuilder(entityPin.data.color);

export default defaultColorGetter;
