import EntityType from "../enum/EntityType";
import {
  AnyEntity,
  Company,
  Deal,
  EntityTypesSupportedByActivities,
  Person,
} from "@mapmycustomers/shared/types/entity";
import GeoPoint from "../types/shapes/GeoPoint";
import MonetaryValue from "../types/customField/MonetaryValue";
import ApiError from "./api/ApiError";
import {
  Cluster,
  CompanyData,
  DealData,
  EntityPin,
  MapEntry,
  MultiPin,
  PersonData,
} from "../types/map";

export const isDefined = <T>(value: T | undefined): value is T =>
  value !== undefined;
export const isNotDefined = <T>(value: T | undefined): value is undefined =>
  value === undefined;
export const isNotEmpty = <T>(value: T | undefined | null): value is T =>
  value != null;

// assertion of entity type based on the given entityType. Object itself is not checked!!!
export const isCompany = (
  entity?: AnyEntity,
  entityType?: EntityType
): entity is Company => entityType === EntityType.COMPANY && isDefined(entity);
export const isPerson = (
  entity?: AnyEntity,
  entityType?: EntityType
): entity is Person => entityType === EntityType.PERSON && isDefined(entity);
export const isDeal = (
  entity?: AnyEntity,
  entityType?: EntityType
): entity is Deal => entityType === EntityType.DEAL && isDefined(entity);

export const isMapped = <T extends object | undefined>(
  entity: T
): entity is T & { geoPoint: GeoPoint } =>
  entity != null && "geoPoint" in entity && !!(entity as any).geoPoint;

export const isMonetaryValue = (value: any): value is MonetaryValue =>
  value &&
  typeof value === "object" &&
  "currencyId" in value &&
  "value" in value;

export const isApiError = (err: unknown): err is ApiError =>
  err instanceof ApiError;

export const isCluster = (entry: MapEntry): entry is Cluster =>
  entry.count > 1 && !entry.multiPin;
export const isMultiPin = (entry: MapEntry): entry is MultiPin =>
  entry.count > 1 && entry.multiPin;
export const isEntityPin = (entry: MapEntry): entry is EntityPin =>
  entry.count === 1 && !entry.multiPin;
export const isCompanyPin = (
  entry: MapEntry
): entry is EntityPin<CompanyData> =>
  isEntityPin(entry) && entry.data.entity === EntityType.COMPANY;
export const isDealPin = (entry: MapEntry): entry is EntityPin<DealData> =>
  isEntityPin(entry) && entry.data.entity === EntityType.DEAL;
export const isPersonPin = (entry: MapEntry): entry is EntityPin<PersonData> =>
  isEntityPin(entry) && entry.data.entity === EntityType.PERSON;

export const doesEntitySupportActivity = (
  entityType: EntityType | undefined
): entityType is EntityTypesSupportedByActivities =>
  !!entityType &&
  [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL].includes(entityType);
