import {
  Actions,
  clearAllUploadedPersonFiles,
  createPerson,
  hideGlobalCreatePersonModal,
  initialize,
  removePersonFile,
  resetParentCompany,
  showGlobalCreatePersonModal,
  uploadPersonFiles,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { Company } from "@mapmycustomers/shared/types/entity";
import FileListItem from "types/FileListItem";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";

export interface CreatePersonModalState {
  availableCompanies: Company[];
  availableCompaniesLoading: boolean;
  createLoading: boolean;
  duplicates: DuplicateEntity[];
  filesAdded: FileListItem[];
  fileGroupId: string;
  filesUploading: boolean;
  initializeLoading: boolean;
  isGlobalAddPerson?: boolean;
  parentCompany?: Company;
  visible: boolean;
}

const initialState: CreatePersonModalState = {
  availableCompanies: [],
  availableCompaniesLoading: false,
  createLoading: false,
  duplicates: [],
  filesAdded: [],
  fileGroupId: "",
  filesUploading: false,
  initializeLoading: false,
  visible: false,
};

const createPersonModal = createReducer<CreatePersonModalState, Actions>(initialState)
  .handleAction(showGlobalCreatePersonModal, (state, action) => ({
    ...state,
    isGlobalAddPerson: action.payload?.showPostCreatePrompt,
    parentCompany: undefined,
    visible: true,
  }))
  .handleAction(hideGlobalCreatePersonModal, (state) => ({
    ...state,
    isGlobalAddPerson: false,
    visible: false,
  }))
  .handleAction(initialize.request, (state) => ({
    ...state,
    duplicates: [],
    filesAdded: [],
    initializeLoading: true,
  }))
  .handleAction(initialize.success, (state, action) => ({
    ...state,
    initializeLoading: false,
    parentCompany: action.payload?.parentCompany,
  }))
  .handleAction(initialize.failure, (state) => ({
    ...state,
    initializeLoading: false,
  }))
  .handleAction(createPerson.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction(createPerson.success, (state) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(createPerson.failure, (state, { payload }) => ({
    ...state,
    duplicates: payload.duplicates ?? [],
    createLoading: false,
  }))
  .handleAction(resetParentCompany, (state) => ({
    ...state,
    parentCompany: undefined,
  }))
  .handleAction(uploadPersonFiles.request, (state, action) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadPersonFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadPersonFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removePersonFile.request, (state, action) => ({
    ...state,
  }))
  .handleAction(removePersonFile.success, (state, action) => ({
    ...state,
    //Removing the deleted file from store
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
  }))
  .handleAction(removePersonFile.failure, (state) => ({
    ...state,
  }))
  .handleAction(clearAllUploadedPersonFiles, (state) => ({
    ...state,
    files: [],
    filesUploading: false,
  }));

export * from "./selectors";
export type CreatePersonModalActions = Actions;
export default createPersonModal;
