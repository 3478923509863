import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchNoContactInCardData, fetchNoContactInCountCardData } from "./actions";
import { getOrganizationId, isBigOrganization } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import { AnyEntity, EntityType, MapEntity } from "@mapmycustomers/shared/types/entity";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { getMapViewSettings } from "store/map";
import ViewMode from "enum/dashboard/ViewMode";
import { MapEntry } from "@mapmycustomers/shared/types/map";
import categorizeMapEntries from "util/map/categorizeMapEnties";
import PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";
import getPrecision from "scene/map/utils/getPrecision";
import EntityTypesSupportedInNoContactIn from "types/dashboard/cards/noContactIn/EntityTypesSupportedInNoContactIn";
import { ApiMethodName } from "store/api/ApiService";
import { CompanyFieldName } from "util/fieldModel/CompanyFieldModel";
import { PersonFieldName } from "util/fieldModel/PersonFieldModel";
import { DealFieldName } from "util/fieldModel/DealFieldModel";
import {
  applyNoContactInDrillDownListViewSettings,
  exportNoContactInCardDrillDownData,
  fetchNoContactInCardDrillDownData,
  showNoContactInDrillDown,
} from "scene/dashboard/store/cards/noContactIn/actions";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import loggingService from "util/logging";
import {
  getNoContactInDrillDownTotalFilteredRecords,
  getNoContactInDrillDownViewStates,
} from "scene/dashboard/store/cards/noContactIn/selectors";
import localSettings from "config/LocalSettings";
import FieldModel from "util/fieldModel/impl/FieldModel";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import { exportEntities } from "store/exportEntities/actions";
import { NO_CONTACT_IN_ENTITY_TYPES } from "scene/dashboard/components/cards/NoContactIn/consts";
import { fetchNoContactInCountCardDataHelper } from "store/dashboard/cardDataFetchHelpers";

const LIST_PAGE_SIZE = 20;

const fetchMethodByEntityType: Record<EntityTypesSupportedInNoContactIn, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

const defaultColumns: Record<EntityTypesSupportedInNoContactIn, string[]> = {
  [EntityType.COMPANY]: [
    CompanyFieldName.NAME,
    CompanyFieldName.USER,
    CompanyFieldName.ADDRESS,
    CompanyFieldName.NO_CONTACT_IN,
    CompanyFieldName.SOURCE,
  ],
  [EntityType.PERSON]: [
    PersonFieldName.NAME,
    PersonFieldName.USER,
    PersonFieldName.ADDRESS,
    PersonFieldName.NO_CONTACT_IN,
    PersonFieldName.SOURCE,
  ],
  [EntityType.DEAL]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.NO_CONTACT_IN,
    DealFieldName.SOURCE,
  ],
};

const defaultSortColumn: Record<EntityTypesSupportedInNoContactIn, string> = {
  [EntityType.COMPANY]: CompanyFieldName.LAST_ACTIVITY,
  [EntityType.PERSON]: PersonFieldName.LAST_ACTIVITY,
  [EntityType.DEAL]: DealFieldName.LAST_ACTIVITY,
};

function* onFetchNoContactInCardData({ payload }: ReturnType<typeof fetchNoContactInCardData>) {
  const { configuration, callback, failureCallback, scope, viewMode, viewport, offset } = payload;
  const { criteria, dateRange } = configuration;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getMapViewSettings);

    const bigOrganization: boolean = yield select(isBigOrganization);

    const entityTypes =
      Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
        ? criteria.entityTypes
        : NO_CONTACT_IN_ENTITY_TYPES;

    const payload: PlatformMapRequest = {
      $filters: {
        $and: [
          { noContactDaysOut: dateRange.days === null ? null : { $gt: dateRange.days } },
          ...(!scope?.userId && scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
          ...(scope?.userId ? [{ userId: { $in: [scope.userId] } }] : []),
          ...(criteria.sources?.length ? [{ source: { $in: criteria.sources } }] : []),
        ],
        cadence: true,
        entities: entityTypes.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: {
              includeCustomFields: true,
              includeGroups: true,
              includeNotes: true,
              includeRoutes: true,
              includeTerritories: true,
            },
          }),
          {}
        ),
        includeCustomFields: true,
        pinLegends: entityTypes.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: {
              color: mapViewState.colorKey![entityType],
              shape: mapViewState.shapeKey![entityType],
            },
          }),
          {}
        ),
        ...(viewMode === ViewMode.MAP
          ? {
              bounds: viewport?.bounds,
              precision: getPrecision(viewport?.zoom ?? 1, bigOrganization),
              precisionThreshold: 1000,
            }
          : {}),
      },
      $order: "-noContactDaysOut",
      $offset: offset,
      $limit: viewMode === ViewMode.LIST ? LIST_PAGE_SIZE : undefined,
    };

    if (viewMode === ViewMode.MAP) {
      const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, payload);
      yield call(callback, {
        categorizedMapEntries: categorizeMapEntries(response.data),
        total: response.total,
      });
    } else {
      const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, payload);
      yield call(callback, { entities: response.data, total: response.total });
    }
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onFetchNoContactInCountCardData({
  payload,
}: ReturnType<typeof fetchNoContactInCountCardData>) {
  const { configuration, callback, failureCallback, scope } = payload;
  try {
    yield call(fetchNoContactInCountCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showNoContactInDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria } = configuration;

  // TODO: read saved viewState from somewhere

  const viewStates = {} as Record<EntityTypesSupportedInNoContactIn, DrillDownViewState>;

  const entityTypes =
    Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
      ? criteria.entityTypes
      : NO_CONTACT_IN_ENTITY_TYPES;

  entityTypes.forEach((entityType) => {
    const fieldModel = getFieldModelByEntityType(entityType);

    const viewState: DrillDownViewState = {
      columns: [
        ...defaultColumns[entityType]
          .map((name) => fieldModel.getByName(name))
          .filter(isDefined)
          .map((field) => ({
            field,
            visible: true,
            pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
            width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
          })),
        ...fieldModel.sortedFields
          .filter((field) => !field.hasFeature(FieldFeature.NON_LIST_VIEW))
          .filter((field) => !defaultColumns[entityType].includes(field.name))
          .map((field) => ({
            field,
            visible: false,
            pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
            width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
          })),
      ],
      range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
      filter: {},
      sort: [
        {
          field: fieldModel.getByName(defaultSortColumn[entityType])!,
          order: SortOrder.DESC,
        },
      ],
      viewAs: undefined,
    };

    const isTeamScope = !!scope?.teamId && !scope?.userId;

    const filter: FilterModel = {
      noContactDaysOut:
        configuration.dateRange.days === null
          ? { operator: FilterOperator.EMPTY, value: undefined }
          : { operator: FilterOperator.GREATER_THAN, value: configuration.dateRange.days },
    };
    if (isTeamScope) {
      filter.team = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
    }
    if (scope?.userId) {
      filter.user = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
    }
    if (criteria.sources.length) {
      filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
    }
    viewState.filter = filter;

    viewStates[entityType] = viewState;
  });

  yield put(showNoContactInDrillDown.success({ viewStates }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchNoContactInCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: no contact in card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(
        applyNoContactInDrillDownListViewSettings({
          ...payload.request,
          entityType: payload.entityType,
        })
      );
    }

    const drillDownViewStates: Record<EntityTypesSupportedInNoContactIn, DrillDownViewState> =
      yield select(getNoContactInDrillDownViewStates);
    const drillDownViewState = drillDownViewStates[payload.entityType];

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(
        drillDownViewState,
        `dashboard/noContactIn/${payload.entityType}`
      );
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const fieldModel: FieldModel = yield call(getFieldModelByEntityType, payload.entityType!);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $offset,
      $limit,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const fetchMethod = fetchMethodByEntityType[payload.entityType];
    const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, requestPayload);
    if (payload.dataCallback) {
      yield call(payload.dataCallback, response);
    }
    yield put(
      fetchNoContactInCardDrillDownData.success({
        entityType: payload.entityType,
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchNoContactInCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport({ payload }: ReturnType<typeof exportNoContactInCardDrillDownData>) {
  const drillDownViewStates: Record<EntityTypesSupportedInNoContactIn, DrillDownViewState> =
    yield select(getNoContactInDrillDownViewStates);
  const total: Record<EntityTypesSupportedInNoContactIn, number> = yield select(
    getNoContactInDrillDownTotalFilteredRecords
  );

  yield put(
    exportEntities.request({
      entityType: payload.entityType,
      viewState: drillDownViewStates[payload.entityType],
      total: total[payload.entityType],
    })
  );
}

export function* noContactInSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchNoContactInCardData, onFetchNoContactInCardData);
  yield takeEvery(fetchNoContactInCountCardData, onFetchNoContactInCountCardData);
  yield takeLatest(showNoContactInDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchNoContactInCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportNoContactInCardDrillDownData, onExport);
}
