import { BaseEditor, Editor, Transforms } from "slate";
import deserializeFromHTML from "./deserializeFromHTML";
import type { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";

const resetEditorValue = (
  editor: ReactEditor & BaseEditor & HistoryEditor,
  value: string
) => {
  const point = { path: [0, 0], offset: 0 };
  editor.selection = { anchor: point, focus: point };
  editor.history = { redos: [], undos: [] };
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
  Transforms.removeNodes(editor, {
    at: [0],
  });
  Transforms.insertNodes(editor, deserializeFromHTML(value));
};

export default resetEditorValue;
