import React, { useCallback } from "react";
import Form from "antd/es/form";
import { FormItem, TextField } from "@mapmycustomers/ui";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { defineMessage, useIntl } from "react-intl";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import useActivityNameValidationRules from "../utils/useActivityNameValidationRules";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  activityTypes: ActivityType[];
  disabled?: boolean;
  required?: boolean;
  title?: string;
}

const label = defineMessage({
  id: "createEditActivityModal.field.name",
  defaultMessage: "Activity Name",
  description: "Activity name field on the Create Activity modal",
});

const ActivityNameField: React.FC<Props> = ({ activityTypes, disabled, required, title }) => {
  const intl = useIntl();
  const form = useFormInstance();

  const [nameFocused, setNameFocused, resetNameFocused] = useBoolean();
  const handleNameChange = useCallback(
    (name: string) => form.setFieldsValue({ default: { name } }),
    [form]
  );

  const activityNameValidationRules = useActivityNameValidationRules(intl);

  return (
    <Form.Item dependencies={[ActivityFieldName.NAME, "activityTypeId"]} noStyle>
      {(form) => {
        const name = form.getFieldValue(ActivityFieldName.NAME);
        const activityTypeId = form.getFieldValue("activityTypeId");

        // Name or activity type name. Thing with nameFocused is to allow user to clear field
        // when its focused to allow user enter his own name.
        const nameFieldValue = nameFocused
          ? name
          : name.trim() || activityTypes.find(({ id }) => id === activityTypeId)?.name || "";

        return (
          <FormItem
            dependencies={["activityTypeId"]}
            label={title ?? intl.formatMessage(label)}
            name={ActivityFieldName.NAME}
            rules={required ? activityNameValidationRules : undefined}
          >
            <TextField
              disabled={disabled}
              label={intl.formatMessage(label)}
              onBlur={resetNameFocused}
              onChange={handleNameChange}
              onFocus={setNameFocused}
              required={required}
              value={nameFieldValue}
            />
          </FormItem>
        );
      }}
    </Form.Item>
  );
};

export default ActivityNameField;
