import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "store/rootReducer";
import { getMe } from "store/iam";
import { connect } from "react-redux";
import User from "@mapmycustomers/shared/types/User";
import { getTeams, getUsersOfEntireOrganization } from "store/members";
import { complementFileUrl } from "util/file";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import Popover from "antd/es/popover";
import Team from "@mapmycustomers/shared/types/Team";
import Dashboard from "types/dashboard/Dashboard";
import { fetchCards, fetchDashboards } from "./store/actions";
import { areCardsLoading, getBoards, getCardIds } from "./store";
import { defineMessages, useIntl } from "react-intl";
import { isOwner } from "store/iam/util";
import ChartSelectionModal from "./components/ChartSelectionModal";
import DashboardCard from "types/dashboard/DashboardCard";
import { LoadingSpinner } from "@mapmycustomers/ui";
import { Dropdown } from "antd";
import ButtonLink from "component/ButtonLink";
import usePreviewCards from "./util/usePreviewCards";
import Content from "./components/Content";
import useUserAccess from "./util/useUserAccess";
import useInnerColors from "./util/useInnerColors";
import Path from "enum/Path";
import { TooltipProps } from "antd/es/tooltip";
import styles from "./UserPreviewCard.module.scss";
import cn from "classnames";

const messages = defineMessages({
  openProfile: {
    id: "userPreviewCard.openProfile",
    defaultMessage: "Open Profile {icon}",
    description: "Open Profile button",
  },
  viewMetrics: {
    id: "userPreviewCard.viewMetrics",
    defaultMessage: "Viewing metrics from {teams}",
    description: "Viewing metrics from multiple teams title",
  },
});

const stopEvents = (e: any) => {
  e.stopPropagation();
  if (!e.target.classList?.contains("mmc-user-preview-allow-click")) {
    e.preventDefault();
  }
};

interface Props extends Pick<TooltipProps, "trigger"> {
  boards: Dashboard[];
  cardIds: Record<number, DashboardCard["id"] | undefined>;
  cardsLoading: boolean;
  children: ReactNode;
  currentUser: User;
  onFetchCards: typeof fetchCards.request;
  onFetchDashBoards: typeof fetchDashboards.request;
  teamId?: Team["id"];
  teams: Team[];
  userId?: User["id"];
  users: User[];
}

const UserPreviewCard: React.FC<Props> = ({
  boards,
  cardIds,
  cardsLoading,
  children,
  currentUser,
  onFetchCards,
  onFetchDashBoards,
  teamId,
  teams,
  trigger = "click",
  userId,
  users,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const user = useMemo(() => users.find(({ id }) => id === userId), [userId, users]);
  const [selectedTeamId, setSelectedTeamId] = useState(teamId);
  const team = useMemo(
    () => (selectedTeamId ? teams.find(({ id }) => id === selectedTeamId) : undefined),
    [selectedTeamId, teams]
  );

  const commonUserTeams = useMemo(
    () =>
      user?.teams.filter(
        ({ id: teamId }) =>
          isOwner(currentUser) || currentUser.teams.some(({ id }) => teamId === id)
      ) ?? [],
    [currentUser, user?.teams]
  );

  const teamOptions = useMemo(
    () => commonUserTeams.map((team) => ({ label: team.name, key: team.id })),
    [commonUserTeams]
  );

  const logoUrl = complementFileUrl(user?.profilePhoto?.publicURI);

  const [userDashboard, teamDashboard, orgDashboard] = useMemo(() => {
    const userDashboard = boards.find(
      ({ teamId: tId, userId: uId }) => selectedTeamId === tId && userId === uId
    );

    const teamDashboard = boards.find(
      ({ teamId: tId, userId: uId }) => selectedTeamId === tId && !uId
    );

    const orgDashboard = boards.find(({ teamId: tId, userId: uId }) => !tId && !uId);
    return [userDashboard, teamDashboard, orgDashboard];
  }, [boards, selectedTeamId, userId]);

  const handleChange = useCallback(
    (open) => {
      if (open) {
        setDropdownOpen(false);
        onFetchDashBoards();
        onFetchCards({ teamId, userId });
        setSelectedTeamId(
          !teamId && user && user.teams.length > 0 ? commonUserTeams[0].id : teamId
        );
      }
      setOpen(open);
    },
    [onFetchCards, onFetchDashBoards, teamId, commonUserTeams, user, userId]
  );

  const [allowOpenUser, viewOnly, viewOnlyNoAccess] = useUserAccess(currentUser, user);
  const innerColors = useInnerColors(logoUrl, userDashboard);
  const previewCards = usePreviewCards(
    user,
    cardIds,
    orgDashboard,
    selectedTeamId,
    teamDashboard,
    userDashboard,
    viewOnlyNoAccess,
    viewOnly,
    setIndex,
    setModalOpen
  );

  const handleDoubleClick = useCallback(() => {
    allowOpenUser &&
      window.open(
        `${Path.DASHBOARD}${teamId ? `/teams/${teamId}` : ""}${userId ? `/users/${userId}` : ""}`,
        "_blank"
      );
  }, [allowOpenUser, teamId, userId]);

  useEffect(() => {
    if (open) {
      onFetchCards({ teamId: team?.id, userId: user?.id });
    }
  }, [onFetchCards, open, team, user]);

  if (!user) {
    return null;
  }

  return (
    <Popover
      content={
        <div className={styles.container} onClick={stopEvents}>
          <div
            className={styles.gradientContainer}
            style={{
              backgroundColor: innerColors && innerColors.length === 1 ? innerColors[0] : undefined,
              backgroundImage:
                innerColors && innerColors.length > 1
                  ? `linear-gradient(to right, ${innerColors?.join(", ")})`
                  : undefined,
            }}
          />
          <Content
            currentUser={currentUser}
            logoUrl={logoUrl}
            scope={{ teamId, userId }}
            user={user}
          />
          {user.teams.length > 1 && !viewOnlyNoAccess && (
            <div className={styles.teamSelect}>
              {intl.formatMessage(messages.viewMetrics, {
                teams: (
                  <Dropdown
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement!}
                    menu={{
                      className: styles.menu,
                      items: teamOptions,
                      onClick: ({ key }) => {
                        setSelectedTeamId(parseInt(key, 10));
                      },
                      selectedKeys: selectedTeamId ? [String(selectedTeamId)] : [],
                    }}
                    open={dropdownOpen}
                    onOpenChange={setDropdownOpen}
                    trigger={["click"]}
                  >
                    <ButtonLink className={styles.dropdown}>
                      {team?.name}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </ButtonLink>
                  </Dropdown>
                ),
              })}
            </div>
          )}
          {!viewOnlyNoAccess && previewCards.length > 0 && (
            <div className={styles.metrics}>{cardsLoading ? <LoadingSpinner /> : previewCards}</div>
          )}
          {allowOpenUser && (
            <div className={styles.footer}>
              <ButtonLink
                className={cn(styles.open, "mmc-user-preview-allow-click")}
                href={`${Path.DASHBOARD}${teamId ? `/teams/${teamId}` : ""}${
                  userId ? `/users/${userId}` : ""
                }`}
                target="_blank"
              >
                {intl.formatMessage(messages.openProfile, {
                  icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
                })}
              </ButtonLink>
            </div>
          )}
          <ChartSelectionModal
            cardIds={cardIds}
            dashboard={
              isOwner(user) ? orgDashboard : selectedTeamId ? teamDashboard : userDashboard
            }
            index={index}
            onHide={() => setModalOpen(false)}
            open={modalOpen}
            scope={{ teamId: selectedTeamId, userId }}
            team={team}
            user={user}
          />
        </div>
      }
      mouseEnterDelay={1.5}
      onOpenChange={handleChange}
      open={open}
      overlayClassName={styles.overlay}
      trigger={trigger}
    >
      {React.cloneElement(children as React.ReactElement, {
        onDoubleClick: trigger === "click" ? handleDoubleClick : undefined,
      })}
    </Popover>
  );
};

const mapStateToProps = (state: RootState) => ({
  boards: getBoards(state),
  cardIds: getCardIds(state),
  cardsLoading: areCardsLoading(state),
  currentUser: getMe(state)!,
  teams: getTeams(state),
  users: getUsersOfEntireOrganization(state),
});

const mapDispatchToProps = {
  onFetchCards: fetchCards.request,
  onFetchDashBoards: fetchDashboards.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreviewCard);
