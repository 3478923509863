import React, {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { isEmptyString } from "@mapmycustomers/shared/util/stringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import LoadingSpinner from "../../LoadingSpinner";
import { bem } from "@react-md/utils";
import cn from "classnames";
import { useConfigProvider } from "../../ConfigProvider";
import Input, { InputRef } from "antd/es/input/Input";
import { faPen } from "@fortawesome/pro-solid-svg-icons";

const block = bem("mmc-editable-text");

interface Props {
  className?: string;
  darkBackground?: boolean;
  editable?: boolean;
  editing?: boolean;
  fieldClassName?: string;
  onChange?: (value: string) => void;
  onEditingChange?: (editing: boolean) => void;
  placeholder?: string;
  required?: boolean;
  requiredTooltip?: ReactNode;
  saving?: boolean;
  size?: "large" | "small";
  tooltipTitle?: ReactNode;
  value?: string;
}

const EditableText: React.FC<Props> = ({
  className,
  darkBackground = false,
  editable = true,
  editing,
  fieldClassName,
  onChange,
  onEditingChange,
  placeholder,
  required,
  requiredTooltip,
  saving,
  size = "large",
  tooltipTitle,
  value,
}) => {
  const configProvider = useConfigProvider();
  const inputRef = useRef<InputRef>(null);

  const [text, setText] = useState(value ?? "");
  useEffect(() => {
    // setting new value regardless of the editMode
    setText(value ?? "");
  }, [value]);

  const [editMode, setEditMode] = useState(editing);
  useEffect(() => {
    if (editing !== undefined) {
      setEditMode(editing);
    }
  }, [editing]);
  const handleStartEditing = useCallback(() => {
    if (onEditingChange) {
      onEditingChange(true);
    } else {
      setEditMode(true);
    }
  }, [onEditingChange]);
  const handleStopEditing = useCallback(() => {
    if (onEditingChange) {
      onEditingChange(false);
    } else {
      setEditMode(false);
    }
    setText(value ?? "");
  }, [onEditingChange, value]);

  const isValid = !required || (required && !isEmptyString(text));

  const handleSaveClick = useCallback(() => {
    handleStopEditing();
    if (text !== value && isValid) {
      onChange?.(text);
    }
  }, [handleStopEditing, isValid, text, onChange, value]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        handleStopEditing();
      }
    },
    [handleStopEditing]
  );

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")
      ) {
        e.preventDefault();
        return;
      }
      handleSaveClick();
    },
    [handleSaveClick]
  );

  const handleChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  useEffect(() => {
    if (editMode && value) {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(0, value.length);
      }
    }
  }, [editMode, value]);

  return (
    <div className={cn(block({ [size]: true, darkBackground }), className)}>
      {editMode ? (
        <Input
          autoComplete="off"
          autoFocus
          className={fieldClassName}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onPressEnter={handleSaveClick}
          placeholder={placeholder}
          ref={inputRef}
          suffix={
            <>
              <Tooltip
                title={configProvider.formatMessage(
                  "ui.editableText.cancel.tooltip"
                )}
              >
                <Button
                  className={cn(block("button"), "mmc-js-skip-save-on-blur")}
                  icon={<FontAwesomeIcon icon={faTimes} />}
                  onClick={handleStopEditing}
                  type="default"
                />
              </Tooltip>

              <Tooltip
                title={
                  !isValid
                    ? requiredTooltip ??
                      configProvider.formatMessage(
                        "ui.editableText.save.tooltip.error.required"
                      )
                    : configProvider.formatMessage(
                        "ui.editableText.save.tooltip"
                      )
                }
              >
                <Button
                  className={block("button")}
                  disabled={!isValid}
                  icon={<FontAwesomeIcon icon={faCheck} />}
                  onClick={handleSaveClick}
                  type="primary"
                />
              </Tooltip>
            </>
          }
          value={text}
        />
      ) : (
        <Tooltip
          mouseLeaveDelay={0}
          placement="bottom"
          title={
            tooltipTitle ??
            configProvider.formatMessage("ui.editableText.tooltip")
          }
          trigger={editable ? ["hover"] : []}
        >
          <div
            className={cn(block("title", { editable }))}
            onClick={editable ? handleStartEditing : undefined}
          >
            {text}
            {editable && (
              <FontAwesomeIcon
                className={block("editIcon")}
                icon={faPen}
                size="sm"
              />
            )}
            {saving && <LoadingSpinner mini />}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default EditableText;
