import ApiError, { ValidationError } from "@mapmycustomers/shared/util/api/ApiError";
import { isDefined } from "../assert";

const getValidationErrors = (error: unknown): ValidationError[] => {
  if (!error || !(error instanceof ApiError) || !error.errors.length) {
    return [];
  }

  return error.errors
    .map((backendError) => backendError?.validationErrors)
    .filter(isDefined)
    .flat() // flatten ValidationError[][] to ValidationError[]
    .map((error) => {
      // sometimes backend returns "invalid" error shape. It was promised to be fixed
      // in a future, but for now we consider this inconsistency temporary and are not
      // updating types. Still, we need to handle that and we use ts-ignore to ignore
      // types inconsistency.
      if (typeof error === "object" && error !== null && "errorCF" in error) {
        // @ts-ignore
        return error.errorCF[0].error as ValidationError[];
      } else {
        return [error];
      }
    })
    .flat();
};

export default getValidationErrors;
