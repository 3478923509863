import React, { useCallback } from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EmailQueue.module.scss";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { isEmailServiceSupported } from "../../../../store/iam";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";
import QueueModalInfo from "./QueueModalInfo";
import { showEmailQueue } from "store/email/actions";
import navbarAnalytics from "util/analytic/navbarAnalytics";
import { isEmailQueueVisible } from "store/email";

interface Props {
  emailQueueVisible: boolean;
  onShowEmailQueue: typeof showEmailQueue;
  orgSupportEmail: boolean;
}

const EmailQueue: React.FC<Props> = ({ emailQueueVisible, onShowEmailQueue, orgSupportEmail }) => {
  const intl = useIntl();

  const handleShow = useCallback(() => {
    navbarAnalytics.clicked(["Email Queue"]);
    onShowEmailQueue();
  }, [onShowEmailQueue]);

  if (!orgSupportEmail) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper} onClick={handleShow}>
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({
            id: "navbar.email.queue",
            defaultMessage: "Open email queue",
            description: "Navigation bar email queue tooltip",
          })}
        >
          <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
        </Tooltip>
      </div>
      {emailQueueVisible && <QueueModalInfo />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQueueVisible: isEmailQueueVisible(state),
  orgSupportEmail: isEmailServiceSupported(state),
});

const mapDispatchToProps = {
  onShowEmailQueue: showEmailQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailQueue);
