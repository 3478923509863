import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Activity, Company, Deal, Group, Person } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import FileListItem from "types/FileListItem";
import { RawFile } from "@mapmycustomers/shared/types/File";
import DealRecordData from "./DealRecordData";
import RecapRange from "enum/preview/RecapRange";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import User from "@mapmycustomers/shared/types/User";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";
import { DealPayload } from "store/api/ApiService";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";
import { NextAssociationsState } from "store/associations/getNextAssociationsState";

export const fetchPreviewData = createAsyncAction(
  "dealRecord/fetchPreviewData/request",
  "dealRecord/fetchPreviewData/success",
  "dealRecord/fetchPreviewData/failure"
)<
  Deal["id"],
  {
    recordData: DealRecordData;
    selectedActivityTypes: ActivityType[];
  },
  void
>();

export const fetchDealActivitiesCompletedByType = createAsyncAction(
  "dealRecord/fetchDealActivitiesCompletedByType/request",
  "dealRecord/fetchDealActivitiesCompletedByType/success",
  "dealRecord/fetchDealActivitiesCompletedByType/failure"
)<
  {
    activityTypes: ActivityType[];
    deal: Deal;
    filter: ActivityStatusOption;
    order: SortModel;
    recapRange: RecapRange;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const changeActivitiesAssignees = createAction("dealRecord/changeActivitiesAssignees")<
  User["id"][]
>();

export const changeActivitiesOrder = createAction("dealRecord/changeActivitiesOrder")<SortModel>();

export const fetchDealActivities = createAsyncAction(
  "dealRecord/fetchDealActivities/request",
  "dealRecord/fetchDealActivities/success",
  "dealRecord/fetchDealActivities/failure"
)<
  {
    activityTypes: ActivityType[];
    deal: Deal;
    filter: ActivityStatusOption;
    order: SortModel;
    recapRange: RecapRange;
    search: string;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const fetchThumbnail = createAction("dealRecord/thumbnail")<{
  fileId: RawFile["id"];
  callback: (data: Blob) => void;
}>();

export const fetchDealActivitiesTotal = createAsyncAction(
  "dealRecord/fetchDealActivitiesTotal/request",
  "dealRecord/fetchDealActivitiesTotal/success",
  "dealRecord/fetchDealActivitiesTotal/failure"
)<Deal, number, void>();

export const fetchDeal = createAsyncAction(
  "dealRecord/fetchDeal/request",
  "dealRecord/fetchDeal/success",
  "dealRecord/fetchDeal/failure"
)<Deal, Deal, void>();

export const createDealNote = createAsyncAction(
  "dealRecord/createDealNote/request",
  "dealRecord/createDealNote/success",
  "dealRecord/createDealNote/failure"
)<string, Note, void>();

export const updateDealNote = createAsyncAction(
  "dealRecord/updateDealNote/request",
  "dealRecord/updateDealNote/success",
  "dealRecord/updateDealNote/failure"
)<Note, Note, Note["id"]>();

export const deleteDealNote = createAsyncAction(
  "dealRecord/deleteDealNote/request",
  "dealRecord/deleteDealNote/success",
  "dealRecord/deleteDealNote/failure"
)<Note, Note["id"], Note["id"]>();

export const uploadDealFiles = createAsyncAction(
  "dealRecord/uploadDealFiles/request",
  "dealRecord/uploadDealFiles/success",
  "dealRecord/uploadDealFiles/failure"
)<
  {
    files: (Blob | File)[];
    fileGroupId?: string;
    callback?: (filesList: FileListItem[]) => void;
  },
  FileListItem[],
  void
>();

export const deleteDealFile = createAsyncAction(
  "dealRecord/deleteDealFile/request",
  "dealRecord/deleteDealFile/success",
  "dealRecord/deleteDealFile/failure"
)<
  RawFile,
  { dealId: Deal["id"]; file: RawFile; removed: boolean },
  { dealId: Deal["id"]; file: RawFile }
>();

export const clearAllUploadedDealFiles = createAction(
  "dealRecord/clearAllUploadedDealFiles"
)<void>();

export const downloadDealFile = createAction("dealRecord/downloadDealFile")<RawFile>();

export const updateDeal = createAsyncAction(
  "dealRecord/updateDeal/request",
  "dealRecord/updateDeal/success",
  "dealRecord/updateDeal/failure"
)<
  {
    deal: OnlyRequiredFields<DealPayload, "id">;
    customFields?: CustomFieldValue[];
    callback?: (updatedDeal: Deal) => void;
    groupIdsToAdd?: Group["id"][];
    groupIdsToRemove?: Group["id"][];
    layoutId?: FormLayout["id"];
  },
  Deal,
  void
>();

export const deleteDeal = createAsyncAction(
  "dealRecord/deleteDeal/request",
  "dealRecord/deleteDeal/success",
  "dealRecord/deleteDeal/failure"
)<void, void, void>();

export const addDealParentCompany = createAsyncAction(
  "dealRecord/addDealParentCompany/request",
  "dealRecord/addDealParentCompany/success",
  "dealRecord/addDealParentDeal/failure"
)<{ id: Deal["id"]; parentCompanyId: Company["id"] }, Company, void>();

export const removeDealParentCompany = createAsyncAction(
  "dealRecord/removeDealParentCompany/request",
  "dealRecord/removeDealParentCompany/success",
  "dealRecord/removeDealParentCompany/failure"
)<Deal["id"], Deal["id"], Deal["id"]>();

export const addDealParentPerson = createAsyncAction(
  "dealRecord/addDealParentPerson/request",
  "dealRecord/addDealParentPerson/success",
  "dealRecord/addDealParentPerson/failure"
)<{ id: Deal["id"]; parentPersonId: Person["id"] }, Person, void>();

export const removeDealParentPerson = createAsyncAction(
  "dealRecord/removeDealParentPerson/request",
  "dealRecord/removeDealParentPerson/success",
  "dealRecord/removeDealParentPerson/failure"
)<Deal["id"], Deal["id"], Deal["id"]>();

export const toggleCompleteActivity = createAsyncAction(
  "dealRecord/toggleCompleteActivity/request",
  "dealRecord/toggleCompleteActivity/success",
  "dealRecord/toggleCompleteActivity/failure"
)<Activity, Pick<Activity, "id" | "completed" | "completedAt">, Activity["id"]>();

export const postponeActivity = createAsyncAction(
  "dealRecord/postponeActivity/request",
  "dealRecord/postponeActivity/success",
  "dealRecord/postponeActivity/failure"
)<Activity, Activity, Activity["id"]>();

export const updateActivityNote = createAsyncAction(
  "dealRecord/updateActivityNote/request",
  "dealRecord/updateActivityNote/success",
  "dealRecord/updateActivityNote/failure"
)<{ activity: Activity; onSuccess: () => void }, Pick<Activity, "id" | "note">, Activity["id"]>();

export const changeActivitiesSearchQuery = createAction(
  "dealRecord/changeActivitiesSearchQuery"
)<string>();
export const changeActivitiesFilter = createAction(
  "dealRecord/changeActivitiesFilter"
)<ActivityStatusOption>();
export const changeActivitiesRecapRange = createAction(
  "dealRecord/changeActivitiesRecapRange"
)<RecapRange>();
export const changeActivitiesSelectedActivityTypes = createAction(
  "dealRecord/changeActivitiesSelectedActivityTypes"
)<ActivityType[]>();

export const setActivityElementId = createAction("dealRecord/setActivityElementId")<string>();

export const setActivityAutoScrolling = createAction(
  "dealRecord/setActivityAutoScrolling"
)<boolean>();

export const setHasChangesFlag = createAction("dealRecord/setHasChangesFlag")<boolean>();

export const goToCalendarSettings = createAction("dealRecord/goToCalendarSettings")<void>();

export const changeAssociatedEntities = createAsyncAction(
  "dealRecord/changeAssociatedEntities/request",
  "dealRecord/changeAssociatedEntities/success",
  "dealRecord/changeAssociatedEntities/failure"
)<
  Pick<ChangeAssociationsPayload, "company" | "person">,
  Pick<NextAssociationsState, "availableCompanies" | "availablePeople">,
  void
>();

export const updateDealFrequency = createAsyncAction(
  "dealRecord/updateDealFrequency/request",
  "dealRecord/updateDealFrequency/success",
  "dealRecord/updateDealFrequency/failure"
)<
  {
    dealId: Deal["id"];
  },
  Deal,
  void
>();

export const relateEntities = createAsyncAction(
  "dealRecord/relateEntities/request",
  "dealRecord/relateEntities/success",
  "dealRecord/relateEntities/failure"
)<
  {
    associatedCompany?: Company;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  },
  { person?: Person },
  void
>();

export type Actions = ActionType<
  | typeof addDealParentCompany
  | typeof addDealParentPerson
  | typeof changeActivitiesAssignees
  | typeof changeActivitiesFilter
  | typeof changeActivitiesOrder
  | typeof changeActivitiesRecapRange
  | typeof changeActivitiesSearchQuery
  | typeof changeActivitiesSelectedActivityTypes
  | typeof changeAssociatedEntities
  | typeof clearAllUploadedDealFiles
  | typeof createDealNote
  | typeof deleteDeal
  | typeof deleteDealFile
  | typeof deleteDealNote
  | typeof downloadDealFile
  | typeof fetchDeal
  | typeof fetchDealActivities
  | typeof fetchDealActivitiesCompletedByType
  | typeof fetchDealActivitiesTotal
  | typeof fetchPreviewData
  | typeof fetchThumbnail
  | typeof goToCalendarSettings
  | typeof postponeActivity
  | typeof removeDealParentCompany
  | typeof removeDealParentPerson
  | typeof setActivityAutoScrolling
  | typeof setActivityElementId
  | typeof setHasChangesFlag
  | typeof toggleCompleteActivity
  | typeof updateActivityNote
  | typeof updateDeal
  | typeof updateDealFrequency
  | typeof updateDealNote
  | typeof uploadDealFiles
  | typeof relateEntities
>;
