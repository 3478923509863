import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Company, Deal, Group, Person } from "@mapmycustomers/shared/types/entity";
import { DealPayload } from "store/api/ApiService";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FileListItem from "types/FileListItem";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";
import { NextAssociationsState } from "store/associations/getNextAssociationsState";

export const showGlobalCreateDealModal = createAction("@component/globalCreateDealModal/show")<{
  showPostCreatePrompt?: boolean;
} | void>();

export const hideGlobalCreateDealModal = createAction(
  "@component/globalCreateDealModal/hide"
)<void>();

export const initialize = createAsyncAction(
  "@component/createDealModal/initialize/request",
  "@component/createDealModal/initialize/success",
  "@component/createDealModal/initialize/failure"
)<
  { fixedCompany?: Company; fixedPerson?: Person },
  {
    associationsState: Pick<NextAssociationsState, "availableCompanies" | "availablePeople">;
    fileGroupId: string;
  },
  void
>();

export const changeAssociatedEntities = createAsyncAction(
  "@component/createDealModal/changeAssociatedEntities/request",
  "@component/createDealModal/changeAssociatedEntities/success",
  "@component/createDealModal/changeAssociatedEntities/failure"
)<
  Pick<ChangeAssociationsPayload, "company" | "person">,
  Pick<NextAssociationsState, "availableCompanies" | "availablePeople">,
  void
>();

export const createDeal = createAsyncAction(
  "@component/createDealModal/createDeal/request",
  "@component/createDealModal/createDeal/success",
  "@component/createDealModal/createDeal/failure"
)<
  {
    callback?: (newDeal?: Deal) => void;
    deal: DealPayload;
    customFieldsValues: CustomFieldValue[];
    groupsIdsToAdd: Array<Group["id"]>;
    layoutId: FormLayout["id"];
  },
  Deal,
  Record<string, string>
>();

export const uploadDealFiles = createAsyncAction(
  "@component/createDealModal/uploadDealFiles/request",
  "@component/createDealModal/uploadDealFiles/success",
  "@component/createDealModal/uploadDealFiles/failure"
)<
  {
    files: (Blob | File)[];
    callback?: (filesList: FileListItem[]) => void;
  },
  FileListItem[],
  void
>();

export const removeDealFile = createAsyncAction(
  "@component/createDealModal/removeDealFile/request",
  "@component/createDealModal/removeDealFile/success",
  "@component/createDealModal/removeDealFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedDealFiles = createAction(
  "@component/createDealModal/clearAllUploadedDealFiles"
)<void>();

export const relateEntities = createAsyncAction(
  "@component/createDealModal/relateEntities/request",
  "@component/createDealModal/relateEntities/success",
  "@component/createDealModal/relateEntities/failure"
)<
  {
    associatedCompany?: Company;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  },
  { person?: Person },
  void
>();

export type Actions = ActionType<
  | typeof changeAssociatedEntities
  | typeof clearAllUploadedDealFiles
  | typeof createDeal
  | typeof hideGlobalCreateDealModal
  | typeof initialize
  | typeof relateEntities
  | typeof removeDealFile
  | typeof showGlobalCreateDealModal
  | typeof uploadDealFiles
>;
