import React, { useCallback } from "react";
import { TextControl } from "../../enum/Control";
import styles from "../Toolbar.module.scss";
import cn from "classnames";
import toggleTextType from "../../util/toggleTextType";
import { useSlate } from "slate-react";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import isTextTypeActive from "../../util/isTextTypeActive";
import ActionTooltipWrapper from "../ActionTooltipWrapper";

interface TextTogglerProps {
  tooltipTranslationString: string;
  value: TextControl;
}

const TextToggler: React.FC<TextTogglerProps> = ({
  tooltipTranslationString,
  value,
}) => {
  const editor = useSlate();
  const handleToggleBlock = useCallback(() => {
    toggleTextType(editor, value.value);
  }, [value.value]);

  return (
    <ActionTooltipWrapper translationString={tooltipTranslationString}>
      <span
        className={cn(styles.togglerButton, {
          [styles.active]: isTextTypeActive(editor, value.value),
        })}
        onClick={handleToggleBlock}
        onMouseDown={stopEvents}
      >
        {value.label}
      </span>
    </ActionTooltipWrapper>
  );
};

export default TextToggler;
