import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";
import TextSize from "@mapmycustomers/shared/enum/textEditor/TextSize";
import TextDecoration from "@mapmycustomers/shared/enum/textEditor/TextDecoration";
import React from "react";
import { RenderLeafProps } from "slate-react/dist/components/editable";
import parsePhoneNumber from "../util/parsePhoneNumber";
import styles from "../EmailBody.module.scss";
import Link from "./Link";

interface LeafProps extends RenderLeafProps {}

const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
  const style = {
    ...(leaf.color ? { color: leaf.color } : {}),
  };

  if (leaf.size === TextSize.LARGE) {
    children = <span className={styles.sizeLarge}>{children}</span>;
  } else if (leaf.size === TextSize.SMALL) {
    children = <span className={styles.sizeSmall}>{children}</span>;
  } else {
    children = <span className={styles.sizeMedium}>{children}</span>;
  }

  if (leaf.decoration === TextDecoration.URL) {
    children = (
      <a
        href={leaf.text}
        onClick={() => {
          window.open(
            leaf.text.startsWith("http") ? leaf.text : `//${leaf.text}`,
            "_blank"
          );
        }}
      >
        <u>{children}</u>
      </a>
    );
  }
  if (leaf.decoration === TextDecoration.EMAIL) {
    children = (
      <a
        href={`mailto:${leaf.text}`}
        onClick={() => {
          window.location.assign(`mailto:${leaf.text}`);
        }}
        {...attributes}
      >
        <u>{children}</u>
      </a>
    );
  }

  if (leaf.decoration === TextDecoration.PHONE) {
    const parsedPhoneNumber = parsePhoneNumber(leaf.text);
    const phoneNumberLink = parsedPhoneNumber?.link;
    if (phoneNumberLink) {
      children = (
        <a
          href={phoneNumberLink!}
          onClick={() => {
            window.location.href = phoneNumberLink!;
          }}
          {...attributes}
        >
          <u>{children}</u>
        </a>
      );
    }
  }

  if (leaf[TextType.BOLD]) {
    children = <strong style={style}>{children}</strong>;
  }

  if (leaf[TextType.ITALIC]) {
    children = <em style={style}>{children}</em>;
  }

  if (leaf[TextType.UNDERLINE]) {
    children = <u style={style}>{children}</u>;
  }

  if (leaf[TextType.STRIKE]) {
    children = (
      <span style={{ ...style, textDecoration: "line-through" }}>
        {children}
      </span>
    );
  }

  if (leaf.link) {
    children = (
      <Link text={leaf.text} url={leaf.link}>
        {children}
      </Link>
    );
  }

  return (
    <span style={style} {...attributes}>
      {children}
    </span>
  );
};

export default Leaf;
