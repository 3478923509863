import React from "react";
import { Form } from "antd";
import { FormItemProps } from "antd/es/form/FormItem";
import cn from "classnames";

interface Props extends FormItemProps {
  children: React.ReactNode;
}

const FormItem: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <Form.Item className={cn("mmc-form-item", className)} {...props}>
      {children}
    </Form.Item>
  );
};

export default FormItem;
