import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import EntitySearch from "./EntitySearch";
import TextWithInfo from "component/typography/TextWithInfo";
import { filterDeals } from "./store/actions";
import { areDealsLoading, getFilteredDeals } from "./store/selectors";
import { RootState } from "store/rootReducer";
import { EntityType } from "@mapmycustomers/shared/enum";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Person from "@mapmycustomers/shared/types/entity/Person";
import { fetchDeal } from "store/deal/actions";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";

export interface DealEntitySearchProps<T = Deal> {
  addDeal?: ReactNode;
  allowAdd?: boolean;
  deals?: T[];
  disabled?: boolean;
  hideLabel?: boolean;
  label?: React.ReactNode;
  loading?: boolean;
  onAddClick?: () => void;
  onChange?: (dealId?: Deal["id"]) => void;
  onChangeAssociatedEntities: (
    payload: Pick<ChangeAssociationsPayload, "company" | "deal" | "person">
  ) => void;
  placeholder?: string;
  required?: boolean;
  selectedCompany?: Company;
  selectedDeal?: T;
  selectedPerson?: Person;
}

interface Props<T = Deal> extends DealEntitySearchProps<T> {
  filteredDeals: T[];
  isFiltering: boolean;
  onFetchDeal: typeof fetchDeal;
  onFilterDeals: typeof filterDeals.request;
}

const DealEntitySearch: React.FC<Props> = ({
  allowAdd,
  deals,
  disabled,
  filteredDeals,
  hideLabel,
  isFiltering,
  label,
  loading,
  onAddClick,
  onChange,
  onChangeAssociatedEntities,
  onFetchDeal,
  onFilterDeals,
  placeholder,
  required,
  selectedCompany,
  selectedDeal,
  selectedPerson,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState("");
  const handleSearch = useCallback(
    (query: string) => {
      setQuery(query);
      onFilterDeals(query);
    },
    [onFilterDeals, setQuery]
  );

  const handleSelect = useCallback(
    (deal: Deal) => {
      onChange?.(deal.id);
      onFetchDeal({
        id: deal.id,
        callback: (deal: Deal) => {
          setQuery("");
          onChangeAssociatedEntities({
            company: selectedCompany ?? deal.account,
            deal,
            person: selectedPerson ?? deal.contact,
          });
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [onChangeAssociatedEntities, selectedCompany, selectedPerson, onChange, onFetchDeal]
  );

  const handleRemove = useCallback(() => {
    onChange?.(undefined);
    onChangeAssociatedEntities({
      company: selectedCompany,
      deal: undefined,
      person: selectedPerson,
    });
  }, [onChange, selectedCompany, selectedPerson, onChangeAssociatedEntities]);

  const primaryEntities = useMemo(() => {
    const searchText = query.toLowerCase().trim();
    if (searchText.length === 0) {
      return deals;
    }
    return deals?.filter((deal) => deal.name.toLowerCase().includes(searchText));
  }, [query, deals]);

  return (
    <EntitySearch<Deal>
      allowAdd={allowAdd}
      disabled={disabled}
      entities={filteredDeals}
      entityType={EntityType.DEAL}
      label={
        hideLabel ? null : (
          <TextWithInfo
            info={
              disabled
                ? intl.formatMessage({
                    id: "entitySearch.deal.disabledTooltip",
                    defaultMessage: "Deal cannot be changed",
                    description: "Tooltip for Deal Search field when it is disabled",
                  })
                : selectedPerson
                ? intl.formatMessage({
                    id: "entitySearch.deal.enabledTooltip.person",
                    defaultMessage: "You can only select a Deal associated with this Person",
                    description:
                      "Tooltip for Deal Search field when it is enabled and person is assigned",
                  })
                : selectedCompany
                ? intl.formatMessage({
                    id: "entitySearch.deal.enabledTooltip.company",
                    defaultMessage: "You can only select a Deal associated with this Company",
                    description:
                      "Tooltip for Deal Search field when it is enabled and company is assigned",
                  })
                : intl.formatMessage({
                    id: "entitySearch.deal.defaultTooltip",
                    defaultMessage:
                      "If Company is selected, you can only select a Deal associated with that Company",
                    description: "Tooltip for Deal Search field default state",
                  })
            }
          >
            {label ??
              intl.formatMessage({
                id: "entitySearch.deal",
                defaultMessage: "Deal",
                description: "Title of the Deal Search field",
              })}
          </TextWithInfo>
        )
      }
      loading={loading || isFiltering}
      onAddClick={onAddClick}
      onRemove={handleRemove}
      onSearch={handleSearch}
      onSelect={handleSelect}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "entitySearch.deal.placeholder",
          defaultMessage: "Select",
          description: "Placeholder of the Deal Search field",
        })
      }
      primaryEntities={primaryEntities}
      required={required}
      selectedEntity={selectedDeal}
      value={query}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  filteredDeals: getFilteredDeals(state),
  isFiltering: areDealsLoading(state),
});

const mapDispatchToProps = {
  onFetchDeal: fetchDeal,
  onFilterDeals: filterDeals.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealEntitySearch);
