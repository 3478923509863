import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.createCompanyModal;

export const isCreating = createSelector(componentState, ({ createLoading }) => createLoading);
export const getDuplicates = createSelector(componentState, ({ duplicates }) => duplicates);

export const isInitializing = createSelector(
  componentState,
  ({ initializeLoading }) => initializeLoading
);
export const getAvailableCompanies = createSelector(
  componentState,
  ({ availableCompanies }) => availableCompanies
);

export const getFileGroupId = createSelector(componentState, ({ fileGroupId }) => fileGroupId);

export const isAvailableLoading = createSelector(
  componentState,
  ({ availableCompaniesLoading }) => availableCompaniesLoading
);

export const isGlobalAddCompany = createSelector(
  componentState,
  ({ isGlobalAddCompany }) => isGlobalAddCompany
);

export const isCreateCompanyModalVisible = createSelector(componentState, ({ visible }) => visible);

export const getUploadedFilesList = createSelector(componentState, ({ filesAdded }) => filesAdded);

export const getUploadedFileListIds = createSelector(getUploadedFilesList, (filesAdded) =>
  filesAdded
    .filter(({ uploadedFile }) => !!uploadedFile)
    .map(({ uploadedFile }) => uploadedFile!.id)
);

export const isFilesUploading = createSelector(
  componentState,
  ({ filesUploading }) => filesUploading
);
