import React from "react";
import Button, { ButtonProps } from "antd/es/button";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "antd/es/tooltip";

interface Props extends ButtonProps {
  recipientCount: number;
}

const SendAndCreateButton: React.FC<Props> = ({ disabled, onClick, recipientCount, ...props }) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage(
        {
          id: "email.creationModal.sendAndCreate.tooltip",
          defaultMessage:
            "Send {count, plural, one {email} other {emails}} and create {count, plural, one {activity} other {activities}}",
          description: "Tooltip for Send and Create button in email creation modal",
        },
        { count: recipientCount }
      )}
      trigger={disabled ? [] : ["hover"]}
    >
      <Button
        {...props}
        disabled={disabled}
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        onClick={onClick}
        type="primary"
      >
        {intl.formatMessage({
          id: "email.creationModal.sendAndCreate",
          defaultMessage: "Send and Create",
          description: "Send and Create button in email creation modal",
        })}
      </Button>
    </Tooltip>
  );
};

export default SendAndCreateButton;
