import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { Actions, fetchSchemaForEntityType } from "./actions";
import { createReducer } from "typesafe-actions";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";

export type EntitiesSchemaMap = Record<EntityTypesSupportingFieldCustomization, SchemaField[]>;

export interface SchemaState {
  entities: EntitiesSchemaMap;
  error: ApiError | undefined;
  loading: boolean;
}

const initialState: SchemaState = {
  entities: {
    [EntityType.ACTIVITY]: [],
    [EntityType.COMPANY]: [],
    [EntityType.DEAL]: [],
    [EntityType.PERSON]: [],
  },
  error: undefined,
  loading: false,
};

const schema = createReducer<SchemaState, Actions>(initialState)
  .handleAction(fetchSchemaForEntityType.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchSchemaForEntityType.success, (state, { payload }) => ({
    ...state,
    entities: {
      ...state.entities,
      [payload.entityType]: payload.schema,
    },
    loading: false,
  }))
  .handleAction(fetchSchemaForEntityType.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }));

export * from "./selectors";

export type SchemaActions = Actions;

export default schema;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// So a field is required in layout if:
/// 1. it is set as "required" in it
/// 2. OR field is systemRequired but NOT relationship field
/// 3. we're creating an entity OR editing, but then it is editable in schema (field.accessStatus.update === true)
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
