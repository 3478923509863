@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .label {
        color: colors.$mmc-soft-slate;

        .required {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 0.25rem;

          &::after {
            color: colors.$mmc-red-100;
            content: "*";
            display: inline-block;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 1;
          }
        }
      }
    }
  }
}
