import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Cluster } from "@mapmycustomers/shared/types/map";
import MapViewportState from "types/map/MapViewportState";
import MapPersistedViewportState from "types/map/MapPersistedViewportState";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import Located from "@mapmycustomers/shared/types/base/Located";
import GeoPath from "types/GeoPath";
import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import { MapModeActions } from "scene/map/store/mapMode/actions";
import { GroupModeActions } from "scene/map/store/groupMode/actions";
import { TerritoryModeActions } from "scene/map/store/territoryMode/actions";
import { LayersActions } from "scene/map/store/layers/actions";
import { LeadFinderActions } from "./leadFinder/actions";
import LassoToolOptions from "scene/map/types/LassoToolOptions";
import MapMode from "scene/map/enums/MapMode";
import { ModifiedMapFilterModel } from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import { LegendsActions } from "scene/map/store/legends/actions";
import { PinLegendsActions } from "scene/map/store/pinLegends/actions";
import { PinLocationActions } from "scene/map/store/pinLocation/actions";
import { MapFetcherPayload } from "../types/MapFetcher";
import PersistentMapSettings from "@mapmycustomers/shared/types/persistent/PersistentMapSettings";

export const initializeMapView = createAsyncAction(
  "map/initialize/request",
  "map/initialize/success",
  "map/initialize/failure"
)<(viewport: MapPersistedViewportState) => void, void, unknown>();

export const updateViewportState = createAction("map/updateViewportState")<MapViewportState>();

export const updateMapStyle = createAction("map/updateStyle")<BaseMapStyle>();

export const selectMapTool = createAction("map/selectTool")<
  | undefined
  | {
      mapTool: MapTool.LASSO;
      mapToolOptions?: LassoToolOptions;
    }
  | { mapTool: Exclude<MapTool, MapTool.LASSO> }
>();

export const updatePinDropCoordinates = createAsyncAction(
  "map/pinDrop/updateCoordinates/request",
  "map/pinDrop/updateCoordinates/success",
  "map/pinDrop/updateCoordinates/failure"
)<GeolocationCoordinates | undefined, Located | undefined, unknown>();

export const appendLassoPath = createAction("map/lasso/appendPath")<GeoPath>();
export const cleanLassoPaths = createAction("map/lasso/cleanPaths")<void>();

export const updateLassoClusters = createAction("map/lasso/updateLassoClusters")<
  Cluster[] | undefined
>();
export const setLassoContainsFlags = createAction("map/lasso/setLassoContainsFlags")<{
  containsClusters?: boolean;
}>();

export const enterLassoMode = createAction("map/lasso/enterLassoMode")<void>();
export const exitLassoMode = createAction("map/lasso/exitLassoMode")<void>();
export const refreshLassoMode = createAction("map/lasso/refreshLassoMode")<void>();

export const enterMode = createAction("map/enterMode")<MapMode | undefined>();
export const exitMode = createAction("map/exitMode")<void>();

export const fetchLassoSelection = createAsyncAction(
  "map/lasso/fetchLassoSelection/request",
  "map/lasso/fetchLassoSelection/success",
  "map/lasso/fetchLassoSelection/failure"
)<MapFetcherPayload, void, unknown>();

export const enableEngagementFields = createAsyncAction(
  "map/lasso/enableEngagementFields/request",
  "map/lasso/enableEngagementFields/success",
  "map/lasso/enableEngagementFields/failure"
)<void, void, unknown>();

export const setOfferedFilters = createAction("map/filtering/setOfferedFilter")<
  ModifiedMapFilterModel | undefined
>();

export const setMapSettings = createAction("map/setMapSettings")<
  Partial<PersistentMapSettings> | undefined
>();

export const setLocationSearchVisibility = createAction("map/toggleLocationSearch")<boolean>();

export * from "./mapMode/actions";
export * from "./groupMode/actions";
export * from "./territoryMode/actions";
export * from "./layers/actions";
export * from "./leadFinder/actions";
export * from "./pinLegends/actions";
export * from "./pinLocation/actions";

export type Actions =
  | MapModeActions
  | GroupModeActions
  | TerritoryModeActions
  | LayersActions
  | LeadFinderActions
  | LegendsActions
  | PinLegendsActions
  | PinLocationActions
  | ActionType<
      | typeof appendLassoPath
      | typeof cleanLassoPaths
      | typeof enableEngagementFields
      | typeof enterLassoMode
      | typeof enterMode
      | typeof exitLassoMode
      | typeof exitMode
      | typeof fetchLassoSelection
      | typeof initializeMapView
      | typeof selectMapTool
      | typeof setLassoContainsFlags
      | typeof setMapSettings
      | typeof setOfferedFilters
      | typeof refreshLassoMode
      | typeof updateLassoClusters
      | typeof updateMapStyle
      | typeof updatePinDropCoordinates
      | typeof updateViewportState
      | typeof setLocationSearchVisibility
    >;
