import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { Descendant, Element } from "slate";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import TextSize from "@mapmycustomers/shared/enum/textEditor/TextSize";
import ElementAlignment from "@mapmycustomers/shared/enum/textEditor/ElementAlignment";
import { DYNAMIC_VAR_REGEX } from "./findThingsInText";

const isTextNode = (element: HTMLElement): boolean => {
  const elementTagName = element.tagName.toLowerCase();
  if (
    "span" === elementTagName &&
    // we wrap dynamic variables to span coz it should be inline in
    // emails, but in editor this will be as element with inline property
    Array.from(element.innerText.matchAll(DYNAMIC_VAR_REGEX)).length
  ) {
    return false;
  }

  return ["a", "em", "b", "u", "span"].includes(elementTagName);
};

const doesSomeElementHaveStyleValue = (
  elements: HTMLElement[],
  styleName: keyof CSSStyleDeclaration,
  value: string
): boolean => elements.some((element) => element.style[styleName] === value);

const getElementsStyleValue = (
  elements: HTMLElement[],
  styleName: keyof CSSStyleDeclaration
): string | undefined => {
  const element = elements.find((element) => !!element.style[styleName]);
  if (!element) {
    return undefined;
  }
  return String(element.style[styleName]);
};

const getElementEntries = (
  element: HTMLElement,
  tagName: string
): HTMLElement[] => [
  ...(element.tagName.toLowerCase() === tagName.toLowerCase() ? [element] : []),
  ...(Array.from(element.getElementsByTagName(tagName) ?? []) as HTMLElement[]),
];

const parseTextNode = (element: HTMLElement): Descendant => {
  const boldElements = getElementEntries(element, "b");
  const italicElements = getElementEntries(element, "em");
  const underlineElements = getElementEntries(element, "u");
  const spanElements = getElementEntries(element, "span");
  const aElements = getElementEntries(element, "a");
  let isUrl = false,
    link = "";

  aElements.forEach((aElement) => {
    link = (aElement as HTMLAnchorElement).href;
    isUrl = true;
  });
  const collectedElements = [
    ...boldElements,
    ...italicElements,
    ...underlineElements,
    ...spanElements,
  ].filter((element) => !!element) as HTMLElement[];

  const color = getElementsStyleValue(collectedElements, "color");
  const fontSize = getElementsStyleValue(collectedElements, "fontSize");

  const isStrike = doesSomeElementHaveStyleValue(
    collectedElements,
    "textDecoration",
    "line-through"
  );

  return {
    text: element.innerText,
    size:
      fontSize === "18px"
        ? TextSize.LARGE
        : fontSize === "12px"
        ? TextSize.SMALL
        : TextSize.MEDIUM,
    ...(boldElements.length > 0 ? { bold: true } : {}),
    ...(italicElements.length > 0 ? { italic: true } : {}),
    ...(underlineElements.length > 0 ? { underline: true } : {}),
    ...(isStrike ? { strike: true } : {}),
    ...(color ? { color: color as AnyColor } : {}),
    ...(isUrl ? { link } : {}),
  };
};

const parseElementNode = (element: HTMLElement): Element => {
  const children = Array.from(element.children).map((child) =>
    isTextNode(child as HTMLElement)
      ? parseTextNode(child as HTMLElement)
      : parseElementNode(child as HTMLElement)
  );
  const alignValue = getElementsStyleValue([element], "textAlign");
  const marginLeftValue = getElementsStyleValue([element], "marginLeft");
  const tagName = element.tagName.toLowerCase();
  const hasDynamicVar =
    Array.from(element.innerText.matchAll(DYNAMIC_VAR_REGEX)).length &&
    tagName === "span";
  if (children.length === 0) {
    children.push({ text: "" });
  }
  const srcAttribute = element.getAttribute("src");
  return {
    type:
      tagName === "ul"
        ? ElementType.BULLETED_LIST
        : tagName === "ol"
        ? ElementType.NUMBERED_LIST
        : tagName === "li"
        ? ElementType.LIST_ITEM
        : hasDynamicVar
        ? ElementType.DYNAMIC_VAR
        : tagName === "img"
        ? ElementType.IMAGE
        : ElementType.PARAGRAPH,
    ...(alignValue
      ? {
          align:
            alignValue === "left"
              ? ElementAlignment.ALIGN_LEFT
              : alignValue === "center"
              ? ElementAlignment.ALIGN_CENTER
              : alignValue === "right"
              ? ElementAlignment.ALIGN_RIGHT
              : ElementAlignment.ALIGN_JUSTIFY,
        }
      : {}),
    ...(marginLeftValue
      ? {
          indent: parseInt(marginLeftValue.replace("rem", ""), 10),
        }
      : {}),
    ...(hasDynamicVar ? { value: element.innerText } : {}),
    ...(srcAttribute ? { url: srcAttribute } : {}),
    children,
  } as Element;
};

const deserializeFromHTML = (html: string): Descendant[] => {
  const mainContainer = new window.DOMParser().parseFromString(
    html,
    "text/html"
  );
  const result: Descendant[] = [];
  const body = mainContainer.body;

  for (let i = 0; i < body.children.length; i++) {
    const child = body.children[i];
    result.push(
      isTextNode(child as HTMLElement)
        ? parseTextNode(child as HTMLElement)
        : parseElementNode(child as HTMLElement)
    );
  }
  if (result.length === 0) {
    result.push({
      type: ElementType.PARAGRAPH,
      children: [{ text: "" }],
    });
  }
  return result;
};

export default deserializeFromHTML;
