import configService from "../../../config/ConfigService";
import Path from "../../../enum/Path";
import SettingPath from "../../../enum/settings/SettingPath";

const connectToNylas = (clientId: string, scopes: string, redirectUrl?: string) => {
  const queryString = new URLSearchParams({
    access_type: "offline",
    client_id: clientId,
    redirect_uri: redirectUrl ?? window.location.href,
    response_type: "code",
    scopes,
  });
  window.location.href = `https://api.us.nylas.com/v3/connect/auth?${queryString.toString()}`;
};

export const connectToCalendarNylas = () => connectToNylas(configService.getNylasId(), "calendar");

export const connectToMailNylas = () =>
  connectToNylas(
    configService.getNylasMailId(),
    "email.modify,email.send",
    // We need to provide redirection path here, because connection to email can be invoked
    // not only from preferences page
    `${origin}${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`
  );
