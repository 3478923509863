import { activitySaga } from "./activity/sagas";
import { all, fork } from "redux-saga/effects";
import { appSaga } from "./app/sagas";
import { authSaga } from "./auth/sagas";
import { companySaga } from "./company/sagas";
import { componentSaga } from "component/componentSaga";
import { cumulSaga } from "./cumul/sagas";
import { customFieldsSaga } from "./customFields/sagas";
import { dealLossReasonsSaga } from "./dealLossReasons/sagas";
import { dealSaga } from "./deal/sagas";
import { emailSaga } from "./email/sagas";
import { entityAddressSaga } from "./entityAddress/sagas";
import { entityViewSaga } from "./entityView/sagas";
import { errorsSaga } from "./errors/sagas";
import { exportEntitiesSaga } from "./exportEntities/sagas";
import { filesSaga } from "./files/sagas";
import { frequencySaga } from "./frequency/saga";
import { globalSearchSaga } from "./globalSearch/sagas";
import { groupsSaga } from "./groups/sagas";
import { iamSaga } from "./iam/sagas";
import { integrationsSaga } from "./integrations/sagas";
import { layoutSaga } from "./layout/sagas";
import { localeSaga } from "./locale/sagas";
import { locationSaga } from "./location/sagas";
import { membersSaga } from "./members/sagas";
import { notificationSaga } from "./notification/sagas";
import { nylasSaga } from "./nylas/sagas";
import { personSaga } from "./person/sagas";
import { pinLegendsSagas } from "./pinLegends/sagas";
import { recordPreviewSaga } from "./recordPreview/sagas";
import { referenceDataSaga } from "./referenceData/sagas";
import { routesSaga } from "./routes/saga";
import { savedFiltersSaga } from "./savedFilters/sagas";
import { sceneSaga } from "scene/sceneSaga";
import { schemaSaga } from "./schema/sagas";
import { territoriesSaga } from "./territories/sagas";
import { themeSaga } from "./theme/sagas";
import { uiSyncSaga } from "./uiSync/sagas";
import { userColorSaga } from "./userColor/sagas";

// Read more about root saga patterns: https://redux-saga.js.org/docs/advanced/RootSaga.html
export function* rootSaga() {
  yield all([
    fork(activitySaga),
    fork(appSaga),
    fork(authSaga),
    fork(companySaga),
    fork(componentSaga),
    fork(cumulSaga),
    fork(customFieldsSaga),
    fork(dealLossReasonsSaga),
    fork(dealSaga),
    fork(emailSaga),
    fork(entityAddressSaga),
    fork(entityViewSaga),
    fork(errorsSaga),
    fork(exportEntitiesSaga),
    fork(filesSaga),
    fork(frequencySaga),
    fork(globalSearchSaga),
    fork(groupsSaga),
    fork(iamSaga),
    fork(integrationsSaga),
    fork(layoutSaga),
    fork(localeSaga),
    fork(locationSaga),
    fork(membersSaga),
    fork(notificationSaga),
    fork(nylasSaga),
    fork(personSaga),
    fork(pinLegendsSagas),
    fork(recordPreviewSaga),
    fork(referenceDataSaga),
    fork(routesSaga),
    fork(savedFiltersSaga),
    fork(sceneSaga),
    fork(schemaSaga),
    fork(territoriesSaga),
    fork(themeSaga),
    fork(uiSyncSaga),
    fork(userColorSaga),
  ]);
}
