import React from "react";
import styles from "./EmailRecipients.module.scss";
import { useIntl } from "react-intl";
import EmailType from "@mapmycustomers/shared/types/email/Email";
import Email from "component/field/Email";

interface Props {
  recipients: EmailType["recipientAddress"];
}

const EmailRecipients: React.FC<Props> = ({ recipients }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.to}>
        {intl.formatMessage({
          id: "email.parts.recipients",
          defaultMessage: "To",
          description: "Email recipients - to",
        })}
      </div>
      <div>
        {recipients.map((recipient) => (
          <div className={styles.recipient}>
            <span>{recipient.name}</span>
            <span>
              (<Email className={styles.email} showIcon={false} value={recipient.email} />)
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailRecipients;
