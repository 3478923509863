import React from "react";
import { UserRef } from "@mapmycustomers/shared/types/User";
import Avatar from "./index";
import { getUserDisplayNameWithInfo } from "./util/getUserDisplayNameWithInfo";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import styles from "./AvatarCommon.module.scss";
import { useConfigProvider } from "../ConfigProvider";

interface Props {
  actionOnHover?: boolean;
  avatarClassName?: string;
  className?: string;
  size?: "s" | "xs";
  user: UserRef;
}

const AvatarWithName: React.FC<Props> = ({
  actionOnHover,
  avatarClassName,
  className,
  size,
  user,
}) => {
  const configProvider = useConfigProvider();

  return (
    <Tooltip
      title={
        actionOnHover
          ? configProvider.formatMessage("ui.avatar.tooltip.open", {
              name: getUserDisplayNameWithInfo(configProvider, user),
            })
          : getUserDisplayNameWithInfo(configProvider, user)
      }
    >
      <div
        className={cn(
          styles.container,
          {
            [styles.deleted]: !!user.deletedAt || user.deactivated,
            [styles.hoverStyle]: actionOnHover,
          },
          styles[`size_${size}`],
          className
        )}
      >
        <Avatar
          className={cn(styles.avatar, avatarClassName)}
          tooltip={false}
          user={user}
        />
        <span className={styles.name}>
          {getUserDisplayNameWithInfo(configProvider, user)}
        </span>
      </div>
    </Tooltip>
  );
};

export default AvatarWithName;
