import React, { ReactNode, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import cn from "classnames";
import Popover from "antd/es/popover";
import Tooltip from "antd/es/tooltip";
import { Avatar } from "@mapmycustomers/ui";
import Checkbox from "component/input/Checkbox";
import RadioButton from "component/input/RadioButton";
import FieldGrid from "component/FieldGrid";
import EntityAddress from "component/RecordCard/components/EntityAddress";
import EntityTypeShapedIcon from "component/EntityTypeIcon/EntityTypeShapedIcon";
import { RootState } from "store/rootReducer";
import { getRecordPreviewConfigurationColumns } from "store/recordPreview/selectors";
import EntityColumnsConfiguration from "store/recordPreview/EntityColumnsConfiguration";
import Color from "@mapmycustomers/shared/enum/Color";
import { UserRef } from "@mapmycustomers/shared/types/User";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import TypedEntity from "@mapmycustomers/shared/types/base/TypedEntity";
import anyColorToHex from "util/colors/anyColorToHex";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import styles from "./AssociationRow.module.scss";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { TOPMOST_MODAL_ZINDEX } from "util/consts";

interface Props {
  columns: EntityColumnsConfiguration;
  disabled?: boolean;
  entity: Company | Person | Deal;
  icon?: ReactNode;
  multiselect?: boolean;
  onChange?: (entityId: Identified["id"], value: boolean) => void;
  selected: boolean;
  tooltipMessage?: ReactNode;
}

const AssociationRow: React.FC<Props> = ({
  columns,
  disabled,
  entity,
  icon,
  multiselect,
  onChange,
  selected = false,
  tooltipMessage,
}) => {
  const intl = useIntl();

  const entityColumns = useMemo(() => {
    return (columns?.[entity.entity] ?? []).filter(
      (field) =>
        !field.hasFeature(FieldFeature.MAP_PINNED_FIELD) &&
        field.isReadable &&
        field.hasNonEmptyValueFor(entity)
    );
  }, [columns, entity]);

  const labelId = `association_${entity.entity}:${entity.id}`;

  const style = useMemo(
    () => ({
      borderColor: anyColorToHex(
        (entity.entity === EntityType.DEAL ? entity.stage.color : entity.color) ?? Color.BLACK
      ),
    }),
    [entity]
  );

  const handleChange = useCallback(
    (checked: boolean, event: CheckboxChangeEvent) => {
      event.stopPropagation();
      onChange?.(entity.id, checked);
    },
    [entity, onChange]
  );

  const handleClick = useCallback(() => {
    onChange?.(entity.id, !selected);
  }, [entity, onChange, selected]);

  return (
    <div
      className={cn(styles.item, { [styles.disabled]: disabled })}
      onClick={handleClick}
      style={style}
    >
      {multiselect !== undefined && (
        <div className={styles.control}>
          <Tooltip title={tooltipMessage} trigger={disabled && tooltipMessage ? ["hover"] : []}>
            {multiselect ? (
              <Checkbox
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            ) : (
              <RadioButton
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            )}
          </Tooltip>
        </div>
      )}

      <div className={cn(styles.details, { [styles.disabled]: disabled })}>
        <div className={styles.name}>
          <span>{entity.name}</span>

          {entityColumns.length > 0 && (
            <span className={styles.info}>
              <Popover
                arrowPointAtCenter
                content={
                  <div className={styles.content}>
                    <div className={styles.title}>
                      {intl.formatMessage({
                        id: "associations.pinnedFields.title",
                        defaultMessage: "Pinned Fields",
                        description: "Pinned Fields popover title",
                      })}
                    </div>

                    <div className={styles.body}>
                      <FieldGrid
                        className={styles.grid}
                        columns={entityColumns}
                        entity={entity}
                        showEmpty={false}
                      />
                    </div>
                  </div>
                }
                destroyTooltipOnHide
                mouseLeaveDelay={0}
                placement="rightTop"
                // modal has TOPMOST_MODAL_ZINDEX, so we need to override popover's z-index to make it appear over the modal
                zIndex={TOPMOST_MODAL_ZINDEX}
              >
                <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
              </Popover>
            </span>
          )}
        </div>
        <EntityAddress entity={entity} showWarning={false} />
      </div>

      <div className={styles.icon}>
        {icon ? (
          <>{icon}</>
        ) : "entity" in entity ? (
          <>
            {"user" in entity ? (
              <Avatar user={(entity as unknown as { user: UserRef }).user} />
            ) : "entity" in entity ? (
              <EntityTypeShapedIcon
                containerClassName={styles.typeIcon}
                entityType={(entity as unknown as TypedEntity<EntityType>).entity}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  columns: getRecordPreviewConfigurationColumns(state),
});

export default connect(mapStateToProps)(AssociationRow);
