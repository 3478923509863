import { defineMessages } from "react-intl";

const messages = defineMessages({
  applyTemplate: {
    id: "email.creationModal.templates.applyTemplate",
    defaultMessage: "Apply Template",
    description: "Manage email templates modal - Apply Template",
  },
  cancel: {
    id: "email.creationModal.templates.cancel",
    defaultMessage: "Cancel",
    description: "Cancel",
  },
  delete: {
    id: "email.creationModal.templates.delete",
    defaultMessage: "Delete",
    description: "Manage email templates modal - Delete",
  },
  deleteConfirmationCancel: {
    id: "email.creationModal.templates.deleteConfirmation.cancel",
    defaultMessage: "Cancel",
    description: "Manage email templates modal - Cancel",
  },
  deleteConfirmationOk: {
    id: "email.creationModal.templates.deleteConfirmation.ok",
    defaultMessage: "Delete",
    description: "Manage email templates modal - Delete",
  },
  deleteConfirmationText: {
    id: "email.creationModal.templates.deleteConfirmation.text",
    defaultMessage: "Are you sure? Deleting templates cannot be undone.",
    description: "Manage email templates modal - Are you sure you want to delete this template?",
  },
  emailTemplates: {
    id: "email.creationModal.templates.emailTemplates",
    defaultMessage: "Email Templates",
    description: "Manage email templates modal - Email Templates",
  },
  mostRecent: {
    id: "email.creationModal.templates.mostRecent",
    defaultMessage: "Most Recent",
    description: "Manage email templates modal - Most Recent",
  },
  okay: {
    id: "email.creationModal.templates.okay",
    defaultMessage: "Okay",
    description: "Okay",
  },
  previewAs: {
    id: "email.creationModal.templates.previewAs",
    defaultMessage: "Preview As",
    description: "Manage email templates modal - Preview As",
  },
  previewTemplateDescription: {
    id: "email.creationModal.templates.previewTemplateDescription",
    defaultMessage:
      'The "Preview As" email feature lets you see how an email would look to a particular recipient, helping you check if personalized details are working correctly.',
    description: "Preview Template Description",
  },
  rename: {
    id: "email.creationModal.templates.rename",
    defaultMessage: "Rename",
    description: "Manage email templates modal - Rename",
  },
  save: {
    id: "email.creationModal.templates.save",
    defaultMessage: "Save",
    description: "Save",
  },
  saveNew: {
    id: "email.creationModal.templates.saveNew",
    defaultMessage: "Save New",
    description: "Manage email templates modal - Save New",
  },
  saveNewDisabled: {
    id: "email.creationModal.templates.saveNewDisabled",
    defaultMessage: "Fill out both subject and body to save new template",
    description: "Manage email templates modal - save new disabled",
  },
  saveNewTemplate: {
    id: "email.creationModal.templates.saveNewTemplate",
    defaultMessage: "Save New Template",
    description: "Manage email templates modal - Save New",
  },
  seeMore: {
    id: "email.creationModal.templates.moreTemplates",
    defaultMessage: "More Templates",
    description: "Manage email templates modal - See More",
  },
  selectTemplate: {
    id: "email.creationModal.templates.selectTemplate",
    defaultMessage: "Select Template",
    description: "Manage email templates modal - Select Template",
  },
  templateName: {
    id: "email.creationModal.templates.templateName",
    defaultMessage: "Template Name",
    description: "Template Name",
  },
  update: {
    id: "email.creationModal.templates.update",
    defaultMessage: "Update",
    description: "Update",
  },
  updateConfirmationCancel: {
    id: "email.creationModal.templates.updateConfirmation.cancel",
    defaultMessage: "No",
    description: "Manage email templates modal - Cancel",
  },
  updateConfirmationOk: {
    id: "email.creationModal.templates.updateConfirmation.ok",
    defaultMessage: "Yes",
    description: "Manage email templates modal - Update",
  },
  updateConfirmationText: {
    id: "email.creationModal.templates.updateConfirmation.text",
    defaultMessage: "This template will be overridden by the current email. Continue?",
    description: "Manage email templates modal - Are you sure you want to update this template?",
  },
  updateDescription: {
    id: "email.creationModal.templates.updateDescription",
    defaultMessage: "Save changes to template",
    description: "Update Description",
  },
});

export default messages;
