import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import AddOrCreateButton from "component/preview/components/AddOrCreateButton";
import { AutoCompleteField, Labeled } from "@mapmycustomers/ui";
import getSearchOptions from "./getSearchOptions";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import { EntityTypesSupportedByActivities } from "@mapmycustomers/shared/types/entity";
import styles from "./EntitySearch.module.scss";
import EntityTypeShapedIcon from "component/EntityTypeIcon/EntityTypeShapedIcon";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props<T> {
  allowAdd?: boolean;
  disabled?: boolean;
  entities?: T[];
  entityType: EntityTypesSupportedByActivities;
  keys?: string[];
  label: React.ReactNode;
  loading: boolean;
  onAddClick?: () => void;
  onRemove: () => void;
  onSearch?: (query: string) => void;
  onSelect: (entity: T) => void;
  placeholder?: string;
  primaryEntities?: T[];
  required?: boolean;
  selectedEntity?: T;
  value?: string;
}

const EntitySearch = <T extends Company | Deal | Person>({
  allowAdd,
  disabled,
  entities,
  entityType,
  label,
  onAddClick,
  onRemove,
  onSearch,
  onSelect,
  placeholder,
  primaryEntities,
  required,
  selectedEntity,
  value,
}: Props<T>) => {
  const intl = useIntl();

  const [search, setSearch] = useState(value ?? "");
  const handleSearch = useCallback((value: string) => setSearch(value), []);

  useEffect(() => {
    onSearch?.(search);
  }, [search, onSearch]);

  const options = useMemo(
    () => getSearchOptions(primaryEntities, entities, entityType),
    [entities, entityType, primaryEntities]
  );

  const handleSelect = useCallback(
    (value: string) => {
      const entityId = parseInt(value, 10);
      const entity = [...(entities ?? []), ...(primaryEntities ?? [])].find(
        ({ id }) => id === entityId
      );
      if (entity) {
        onSelect(entity);
      }
    },
    [entities, primaryEntities, onSelect]
  );

  const handleRemove = useCallback(
    (event) => {
      stopEvents(event);
      onRemove();
      setSearch("");
    },
    [onRemove, setSearch]
  );

  return (
    <Labeled
      extra={
        allowAdd ? (
          <AddOrCreateButton
            className={styles.addAssociation}
            label={intl.formatMessage({
              id: "preview.activityList.create",
              defaultMessage: "Add",
              description: "Title of add button for activities",
            })}
            onClick={onAddClick}
            size="small"
          />
        ) : null
      }
      label={label}
      required={required}
    >
      <AutoCompleteField
        className={styles.associatedEntitySelect}
        disabled={disabled}
        onSearch={handleSearch}
        onSelect={handleSelect}
        options={options}
        placeholder={placeholder}
        prefixIcon={
          <EntityTypeShapedIcon
            containerClassName={styles.entityIcon}
            entityType={entityType}
            size="2xs"
          />
        }
        suffixIcon={
          selectedEntity && !disabled ? (
            <FontAwesomeIcon icon={faTimes} onClick={handleRemove} />
          ) : undefined
        }
        value={selectedEntity?.name ?? value ?? search}
      />
    </Labeled>
  );
};

export default EntitySearch;
