import React from "react";
import AntdCollapse, { CollapseProps } from "antd/es/collapse";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import styles from "./Collapse.module.scss";

export const CollapsePanel = AntdCollapse.Panel;

const Collapse: React.FC<CollapseProps> = ({ children, className, ...restProps }) => {
  return (
    <AntdCollapse
      className={cn(styles.container, className)}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <FontAwesomeIcon
          className={cn(styles.icon, { [styles.rotatedIcon]: isActive })}
          icon={faAngleRight}
        />
      )}
      {...restProps}
    >
      {children}
    </AntdCollapse>
  );
};

export default Collapse;
