import React, { useCallback, useState } from "react";
import { Modal } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import { EntityTypeSupportingRoutes, Route } from "@mapmycustomers/shared/types/entity";
import useFindMyLocation from "util/hook/useFindMyLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import RouteName from "component/input/CreateRoute/RouteName";
import VisitOrder, { VisitType } from "component/input/CreateRoute/VisitOrder";
import AllottedTime from "component/input/CreateRoute/AllottedTime";
import StartingLocation from "component/input/CreateRoute/StartingLocation";
import EndingLocation from "component/input/CreateRoute/EndingLocation";
import { TOPMOST_MODAL_ZINDEX } from "util/consts";
import styles from "./CreateRouteModal.module.scss";
import StartDateTime from "../input/CreateRoute/StartDateTime";
import { RoutePayload } from "@mapmycustomers/shared/types/entity/Route";
import getDefaultStartDate from "../../util/route/getDefaultStartDate";
import { reverseGeocodeAddress } from "store/location/actions";
import { connect } from "react-redux";

interface Props {
  createOnly?: boolean;
  creating?: boolean;
  entityType: EntityTypeSupportingRoutes;
  onCreate: (route: RoutePayload) => void;
  onHide: () => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  routes: Pick<Route, "name">[];
}

const CreateRoutePureModal: React.FC<Props> = ({
  createOnly,
  creating,
  entityType,
  onCreate,
  onHide,
  onReverseGeocodeAddress,
  routes,
}) => {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [visitOrder, setVisitOrder] = useState<VisitType>("optimized");
  const [allottedTime, setAllottedTime] = useState<number>(0);
  const [startTime, setStartTime] = useState<Date>(getDefaultStartDate());
  const [nameError, setNameError] = useState<string | undefined>();
  const [startAddressEmptyError, setStartAddressEmptyError] = useState<string | undefined>();
  const [startAddress, setStartAddress, , startAddressError, findMeForStartAddress] =
    useFindMyLocation(onReverseGeocodeAddress);
  const [endAddress, setEndAddress, , endAddressError, findMeForEndAddress] =
    useFindMyLocation(onReverseGeocodeAddress);

  const q = name.trim();
  const isDuplicateName = routes.some(({ name }) => name === q);
  const isValidRoute = !isDuplicateName && visitOrder && allottedTime !== undefined;
  const handleCreateClick = useCallback(() => {
    let allowSave = true;
    setNameError(undefined);
    setStartAddressEmptyError(undefined);
    if (name.trim().length === 0) {
      setNameError(
        intl.formatMessage({
          id: "createRouteModal.name.emptyError",
          defaultMessage: "Name should not be empty",
          description: "Empty name error of Create Route modal",
        })
      );
      allowSave = false;
    }
    if (startAddress.trim().length === 0) {
      setStartAddressEmptyError(
        intl.formatMessage({
          id: "createRouteModal.startAddress.emptyError",
          defaultMessage: "Start address should not be empty",
          description: "Empty address error of Create Route modal",
        })
      );
      allowSave = false;
    }
    if (!allowSave) {
      return;
    }

    onCreate({
      entityType,
      name,
      routeDetail: {
        allottedTime,
        endAddress,
        startAddress,
        type: visitOrder,
        startAt: startTime.toISOString(),
      },
    });
  }, [
    allottedTime,
    endAddress,
    entityType,
    intl,
    name,
    onCreate,
    setNameError,
    setStartAddressEmptyError,
    startAddress,
    startTime,
    visitOrder,
  ]);

  return (
    <Modal
      okButtonProps={{
        disabled: !isValidRoute || creating,
        loading: creating,
        icon: <FontAwesomeIcon icon={faRoute} />,
      }}
      okText={
        createOnly
          ? intl.formatMessage({
              id: "createRouteModal.footer.createOnly",
              defaultMessage: "Create Route",
              description: "Create button in a footer of Create Route modal",
            })
          : intl.formatMessage({
              id: "createRouteModal.footer.create",
              defaultMessage: "Create & Add to Route",
              description: "Create button in a footer of Create Route modal",
            })
      }
      onCancel={onHide}
      onOk={handleCreateClick}
      title={intl.formatMessage({
        id: "createRouteModal.header",
        defaultMessage: "Create Route",
        description: "Header of Create Route modal",
      })}
      visible
      zIndex={TOPMOST_MODAL_ZINDEX}
    >
      <section className={styles.content}>
        <RouteName
          error={nameError}
          isDuplicateName={isDuplicateName}
          onChange={setName}
          value={name}
        />
        <VisitOrder onChange={setVisitOrder} value={visitOrder} />
        <StartDateTime onChange={setStartTime} value={startTime} />
        <AllottedTime onChange={setAllottedTime} value={allottedTime} />
        <StartingLocation
          error={startAddressEmptyError ?? startAddressError}
          onChange={setStartAddress}
          onFindMe={findMeForStartAddress}
          value={startAddress}
        />
        <EndingLocation
          error={endAddressError}
          onChange={setEndAddress}
          onFindMe={findMeForEndAddress}
          value={endAddress}
        />
      </section>
    </Modal>
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps)(CreateRoutePureModal);
