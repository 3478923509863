import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import { isDefined } from "../assert";

const getErrorMessages = (error: Error | undefined): string[] => {
  if (!error || !(error instanceof ApiError) || !error.errors.length) {
    return [];
  }

  return error.errors.map((backendError) => backendError?.message).filter(isDefined);
};

export default getErrorMessages;
