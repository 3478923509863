import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import styles from "./EmailPeriod.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import layout from "styles/layout";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getOrganizationSettingValue, getUserSettings } from "store/iam";
import Setting from "@mapmycustomers/shared/types/Setting";
import messages from "./messages";
import ButtonLink from "../../ButtonLink";
import Path from "enum/Path";
import SettingPath from "enum/settings/SettingPath";
import { Link } from "react-router-dom";
import OrganizationSetting from "../../../enum/OrganizationSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import TimeZones from "../../../enum/TimeZones";
import TimeZone from "@mapmycustomers/shared/types/TimeZone";

interface Props {
  onEmailQueueOnChangeHours?: () => void;
  onSettings?: () => void;
  settings: Setting[];
  showIcon?: boolean;
  timezoneSettingValue?: string;
}

const EmailPeriod: React.FC<Props> = ({
  onEmailQueueOnChangeHours,
  onSettings,
  settings,
  showIcon,
  timezoneSettingValue,
}) => {
  const intl = useIntl();

  const bulkEmail: Setting = useMemo(
    () => settings.find(({ key }) => key === "bulkEmail")!,
    [settings]
  );

  return (
    <div>
      <Row align="middle" gutter={layout.spacerM}>
        {showIcon ? <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} /> : null}
        <Col className={styles.container}>
          {intl.formatMessage(messages.period, {
            endTime: bulkEmail.value.endTime ?? "",
            startTime: bulkEmail.value.startTime ?? "",
            timezone: intl.formatMessage(TimeZones[timezoneSettingValue as TimeZone]) ?? "",
          })}
        </Col>
        <Col>
          <Link to={`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`}>
            <ButtonLink onClick={onSettings}>
              {intl.formatMessage(messages.changePeriod)}
            </ButtonLink>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: getUserSettings(state)!,
  timezoneSettingValue: getOrganizationSettingValue(state)(OrganizationSetting.TIMEZONE),
});

export default connect(mapStateToProps)(EmailPeriod);
