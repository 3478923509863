import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Alert } from "@mapmycustomers/ui";
import styles from "./RelatedEntitiesMatching.module.scss";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { messages } from "./messages";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { isEntityRelating } from "../../store";
import { relateEntities } from "../../store/actions";
import { LoadingSpinner } from "@mapmycustomers/ui";
import ButtonLink from "component/ButtonLink";

interface Props {
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  isEntityRelating: boolean;
  relateEntities: typeof relateEntities.request;
}

const RelatedEntitiesMatching: React.FC<Props> = ({
  associatedCompany,
  associatedDeal,
  associatedPerson,
  isEntityRelating,
  relateEntities,
}) => {
  const intl = useIntl();

  const [error, setError, unsetError] = useBoolean();
  const [success, setSuccess, unsetSuccess] = useBoolean();
  const [visible, show, hide] = useBoolean();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [savedCompanyId, setSavedCompanyId] = useState<Company["id"] | undefined>();
  const [savedDealId, setSavedDealId] = useState<Deal["id"] | undefined>();
  const [savedPersonId, setSavedPersonId] = useState<Person["id"] | undefined>();

  const isPersonCorrectlyRelatedToCompany =
    !associatedCompany ||
    !associatedPerson ||
    (associatedPerson?.accounts ?? []).some(({ id }) => associatedCompany?.id === id);
  const isDealCorrectlyRelatedToCompany =
    !associatedDeal || !associatedCompany || associatedCompany?.id === associatedDeal?.account?.id;
  const isDealCorrectlyRelatedToPerson =
    !associatedDeal || !associatedPerson || associatedPerson?.id === associatedDeal?.contact?.id;

  useEffect(() => {
    if (
      savedCompanyId !== associatedCompany?.id ||
      savedDealId !== associatedDeal?.id ||
      savedPersonId !== associatedPerson?.id
    ) {
      unsetSuccess();
      unsetError();
    }
    setSavedCompanyId(associatedCompany?.id);
    setSavedDealId(associatedDeal?.id);
    setSavedPersonId(associatedPerson?.id);
  }, [
    associatedCompany?.id,
    associatedDeal?.id,
    associatedPerson?.id,
    savedCompanyId,
    savedDealId,
    savedPersonId,
    unsetError,
    unsetSuccess,
  ]);

  const message = useMemo(() => {
    if (success) {
      return successMessage;
    }
    if (
      !isPersonCorrectlyRelatedToCompany &&
      !(isDealCorrectlyRelatedToCompany && isDealCorrectlyRelatedToPerson)
    ) {
      return intl.formatMessage(messages.companyPersonDealWrongRelation, { error });
    }
    if (isPersonCorrectlyRelatedToCompany) {
      if (!isDealCorrectlyRelatedToCompany) {
        return intl.formatMessage(messages.companyDealWrongRelation, { error });
      } else if (!isDealCorrectlyRelatedToPerson) {
        return intl.formatMessage(messages.personDealWrongRelation, { error });
      }
    } else {
      return intl.formatMessage(messages.companyPersonWrongRelation, { error });
    }
    return "";
  }, [
    error,
    intl,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    success,
    successMessage,
  ]);

  const handleRelateEntities = useCallback(() => {
    relateEntities({
      associatedCompany,
      associatedDeal,
      associatedPerson,
      failureCallback: () => {
        setError();
        unsetSuccess();
      },
      isDealCorrectlyRelatedToCompany,
      isDealCorrectlyRelatedToPerson,
      isPersonCorrectlyRelatedToCompany,
      successCallback: () => {
        unsetError();
        setSuccess();
      },
    });
  }, [
    associatedCompany,
    associatedDeal,
    associatedPerson,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    relateEntities,
    setError,
    setSuccess,
    unsetError,
    unsetSuccess,
  ]);

  useEffect(() => {
    if (
      !isPersonCorrectlyRelatedToCompany &&
      !(isDealCorrectlyRelatedToCompany && isDealCorrectlyRelatedToPerson)
    ) {
      setSuccessMessage(intl.formatMessage(messages.companyPersonDealWrongRelationRelated));
    }
    if (isPersonCorrectlyRelatedToCompany) {
      if (!isDealCorrectlyRelatedToCompany) {
        setSuccessMessage(intl.formatMessage(messages.companyDealWrongRelationRelated));
      } else if (!isDealCorrectlyRelatedToPerson) {
        setSuccessMessage(intl.formatMessage(messages.personDealWrongRelationRelated));
      }
    } else {
      setSuccessMessage(intl.formatMessage(messages.companyPersonWrongRelationRelated));
    }
  }, [
    intl,
    setSuccessMessage,
    isPersonCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
  ]);

  useEffect(() => {
    if (
      success ||
      !isDealCorrectlyRelatedToCompany ||
      !isDealCorrectlyRelatedToPerson ||
      !isPersonCorrectlyRelatedToCompany
    ) {
      show();
    } else {
      hide();
    }
  }, [
    show,
    hide,
    success,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      action={
        isEntityRelating ? (
          <LoadingSpinner micro />
        ) : success ? undefined : (
          <ButtonLink onClick={handleRelateEntities}>
            {error
              ? intl.formatMessage(messages.tryAgain)
              : intl.formatMessage(messages.relateRecords)}
          </ButtonLink>
        )
      }
      className={styles.container}
      closable={success}
      message={message}
      onClose={hide}
      showIcon
      type={success ? "success" : error ? "error" : "warning"}
    />
  );
};

export const mapStateToProps = (state: RootState) => ({
  isEntityRelating: isEntityRelating(state),
});

const mapDispatchToProps = {
  relateEntities: relateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedEntitiesMatching);
