import React, { useCallback, useMemo, useState } from "react";
import Team from "@mapmycustomers/shared/types/Team";
import TextField from "../input/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import Button from "antd/es/button";
import cn from "classnames";
import { useConfigProvider } from "../ConfigProvider";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";
import { bem } from "@react-md/utils";
import TeamItem from "../common/TeamItem";
import SelectableTeam from "@mapmycustomers/shared/types/ui/SelectableTeam";

const block = bem("mmc-team-picker");

export const messages = {
  "ui.teamPicker.search.placeholder": "Search teams",
  "ui.teamPicker.deselectAll": "Deselect All",
  "ui.teamPicker.selectAll": "Select All",
} as const;

export interface TeamPickerProps {
  className?: string;
  onChange?: (value: Team["id"][]) => void;
  teams: Team[];
  value: Team["id"][];
}

export const TeamPicker: React.FC<TeamPickerProps> = ({
  className,
  onChange,
  teams,
  value,
}) => {
  const configProvider = useConfigProvider();
  const [query, setQuery] = useState("");

  const filteredTeams = useMemo(
    () =>
      teams
        .filter(
          (team) =>
            !query || team.name.toLowerCase().includes(query.toLowerCase())
        )
        .sort(nameComparator),
    [query, teams]
  );

  const handleToggle = useCallback(
    (team: SelectableTeam) => {
      if (typeof team === "object") {
        if (value.includes(team.id)) {
          onChange?.(value.filter((id) => id !== team.id));
        } else {
          onChange?.([...value, team.id]);
        }
      }
    },
    [value]
  );

  const handleSelectAll = useCallback(
    () => onChange?.(teams.map(({ id }) => id)),
    [teams]
  );
  const handleDeselectAll = useCallback(() => onChange?.([]), []);

  return (
    <div className={cn(block(), className)}>
      <TextField
        className={block("search-field")}
        onChange={setQuery}
        placeholder={configProvider.formatMessage(
          "ui.teamPicker.search.placeholder"
        )}
        size="middle"
        suffix={
          <FontAwesomeIcon className={block("search-icon")} icon={faSearch} />
        }
        value={query}
      />
      <div className={block("team-list")}>
        {filteredTeams.map((team) => {
          return (
            <TeamItem
              key={team.id}
              onClick={handleToggle}
              selected={value.includes(team.id)}
              team={team}
            />
          );
        })}
      </div>
      <div className={block("buttons")}>
        <Button
          className={block("deselect-button")}
          onClick={handleDeselectAll}
          size="small"
        >
          {configProvider.formatMessage("ui.teamPicker.deselectAll")}
        </Button>
        <Button
          className={cn(block("select-button"))}
          onClick={handleSelectAll}
          size="small"
          type="text"
        >
          {configProvider.formatMessage("ui.teamPicker.selectAll")}
        </Button>
      </div>
    </div>
  );
};

export default TeamPicker;
