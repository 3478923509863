import {
  Actions,
  addToRoute,
  createRoute,
  filterRoutes,
  hideCreateRouteModal,
  initialize,
  selectRoute,
  showCreateRouteModal,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { EntitiesSupportingRoutes, Route } from "@mapmycustomers/shared/types/entity";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

export interface AddToRouteModalState {
  addToRouteLoading: boolean;
  autoAssignOnCreate: boolean | undefined;
  createLoading: boolean;
  createRouteModalVisible: boolean;
  entities: EntitiesSupportingRoutes[];
  initializeLoading: boolean;
  routes: Route[];
  selectedRouteId: Route["id"] | undefined;
}

const initialState: AddToRouteModalState = {
  addToRouteLoading: false,
  autoAssignOnCreate: false,
  createLoading: false,
  createRouteModalVisible: false,
  entities: [],
  initializeLoading: false,
  routes: [],
  selectedRouteId: undefined,
};

const addToRouteModal = createReducer<AddToRouteModalState, Actions>(initialState)
  .handleAction(selectRoute, (state, action) => ({
    ...state,
    selectedRouteId: action.payload,
  }))
  .handleAction(initialize.request, (state, action) => ({
    ...state,
    autoAssignOnCreate: action.payload.autoAssignOnCreate,
    entities: action.payload.entities,
    initializeLoading: true,
  }))
  .handleAction(initialize.success, (state, action) => ({
    ...state,
    initializeLoading: false,
    routes: action.payload.routes,
  }))
  .handleAction(initialize.failure, (state) => ({
    ...state,
    initializeLoading: false,
  }))
  .handleAction(addToRoute.request, (state) => ({
    ...state,
    addToRouteLoading: true,
  }))
  .handleAction(addToRoute.success, (state) => ({
    ...state,
    addToRouteLoading: false,
  }))
  .handleAction(addToRoute.failure, (state, action) => ({
    ...state,
    addToRouteLoading: false,
  }))
  .handleAction(createRoute.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction(createRoute.success, (state, action) => ({
    ...state,
    createLoading: false,
    createRouteModalVisible: false,
    routes: [...state.routes, action.payload].sort(nameComparator),
    selectedRouteId: action.payload.id,
  }))
  .handleAction(createRoute.failure, (state, action) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(filterRoutes.success, (state, { payload }) => ({
    ...state,
    routes: payload,
  }))
  .handleAction(showCreateRouteModal, (state) => ({
    ...state,
    createRouteModalVisible: true,
  }))
  .handleAction(hideCreateRouteModal, (state) => ({
    ...state,
    createRouteModalVisible: false,
  }));

export * from "./selectors";
export type AddToRouteModalActions = Actions;
export default addToRouteModal;
