import React from "react";
import LockedArea from "./LockedArea";
import ActivityTypeIcon from "../../../../activity/ActivityTypeIcon";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { useIntl } from "react-intl";
import Form from "antd/es/form";

interface Props {
  activityType: ActivityType;
  isAutoEmail?: boolean;
}

const LockedActivityType: React.FC<Props> = ({ activityType, isAutoEmail }) => {
  const intl = useIntl();

  return (
    <LockedArea
      icon={<ActivityTypeIcon activityType={activityType} isAutoEmail={isAutoEmail} size="sm" />}
      tooltip={intl.formatMessage({
        id: "createActivityModal.lockedActivityType.tooltip",
        defaultMessage: "Activity type cannot be changed",
        description: "Tooltip for locked activity type",
      })}
    >
      <div>
        {isAutoEmail
          ? intl.formatMessage({
              id: "createActivityModal.lockedActivityType.autoEmail",
              defaultMessage: "Auto Email",
              description: "Auto Email activity type name for locked activity type",
            })
          : activityType.name}
      </div>
      <Form.Item hidden name="activityTypeId" />
    </LockedArea>
  );
};

export default LockedActivityType;
