import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from "react";
import { RefSelectProps, SelectValue } from "antd/es/select";
import Labeled, { LabeledFieldProps } from "../Labeled";
import ErrorRow from "../ErrorRow";
import cn from "classnames";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import AutoComplete, { AutoCompleteProps } from "antd/es/auto-complete";
import Input from "antd/es/input/Input";

const block = bem("mmc-auto-complete-field");

export interface AutoCompleteFieldProps<T extends SelectValue = SelectValue>
  extends Omit<AutoCompleteProps<T>, "className" | "ref">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  error?: ReactNode;
  locked?: boolean;
  prefixIcon?: ReactNode;
  selectClassName?: string;
}

const AutoCompleteField = <T extends SelectValue = SelectValue>(
  {
    caption,
    className,
    disabled,
    error,
    label,
    labelClassName,
    labelPosition,
    locked,
    placeholder,
    prefixIcon,
    required,
    rowProps,
    selectClassName,
    sideLabelSpan,
    suffixIcon,
    size = "large",
    ...props
  }: AutoCompleteFieldProps<T>,
  ref: React.Ref<AutoCompleteFieldComponent>
) => {
  const autoCompleteRef = useRef<RefSelectProps>(null);

  useImperativeHandle(ref, () => ({
    focus: () => autoCompleteRef.current?.focus(),
    blur: () => autoCompleteRef.current?.blur(),
  }));

  return (
    <Labeled
      className={cn(
        block({ hasIcon: !!prefixIcon, disabled: disabled || locked }),
        className
      )}
      label={label}
      labelClassName={cn(block("label"), labelClassName)}
      labelPosition={labelPosition}
      required={required}
      rowProps={rowProps}
      sideLabelSpan={sideLabelSpan}
    >
      <div className={block("auto-complete")}>
        <div className={block("auto-complete-row")}>
          {prefixIcon && (
            <div className={block("icon-plate")}>{prefixIcon}</div>
          )}
          <AutoComplete
            className={cn(block("auto-complete-field"), selectClassName)}
            disabled={disabled || locked}
            placeholder={placeholder ?? "Select"}
            ref={autoCompleteRef}
            size={size}
            {...props}
          >
            <Input suffix={suffixIcon} size="large" />
          </AutoComplete>
          {locked && (
            <FontAwesomeIcon className={block("lock")} icon={faLock} />
          )}
        </div>
        {error ? (
          <ErrorRow>{error}</ErrorRow>
        ) : caption ? (
          <div className={block("caption")}>{caption}</div>
        ) : null}
      </div>
    </Labeled>
  );
};

export interface AutoCompleteFieldComponent {
  focus: () => void;
  blur(): void;
}

export default forwardRef(AutoCompleteField) as <
  T extends SelectValue = SelectValue
>(
  props: AutoCompleteFieldProps<T> & {
    ref?: React.Ref<AutoCompleteFieldComponent>;
  }
) => React.ReactElement;
