import React, { useCallback } from "react";
import { Editor } from "slate";
import ColorDropdown from "../../../colorComponents/ColorDropdown";
import { faA, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../Toolbar.module.scss";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { Col, Row } from "antd";
import { useSlate } from "slate-react";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import Tooltip from "antd/es/tooltip";
import { useConfigProvider } from "../../../ConfigProvider";

interface Props {}

const TextColorToggler: React.FC<Props> = () => {
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const handleToggleBlock = useCallback((color: AnyColor) => {
    Editor.addMark(editor, "color", color);
  }, []);

  const color = Editor.marks(editor)?.color ?? ("black" as AnyColor);

  return (
    <ColorDropdown
      colorPanelProps={{ showColorSpace: true, showNamedColors: true }}
      onChange={handleToggleBlock}
      placement="top"
      value={color}
    >
      <Tooltip
        title={configProvider.formatMessage("ui.emailBody.toolbar.textColor")}
      >
        <Row
          align="middle"
          className={styles.togglerButton}
          gutter={4}
          onMouseDown={stopEvents}
        >
          <Col>
            <FontAwesomeIcon
              style={{
                borderBottom: `2px solid ${color}`,
              }}
              icon={faA}
            />
          </Col>
          <Col>
            <FontAwesomeIcon
              className={styles.dropDownChevron}
              icon={faChevronDown}
              size="xs"
            />
          </Col>
        </Row>
      </Tooltip>
    </ColorDropdown>
  );
};

export default TextColorToggler;
