import { createReducer } from "typesafe-actions";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import {
  Actions,
  changeAssociatedEntities,
  clearActivity,
  clearAllUploadedCreateEditActivityFiles,
  clearAssociations,
  createActivity,
  deleteActivity,
  fetchFilePreview,
  hideGlobalCreateActivityModal,
  initializeActivityModal,
  initializeEditActivityModal,
  relateEntities,
  removeCreateEditActivityFile,
  showGlobalCreateActivityModal,
  updateActivity,
  uploadCreateEditActivityFiles,
} from "./actions";
import FileListItem from "types/FileListItem";
import { RawFile } from "@mapmycustomers/shared/types/File";
import AssociationsState from "store/associations/AssociationsState";
import { DEFAULT_ASSOCIATIONS_STATE } from "store/associations/const";

export interface CreateEditActivityModalState {
  activityCompleted: boolean;
  activityTypes: ActivityType[];
  associatedFiles: RawFile[];
  associations: AssociationsState;
  cloning: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  editedActivity: Activity | undefined;
  entityRelating: boolean;
  filePreview?: Blob;
  filePreviewId?: RawFile["id"];
  filePreviewLoading: boolean;
  filesAdded: FileListItem[];
  fileGroupId: string;
  filesUploading: boolean;
  initializeLoading: boolean;
  isWarningDismissed: boolean;
  noAccess?: boolean;
  prefilledValues: Partial<Omit<Activity, "id">> | undefined;
  updateLoading: boolean;
  visible: boolean;
}

const initialState: CreateEditActivityModalState = {
  activityCompleted: false,
  activityTypes: [],
  associatedFiles: [],
  associations: DEFAULT_ASSOCIATIONS_STATE,
  cloning: false,
  createLoading: false,
  deleteLoading: false,
  editedActivity: undefined,
  entityRelating: false,
  filePreviewLoading: false,
  filesAdded: [],
  fileGroupId: "",
  filesUploading: false,
  initializeLoading: false,
  isWarningDismissed: false,
  prefilledValues: undefined,
  updateLoading: false,
  visible: false,
};

const createEditActivityModal = createReducer<CreateEditActivityModalState, Actions>(initialState)
  .handleAction(showGlobalCreateActivityModal, (state, { payload }) => ({
    ...state,
    cloning: payload?.cloning ?? false,
    prefilledValues: payload?.activity,
    visible: true,
  }))
  .handleAction(hideGlobalCreateActivityModal, (state) => ({
    ...state,
    cloning: false,
    prefilledValues: undefined,
    visible: false,
  }))
  .handleAction(initializeActivityModal.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.fixedCompany,
      associatedDeal: payload.fixedDeal,
      associatedPerson: payload.fixedPerson,
      inaccessibleEntity: false,
    },
    initializeLoading: true,
  }))
  .handleAction(initializeEditActivityModal.request, (state) => ({
    ...state,
    initializeLoading: true,
  }))
  .handleAction(initializeActivityModal.success, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      ...payload.associationsState,
    },
    fileGroupId: payload.fileGroupId,
    initializeLoading: false,
  }))
  .handleAction(initializeEditActivityModal.success, (state, { payload }) => ({
    ...state,
    activityCompleted: payload.activityCompleted,
    associatedFiles: payload.files,
    associations: {
      ...state.associations,
      inaccessibleEntity:
        !!payload.activity &&
        !!payload.activity.assignee?.id &&
        (payload.activity.account?.userIds?.includes(payload.activity.assignee?.id) === false ||
          payload.activity.contact?.userIds?.includes(payload.activity.assignee?.id) === false ||
          payload.activity.deal?.userIds?.includes(payload.activity.assignee?.id) === false),
    },
    editedActivity: payload.activity,
    fileGroupId: payload.fileGroupId,
    noAccess: false,
    // not setting initializeLoading to false, because initializeEditActivityModal calls initializeActivityModal
    // immediately, so it doesn't make sense to set it to false because it will be set to true again
  }))
  .handleAction(
    [initializeActivityModal.failure, initializeEditActivityModal.failure],
    (state, { payload }) => ({
      ...state,
      initializeLoading: false,
      noAccess: !!payload,
    })
  )
  .handleAction(createActivity.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction([createActivity.success, createActivity.failure], (state) => ({
    ...state,
    createLoading: false,
  }))
  .handleAction(updateActivity.request, (state) => ({
    ...state,
    updateLoading: true,
  }))
  .handleAction([updateActivity.success, updateActivity.failure], (state) => ({
    ...state,
    updateLoading: false,
  }))
  .handleAction(deleteActivity.request, (state) => ({
    ...state,
    deleteLoading: true,
  }))
  .handleAction([deleteActivity.success, deleteActivity.failure], (state) => ({
    ...state,
    deleteLoading: false,
  }))
  .handleAction(changeAssociatedEntities.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany:
        "company" in payload ? payload.company : state.associations.associatedCompany,
      associatedDeal: "deal" in payload ? payload.deal : state.associations.associatedDeal,
      associatedPerson: "person" in payload ? payload.person : state.associations.associatedPerson,
      availableLoading: true,
    },
  }))
  .handleAction(changeAssociatedEntities.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload, availableLoading: false },
  }))
  .handleAction(changeAssociatedEntities.failure, (state) => ({
    ...state,
    associations: { ...state.associations, availableLoading: false },
  }))
  .handleAction(clearActivity, (state) => ({
    ...state,
    editedActivity: undefined,
  }))
  .handleAction(clearAssociations, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany:
        payload?.[EntityType.COMPANY] ?? true ? undefined : state.associations.associatedCompany,
      associatedDeal:
        payload?.[EntityType.DEAL] ?? true ? undefined : state.associations.associatedDeal,
      associatedPerson:
        payload?.[EntityType.PERSON] ?? true ? undefined : state.associations.associatedPerson,
      inaccessibleEntity: false,
    },
  }))
  .handleAction(uploadCreateEditActivityFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadCreateEditActivityFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadCreateEditActivityFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removeCreateEditActivityFile.request, (state, action) => ({ ...state }))
  .handleAction(removeCreateEditActivityFile.success, (state, action) => ({
    ...state,
    // Removing the deleted file from store
    associatedFiles: state.associatedFiles.filter((file) => file.id !== action.payload),
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
  }))
  .handleAction(clearAllUploadedCreateEditActivityFiles, (state) => ({
    ...state,
    associatedFiles: [],
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(fetchFilePreview.request, (state, action) => ({
    ...state,
    filePreviewId: action.payload,
    filePreviewLoading: true,
  }))
  .handleAction(fetchFilePreview.success, (state, action) => ({
    ...state,
    filePreview: action.payload,
    filePreviewLoading: false,
  }))
  .handleAction(fetchFilePreview.failure, (state) => ({ ...state, filePreviewLoading: false }))
  .handleAction(relateEntities.request, (state, action) => ({
    ...state,
    entityRelating: true,
  }))
  .handleAction(relateEntities.success, (state, { payload: { deal, person } }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedDeal: deal ?? state.associations.associatedDeal,
      associatedPerson: person ?? state.associations.associatedPerson,
    },
    entityRelating: false,
  }))
  .handleAction(relateEntities.failure, (state) => ({
    ...state,
    entityRelating: false,
  }));

export * from "./selectors";
export type CreateEditActivityModalActions = Actions;

export default createEditActivityModal;
