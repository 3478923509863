import MapViewportState from "types/map/MapViewportState";
import MapPersistedViewportState from "types/map/MapPersistedViewportState";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { convertPlatformBoundsToLatLngBounds } from "@mapmycustomers/shared/util/geo/GeoService";

const convertViewportToPersist = (viewport: MapViewportState): MapPersistedViewportState => {
  let center: LongLat | undefined = undefined;
  if (viewport.bounds) {
    const bounds = convertPlatformBoundsToLatLngBounds(viewport.bounds);
    const latLngCenter = bounds.getCenter();
    center = [latLngCenter.lng(), latLngCenter.lat()] as LongLat;
  }
  return { center, zoom: viewport.zoom };
};

export default convertViewportToPersist;
