import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  createRoute,
  initializeRouteBuilder,
  setActivities,
  setActivityTypes,
  setDateRange,
  setEntityType,
  updateActivityAssociation,
} from "./actions";
import { handleError } from "store/errors/actions";
import {
  getEntityType,
  getInvalidStops,
  getRange,
  getRangeType,
  getSelectedActivityTypes,
  getStops,
} from "./selectors";
import { addDays, addWeeks, endOfDay, endOfToday, startOfDay, startOfToday } from "date-fns/esm";
import {
  Condition,
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { getLocalTimeZoneFormattedOffset, getWeekEnd, getWeekStart } from "util/dates";
import DateRangeType from "scene/activity/component/ActivityCalendarPage/components/Sidebar/RouteBuilder/enum/DateRangeType";
import { RouteBuilderState } from "./index";
import { callApi } from "store/api/callApi";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getCurrentUser, getOrganizationId } from "store/iam";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import {
  Activity,
  EntitiesSupportingRoutes,
  EntityTypeSupportingRoutes,
  Route,
} from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { notifyAboutChanges } from "store/uiSync/actions";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { hideEntityView } from "store/entityView/actions";
import i18nService from "config/I18nService";
import notification from "antd/es/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { defineMessages } from "react-intl";
import getSuccessNotificationNode from "component/createEditEntity/util/getSuccessNotificationNode";
import { getLegacyAppUrl } from "util/appUrl";
import configService from "config/ConfigService";
import authService from "store/auth/AuthService";
import User from "@mapmycustomers/shared/types/User";
import styles from "./saga.module.scss";
import { getActivityTypes } from "store/activity";
import { NOTIFICATION_DURATION_WITH_ACTION } from "util/consts";

import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import { activityLayoutModel } from "util/layout/impl";

const messages = defineMessages({
  buildingRoute: {
    id: "activities.calendarView.routeBuilder.buildingRoute",
    defaultMessage: "Building route...",
    description: "message for build route when creating new route",
  },
  success: {
    id: "activities.calendarView.routeBuilder.success",
    defaultMessage: "Route built successfully",
    description: "Route is updated successfully in Route Builder in Activities Calendar view",
  },
  failedGeocodeStartAddress: {
    id: "activities.calendarView.routeBuilder.error.failedGeocodeStart",
    defaultMessage: "Failed to find starting location",
    description: "Failed to find starting location in Route Builder in Activities Calendar view",
  },
  failedGeocodeEndAddress: {
    id: "activities.calendarView.routeBuilder.error.failedGeocodeEnd",
    defaultMessage: "Failed to find ending location",
    description: "Failed to find ending location in Route Builder in Activities Calendar view",
  },
});

const getRangeCondition = (
  rangeType: DateRangeType,
  range?: { start: Date; end: Date }
): Condition | undefined => {
  switch (rangeType) {
    case DateRangeType.TODAY:
      return { $gte: startOfToday(), $lte: endOfToday() };
    case DateRangeType.TOMORROW:
      return { $gte: addDays(startOfToday(), 1), $lte: addDays(endOfToday(), 1) };
    case DateRangeType.THIS_WEEK:
      return { $gte: getWeekStart(Date.now()), $lte: getWeekEnd(Date.now()) };
    case DateRangeType.NEXT_WEEK:
      return {
        $gte: addWeeks(getWeekStart(Date.now()), 1),
        $lte: addWeeks(getWeekEnd(Date.now()), 1),
      };
    case DateRangeType.CUSTOM:
      return range ? { $gte: startOfDay(range.start), $lte: endOfDay(range.end) } : undefined;
  }
};

const getActivityDateRangeFilter = (
  rangeType: DateRangeType,
  range?: { start: Date; end: Date }
): PlatformFilterCondition | undefined => {
  const rangeCondition = getRangeCondition(rangeType, range);
  if (!rangeCondition) {
    return undefined;
  }
  // prepare for fetching, convert dates to strings
  rangeCondition.$gte = (rangeCondition.$gte! as Date).toISOString().substring(0, 10);
  rangeCondition.$lte = (rangeCondition.$lte! as Date).toISOString().substring(0, 10);
  // $offset is a valid param used for date filtering, but it's not an operator
  // @ts-ignore
  rangeCondition.$offset = getLocalTimeZoneFormattedOffset();
  return { startAt: rangeCondition };
};

export function* onInitialize() {
  const activityTypes: ActivityType[] = yield select(getActivityTypes);
  yield put(initializeRouteBuilder.success(activityTypes));
}

export function* onFetchActivities() {
  try {
    const dateRangeType: DateRangeType = yield select(getRangeType);
    const dateRange: RouteBuilderState["dateRange"] = yield select(getRange);
    const activityTypes: ActivityType[] = yield select(getSelectedActivityTypes);

    const rangeFilter = getActivityDateRangeFilter(dateRangeType, dateRange);
    if (!rangeFilter) {
      yield put(setActivities(undefined));
      return; // can't even fetch
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const currentUser: User = yield select(getCurrentUser);

    const response: ListResponse<Activity> = yield callApi("fetchActivities", orgId, {
      $order: "startAt",
      $filters: {
        includeAccessStatus: true,
        $and: [
          rangeFilter,
          { assigneeId: currentUser.id },
          { completed: false },
          ...(activityTypes.length
            ? [{ crmActivityTypeId: { $in: activityTypes.map(({ id }) => id) } }]
            : []),
        ],
      },
      $limit: 1000,
    });

    yield put(setActivities({ activities: response.data, total: response.total }));
  } catch (error) {
    yield put(setActivities(undefined));
    yield put(handleError({ error }));
  }
}

export function* onUpdateStops({ payload }: ReturnType<typeof notifyAboutChanges>) {
  const invalidStops: Activity[] = yield select(getInvalidStops);
  const entityType: EntityTypeSupportingRoutes = yield select(getEntityType);

  // if updated any of activities from stops
  // or if updated any of entities from stops
  // then reload all stops
  if (payload.entityType === EntityType.ACTIVITY) {
    const activityIds = new Set(invalidStops.map(({ id }) => id));
    if ((payload.updated ?? []).some((activity) => activityIds.has(activity.id))) {
      yield call(onFetchActivities);
    }
  } else if (payload.entityType === entityType) {
    const entityIds = new Set(
      invalidStops
        .map(({ account, contact }) =>
          entityType === EntityType.COMPANY ? account?.id : contact?.id
        )
        .filter(isDefined)
    );
    if (!(payload.updated ?? []).every((entity) => !entityIds.has(entity.id))) {
      yield call(onFetchActivities);
    }
  }
}

export function* onUpdateActivityAssociation({
  payload,
}: ReturnType<typeof updateActivityAssociation.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    yield callApi("updateActivity", orgId, layoutId, {
      ...payload.activity,
      ...(payload.entityType === EntityType.COMPANY
        ? { account: { id: payload.entityId } }
        : { contact: { id: payload.entityId } }),
    });
    yield put(updateActivityAssociation.success());
    yield call(onFetchActivities);
  } catch (error) {
    yield put(updateActivityAssociation.failure());
    yield put(handleError({ error }));
  }
}

export function* keepZendeskButtonHidden() {
  yield call(setTimeout, () => window.zE("webWidget", "hide"), 0);
}

export function* onCreateRoute({ payload }: ReturnType<typeof createRoute.request>) {
  const key = `createRouteNotification_${payload.route.name}`;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const intl = i18nService.getIntl();
    const { route, callback } = payload;

    let startPoint: GeocodeResult;
    try {
      startPoint = yield callApi("geocodeAddress", orgId, {
        address: route.routeDetail.startAddress,
      });
    } catch (e) {
      notification.error({ message: intl?.formatMessage(messages.failedGeocodeStartAddress) });
      yield put(createRoute.failure());
      return;
    }

    let endPoint: GeocodeResult | undefined;
    if (route.routeDetail.endAddress) {
      try {
        endPoint = yield callApi("geocodeAddress", orgId, {
          address: route.routeDetail.endAddress,
        });
      } catch (e) {
        notification.error({ message: intl?.formatMessage(messages.failedGeocodeEndAddress) });
        yield put(createRoute.failure());
        return;
      }
    }

    const stops: Activity[] = yield select(getStops);
    const entityIds: EntitiesSupportingRoutes["id"][] = stops.map(({ account, contact }) =>
      route.entityType === EntityType.COMPANY ? account!.id : contact!.id
    );

    // try to build a route with new entities
    const previewPayload = {
      routeType: route.routeDetail.type,
      startGeoPoint: startPoint.geoPoint ? startPoint.geoPoint : undefined,
      endGeoPoint: endPoint?.geoPoint ? endPoint?.geoPoint : undefined,
      ...route.routeDetail,
      routeAccounts: route.entityType === EntityType.COMPANY ? entityIds : undefined,
      routeContacts: route.entityType === EntityType.PERSON ? entityIds : undefined,
    };

    // Info message to show user that we are adding the record to route after creating the route
    notification.info({
      key,
      message: intl?.formatMessage(messages.buildingRoute),
      icon: <FontAwesomeIcon className={styles.notificationLoader} icon={faSpinnerThird} spin />,
    });

    yield callApi("buildRoute", orgId, route.entityType, previewPayload);

    //Create Route
    const createdRoute: Route = yield callApi("createRoute", orgId, route.entityType, {
      name: route.name,
      routeDetail: {
        ...route.routeDetail,
        startGeoPoint: startPoint.geoPoint,
        endGeoPoint: endPoint?.geoPoint ? endPoint?.geoPoint : undefined,
      },
    } as Partial<Route>);

    // now update route if all is success buildRoute hasn't fail
    yield callApi(
      "updateRouteStops",
      orgId,
      route.entityType,
      createdRoute.id,
      entityIds,
      route.routeDetail.allottedTime
    );

    const baseOldAppUrl = configService.getBaseOldAppUrl();
    const token = authService.getToken();
    if (!token) {
      return null;
    }
    notification.success({
      key: key,
      message: getSuccessNotificationNode(
        intl,
        i18nService.formatMessage(messages.success, "Route built successfully"),
        () => {
          window.location.href = getLegacyAppUrl(
            baseOldAppUrl,
            token,
            route.entityType === EntityType.COMPANY
              ? `/accounts/routing/edit/${createdRoute.id}`
              : `/contacts/routing/edit/${createdRoute.id}`
          );
          notification.close(key);
        }
      ),
      duration: NOTIFICATION_DURATION_WITH_ACTION,
    });

    yield put(createRoute.success(createdRoute));
    callback?.();
  } catch (error) {
    notification.close(key);
    yield put(createRoute.failure());
    yield put(handleError({ error }));
  }
}

export function* routeBuilderSaga() {
  yield takeEvery(initializeRouteBuilder.request, onInitialize);
  yield takeLatest(
    [initializeRouteBuilder.request, setEntityType, setDateRange, setActivityTypes],
    onFetchActivities
  );
  yield takeEvery(notifyAboutChanges, onUpdateStops);
  yield takeEvery(updateActivityAssociation.request, onUpdateActivityAssociation);
  yield takeEvery(hideEntityView, keepZendeskButtonHidden);
  yield takeEvery(createRoute.request, onCreateRoute);
}
