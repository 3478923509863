import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Select from "antd/es/select";
import cn from "classnames";
import defaultFilterOption from "./utils/defaultFilterOption";
import styles from "./EntityMultiSelectField.module.scss";
import {
  AnyEntity,
  Company,
  EntityType,
  EntityTypesSupportingFilteringBy,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getApiService } from "store/api";
import ApiService from "../../store/api/ApiService";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { LoadingSpinner } from "@mapmycustomers/ui";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

interface Props {
  apiService?: ApiService;
  className?: string;
  entityType: EntityTypesSupportingFilteringBy;
  onChange: (entityIds: Array<AnyEntity["id"]>) => void;
  onClick?: () => void;
  oneLiner?: boolean;
  organizationId?: Organization["id"];
  placeholder?: string;
  value: Array<AnyEntity["id"]>;
}

const EntityMultiSelectField: React.FC<Props> = ({
  apiService,
  className,
  entityType,
  onChange,
  onClick,
  oneLiner,
  organizationId,
  placeholder,
  value,
}) => {
  const intl = useIntl();
  const [entities, setEntities] = useState<(Company | Person)[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [initialized, initialize] = useBoolean();

  const handleFetchEntities = useCallback(
    async (text: string, callback?: () => void) => {
      const searchText = text.trim();
      setFetching(true);

      const searchPayload = {
        $filters: {
          $and: [
            ...(searchText.length ? [{ name: { $in: searchText } }] : []),
            ...(value.length ? [{ id: { $nin: value } }] : []),
          ],
        },
        $columns: ["id", "name"],
        $order: "name",
        $limit: 100,
      };
      const searchResponse: ListResponse<Company> | ListResponse<Person> | undefined =
        await (entityType === EntityType.COMPANY
          ? apiService?.fetchCompanies
          : apiService?.fetchPeople)?.(organizationId!, searchPayload);

      let response: ListResponse<Company> | ListResponse<Person> | undefined = undefined;
      if (value.length) {
        const payload = {
          $filters: {
            $and: [...(value.length ? [{ id: { $in: value } }] : [])],
          },
          $columns: ["id", "name"],
          $order: "name",
          $limit: 100,
        };
        response = await (entityType === EntityType.COMPANY
          ? apiService?.fetchCompanies
          : apiService?.fetchPeople)?.(organizationId!, payload);
      }

      setFetching(false);
      setEntities([...(searchResponse?.data ?? []), ...(response?.data ?? [])]);
      callback?.();
    },
    [apiService, entityType, organizationId, setFetching, value]
  );

  useEffect(() => {
    if (!initialized) {
      handleFetchEntities("", initialize);
    }
  }, [handleFetchEntities, initialize, initialized]);

  useChangeTracking(() => {
    handleFetchEntities("");
  }, [handleFetchEntities, value]);

  const handleSearch = useDebouncedCallback(
    [
      async (text: string) => {
        handleFetchEntities(text);
      },
      500,
    ],
    [handleFetchEntities]
  );

  if (!initialized) {
    return <LoadingSpinner mini />;
  }

  return (
    <Select<Array<AnyEntity["id"]>>
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      maxTagCount={oneLiner ? "responsive" : undefined}
      mode="multiple"
      notFoundContent={fetching ? <LoadingSpinner /> : null}
      onChange={onChange}
      onClick={onClick}
      onSearch={handleSearch}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "entityMultiSelectField.placeholder",
          defaultMessage: "Click or type to select ",
          description: "Placeholder displayed in a EntityMultiSelectField component",
        })
      }
      value={value}
    >
      {entities.map((entity) => (
        <Select.Option key={entity.id} label={entity.name} value={entity.id}>
          {entity.name}
        </Select.Option>
      ))}
      {entities.length && fetching ? (
        <div key="loader">
          <LoadingSpinner />
        </div>
      ) : null}
    </Select>
  );
};

const mapStateToProps = (state: RootState) => ({
  apiService: getApiService(state),
  organizationId: getOrganizationId(state),
});

export default connect(mapStateToProps)(EntityMultiSelectField);
