@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .actionContainer {
        width: 100%;
        background-color: colors.$mmc-white;
      }

      .toolbarPopover {
        :global(.ant-popover-content) {
          :global(.ant-popover-inner) {
            border: none;
            :global(.ant-popover-inner-content) {
              padding: 0;
            }
          }
        }
      }

      .togglerButton {
        padding: 0.25rem 0.5rem;
        cursor: pointer;
      }

      span.active {
        background-color: colors.$mmc-primary-010;
        color: colors.$mmc-primary-100;
      }

      .disabledIcon {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
