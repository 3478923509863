import React from "react";
import { Redirect } from "react-router-dom";
import { LoadingSpinner } from "@mapmycustomers/ui";
import { isSignedIn } from "store/auth";
import { loggedIn, login, logout } from "store/auth/actions";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import Iam from "../../types/Iam";
import Path from "../../enum/Path";
import { FormattedMessage } from "react-intl";
import authService from "../../store/auth/AuthService";
import { isManager, isMember, isOwner, isReadOnly } from "store/iam/util";
import styles from "./RootPage.module.scss";
import { getError, getMe } from "store/iam";
import hasTrialExpired from "../../util/hasTrialExpired";

interface Props {
  error: any;
  isSignedIn: boolean | undefined;
  loggedIn: typeof loggedIn;
  login: typeof login;
  logout: typeof logout;
  me?: Iam;
}

export const RootPage: React.FC<Props> = ({ error, isSignedIn, loggedIn, login, logout, me }) => {
  const hasToken = authService.hasToken();

  // if token is not in the store and we're not in the loading state
  if (isSignedIn === false) {
    // if token is in the AuthService...
    if (hasToken) {
      // ...proceed with it
      loggedIn({ token: authService.getToken() });
    } else {
      // otherwise, go log in
      login();
    }
    return null;
  }

  // if token is in the store
  if (isSignedIn === true) {
    // ...and we've got a profile data
    if (me) {
      // logout if it's some weird unknown role
      if (!isOwner(me) && !isManager(me) && !isMember(me) && !isReadOnly(me)) {
        logout();
      }

      if (hasTrialExpired(me.organization)) {
        return <Redirect to={Path.BILLING} />;
      }

      // otherwise, redirect to the home page
      return <Redirect to={Path.DASHBOARD} />;
    }

    // if we're logged in but don't have a profile yet...
    loggedIn({});

    return (
      <LoadingSpinner global>
        <FormattedMessage
          id="auth.in_progress"
          description="Message visible when user authentication is in progress"
          defaultMessage="Logging in, please wait..."
        />
      </LoadingSpinner>
    );
  }

  // if there was a critical error (fetching the profile), show a message
  if (error) {
    return (
      <div className={styles.container}>
        <FormattedMessage
          id="auth.server_error"
          description="Authentication failed due to a server issue."
          defaultMessage="
            Server error occurred. Please retry in several minutes.<br />
            If the problem persists, please contact our support team.
          "
        />
      </div>
    );
  }

  // otherwise, we're loading user's profile. Show the "please stand by"-like message.
  return (
    <LoadingSpinner global>
      <FormattedMessage
        id="auth.in_progress"
        description="Message visible when user authentication is in progress"
        defaultMessage="Logging in, please wait..."
      />
    </LoadingSpinner>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  isSignedIn: isSignedIn(state),
  me: getMe(state),
});

const mapDispatchToProps = {
  login,
  logout,
  loggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
