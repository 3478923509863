import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { LoadingSpinner, Modal } from "@mapmycustomers/ui";
import styles from "./QueueModalInfo.module.scss";
import image from "assets/email/top-nav-modal-image.svg";
import imageEmptyQueue from "assets/email/top-nav-modal-image-empty-queue.svg";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import EmailSummaryInfo from "component/email/EmailSummaryInfo";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import {
  clearEmailQueue,
  fetchEmailInfo,
  hideEmailFeatures,
  hideEmailQueue,
} from "store/email/actions";
import { getEmailQuota, isEmailQuotaUpdating } from "store/email/selectors";
import messages from "./messages";
import EmailPeriod from "../../../email/EmailPeriod";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import layout from "styles/layout";

interface Props {
  emailQuota?: EmailQuota;
  emailQuotaUpdating: boolean;
  onClearEmailQueue: typeof clearEmailQueue.request;
  onFetchEmailInfo: typeof fetchEmailInfo.request;
  onHideEmailFeatures: typeof hideEmailFeatures;
  onHideEmailQueue: typeof hideEmailQueue;
}

const QueueModalInfo: React.FC<Props> = ({
  emailQuota,
  emailQuotaUpdating,
  onClearEmailQueue,
  onFetchEmailInfo,
  onHideEmailFeatures,
  onHideEmailQueue,
}) => {
  const intl = useIntl();

  const handleClear = useCallback(() => {
    onClearEmailQueue();
  }, [onClearEmailQueue]);

  const handleSettings = useCallback(() => {
    onHideEmailQueue();
    onHideEmailFeatures();
  }, [onHideEmailQueue, onHideEmailFeatures]);

  useEffect(() => {
    onFetchEmailInfo();
  }, [onFetchEmailInfo]);

  const submitted = emailQuota?.submitted ?? 0;

  return (
    <Modal
      footer={null}
      onCancel={() => onHideEmailQueue()}
      open
      title={
        <Row align="middle" gutter={layout.spacerS}>
          <Col>{intl.formatMessage(messages.title)}</Col>
          {emailQuotaUpdating && (
            <Col>
              <LoadingSpinner mini />
            </Col>
          )}
        </Row>
      }
    >
      <div className={styles.container}>
        <img alt="logo" src={submitted === 0 ? imageEmptyQueue : image} />
        <div className={styles.queueCount}>
          {submitted === 0
            ? intl.formatMessage(messages.queueEmpty)
            : intl.formatMessage(messages.queueCount, {
                count: submitted,
              })}
        </div>
        <EmailPeriod onSettings={handleSettings} />
        <EmailSummaryInfo
          footer={
            <Row justify="center">
              <Col>
                {submitted === 0 ? (
                  <Button danger disabled type="link">
                    {intl.formatMessage(messages.clearQueue)}
                  </Button>
                ) : (
                  <Popconfirm
                    cancelText={intl.formatMessage(messages.popconfirmCancel)}
                    okText={intl.formatMessage(messages.popconfirmOk)}
                    onConfirm={handleClear}
                    title={intl.formatMessage(messages.popconfirmTitle, {
                      b: (text: string) => <b>{text}</b>,
                      br: <br />,
                    })}
                  >
                    <Button danger type="link">
                      {intl.formatMessage(messages.clearQueue)}
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          }
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuota: getEmailQuota(state),
  emailQuotaUpdating: isEmailQuotaUpdating(state),
});

const mapDispatchToProps = {
  onClearEmailQueue: clearEmailQueue.request,
  onFetchEmailInfo: fetchEmailInfo.request,
  onHideEmailFeatures: hideEmailFeatures,
  onHideEmailQueue: hideEmailQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueueModalInfo);
