import { call, cancel, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchInvitationInfo,
  fetchUserDetailsSso,
  forgotPassword,
  loggedIn,
  loggedInWithSso,
  login,
  logout,
  requestAccountSso,
  resetPassword,
  signUp,
  signUpMember,
  ssoLogin,
  tryLogin,
  updateToken,
  updateUserStatus,
  userExists,
} from "./actions";
import authService from "./AuthService";
import { callApi } from "../api/callApi";
import { push } from "connected-react-router";
import Path from "../../enum/Path";
import { isActionOf } from "typesafe-actions";
import { fetchMe } from "../iam/actions";
import { initializeApp } from "../app/actions";
import getValidationErrors from "../../util/errorHandling/getValidationErrors";
import { handleError } from "../errors/actions";
import { getCaptchaToken } from "util/captcha";
import { Task } from "redux-saga";
import Organization from "@mapmycustomers/shared/types/Organization";
import UserRef from "@mapmycustomers/shared/types/User";
import { getOrganization, isCumulEnabled } from "../iam";
import hasTrialExpired from "../../util/hasTrialExpired";
import InvitationInfo from "../../types/auth/InvitationInfo";
import analyticsService, { AnalyticsService } from "util/analytic/AnalyticsService";
import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import Version from "@mapmycustomers/shared/enum/Version";
import localSettings from "config/LocalSettings";
import Feature from "@mapmycustomers/shared/enum/Feature";
import TokenResponse from "../../types/auth/TokenResponse";
import { initializeCumul } from "../cumul/actions";
import UserDetails from "types/auth/UserDetails";
import { getLocalTimeZone } from "util/dates";

const redirectTo = (url: string) => {
  window.location.href = url;
};

export function* onLogin() {
  yield call(authService.saveLocation);
  yield call(authService.resetSession);
  yield put(tryLogin.success(undefined)); // reset token in the store
  analyticsService.completed("Login");
  yield put(fetchMe.success(undefined)); // reset "me" data
  AnalyticsService.traits = {}; // reset traits to avoid sending incorrect orgId/userId combinations
  yield put(push(Path.LOGIN));
}

export function* onLogout(action: ReturnType<typeof logout>) {
  yield call(authService.resetSession);
  yield put(tryLogin.success(undefined)); // reset token in the store
  yield put(fetchMe.success(undefined)); // reset "me" data
  yield call(authService.signOut, action.payload || undefined);
  analyticsService.completed("Logout");
}

export function* onLoggedIn(action: ReturnType<typeof loggedIn>) {
  // assuming token is already in the storage if it's not specified in the action
  if (action.payload.token !== undefined) {
    yield call(authService.setToken, action.payload.token);
    yield put(tryLogin.success(action.payload.token));
    analyticsService.completed("Login");
  }

  yield put(initializeApp.request());

  const initializationResult: ReturnType<
    typeof initializeApp.success | typeof initializeApp.failure
  > = yield take([initializeApp.success, initializeApp.failure]);
  if (isActionOf(initializeApp.success, initializationResult)) {
    // move user to a billing page if trial expired
    const organization: Organization | undefined = yield select(getOrganization);
    if (hasTrialExpired(organization)) {
      yield put(push(Path.BILLING));
    } else if (!action.payload.keepLocation) {
      // go to saved location only once (most probably right after logging in)
      // do not go there when browser page is refreshed, thus we're resetting it after use
      yield put(push(authService.getSavedLocation()));
      yield call(authService.resetSavedLocation);
    }
  }
}

export function* authorize(username: string, password: string) {
  try {
    const tokenResponse: TokenResponse = yield callApi("authorize", {
      username,
      password,
    });
    yield call(authService.setToken, tokenResponse.accessToken);
    localSettings.setSkeletonKeyUsed(tokenResponse?.skeletonKeyUsed ?? false);

    // AnalyticsService.shouldTrack = !tokenResponse?.skeletonKeyUsed;

    if (tokenResponse?.refreshToken) {
      localSettings.setAuthRefreshToken(tokenResponse.refreshToken);
    }
    yield put(tryLogin.success(tokenResponse.accessToken));
    analyticsService.completed("Login");
  } catch (error) {
    yield put(tryLogin.failure(error));
  }
}
export function* onUpdateToken({ payload }: ReturnType<typeof updateToken>) {
  try {
    const tokenResponse: TokenResponse = yield callApi("authorize", {
      grant_type: "refresh_token",
      refresh_token: localSettings.getAuthRefreshToken(),
      scope: JSON.stringify({ anonymize: payload }),
    });
    yield call(authService.setToken, tokenResponse.accessToken);
    if (tokenResponse?.refreshToken) {
      localSettings.setAuthRefreshToken(tokenResponse.refreshToken);
    }
    (payload ? localSettings.setAnonymousFlag : localSettings.removeAnonymousFlag)();
    yield put(fetchMe.request());
    const result: ReturnType<typeof fetchMe.success> = yield take([fetchMe.success]);
    if (isActionOf(fetchMe.success, result)) {
      const isCumulEnabledForOrg: boolean = yield select(isCumulEnabled);
      if (isCumulEnabledForOrg) {
        yield put(initializeCumul.request());
      }
    }
  } catch (error) {
    yield put(tryLogin.failure(error));
  }
}

export function* loginFlow() {
  while (true) {
    const { payload }: ReturnType<typeof tryLogin.request> = yield take(tryLogin.request);
    const task: Task = yield fork(authorize, payload.username, payload.password);
    const action: ReturnType<typeof login | typeof logout | typeof tryLogin.failure> = yield take([
      login,
      logout,
      tryLogin.failure,
    ]);
    if (isActionOf([login, logout], action)) {
      yield cancel(task); // kill authorize task
    }
  }
}

export function* onForgotPassword({ payload: email }: ReturnType<typeof forgotPassword.request>) {
  try {
    yield callApi("requestPasswordReset", email);
    yield put(forgotPassword.success());
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(forgotPassword.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onResetPassword({ payload }: ReturnType<typeof resetPassword.request>) {
  try {
    yield callApi("resetPassword", payload.code, payload.password, payload.username);
    yield put(resetPassword.success());
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(resetPassword.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onSignUp({ payload }: ReturnType<typeof signUp.request>) {
  try {
    const token: string = yield call(getCaptchaToken, "signup");

    const signUpResult: { organization: Organization; user: UserRef } = yield callApi(
      "signup",
      token,
      payload.fullName,
      payload.email,
      payload.phone,
      payload.password,
      payload.organizationName,
      payload.promoEmails,
      payload.referralSource,
      {
        numFieldReps: payload.numFieldReps.toString(),
        crm: payload.crm,
        webVersion: Version.V2_BETA,
        features: {
          [Feature.MAP]: { enabled: true },
          [Feature.DISALLOW_COLOR]: { enabled: true },
        },
      },
      { tnc: payload.tnc },
      { timezone: { value: getLocalTimeZone() } }
    );
    yield put(signUp.success(signUpResult));
    localSettings.setSignupCompleted(true);
    analyticsService.completed("Sign Up", {
      fullName: payload.fullName,
      email: payload.email,
      phone: payload.phone,
      organizationName: payload.organizationName,
    });

    // now log-in automatically:
    yield put(tryLogin.request({ username: payload.email, password: payload.password }));
    const action: ReturnType<typeof tryLogin.success | typeof tryLogin.failure> = yield take([
      tryLogin.success,
      tryLogin.failure,
    ]);
    if (isActionOf([tryLogin.failure], action)) {
      // if failed to login from the first attempt, go to the login page to proceed
      yield put(push(Path.ROOT));
    }
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(signUp.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onSignUpMember({ payload }: ReturnType<typeof signUpMember.request>) {
  try {
    if (!payload.invitationCode) {
      return; // nothing to do here
    }

    const token: string = yield call(getCaptchaToken, "signup");

    const signUpResult: { organization: Organization; user: UserRef } = yield callApi(
      "acceptInvitation",
      token,
      payload.invitationCode,
      payload.fullName,
      payload.email,
      payload.phone,
      payload.password,
      payload.promoEmails,
      {
        tnc: payload.tnc,
      },
      payload.trialType
    );
    yield put(signUpMember.success(signUpResult));

    // now log-in automatically:
    yield put(tryLogin.request({ username: payload.email, password: payload.password }));
    const action: ReturnType<typeof tryLogin.success | typeof tryLogin.failure> = yield take([
      tryLogin.success,
      tryLogin.failure,
    ]);
    if (isActionOf([tryLogin.failure], action)) {
      // if failed to login from the first attempt, go to the login page to proceed
      yield put(push(Path.ROOT));
    }
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(signUpMember.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onUserExistCheck({ payload }: ReturnType<typeof userExists.request>) {
  try {
    const userExistsResponse: boolean = yield callApi("userExists", payload.username);
    yield put(userExists.success(userExistsResponse));
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(userExists.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onFetchInvitationInfo({
  payload,
}: ReturnType<typeof fetchInvitationInfo.request>) {
  try {
    const invitationInfo: InvitationInfo = yield callApi("fetchInvitation", payload);
    yield put(fetchInvitationInfo.success(invitationInfo));
  } catch (error) {
    if (error instanceof ApiError && error?.status === 404) {
      // most probably this invitation code has been used already
      // so let's imitate the expired invitation state
      yield put(
        fetchInvitationInfo.success({
          teamNames: [""],
          ownerName: "",
          organizationName: "",
          invitationCodeExpiresAt: new Date(0).toISOString(),
        })
      );
      return;
    }
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(fetchInvitationInfo.failure());
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onLoggedInWithSso({ payload: { sessionId } }: ReturnType<typeof loggedInWithSso>) {
  try {
    const tokenResponse: TokenResponse = yield callApi("authorizeSso", sessionId);
    yield put(loggedIn({ token: tokenResponse.accessToken }));
  } catch (error) {
    yield put(tryLogin.failure(error));
    yield put(push(Path.LOGIN));
  }
}

export function* onRequestAccountSso({
  payload: { callback, sessionId },
}: ReturnType<typeof requestAccountSso.request>) {
  try {
    yield callApi("requestAccountSso", sessionId);
    callback();
  } catch (error) {
    yield put(requestAccountSso.failure(error));
  }
}

export function* onSsoLogin({ payload }: ReturnType<typeof ssoLogin.request>) {
  try {
    const redirectUrl: string = yield callApi("fetchSsoLoginRedirectUri", payload.username);
    yield call(redirectTo, redirectUrl);
    yield put(ssoLogin.success());
  } catch (error) {
    const validationErrors = getValidationErrors(error);
    if (validationErrors.length) {
      yield put(ssoLogin.failure(error));
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onFetchUserDetailsSso({
  payload,
}: ReturnType<typeof fetchUserDetailsSso.request>) {
  try {
    const userDetails: UserDetails = yield callApi("fetchUserDetailsSso", payload);
    yield put(fetchUserDetailsSso.success(userDetails));
  } catch (error) {
    yield put(fetchUserDetailsSso.failure(error));
  }
}

export function* onUpdateUserStatus({ payload }: ReturnType<typeof updateUserStatus.request>) {
  try {
    const userDetails: UserDetails = yield callApi("updateUserRequestStatus", payload);
    yield put(updateUserStatus.success({ userDetails, status: payload.status }));
  } catch (error) {
    yield put(updateUserStatus.failure(error));
  }
}

export function* authSaga() {
  yield fork(loginFlow);
  yield takeEvery(login, onLogin);
  yield takeEvery(logout, onLogout);
  yield takeEvery(loggedIn, onLoggedIn);
  yield takeLatest(forgotPassword.request, onForgotPassword);
  yield takeLatest(resetPassword.request, onResetPassword);
  yield takeLatest(signUp.request, onSignUp);
  yield takeLatest(signUpMember.request, onSignUpMember);
  yield takeLatest(userExists.request, onUserExistCheck);
  yield takeLatest(fetchInvitationInfo.request, onFetchInvitationInfo);
  yield takeLatest(updateToken, onUpdateToken);
  yield takeEvery(loggedInWithSso, onLoggedInWithSso);
  yield takeEvery(requestAccountSso.request, onRequestAccountSso);
  yield takeLatest(ssoLogin.request, onSsoLogin);
  yield takeLatest(updateUserStatus.request, onUpdateUserStatus);
  yield takeLatest(fetchUserDetailsSso.request, onFetchUserDetailsSso);
}
