import React from "react";
import { defineMessages, useIntl } from "react-intl";
import BaseCompanyField, { BaseCompanyFieldProps } from "./BaseCompanyField";

const messages = defineMessages({
  label: {
    id: "formFields.selectParentCompany.forCompany",
    defaultMessage: "Parent Company",
    description: "Parent Company field",
  },
  modalTitle: {
    id: "formFields.selectParentCompany.forCompany.modal.title",
    defaultMessage: "Select Parent Company",
    description: "Title of the Select Parent Company modal",
  },
});

interface Props extends Omit<BaseCompanyFieldProps, "idFieldName" | "nameFieldName"> {}

const ParentCompanyField: React.FC<Props> = ({ entityType, label, modalTitle, ...props }) => {
  const intl = useIntl();
  return (
    <BaseCompanyField
      allowAdd={false}
      entityType={entityType}
      idFieldName="parentCompanyId"
      label={label ?? intl.formatMessage(messages.label)}
      modalTitle={label ?? intl.formatMessage(messages.modalTitle)}
      nameFieldName="parentCompanyName"
      {...props}
    />
  );
};

export default ParentCompanyField;
