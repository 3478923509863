import { Editor, Transforms } from "slate";
import { ImageElement } from "../../type/slate-types";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const insertImage = (editor: Editor, url: string) => {
  const text = { text: "" };
  const image: ImageElement = {
    type: ElementType.IMAGE,
    url,
    children: [text],
  };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: ElementType.PARAGRAPH,
    children: [{ text: "" }],
  });
};

export default insertImage;
