@use "../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        min-width: 0;
      }

      .hoverStyle {
        &:hover {
          color: colors.$mmc-primary-100;

          :global(.ant-avatar) {
            outline-color: colors.$mmc-primary-100;
          }
        }
      }

      .avatarHover {
        &:hover {
          outline-color: colors.$mmc-primary-100;
        }
      }

      .deleted {
        color: colors.$mmc-gray;
      }

      .avatar {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1rem;
        line-height: 1.6rem;
        flex-shrink: 0;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .size_s {
        .avatar {
          width: 1.25rem;
          height: 1.25rem;
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }

      .size_xs {
        .avatar {
          width: 1rem;
          height: 1rem;
          font-size: 0.5rem;
          line-height: 1rem;
        }
      }

      .avatarIcon {
        outline: 2px solid transparent;
      }
    }
  }
}
