import React from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import cn from "classnames";
import { useIntl } from "react-intl";
import styles from "./ActivityTypesFilter.module.scss";
import MultiSelectActivityTypeField from "component/input/MultiSelectActivityTypeField";

export const ACTIVITY_TYPES_FILTER_OPERATORS = [FilterOperator.IN_ANY];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  ACTIVITY_TYPES_FILTER_OPERATORS.includes(operator);

interface ActivityTypesFilterProps extends IFilterComponentProps {}

const ActivityTypesFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_ACTIVITY_TYPES,
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const activityTypesMap = new Map(
      ((options?.availableActivityTypes ?? []) as ActivityType[]).map(({ id, name }) => [id, name])
    );
    const selectedValues = value.value as ActivityType["id"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map(
        (activityTypeId) => activityTypesMap.get(activityTypeId) ?? String(activityTypeId)
      )
    );
  },
  getComponent:
    (): React.FC<ActivityTypesFilterProps> =>
    ({ className, onChange, options, value }) => {
      const intl = useIntl();
      return (
        <MultiSelectActivityTypeField
          className={cn(styles.container, className)}
          onChange={(activityTypeIds: ActivityType["id"][]) =>
            onChange?.({ ...value, value: activityTypeIds })
          }
          placeholder={intl.formatMessage({
            id: "filters.activityType.placeholder",
            defaultMessage: "Click or type to select activity types",
            description: "Placeholder displayed in a ActivityType filter's selectbox",
          })}
          size="middle"
          specificActivityTypes={(options?.availableActivityTypes ?? []) as ActivityType[]}
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
};

export default ActivityTypesFilter;
