import { stringifyActivityInterval } from "./activityInterval";
import FormValues from "../types/FormValues";
import { ActivityPayload } from "../../../../store/api/ApiService";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

const getActivityPayloadFromCreationForm = (
  values: FormValues,
  activityTypes: ActivityType[]
): ActivityPayload => {
  const activityType = activityTypes.find(({ id }) => id === values.activityTypeId);
  return {
    account: values.companyId ? { id: values.companyId } : null,
    allDay: values.allDay,
    assigneeId: values.assignee?.id,
    completed: values.completed,
    contact: values.personId ? { id: values.personId } : null,
    crmActivityType: { id: values.activityTypeId! },
    deal: values.dealId ? { id: values.dealId } : null,
    endAt: values.allDay ? undefined : values.endAt?.toISOString(),
    name: values.name || activityType?.name || "",
    note: values.note,
    recurInterval: stringifyActivityInterval(values.recurInterval),
    reminders: values.reminders,
    startAt: values.startAt?.toISOString(),
    visibility: values.visibility,
    teamId: values.teamIds,
  };
};

export default getActivityPayloadFromCreationForm;
