import React, { memo, ReactNode, useCallback, useMemo } from "react";
import {
  DatePicker,
  Labeled,
  LabeledFieldProps,
  MonetaryField,
  NumberField,
  SelectField,
  TimePicker,
} from "@mapmycustomers/ui";
import PhoneTextField from "component/input/PhoneTextField";
import AddressField from "./AddressField";
import { TextAreaWithMentions } from "@mapmycustomers/ui";
import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import OptionValue from "@mapmycustomers/shared/types/customField/OptionValue";
import { SHOULD_USE_12_HOUR_FORMAT, TEXT_LENGTH_LIMIT } from "util/consts";
import { isMonetaryValue } from "@mapmycustomers/shared/util/assert";
import { parseApiDateWithTz, parseTime } from "util/parsers";
import isValidDate from "util/isValidDate";
import { formatDate } from "util/formatters";
import { OptionalFields } from "@mapmycustomers/shared/util/ts";
import { EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import getFieldModelByEntityType from "../../util/fieldModel/getByEntityType";
import CalculatedFieldValue from "./CalculatedFieldValue";
import styles from "./CustomField.module.scss";
import { getUsers } from "store/members";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import User from "@mapmycustomers/shared/types/User";

interface Props extends Omit<LabeledFieldProps, "children"> {
  allowPreview?: boolean;
  definition: CustomFieldType;
  disabled?: boolean;
  entityType?: EntityTypeSupportingCustomFields;
  onChange?: (value: OptionalFields<CustomFieldValue, "id">) => void;
  tabIndex?: number;
  users: User[];
  value?: OptionalFields<CustomFieldValue, "id">;
}

const CustomField = memo<Props>(
  ({ allowPreview, definition, disabled, entityType, onChange, users, value, ...labeledProps }) => {
    const handleChange = useCallback(
      (updatedValue: MonetaryValue | number | number[] | string | undefined) => {
        const valueToSend =
          typeof updatedValue === "string"
            ? ([updatedValue] as [string])
            : typeof updatedValue === "number"
            ? ([updatedValue] as [number])
            : isMonetaryValue(updatedValue)
            ? ([updatedValue] as [MonetaryValue])
            : // this is what single-select fields send when you click on "clear" - an [undefined] array
            Array.isArray(updatedValue) &&
              updatedValue.length === 1 &&
              updatedValue[0] === undefined
            ? undefined
            : updatedValue;

        onChange?.({
          ...(value ?? {
            crmPropertyKey: definition.crmPropertyKey,
            currencyId: definition.currencyId,
            customField: {
              id: definition.id,
            },
            dataType: definition.dataType,
            esKey: definition.esKey,
            options: definition.options,
          }),
          value: valueToSend,
        });
      },
      [definition, onChange, value]
    );

    const options = useMemo(() => {
      if (!Array.isArray(definition.options)) {
        return [];
      }
      return definition.options
        .map(({ displayName, displayOrder, value }) => ({
          displayOrder,
          label: displayName,
          value,
        }))
        .sort((a, b) => a.displayOrder - b.displayOrder);
    }, [definition.options]);

    const handleFilterOption = useCallback((inputValue: string, option?: { label?: ReactNode }) => {
      return (
        (option?.label as string)?.toLowerCase().includes(inputValue.toLowerCase().trim()) ?? false
      );
    }, []);

    const placeholder = disabled ? undefined : definition.placeholder || definition.displayName;

    const field = useMemo(
      () => entityType && getFieldModelByEntityType(entityType).getByName(definition.esKey),
      [definition, entityType]
    );

    if (definition.isCalculated && field) {
      return <CalculatedFieldValue field={field} value={value} />;
    }

    switch (definition.dataType) {
      case CustomFieldDataType.TEXT:
        return (
          <TextAreaWithMentions
            allUsers={users}
            className={styles.maxWidth}
            disabled={disabled}
            disableMentions
            isTextField
            maxLength={TEXT_LENGTH_LIMIT}
            onChange={handleChange}
            placeholder={placeholder}
            value={(value?.value as [string])?.[0]}
            {...labeledProps}
          />
        );
      case CustomFieldDataType.ADDRESS:
        return (
          <AddressField
            className={styles.maxWidth}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            value={value?.value ? (value.value as [string])[0] : ""}
            {...labeledProps}
          />
        );

      case CustomFieldDataType.PHONE:
        return (
          <PhoneTextField
            className={styles.maxWidth}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            value={(value?.value as [string])?.[0]}
            {...labeledProps}
          />
        );
      case CustomFieldDataType.LARGE_TEXT:
        return (
          <TextAreaWithMentions
            allUsers={users}
            className={styles.maxWidth}
            disabled={disabled}
            disableMentions
            onChange={handleChange}
            placeholder={placeholder}
            value={(value?.value as [string])?.[0]}
            {...labeledProps}
          />
        );
      // We do not support boolean custom fields yet. And never did.
      // case CustomFieldDataType.BOOLEAN:
      //   return <Switch onChange={handleChange} value={(value.value as [boolean])[0]} />;
      case CustomFieldDataType.DATE:
        const parsedDate = value?.value ? parseApiDateWithTz((value.value as [string])[0]) : null;
        return (
          <Labeled {...labeledProps}>
            <DatePicker
              allowClear
              disabled={disabled}
              onChange={(date: Date | undefined) =>
                handleChange(date ? formatDate(date, "yyyy-MM-dd") : [])
              }
              placeholder={disabled ? "" : placeholder}
              size="large"
              value={isValidDate(parsedDate) ? parsedDate : null}
            />
          </Labeled>
        );
      case CustomFieldDataType.TIME:
        const parsedDateTime = parseTime(value?.value ? `${(value.value as [string])[0]}:00` : "");
        return (
          <Labeled {...labeledProps}>
            <TimePicker
              allowClear
              disabled={disabled}
              format={`${SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm"}`}
              onChange={(dateTime: Date | null) =>
                handleChange(dateTime ? formatDate(dateTime, "HH:mm") : [])
              }
              placeholder={disabled ? "" : placeholder}
              size="large"
              use12Hours={SHOULD_USE_12_HOUR_FORMAT}
              value={isValidDate(parsedDateTime) ? parsedDateTime : null}
            />
          </Labeled>
        );
      case CustomFieldDataType.SINGLE_OPTION:
        return (
          <SelectField<OptionValue["value"]>
            allowClear
            className={styles.maxWidth}
            disabled={disabled}
            filterOption={handleFilterOption}
            onChange={(value) => handleChange([value])}
            options={options}
            placeholder={placeholder}
            showSearch
            value={value?.value as number | undefined}
            {...labeledProps}
          />
        );
      case CustomFieldDataType.MULTI_OPTION:
        return (
          <SelectField<OptionValue["value"][]>
            allowClear
            className={styles.maxWidth}
            disabled={disabled}
            filterOption={handleFilterOption}
            mode="multiple"
            onChange={handleChange}
            options={options}
            placeholder={placeholder}
            showSearch
            value={value?.value as number[] | undefined}
            {...labeledProps}
          />
        );
      case CustomFieldDataType.INTEGER:
      case CustomFieldDataType.DECIMAL: {
        const fieldValue =
          Array.isArray(value?.value) && value?.value.length
            ? (value?.value[0] as number)
            : undefined;
        return (
          <NumberField
            className={styles.maxWidth}
            disabled={disabled}
            format={definition.displayType}
            inputClassName={styles.maxWidth}
            onChange={handleChange}
            placeholder={placeholder}
            precision={definition.dataType === CustomFieldDataType.INTEGER ? 0 : undefined}
            value={fieldValue}
            {...labeledProps}
          />
        );
      }
      case CustomFieldDataType.MONETARY:
        return (
          <MonetaryField
            className={styles.maxWidth}
            currencyId={definition.currencyId!}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            value={value?.value ? (value.value as [MonetaryValue])[0] : undefined}
            {...labeledProps}
          />
        );
      default:
        return null;
    }
  }
);

const mapStateToProps = (state: RootState) => ({
  users: getUsers(state),
});

export default connect(mapStateToProps)(CustomField);
