import React from "react";
import toolbarStyles from "../Toolbar.module.scss";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import Tooltip from "antd/es/tooltip";
import { useConfigProvider } from "../../../ConfigProvider";
import CreateLink from "../actions/component/Links/CreateLink";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

interface InsertLinkProps {
  onAction?: (actionKey: string) => void;
}

const InsertLink: React.FC<InsertLinkProps> = ({ onAction }) => {
  const [insetLinkVisible, setInsetLinkVisible] = React.useState(false);
  const configProvider = useConfigProvider();

  useChangeTracking(() => {
    insetLinkVisible && onAction?.("Insert Link");
  }, [insetLinkVisible, onAction]);

  return (
    <>
      <Tooltip
        title={configProvider.formatMessage(
          "ui.emailBody.toolbar.actions.insertLink"
        )}
      >
        <span
          className={toolbarStyles.togglerButton}
          onMouseDown={stopEvents}
          onClick={() => setInsetLinkVisible(true)}
        >
          <FontAwesomeIcon icon={faLink} />
        </span>
      </Tooltip>
      {insetLinkVisible && (
        <CreateLink onHide={() => setInsetLinkVisible(false)} />
      )}
    </>
  );
};

export default InsertLink;
