import React, { FC, useCallback, useState } from "react";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import { useConfigProvider } from "../../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import cn from "classnames";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import Footer from "../Footer";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { format } from "date-fns/esm";
import useDatePicker from "../../util/useDatePicker";

const SHOULD_USE_12_HOUR_FORMAT = new Date(2000, 1, 1, 1, 0, 0)
  .toLocaleString()
  .includes("AM");

const block = bem("mmc-inline-time-picker");

export interface InlineTimePickerProps
  extends Omit<PickerTimeProps<Date>, "onChange" | "picker">,
    Omit<LabeledFieldProps, "children" | "suffix"> {
  caption?: string;
  className?: string;
  locked?: boolean;
  onChange?: (date: Date | undefined) => void;
  use12Hours?: boolean;
}

export const InlineTimePicker: FC<InlineTimePickerProps> = ({
  caption,
  className,
  disabled,
  label,
  labelClassName,
  labelPosition = "side",
  locked,
  onChange,
  required,
  rowProps,
  sideLabelSpan,
  size = "large",
  value,
  ...props
}) => {
  const { formatMessage } = useConfigProvider();

  const [inlineValue, setInlineValue] = useState<Date | null | undefined>(
    value
  );

  const formattedTime = value
    ? format(value, SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm")
    : undefined;

  const [editing, startEditing, cancelEditing] = useBoolean();

  const handleChange = useCallback(
    (date: Date | null) => setInlineValue(date),
    []
  );

  const handleSave = useCallback(() => {
    cancelEditing();
    onChange?.(inlineValue ?? undefined);
  }, [inlineValue, cancelEditing, onChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value);
    startEditing();
  }, [disabled, setInlineValue, startEditing, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")
      ) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [handleSave]
  );

  const DatePickerComponent = useDatePicker();

  return (
    <div className={cn(block({ disabled: !!disabled }), className)}>
      <Labeled
        label={<div className={block("label-item")}>{label}</div>}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        extra={
          disabled ? (
            <FontAwesomeIcon className={block("lock")} icon={faLock} />
          ) : undefined
        }
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        {editing ? (
          <DatePickerComponent
            allowClear
            autoFocus
            className={block("input")}
            defaultOpen
            disabled={disabled || locked}
            mode={undefined}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            picker="time"
            size={size}
            suffixIcon={undefined}
            value={inlineValue}
            {...props}
          />
        ) : (
          <div className={block("value")} onClick={handleStartEditing}>
            {formattedTime ?? (
              <span className={block("unknown")}>
                {formatMessage("ui.inlineInput.largeTextField.unknown")}
              </span>
            )}
          </div>
        )}
      </Labeled>
      <Footer
        caption={caption}
        disabled={disabled}
        editing={editing}
        onCancel={cancelEditing}
        onSave={handleSave}
      />
    </div>
  );
};

export default InlineTimePicker;
