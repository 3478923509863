import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { EntityTypeSupportingGroups, Group } from "@mapmycustomers/shared/types/entity";
import { RootState } from "store/rootReducer";
import { Modal } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import { createGroup } from "store/groups/actions";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { isCreatingGroup } from "store/groups";
import User from "@mapmycustomers/shared/types/User";
import { TOPMOST_MODAL_ZINDEX } from "util/consts";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Footer from "./components/Footer";
import Form from "./components/Form";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import useAnalytics from "util/contexts/useAnalytics";
import { getEntityTypeAnalyticsName } from "util/ui";
import styles from "./CreateGroupModal.module.scss";

interface Props {
  createGroup: typeof createGroup.request;
  creating: boolean;
  entityType: EntityTypeSupportingGroups;
  onCreate?: (group: Group, addItems?: boolean) => void;
  onHide: (created: boolean, addItems?: boolean) => void;
  showCreateOptions?: boolean;
  visible: boolean;
}

const CreateGroupModal: React.FC<Props> = ({
  createGroup,
  creating,
  entityType,
  onCreate,
  onHide,
  showCreateOptions,
  visible,
}) => {
  const intl = useIntl();
  const analytics = useExtendedAnalytics(
    ["Create Group", getEntityTypeAnalyticsName(entityType, false, false)],
    useAnalytics()
  );
  useEffect(() => {
    if (visible) {
      analytics.initiated();
    }
  }, [analytics, visible]);

  const [groupName, setGroupName] = useState<string>("");
  useEffect(() => {
    if (visible) {
      setGroupName("");
    }
  }, [visible]);

  const [selectedColor, setSelectedColor] = useState<AnyColor | undefined>();
  const [cadenceInterval, setCadenceInterval] = useState<number | false>(false);
  const [selectedActivityTypeIds, setSelectedActivityTypeIds] = useState<ActivityType["id"][]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<User["id"][]>([]);

  const handleCancelModal = useCallback(() => {
    onHide(false);
    analytics.cancelled("");
  }, [analytics, onHide]);

  const handleCreate = useCallback(
    (createAndAdd?: boolean) => {
      return createGroup({
        color: selectedColor,
        entityType,
        name: groupName,
        cadenceInterval: cadenceInterval || undefined,
        activityTypesIds: selectedActivityTypeIds,
        userIdsToShareWith: selectedUserIds,
        onSuccess: (group: Group) => {
          onCreate?.(group, createAndAdd === true);
          onHide(true, createAndAdd === true);
          analytics.completed("", {
            cadenceInterval,
            sharingWithUsers: selectedUserIds.length > 0,
          });
        },
      });
    },
    [
      analytics,
      cadenceInterval,
      createGroup,
      selectedColor,
      entityType,
      groupName,
      onCreate,
      onHide,
      selectedActivityTypeIds,
      selectedUserIds,
    ]
  );

  const handleCreateAndAdd = useCallback(() => handleCreate(true), [handleCreate]);

  return (
    <Modal
      className={styles.modal}
      footer={
        <Footer
          creating={creating}
          entityType={entityType}
          onCancel={handleCancelModal}
          onCreate={handleCreate}
          onCreateAndAdd={handleCreateAndAdd}
          showCreateOptions={showCreateOptions}
        />
      }
      onCancel={handleCancelModal}
      open={visible}
      title={intl.formatMessage(
        {
          id: "createGroupModal.title",
          defaultMessage:
            "Create New{entityType, select, accounts { Company} contacts { Person} deals { Deal} other {}} Group",
          description: "Title of the Create Group modal",
        },
        {
          entityType,
        }
      )}
      zIndex={TOPMOST_MODAL_ZINDEX}
    >
      <Form
        cadence={cadenceInterval}
        cadenceActivityTypes={selectedActivityTypeIds}
        color={selectedColor}
        name={groupName}
        onCadenceChange={setCadenceInterval}
        onCadenceActivityTypesChange={setSelectedActivityTypeIds}
        onColorChange={setSelectedColor}
        onNameChange={setGroupName}
        onSharedUsersChange={setSelectedUserIds}
        userIds={selectedUserIds}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  creating: isCreatingGroup(state),
});

const mapDispatchToProps = {
  createGroup: createGroup.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupModal);
