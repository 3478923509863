import Modal from "../../../../../Modal";
import Input from "antd/es/input/Input";
import React, { useCallback, useState } from "react";
import Labeled from "../../../../../input/Labeled";
import styles from "./LinkEditModal.module.scss";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { useConfigProvider } from "../../../../../ConfigProvider";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import TextField from "../../../../../input/TextField";

interface Props {
  defaultLink?: string;
  defaultText?: string;
  onConfirm?: (text: string, url: string) => void;
  onHide?: () => void;
  textDisabled?: boolean;
}

const LinkEditModal: React.FC<Props> = ({
  defaultLink,
  defaultText,
  onConfirm,
  onHide,
  textDisabled,
}) => {
  const configProvider = useConfigProvider();

  const [text, setText] = useState<string>(defaultText ?? "");
  const [url, setUrl] = useState<string>(defaultLink ?? "");

  const handleOk = useCallback(() => {
    onConfirm?.(text, url);
  }, [onHide, onConfirm, text, url]);

  const handlePreview = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <Modal
      className={styles.container}
      onCancel={onHide}
      okButtonProps={{
        disabled: url.trim().length === 0 || text.trim().length === 0,
        // onMouseDown: stopEvents,
      }}
      cancelButtonProps={{ hidden: true }}
      okText={configProvider.formatMessage("ui.emailBody.linkModal.okay")}
      title={configProvider.formatMessage("ui.emailBody.linkModal.title")}
      onOk={handleOk}
      open
    >
      <Labeled
        labelClassName={styles.label}
        label={configProvider.formatMessage("ui.emailBody.linkModal.linkText")}
      >
        {textDisabled ? (
          <span>{text}</span>
        ) : (
          <TextField
            className={styles.link}
            type="text"
            placeholder={configProvider.formatMessage(
              "ui.emailBody.linkModal.linkText"
            )}
            onChange={setText}
            value={text}
          />
        )}
      </Labeled>
      <Row justify="space-between">
        <Col className={styles.label}>
          {configProvider.formatMessage("ui.emailBody.linkModal.url")}
        </Col>
        <Col>
          <Row
            align="middle"
            className={styles.preview}
            gutter={8}
            onClick={handlePreview}
          >
            <Col>
              {configProvider.formatMessage("ui.emailBody.linkModal.preview")}
            </Col>
            <Col>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Col>
          </Row>
        </Col>
      </Row>

      <TextField
        type="text"
        placeholder={configProvider.formatMessage("ui.emailBody.linkModal.url")}
        onChange={setUrl}
        value={url}
      />
    </Modal>
  );
};

export default LinkEditModal;
