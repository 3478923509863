import React from "react";
import { useIntl } from "react-intl";
import Tooltip from "antd/es/tooltip";
import Email from "component/field/Email";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import styles from "./EmailField.module.scss";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

const EmailField: React.FC<RegistryProps> = ({ entity, field }) => {
  const intl = useIntl();

  const value = field.getValueFor(entity) as string;
  return (
    <Tooltip
      placement="top"
      title={intl.formatMessage({
        defaultMessage: "Send email",
        description: "Send email tooltip",
        id: "fieldGrid.field.email.tooltip",
      })}
    >
      <div className={styles.container}>
        <Email
          className={styles.email}
          entity={entity as EntitiesSupportedByEmailFeature}
          showIcon={false}
          value={value}
        />
      </div>
    </Tooltip>
  );
};

export default EmailField;
