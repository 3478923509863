import React, { useEffect, useMemo, useRef } from "react";
import { RawFile } from "@mapmycustomers/shared/types/File";
import LightGalleryComponent from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { LightGallery } from "lightgallery/lightgallery";
import { BeforeSlideDetail, InitDetail } from "lightgallery/lg-events";
import hasMimeType, { VIDEO_ANY } from "util/mimeType";
import { VideoSource } from "lightgallery/plugins/video/types";
import loader from "assets/loader.svg";
import { GalleryItem } from "lightgallery/lg-utils";
import { useIntl } from "react-intl";
import "./styles/lightgallery.scss";

interface Props {
  files: RawFile[];
  onFetchPreview?: (payload: { fileId: RawFile["id"]; callback: (blob: Blob) => void }) => void;
  onHide: () => void;
  selectedFile?: RawFile;
}

const Preview: React.FC<Props> = ({ files, onFetchPreview, onHide, selectedFile }) => {
  const intl = useIntl();

  const lightGalleryRef = useRef<LightGallery>();
  const lightGalleryElement = useMemo(
    () => (
      <LightGalleryComponent
        actualSize={false} // it's a good button, but since it has no tooltip, it's confusing
        addClass="lg-media-overlap"
        allowMediaOverlap={false}
        dynamic
        dynamicEl={[]} // empty, but is populated in useEffect below
        infiniteZoom
        licenseKey={process.env.REACT_APP_LIGHTGALLERY_LICENSE_KEY as string}
        onInit={(lightGallery: InitDetail) => {
          lightGalleryRef.current = lightGallery.instance;
        }}
        plugins={[lgZoom, lgVideo]}
        showZoomInOutIcons
        zoom
        // I actually don't see they're used anywhere, but it's still better to provide them
        zoomPluginStrings={{
          zoomIn: intl.formatMessage({
            id: "fileContent.preview.zoom.in",
            defaultMessage: "Zoom In",
          }),
          zoomOut: intl.formatMessage({
            id: "fileContent.preview.zoom.out",
            defaultMessage: "Zoom Out",
          }),
          viewActualSize: intl.formatMessage({
            id: "fileContent.preview.zoom.actualSize",
            defaultMessage: "Actual size",
          }),
        }}
      />
    ),
    // LightGallery is too sensitive to re-rendering, so we're creating it only once
    // Please keep deps array empty. And if you need to add any listeners, do that
    // via lightGalleryRef
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // a map to store downloaded file preview urls
  const fileMap = useRef<Record<RawFile["id"], string>>({});
  useEffect(
    () => () => {
      // revoke all object urls when Preview component is destructed
      Object.values(fileMap.current).forEach((url) => {
        URL.revokeObjectURL(url);
      });
      fileMap.current = {};
    },
    []
  );

  // populate LG with gallery items when files is changed
  useEffect(() => {
    const lightGallery = lightGalleryRef.current;
    if (!lightGallery) {
      return;
    }

    const items: GalleryItem[] = files.map((file) => {
      const isVideo = hasMimeType(file, VIDEO_ANY) && file.streamUrl;
      return {
        alt: file.name,
        download: file.name,
        downloadUrl: isVideo ? file.streamUrl : undefined,
        title: file.name,
        // use loader src to have at least something here
        // we'll replace it with a real image once slide is active
        src: isVideo ? undefined : fileMap.current[file.id] ?? loader,
        video: isVideo
          ? ({
              attributes: { preload: false, controls: true },
              source: [{ src: file.streamUrl, type: file.contentType }],
            } as unknown as VideoSource) // has to have this conversion because of incorrect types for the library
          : undefined,
      };
    });

    lightGallery.refresh(items);
  }, [files]);

  // open gallery when selectedFile is changed
  useEffect(() => {
    if (!selectedFile) {
      lightGalleryRef.current?.closeGallery();
      return;
    }

    const index = files.findIndex((file) => file.id === selectedFile.id);
    if (index >= 0) {
      lightGalleryRef.current?.openGallery(index);
    }
  }, [files, selectedFile]);

  // add onHide event listener
  useEffect(() => {
    lightGalleryRef.current?.el.addEventListener("lgAfterClose", onHide);
    return () => {
      lightGalleryRef.current?.el.removeEventListener("lgAfterClose", onHide);
    };
  }, [onHide]);

  // add onBeforeSlide listener
  useEffect(() => {
    const lightGallery = lightGalleryRef.current;
    if (!lightGallery) {
      return;
    }

    const listener = (event: Event) => {
      // TS obviously doesn't know about such event, but the detail property will
      // be provided by the LightGallery
      // @ts-ignore
      const detail: BeforeSlideDetail = event.detail;
      const file = files[detail.index];
      // we only load preview if it's not a video file and if it wasn't load before
      if (file && !hasMimeType(file, VIDEO_ANY) && !fileMap.current[file.id]) {
        onFetchPreview?.({
          fileId: file.id,
          callback: (blob: Blob) => {
            const url = URL.createObjectURL(blob);
            fileMap.current[file.id] = url;
            const updatedItems = lightGallery.galleryItems.map((item, index) =>
              index === detail.index ? { ...item, src: url, downloadUrl: url } : item
            );
            lightGallery.updateSlides(updatedItems, detail.index);
            // 2nd updateSlides call is NOT a mistake
            // Due to internal LG logic, updateSlides looks for the GalleryItem with the same src
            // its current item had. But since src is exactly the field we changed, it fails to
            // find one and falls back to the first slide.
            // Hence, we need to tell it to go back to detail.index's slide. To do that we use
            // updateSlides instead of slide method because the latter one depends on
            // lightGallery.lgBusy flag and might skip transition. Whereas updateSlides
            // does go to the desired index with no issues.
            // Note: I hate LG library...
            lightGallery.updateSlides(updatedItems, detail.index);
            // and since stupid LG can't figure this out itself, we need to force-update
            // the href on the download button for this item
            lightGallery.setDownloadValue(detail.index);
          },
        });
      }
    };
    lightGallery.el.addEventListener("lgBeforeSlide", listener);
    return () => {
      lightGallery.el.removeEventListener("lgBeforeSlide", listener);
    };
  }, [files, onFetchPreview]);

  return lightGalleryElement;
};

export default Preview;
