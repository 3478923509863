import React from "react";
import cn from "classnames";
import styles from "../Components.module.scss";
import toolbarStyles from "../Toolbar.module.scss";
import { Col, Row } from "antd/es/grid";
import { faEye, faText } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateLink from "./component/Links/CreateLink";
import AttachFiles from "./component/AttachFiles";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import Tooltip from "antd/es/tooltip";
import { useConfigProvider } from "../../../ConfigProvider";
import Popover from "antd/es/popover";
import Toolbar from "../Toolbar";
import DynamicVars from "./component/DynamicVars";
import DynamicVarVariant from "../../type/DynamicVarVariant";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import FileUploadCallback from "../../type/FileUploadCallback";
import InsertLink from "../InsertLink";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

interface Props {
  action?: React.ReactNode;
  dynamicVarVariants?: DynamicVarVariant[];
  editorId?: string;
  entityType?: EntityTypesSupportedByEmailFeature;
  onAction?: (actionKey: string) => void;
  onFilesSelect?: (files: File) => void;
  onLoadFile: FileUploadCallback;
  onPreview?: () => void;
  previewDisableText?: string;
}

const Actions: React.FC<Props> = ({
  action,
  dynamicVarVariants,
  entityType,
  editorId,
  onAction,
  onFilesSelect,
  onLoadFile,
  onPreview,
  previewDisableText,
}) => {
  const [insetLinkVisible, setInsetLinkVisible] = React.useState(false);
  const configProvider = useConfigProvider();
  const [toolbarVisible, setToolbarVisibility] = React.useState(false);

  useChangeTracking(() => {
    toolbarVisible && onAction?.("Text Formatting Bar");
  }, [toolbarVisible]);

  return (
    <>
      <Row
        align="middle"
        className={styles.actionContainer}
        justify="space-between"
      >
        <Col>
          <Row align="middle">
            <Col>
              <Popover
                content={<Toolbar onLoadFile={onLoadFile} />}
                destroyTooltipOnHide={false}
                onOpenChange={setToolbarVisibility}
                // open={toolbarVisible}
                overlayClassName={styles.toolbarPopover}
                placement="topLeft"
                trigger="click"
              >
                <Tooltip
                  title={configProvider.formatMessage(
                    "ui.emailBody.toolbar.actions.formatting"
                  )}
                >
                  <span
                    className={cn(toolbarStyles.togglerButton, {
                      [styles.active]: toolbarVisible,
                    })}
                    onMouseDown={stopEvents}
                  >
                    <FontAwesomeIcon icon={faText} />
                  </span>
                </Tooltip>
              </Popover>
            </Col>
            <Col>
              <InsertLink onAction={onAction} />
            </Col>
            <Col>
              <AttachFiles onAction={onAction} onFilesSelect={onFilesSelect} />
            </Col>
            {dynamicVarVariants && (
              <Col>
                <DynamicVars
                  dynamicVarVariants={dynamicVarVariants}
                  editorId={editorId}
                  entityType={entityType}
                  onAction={onAction}
                />
              </Col>
            )}
            <Col>
              <Tooltip
                title={configProvider.formatMessage(
                  "ui.emailBody.toolbar.actions.preview"
                )}
              >
                <Tooltip
                  title={previewDisableText}
                  trigger={previewDisableText ? ["hover"] : []}
                >
                  <span
                    className={toolbarStyles.togglerButton}
                    onMouseDown={stopEvents}
                    onClick={previewDisableText ? undefined : onPreview}
                  >
                    <FontAwesomeIcon
                      className={cn({
                        [styles.disabledIcon]: !!previewDisableText,
                      })}
                      icon={faEye}
                    />
                  </span>
                </Tooltip>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col>{action}</Col>
      </Row>
      {insetLinkVisible && (
        <CreateLink onHide={() => setInsetLinkVisible(false)} />
      )}
    </>
  );
};

export default Actions;
