import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner, Modal, TextField } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import { RootState } from "store/rootReducer";
import {
  getEmailRecipientsEntityType,
  getEmailTemplates,
  getTemplatePreview,
  isEmailTemplateDeleting,
  isEmailTemplateUpdating,
  isTemplatePreviewLoading,
} from "store/email";
import { connect } from "react-redux";
import messages from "./messages";
import styles from "./TemplateModal.module.scss";
import cn from "classnames";
import {
  deleteEmailTemplate,
  fetchTemplatePreview,
  updateEmailTemplate,
} from "../../../../store/email/actions";
import Row from "antd/es/row";
import { Col } from "antd/es/grid";
import Button from "antd/es/button";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import layout from "../../../../styles/layout";
import Dropdown from "antd/es/dropdown";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import Popconfirm from "antd/es/popconfirm";
import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/map/types";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import PreviewAsModal from "./PreviewAsModal";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import Preview from "../Preview";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";

interface Props {
  deleting: boolean;
  entityType?: EntityTypesSupportedByEmailFeature;
  isTemplatePreviewLoading: boolean;
  onDeleteEmailTemplate: typeof deleteEmailTemplate.request;
  onFetchTemplatePreview: typeof fetchTemplatePreview.request;
  onHide: () => void;
  onSelect: (template: EmailTemplate) => void;
  onSelectRecipientId: (id?: EntitiesSupportedByEmailFeature["id"]) => void;
  onUpdateEmailTemplate: typeof updateEmailTemplate.request;
  recipients: EntitiesSupportedByEmailFeature[];
  selectedRecipientId?: EntitiesSupportedByEmailFeature["id"];
  templatePreview?: EmailPreview;
  templates: EmailTemplate[];
  updating: boolean;
}

const TemplateModal: React.FC<Props> = ({
  deleting,
  entityType,
  isTemplatePreviewLoading,
  onDeleteEmailTemplate,
  onFetchTemplatePreview,
  onHide,
  onSelect,
  onSelectRecipientId,
  onUpdateEmailTemplate,
  recipients,
  selectedRecipientId,
  templatePreview,
  templates,
  updating,
}) => {
  const intl = useIntl();
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | undefined>(templates[0]);
  const [editingName, setEditingName] = useState<string | undefined>();
  const [previewAsModalVisible, showPreviewAsModal, hidePreviewAsModal] = useBoolean();

  const selectedRecipient = useMemo(
    () => recipients.find((recipient) => recipient.id === selectedRecipientId),
    [recipients, selectedRecipientId]
  );

  useEffect(() => {
    if (selectedRecipientId && selectedTemplate && entityType) {
      onFetchTemplatePreview({
        body: selectedTemplate.body,
        entityIds: [selectedRecipientId],
        entityType,
        isHtml: true,
        logActivity: false,
        subject: selectedTemplate.subject,
        tracking: true,
      });
    }
  }, [entityType, onFetchTemplatePreview, selectedRecipientId, selectedTemplate]);

  useEffect(() => {
    if (recipients.length) {
      onSelectRecipientId(recipients[0]?.id);
    }
  }, [onSelectRecipientId, recipients]);

  const handleDelete = useCallback(() => {
    if (selectedTemplate) {
      let newTemplateToBeSelected = undefined;
      if (templates.length > 1) {
        const index = templates.findIndex(({ id }) => id === selectedTemplate.id);
        // if removed template is the end of list we select a previous one, otherwise - next one.
        newTemplateToBeSelected = templates[index + (index === templates.length - 1 ? -1 : 1)];
      }
      setSelectedTemplate(newTemplateToBeSelected);
      onDeleteEmailTemplate({ templateId: selectedTemplate.id });
    }
  }, [onDeleteEmailTemplate, setSelectedTemplate, selectedTemplate, templates]);

  const handleRename = useCallback(() => {
    if (selectedTemplate && editingName) {
      onUpdateEmailTemplate({
        callback: (template: EmailTemplate) => {
          setEditingName(undefined);
          setSelectedTemplate(template);
        },
        template: { ...selectedTemplate, name: editingName },
      });
    }
  }, [editingName, selectedTemplate, setEditingName, setSelectedTemplate, onUpdateEmailTemplate]);

  useEffect(() => {
    setEditingName(undefined);
    // use need to drop name by selected template changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const actionItems = useMemo(() => {
    return [
      {
        key: "previewAs",
        label: intl.formatMessage(messages.previewAs),
        onClick: showPreviewAsModal,
      },
      {
        key: "rename",
        label: intl.formatMessage(messages.rename),
        onClick: () => setEditingName(selectedTemplate?.name),
      },
      {
        key: "delete",
        label: (
          <Popconfirm
            cancelText={intl.formatMessage(messages.deleteConfirmationCancel)}
            okButtonProps={{ danger: true, disabled: deleting, loading: deleting }}
            okText={intl.formatMessage(messages.deleteConfirmationOk)}
            onConfirm={handleDelete}
            overlayClassName={styles.confirmation}
            placement="left"
            title={intl.formatMessage(messages.deleteConfirmationText)}
          >
            <span onClick={stopEvents}>{intl.formatMessage(messages.delete)}</span>
          </Popconfirm>
        ),
      },
    ];
  }, [deleting, handleDelete, intl, showPreviewAsModal, setEditingName, selectedTemplate]);

  const handleSelect = useCallback(() => {
    if (selectedTemplate) {
      onSelect(selectedTemplate);
      onHide();
    }
  }, [onHide, onSelect, selectedTemplate]);

  return (
    <>
      <Modal
        centered
        className={styles.modal}
        footer={null}
        onCancel={onHide}
        open
        title={intl.formatMessage(messages.emailTemplates)}
        width="clamp(300px, 80vw, 950px)"
      >
        <div className={styles.container}>
          <div className={styles.templateList}>
            {templates.map((template) => (
              <div
                className={cn(styles.template, {
                  [styles.templateSelected]: template.id === selectedTemplate?.id,
                })}
                key={template.id}
                onClick={() => setSelectedTemplate(template)}
              >
                {template.name}
              </div>
            ))}
          </div>
          {selectedTemplate && (
            <div className={styles.templatePreview}>
              <div className={styles.templateHeader}>
                {editingName === undefined ? (
                  <div
                    className={styles.templateName}
                    onClick={() => setEditingName(selectedTemplate.name)}
                  >
                    {selectedTemplate.name}
                  </div>
                ) : (
                  <div className={styles.templateNameEdit}>
                    <TextField
                      className={styles.templateNameEditField}
                      onChange={setEditingName}
                      value={editingName}
                    />
                    <Row gutter={layout.spacerS}>
                      <Col>
                        <Button
                          disabled={updating}
                          loading={updating}
                          onClick={() => setEditingName(undefined)}
                          size="small"
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          disabled={updating || editingName.trim() === ""}
                          loading={updating}
                          onClick={handleRename}
                          size="small"
                          type="primary"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
                <div>
                  <Dropdown
                    menu={{ items: actionItems }}
                    overlayClassName={styles.templateMenu}
                    trigger={["click"]}
                  >
                    <FontAwesomeIcon className={styles.moreIcon} icon={faEllipsisH} />
                  </Dropdown>
                </div>
              </div>
              <div className={styles.templateBody}>
                {isTemplatePreviewLoading && <LoadingSpinner />}
                {!isTemplatePreviewLoading && templatePreview && selectedRecipient && (
                  <Preview preview={templatePreview} recipient={selectedRecipient} />
                )}
              </div>
            </div>
          )}
        </div>
        <Row className={styles.footer} justify="end">
          <Col>
            <Button disabled={!selectedTemplate} onClick={handleSelect} type="primary">
              {intl.formatMessage(messages.applyTemplate)}
            </Button>
          </Col>
        </Row>
      </Modal>
      {previewAsModalVisible && (
        <PreviewAsModal
          onHide={hidePreviewAsModal}
          onSelectRecipientId={onSelectRecipientId}
          recipients={recipients}
          selectedRecipientId={selectedRecipientId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  deleting: isEmailTemplateDeleting(state),
  entityType: getEmailRecipientsEntityType(state),
  isTemplatePreviewLoading: isTemplatePreviewLoading(state),
  templatePreview: getTemplatePreview(state),
  templates: getEmailTemplates(state),
  updating: isEmailTemplateUpdating(state),
});

const mapDispatchToProps = {
  onDeleteEmailTemplate: deleteEmailTemplate.request,
  onFetchTemplatePreview: fetchTemplatePreview.request,
  onUpdateEmailTemplate: updateEmailTemplate.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateModal);
