import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./NextActivityState.module.scss";
import FrequencyIcon from "../FrequencyIcon";
import cn from "classnames";
import { messages, titleMessages } from "./messages";
import ActivityActions from "../ActivityActions";
import colors from "styles/_colors.module.scss";
import { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import { isNotEmpty } from "@mapmycustomers/shared/util/assert";
import FrequencyPreviewConfig from "../type/FrequencyPreviewConfig";
import { addDays } from "date-fns/esm";
import Point from "./Point";
import getGradientColorBreakPoints from "../util/getGradientColorBreakPoints";
import useSizeObserver from "../../../util/hook/useSizeObserver";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import ActivityScheduled from "../ActivityScheduled";

const POINT_CONTAINER_LEFT = 24;

const borderColors: Record<FrequencyStatus, HexColor> = {
  [FrequencyStatus.PAST_DUE]: colors.red100,
  [FrequencyStatus.UNKNOWN]: colors.gray,
  [FrequencyStatus.UP_TO_DATE]: colors.green100,
  [FrequencyStatus.UPCOMING]: colors.yellow100,
};

const fillColors: Record<FrequencyStatus, HexColor> = {
  [FrequencyStatus.PAST_DUE]: colors.red010,
  [FrequencyStatus.UNKNOWN]: colors.softGray,
  [FrequencyStatus.UP_TO_DATE]: colors.green010,
  [FrequencyStatus.UPCOMING]: colors.yellow010,
};

interface Props {
  config: FrequencyPreviewConfig;
  hideActions?: boolean;
}

const NextActivityState: React.FC<Props> = ({ config, hideActions }) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const [points, setPoints] = useState<Date[]>([]);
  const [positionIndex, setPositionIndex] = useState<number>(0);
  const [pointContainerWidth, , ref] = useSizeObserver<HTMLDivElement>();

  const {
    actualFrequencyCriteria,
    entity: { cadenceDaysOut, cadenceStatus },
  } = config;

  const pointsCount = (actualFrequencyCriteria?.cadenceInterval ?? 0) + 1;
  const pointPercentSize = 100 / pointsCount;

  const [lightGreenPercentage, yellowPercentage] = useMemo(
    () => getGradientColorBreakPoints(actualFrequencyCriteria?.cadenceInterval),
    [actualFrequencyCriteria?.cadenceInterval]
  );

  const getStatusByIndex = useCallback(
    (index: number) => {
      let percentPosition = index * pointPercentSize + pointPercentSize / 2;
      if (index === 0 && pointContainerWidth) {
        percentPosition = percentPosition + (POINT_CONTAINER_LEFT * 100) / pointContainerWidth;
      }
      const greenBreakPoint = (lightGreenPercentage + yellowPercentage) / 2;
      if (percentPosition <= greenBreakPoint) {
        return FrequencyStatus.UP_TO_DATE;
      }
      if (index === points.length - 1) {
        return FrequencyStatus.PAST_DUE;
      }
      return FrequencyStatus.UPCOMING;
    },
    [lightGreenPercentage, pointPercentSize, pointContainerWidth, points.length, yellowPercentage]
  );

  useLayoutEffect(() => {
    if (containerRef.current && actualFrequencyCriteria.cadenceInterval) {
      const points: Date[] = [];
      if (cadenceStatus !== FrequencyStatus.UNKNOWN && isNotEmpty(cadenceDaysOut)) {
        let positionIndex = actualFrequencyCriteria.cadenceInterval + cadenceDaysOut;
        const startDate = addDays(
          Date.now(),
          -cadenceDaysOut - actualFrequencyCriteria.cadenceInterval
        );

        positionIndex =
          positionIndex < 0
            ? 0
            : positionIndex > actualFrequencyCriteria.cadenceInterval
            ? actualFrequencyCriteria.cadenceInterval
            : positionIndex;

        const position = pointPercentSize * positionIndex + pointPercentSize / 2;
        containerRef.current?.style.setProperty("--pointer-pos", `${position}%`);

        new Array(pointsCount).fill(1).forEach((_, index) => {
          points.push(addDays(startDate, index));
        });
        setPositionIndex(positionIndex);
        setPoints(points);
      }
      if (cadenceStatus) {
        containerRef.current?.style.setProperty(
          "--pointer-border-color",
          borderColors[cadenceStatus]
        );
        containerRef.current?.style.setProperty("--pointer-color", fillColors[cadenceStatus]);
      }
    }
  }, [
    cadenceDaysOut,
    actualFrequencyCriteria,
    pointPercentSize,
    pointsCount,
    cadenceStatus,
    setPositionIndex,
  ]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={styles.pointsContainer}
        ref={ref}
        style={{
          left: `${POINT_CONTAINER_LEFT}px`,
          width: `calc(100% - ${POINT_CONTAINER_LEFT * 2}px)`,
        }}
      >
        {isNotEmpty(cadenceDaysOut) && (
          <>
            <div className={styles.pointerOuter} />
            <div className={styles.pointerInner} />
          </>
        )}
        {points.map((date, i) => (
          <Point
            cadenceDaysOut={cadenceDaysOut}
            date={date}
            isToday={positionIndex === i}
            key={date.valueOf()}
            pointPercentSize={pointPercentSize}
            position={i}
            sequenceLength={points.length}
            status={getStatusByIndex(i)}
          />
        ))}
      </div>
      <div className={styles.leftItems}>
        <div className={styles.stateIconContainer}>
          <FrequencyIcon size="tiny" status={cadenceStatus} />
        </div>
        <div>
          <div
            className={cn(styles.title, {
              [styles.titleOverdue]: cadenceStatus === FrequencyStatus.PAST_DUE,
            })}
          >
            {cadenceDaysOut === 0
              ? intl.formatMessage(titleMessages.overdueToday)
              : cadenceStatus
              ? cadenceStatus === FrequencyStatus.UPCOMING && (cadenceDaysOut ?? 0) === 0
                ? intl.formatMessage(titleMessages.dueToday)
                : intl.formatMessage(titleMessages[cadenceStatus], {
                    count:
                      (cadenceStatus === FrequencyStatus.PAST_DUE ? 1 : -1) * (cadenceDaysOut ?? 0),
                  })
              : ""}
          </div>
          <div className={styles.subTitle}>
            {intl.formatMessage(messages.subTitle, {
              count: actualFrequencyCriteria.cadenceInterval,
            })}
          </div>
        </div>
      </div>
      <div>
        {hideActions ? (
          <ActivityScheduled />
        ) : (
          <ActivityActions criteria={config.actualFrequencyCriteria} entity={config.entity} />
        )}
      </div>
    </div>
  );
};

export default NextActivityState;
