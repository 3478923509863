import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import LockedFiles from "./LockedFormFields/LockedFiles";
import { getAssociatedFiles } from "../store";

const mapStateToPropsForLocked = (state: RootState) => ({
  files: getAssociatedFiles(state),
});

const LockedFile = connect(mapStateToPropsForLocked)(LockedFiles);

export default LockedFile;
