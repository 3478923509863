import React from "react";
import useNameRequiredValidationRules from "util/form/useNameRequiredValidationRules";
import { defineMessages, useIntl } from "react-intl";
import styles from "./NameField.module.scss";
import { FormItem, TextField } from "@mapmycustomers/ui";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import useFirstLastNameRequirement from "../../../../util/hook/entityForm/useFirstLastNameRequirement";

const messages = defineMessages({
  firstName: {
    id: "formFields.personName.firstName",
    defaultMessage: "First Name",
    description: "First name field on the Create Person modal",
  },
  lastName: {
    id: "formFields.personName.lastName",
    defaultMessage: "Last Name",
    description: "Last name field on the Create Person modal",
  },
});

interface Props {
  actualSchema: LayoutSchemaField[];
  disabled?: boolean;
  required?: boolean;
}

const PersonNameField: React.FC<Props> = ({ actualSchema, disabled, required }) => {
  const intl = useIntl();

  const [firstNameRequired, lastNameRequired] = useFirstLastNameRequirement(actualSchema);

  const someRuleAreRequired = firstNameRequired || lastNameRequired;

  const nameRules = useNameRequiredValidationRules(intl);

  return (
    <div className={styles.container}>
      <FormItem
        className={styles.fullWidth}
        label={intl.formatMessage(messages.firstName)}
        name="firstName"
        rules={someRuleAreRequired ? [{ required: firstNameRequired }] : nameRules}
      >
        <TextField
          disabled={disabled}
          label={intl.formatMessage(messages.firstName)}
          placeholder={intl.formatMessage(messages.firstName)}
          required={firstNameRequired}
        />
      </FormItem>
      <FormItem
        className={styles.fullWidth}
        label={intl.formatMessage(messages.lastName)}
        name="lastName"
        rules={someRuleAreRequired ? [{ required: lastNameRequired }] : nameRules}
      >
        <TextField
          disabled={disabled}
          label={intl.formatMessage(messages.lastName)}
          placeholder={intl.formatMessage(messages.lastName)}
          required={lastNameRequired}
        />
      </FormItem>
    </div>
  );
};

export default PersonNameField;
