import { Person } from "@mapmycustomers/shared/types/entity";
import Color from "@mapmycustomers/shared/enum/Color";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";

const getInitialValues = (initialValues?: Partial<Person>, fixedCompany?: CompanyRef) => {
  const result = initialValues
    ? {
        color: Color.BLACK,
        name: {
          firstName: (initialValues.name ?? "").substring(
            0,
            (initialValues.name ?? "").indexOf(" ")
          ),
          lastName: (initialValues.name ?? "").substring((initialValues.name ?? "").indexOf(" ")),
        },
        phone: initialValues.phone,
      }
    : {};

  const fixedCompanyList = fixedCompany ? [fixedCompany] : [];
  return {
    ...(!initialValues
      ? {}
      : initialValues.address || initialValues.city || !initialValues.geoPoint
      ? {
          ...result,
          addressDetails: {
            address: initialValues.address,
            city: initialValues.city,
            region: initialValues.region,
            regionCode: initialValues.regionCode,
            postalCode: initialValues.postalCode,
            countryCode: initialValues.countryCode,
            geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
          },
        }
      : {
          ...result,
          longitude: initialValues.geoPoint.coordinates[0] ?? "",
          latitude: initialValues.geoPoint.coordinates[1] ?? "",
        }),
    accounts: initialValues?.accounts
      ? [...initialValues.accounts, ...fixedCompanyList]
      : [...fixedCompanyList],
  };
};

export default getInitialValues;
