import { useCallback } from "react";
import { createActivity } from "../store/actions";
import { FormInstance } from "antd/es/form";
import { ActivityPayload } from "store/api/ApiService";
import FormValues from "../types/FormValues";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import HideAction from "types/HideCallback";
import { Activity } from "@mapmycustomers/shared/types/entity";
import localSettings from "config/LocalSettings";
import { AnalyticsService } from "util/analytic/AnalyticsService";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import getActivityPayloadFromCreationForm from "./getActivityPayloadFromCreationForm";

const useCreateCallback = (
  activityTypes: ActivityType[],
  createActivityRequest: typeof createActivity.request,
  form: FormInstance<FormValues>,
  formLayout: FormLayout | undefined,
  onHide: HideAction<Activity>,
  analyticIssuer?: AnalyticsService
) =>
  useCallback(() => {
    if (!formLayout) {
      return;
    }
    const values = form.getFieldsValue(true);

    const activity: ActivityPayload = getActivityPayloadFromCreationForm(values, activityTypes);

    const customFieldsValues = values.customFields
      ? Object.values<CustomFieldValue>(values.customFields).filter(isDefined)
      : [];

    localSettings.setActivityCreationNotifyPreferences(values.reminders);

    createActivityRequest({
      callback: onHide,
      activity,
      customFieldsValues,
      layoutId: formLayout.id,
    });
    analyticIssuer?.feature(["Activity Created"], { activity });
  }, [activityTypes, analyticIssuer, createActivityRequest, form, formLayout, onHide]);

export default useCreateCallback;
