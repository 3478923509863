import React, {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import * as styles from "./ListWithOverflowItem.module.scss";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";

const getShrinkedStyles = (maxWidthValue: string): CSSProperties => ({
  maxWidth: maxWidthValue,
  minWidth: maxWidthValue,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline",
});

interface ListWithOverflowItemProps {
  children: Array<ReactElement>;
  className?: string;
  overflowRender: (hiddenItemsCount: number) => ReactNode;
}

const ListWithOverflowItemWithShrinking: FC<ListWithOverflowItemProps> = ({
  children,
  className,
  overflowRender,
}) => {
  const listElement = useRef<HTMLDivElement>(null);
  const hiddenListElement = useRef<HTMLDivElement>(null);
  const [hiddenItemsMap, setHiddenItemsMap] = useState<Record<string, boolean>>(
    {}
  );

  const [elementMaxWidth, setElementMaxWidth] = useState<
    Record<number, string>
  >({});

  useLayoutEffect(() => {
    if (listElement.current) {
      let calculatedWidth = listElement.current.clientWidth;
      const containerWidth =
        (listElement.current.parentNode as HTMLElement).clientWidth ?? 0;

      let changed = false;
      hiddenListElement?.current?.childNodes.forEach((child, i) => {
        if ((child as HTMLElement).offsetWidth + 8 > calculatedWidth) {
          if (!changed) {
            const maxWidthValue =
              (hiddenListElement?.current?.childNodes?.length === 1
                ? containerWidth
                : calculatedWidth) - 8;
            if (maxWidthValue > 50) {
              setElementMaxWidth({ [i]: `${maxWidthValue}px` });
            }
            changed = true;
          }
        } else {
          calculatedWidth -= (child as HTMLElement).clientWidth + 8;
        }
      });
    }
  }, [
    listElement.current?.parentNode?.lastChild,
    hiddenListElement?.current?.childNodes,
    setElementMaxWidth,
  ]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      setHiddenItemsMap((previous) =>
        entries.reduce(
          (result, entry) => ({
            ...result,
            // we know that key is defined since we set it ourselves in this component
            [(entry.target as HTMLElement).dataset.key!]: !entry.isIntersecting,
          }),
          previous
        )
      );
    },
    []
  );

  const key = stringHash(
    React.Children.map(
      children,
      (child: ReactElement, index) => child.key ?? index
    )?.join("_") ?? ""
  );

  useEffect(() => {
    if (!listElement.current) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: listElement.current,
      threshold: 1,
    });

    Array.from(listElement.current.children).forEach((item) => {
      if ((item as HTMLElement).dataset.key !== undefined) {
        observer.observe(item);
      }
    });
    return () => {
      observer.disconnect();
      setHiddenItemsMap({});
    };
  }, [handleIntersection, key]);

  const hiddenItemsCount = useMemo(
    () => Object.values(hiddenItemsMap).filter((value) => value).length,
    [children, hiddenItemsMap]
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.hiddenList} ref={hiddenListElement}>
        {React.Children.map(children, (child: ReactElement) => {
          return React.cloneElement(child, {
            className: child.props.className,
            style: { display: "inline" },
          });
        })}
      </div>
      <div className={styles.list} ref={listElement}>
        {React.Children.map(children, (child: ReactElement, index) => {
          return React.cloneElement(child, {
            className: cn(child.props.className, {
              [styles.visible]: !hiddenItemsMap[child.key ?? index],
              [styles.invisible]: hiddenItemsMap[child.key ?? index],
            }),
            "data-key": child.key ?? index,
            ...(elementMaxWidth[index]
              ? { style: getShrinkedStyles(elementMaxWidth[index]) }
              : {}),
          });
        })}
      </div>

      {hiddenItemsCount && Object.values(hiddenItemsMap).length > 1
        ? overflowRender(hiddenItemsCount)
        : null}
    </div>
  );
};

export default ListWithOverflowItemWithShrinking;
