import React, { FC, useEffect, useLayoutEffect, useRef } from "react";
import * as styles from "./SubjectInput.module.scss";
import cn from "classnames";
import TextField, { TextFieldProps } from "../input/TextField";
import { useConfigProvider } from "../ConfigProvider";

const SUBJECT_MAX_LENGTH = 998;

const SubjectInput: FC<TextFieldProps> = ({
  className,
  maxLength = SUBJECT_MAX_LENGTH,
  onChange,
  ...restProps
}) => {
  const configProvider = useConfigProvider();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref?.current && ref.current.focus();
  }, [ref]);

  return (
    <TextField
      className={cn(className, styles.container)}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={configProvider.formatMessage("ui.subjectInput.Placeholder")}
      ref={ref}
      {...restProps}
    />
  );
};

export default SubjectInput;
