import { defineMessages, IntlShape } from "react-intl";
import TimelineEventType from "scene/dashboard/enum/TimelineEventType";

enum TimelineViewFilter {
  ALL = "all",
  CHECK_INS = "checkIns",
  ACTIVITIES = "activities",
  MENTIONS = "mentions",
  NEW_RECORDS = "newRecords",
  ROUTES = "routes",
  DEALS = "deals",
}

const messages = defineMessages({
  [TimelineViewFilter.ALL]: {
    id: "dashboard.timelineViewFilter.all",
    defaultMessage: "All",
    description: "Timeline view filter - All",
  },
  [TimelineViewFilter.CHECK_INS]: {
    id: "dashboard.timelineViewFilter.checkIns",
    defaultMessage: "Check-ins",
    description: "Timeline view filter - Check-ins",
  },
  [TimelineViewFilter.ACTIVITIES]: {
    id: "dashboard.timelineViewFilter.activities",
    defaultMessage: "Activities",
    description: "Timeline view filter - Activities",
  },
  [TimelineViewFilter.MENTIONS]: {
    id: "dashboard.timelineViewFilter.mentions",
    defaultMessage: "Mentions",
    description: "Timeline view filter - Mentions",
  },
  [TimelineViewFilter.NEW_RECORDS]: {
    id: "dashboard.timelineViewFilter.newRecords",
    defaultMessage: "New Records",
    description: "Timeline view filter - New Records",
  },
  [TimelineViewFilter.ROUTES]: {
    id: "dashboard.timelineViewFilter.routes",
    defaultMessage: "Routes",
    description: "Timeline view filter - Routes",
  },
  [TimelineViewFilter.DEALS]: {
    id: "dashboard.timelineViewFilter.deals",
    defaultMessage: "Deals",
    description: "Timeline view filter - Deals",
  },
});

export const getTimelineTypeFilterDisplayName = (intl: IntlShape, filter: TimelineViewFilter) =>
  intl.formatMessage(messages[filter]);

export const getEventTypesByViewFilter = (
  filter: TimelineViewFilter | undefined
): TimelineEventType[] => {
  switch (filter) {
    case TimelineViewFilter.ACTIVITIES:
      return [
        TimelineEventType.ACTIVITIES_ASSIGNED,
        TimelineEventType.ACTIVITIES_COMPLETED,
        TimelineEventType.ACTIVITIES_SCHEDULED,
        TimelineEventType.UNVERIFIED_CHECK_INS,
        TimelineEventType.VERIFIED_CHECK_INS,
      ];
    case TimelineViewFilter.CHECK_INS:
      return [TimelineEventType.VERIFIED_CHECK_INS, TimelineEventType.UNVERIFIED_CHECK_INS];
    case TimelineViewFilter.MENTIONS:
      return [TimelineEventType.MENTIONS];
    case TimelineViewFilter.ROUTES:
      return [TimelineEventType.NEW_ROUTES];
    case TimelineViewFilter.NEW_RECORDS:
      return [
        TimelineEventType.NEW_COMPANIES,
        TimelineEventType.NEW_PEOPLE,
        TimelineEventType.NEW_DEALS,
      ];
    case TimelineViewFilter.DEALS:
      return [
        TimelineEventType.DEAL_STAGE_CHANGE,
        TimelineEventType.DEALS_WON,
        TimelineEventType.DEALS_LOST,
        TimelineEventType.NEW_DEALS,
      ];
    default:
      return Object.values(TimelineEventType);
  }
};

export default TimelineViewFilter;
