import React, { useCallback, useMemo, useRef } from "react";
import cn from "classnames";
import AntDTable, { TableProps } from "antd/es/table";
import { useSize } from "react-hook-size/dist";
import isFunction from "lodash-es/isFunction";
import { LoadingSpinner } from "@mapmycustomers/ui";
import { isMacOS } from "@mapmycustomers/shared/util/browser";
import styles from "./Table.module.scss";

interface Props<T> extends Omit<TableProps<T>, "className" | "rowClassName" | "rowKey"> {
  className?: string;
  clickableRows?: boolean;
  loading?: boolean;
  noHover?: boolean;
  rowClassName?: TableProps<T>["rowClassName"];
  rowKey: ((record: T) => string) | keyof T;
  scrollable?: boolean;
  showBorder?: boolean;
  tableClassName?: string;
}

const Table = <T extends object>({
  className,
  clickableRows,
  loading,
  noHover,
  rowClassName,
  rowKey,
  scroll,
  scrollable,
  showBorder,
  tableClassName,
  ...tableProps
}: Props<T>) => {
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableContainerSize = useSize(tableContainerRef);

  const hasHeader = tableProps?.showHeader !== false;
  const hasFooter = !!(tableProps && tableProps.footer);
  const hasPagination = !!(tableProps && tableProps.pagination);
  const hasSummary = !!(tableProps && tableProps.summary);

  // Add styles for odd and even rows. Could be possible to use use :nth-child to detect that,
  // but antd may insert a service tr inside tbody and it is counted. So that special antd's
  // tr tag ruins rows order. And unfortunately there's no way to apply nth-child to a
  // subset of children (https://stackoverflow.com/a/5546296/5346779)
  // So we have to manually add "odd" or "even" styles
  const getRowClassName = useCallback(
    (record: T, index: number, indent: number): string =>
      cn(
        isFunction(rowClassName) ? rowClassName(record, index, indent) : rowClassName,
        // using the reverse order here because index starts from 0:
        index % 2 === 0 ? styles.oddRow : styles.evenRow,
        { [styles.clickableRows]: clickableRows }
      ),
    [clickableRows, rowClassName]
  );
  const tableElement = tableContainerRef.current;
  const headerHeight = tableElement
    ?.querySelector(".ant-table-header")
    ?.getBoundingClientRect().height;
  const paginationHeight = tableElement
    ?.querySelector(".ant-pagination")
    ?.getBoundingClientRect().height;
  const footerHeight = tableElement
    ?.querySelector(".ant-table-footer")
    ?.getBoundingClientRect().height;
  const summaryHeight = tableElement
    ?.querySelector(".ant-table-summary")
    ?.getBoundingClientRect().height;

  const size = useMemo(() => {
    return {
      border: 2,
      footer: footerHeight ?? 0,
      header: headerHeight ?? 0,
      pagination: paginationHeight ? paginationHeight + 16 : 0, // 16px padding ant-pagination
      summary: summaryHeight ?? 0,
    };
  }, [footerHeight, headerHeight, paginationHeight, summaryHeight]);

  return (
    <>
      <div
        className={cn(
          styles.container,
          { [styles.loading]: loading, [styles.noHover]: noHover },
          className
        )}
        ref={tableContainerRef}
      >
        <AntDTable<T>
          className={cn(styles.table, tableClassName, {
            [styles.borderedTable]: showBorder,
            [styles.hasPagination]: hasPagination,
            [styles.scrollableTable]: scrollable,
          })}
          rowClassName={getRowClassName}
          rowKey={rowKey as string}
          scroll={
            // use scrollable logic, otherwise use given scroll
            scrollable
              ? {
                  x: tableContainerSize.width
                    ? tableContainerSize.width - (isMacOS() ? 20 : 24) // subtract vertical scrollbar width
                    : undefined,
                  y: tableContainerSize.height
                    ? // header, pagination, footer and border sizes
                      tableContainerSize.height -
                      (hasHeader ? size.header : 0) -
                      (hasPagination ? size.pagination : 0) -
                      (hasFooter ? size.footer : 0) -
                      (showBorder ? size.border : 0) -
                      (hasSummary ? size.summary : 0)
                    : undefined,
                }
              : scroll
          }
          {...tableProps}
        />
      </div>
      {loading && <LoadingSpinner global />}
    </>
  );
};

export default Table;
