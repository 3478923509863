import React, { useCallback, useEffect, useMemo, useState } from "react";
import AreaFilterMap from "./AreaFilterMap";
import localSettings from "config/LocalSettings";
import { METERS_IN_MILE } from "util/consts";
import { useIntl } from "react-intl";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { AreaFilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import Button from "antd/es/button";
import { OptionalFields } from "@mapmycustomers/shared/util/ts";
import AreaSearchInput from "component/input/AreaSearchInput";
import { AreaSearchQuery } from "types/filters/AreaSearchQuery";
import { isEntityValue } from "util/filters/Location/assert";
import AreaSearchMode from "enum/AreaSearchMode";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import {
  CUSTOM_DISTANCES_LIMIT,
  DEFAULT_DISTANCE_KM,
  DEFAULT_DISTANCE_MILES,
  DISTANCES_KM,
  DISTANCES_MILES,
} from "util/filters/Location/const";
import { isEntityAreaSearchQuery } from "component/input/AreaSearchInput/utils/assert";
import {
  convertCoordinatesToLongLat,
  convertLatLngToLongLat,
  convertLongLatToLatLngLiteral,
} from "@mapmycustomers/shared/util/geo/GeoService";
import roundToPrecision from "util/number/roundToPrecision";
import styles from "./LocationFilterPopover.module.scss";
import { fetchCompany } from "store/company/actions";
import { fetchPerson } from "store/person/actions";
import { connect } from "react-redux";
import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import { reverseGeocodeAddress } from "store/location/actions";

interface Props {
  mapCenter?: LongLat;
  onChange?: (value: AreaFilterCondition) => void;
  onFetchCompany: typeof fetchCompany;
  onFetchPerson: typeof fetchPerson;
  onHide?: () => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  userPosition?: GeolocationPosition;
  value?: OptionalFields<AreaFilterCondition, "value">; // e.g. when you just added location as first filter
}

const LocationFilterPopover: React.FC<Props> = ({
  mapCenter,
  onFetchCompany,
  onFetchPerson,
  onChange,
  onHide,
  onReverseGeocodeAddress,
  userPosition,
  value,
}) => {
  const intl = useIntl();

  const usesSiUnits = localSettings.doesUseSiUnits();

  const [query, setQuery] = useState<AreaSearchQuery | undefined>();
  useEffect(
    () => {
      if (isEntityValue(value?.value)) {
        if (value!.value.entity.type === EntityType.COMPANY) {
          onFetchCompany({
            id: value!.value.entity.id,
            callback: (company: Company) =>
              setQuery({ mode: AreaSearchMode.ENTITY, entity: company }),
          });
        } else if (value!.value.entity.type === EntityType.PERSON) {
          onFetchPerson({
            id: value!.value.entity.id,
            callback: (person: Person) => setQuery({ mode: AreaSearchMode.ENTITY, entity: person }),
          });
        }
      } else if (value?.value) {
        setQuery({
          mode: AreaSearchMode.ADDRESS,
          address: value.value.location ?? "",
          coordinates: [value.value.longitude, value.value.latitude] as LongLat,
        });
      } else {
        if (userPosition) {
          const coordinates = convertCoordinatesToLongLat(userPosition.coords);
          onReverseGeocodeAddress({
            coordinates,
            callback: (result: GeocodeResult) => {
              // do not re-assign query if it is set already
              setQuery(
                (query) =>
                  query ?? {
                    mode: AreaSearchMode.CURRENT_USER_LOCATION,
                    address: result.address.formattedAddress ?? "",
                    coordinates,
                  }
              );
            },
            failureCallback: () => {
              // do not re-assign query if it is set already
              setQuery(
                (query) =>
                  query ?? {
                    mode: AreaSearchMode.CURRENT_USER_LOCATION,
                    address: coordinates.join(", "),
                    coordinates,
                  }
              );
            },
          });
        } else {
          // do not re-assign query if it is set already
          setQuery(
            (query) =>
              query ?? {
                mode: AreaSearchMode.ADDRESS,
                address: "",
              }
          );
        }
      }
    },
    // userPosition is not included into deps because we don't wanna call this useEffect when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, onFetchPerson, onFetchCompany, onReverseGeocodeAddress]
  );

  const availableDistances = usesSiUnits ? DISTANCES_KM : DISTANCES_MILES;
  const [distance, setDistance] = useState(() => {
    const distanceInMeters = value?.value?.distanceInMeters;
    return usesSiUnits
      ? distanceInMeters
        ? distanceInMeters / 1000
        : DEFAULT_DISTANCE_KM
      : distanceInMeters
      ? distanceInMeters / METERS_IN_MILE
      : DEFAULT_DISTANCE_MILES;
  });

  const handleApplyClick = useCallback(() => {
    const distanceInMeters = distance * (usesSiUnits ? 1000 : METERS_IN_MILE);
    if (isEntityAreaSearchQuery(query)) {
      onChange?.({
        operator: FilterOperator.IN_AREA,
        value: { distanceInMeters, entity: { id: query.entity.id, type: query.entity.entity } },
      });
    } else if (query?.coordinates) {
      const [longitude, latitude] = query.coordinates;
      onChange?.({
        operator: FilterOperator.IN_AREA,
        value: { latitude, longitude, location: query.address ?? "", distanceInMeters },
      });
    }
  }, [onChange, query, distance, usesSiUnits]);

  const coordinates = useMemo(() => {
    if (!query) {
      return undefined;
    }
    const coordinates = isEntityAreaSearchQuery(query)
      ? query.entity.geoPoint?.coordinates
      : query.coordinates;
    return coordinates ? convertLongLatToLatLngLiteral(coordinates) : undefined;
  }, [query]);

  const handleMapChange = useCallback(
    (updatedCoordinates: google.maps.LatLng, radiusInMeters: number) => {
      if (
        coordinates &&
        (coordinates.lat !== updatedCoordinates.lat() ||
          coordinates.lng !== updatedCoordinates.lng())
      ) {
        // reset address and mode when position is changed
        const coordinates = convertLatLngToLongLat(updatedCoordinates);
        onReverseGeocodeAddress({
          coordinates,
          callback: (result) => {
            setQuery({
              mode: AreaSearchMode.ADDRESS,
              address: result.address.formattedAddress ?? "",
              coordinates,
            });
          },
          failureCallback: () =>
            setQuery({ mode: AreaSearchMode.ADDRESS, address: "", coordinates }),
        });
      }
      setDistance(roundToPrecision(radiusInMeters / (usesSiUnits ? 1000 : METERS_IN_MILE), 2));
    },
    [coordinates, onReverseGeocodeAddress, usesSiUnits]
  );

  return (
    <div className={styles.container}>
      <AreaSearchInput
        availableDistances={availableDistances}
        customDistances={localSettings.getAreaFilterCustomDistances()}
        distance={distance}
        mapCenter={mapCenter}
        maxNumberOfCustomDistances={CUSTOM_DISTANCES_LIMIT}
        onCustomDistancesChange={localSettings.setAreaFilterCustomDistances}
        onDistanceChange={setDistance}
        onQueryChange={setQuery}
        showDynamicLocationsWarning
        query={query}
        userPosition={userPosition}
      />
      <AreaFilterMap
        className={styles.map}
        coordinates={coordinates}
        onChange={handleMapChange}
        radiusInMeters={distance * (localSettings.doesUseSiUnits() ? 1000 : METERS_IN_MILE)}
      />
      <div className={styles.footer}>
        <Button onClick={onHide} size="middle" type="default">
          {intl.formatMessage({
            id: "filters.location.footer.cancel",
            defaultMessage: "Cancel",
            description: "Reset button title in LocationFilter",
          })}
        </Button>
        <Button onClick={handleApplyClick} size="middle" type="primary">
          {intl.formatMessage({
            id: "filters.location.footer.apply",
            defaultMessage: "Apply",
            description: "Apply button title in LocationFilter",
          })}
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  onFetchCompany: fetchCompany,
  onFetchPerson: fetchPerson,
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps)(LocationFilterPopover);
