import React, { useCallback } from "react";
import { Cluster } from "@mapmycustomers/shared/types/map";
import defaultIdGetter from "util/defaultIdGetter";
import { Feature, useMap } from "component/map/FeaturedMap/index";
import loggingService from "util/logging";
import { convertRegionToLatLngBounds } from "@mapmycustomers/shared/util/geo/GeoService";
import defaultMapEntryGeometryGetter from "util/map/defaultMapEntryGeometryGetter";
import isValidMapEntry from "util/map/isValidMapEntry";
import defaultClusterStyleGetter from "util/map/markerStyles/defaultClusterStyleGetter";

const isDifferentCluster = (a: Cluster, b: Cluster) =>
  a.id !== b.id ||
  a.count !== b.count ||
  Math.abs(a.region!.latitude - b.region!.latitude) > Number.EPSILON ||
  Math.abs(a.region!.longitude - b.region!.longitude) > Number.EPSILON;

interface Props {
  clusters: Cluster[];
  styleGetter?: (item: Cluster) => google.maps.Data.StyleOptions;
  type?: string;
}

const ClusterFeature: React.FC<Props> = ({
  clusters,
  styleGetter = defaultClusterStyleGetter,
  type = "clusters",
}) => {
  const { map } = useMap();

  const handleClusterClick = useCallback(
    (cluster: Cluster) => {
      loggingService.debug("cluster clicked", cluster);
      const newBounds = convertRegionToLatLngBounds(cluster.region!);
      map.fitBounds(newBounds);
    },
    [map]
  );

  return (
    <Feature<Cluster>
      geometryGetter={defaultMapEntryGeometryGetter}
      idGetter={defaultIdGetter}
      isDifferent={isDifferentCluster}
      items={clusters.filter(isValidMapEntry)}
      onClick={handleClusterClick}
      styleGetter={styleGetter}
      type={type}
    />
  );
};

export default ClusterFeature;
