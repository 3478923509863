import { Col, Row } from "antd/es/grid";
import styles from "./Link.module.scss";
import Popover from "antd/es/popover";
import { Divider } from "antd";
import Button from "antd/es/button";
import { useSlate } from "slate-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Editor, Text, Transforms } from "slate";
import LinkEditModal from "./actions/component/Links/LinkEditModal";
import { useConfigProvider } from "../../ConfigProvider";
import TextStyleType from "@mapmycustomers/shared/enum/textEditor/TextStyleType";

interface Props {
  children: React.ReactNode;
  text: string;
  url: string;
}

const Link: React.FC<Props> = ({ children, text, url }) => {
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const [editModalVisible, setEditModalVisibility] = useState(false);
  const [popoverVisible, setPopoverVisibility] = useState(false);

  useEffect(() => {
    // self-destruction. If text of link is empty we should remove link from leaf.
    if (text.length === 0) {
      Editor.removeMark(editor, TextStyleType.LINK);
    }
  }, [editor, text]);

  const handleUpdate = useCallback(
    (text: string, url: string) => {
      const [linkNode] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Text.isText(n) && !!n.link,
      });
      const savedNode = Array.isArray(linkNode) ? linkNode[0] : linkNode;

      Transforms.setNodes(editor, { ...savedNode, link: url, text } as Text, {
        match: (n) => !Editor.isEditor(n) && Text.isText(n) && !!n.link,
      });
      setEditModalVisibility(false);
    },
    [editor, setEditModalVisibility]
  );

  const handleRemove = useCallback(() => {
    const [linkNode] = Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && Text.isText(n) && !!n.link,
    });
    const savedNode = Array.isArray(linkNode) ? linkNode[0] : linkNode;

    Transforms.setNodes(editor, { ...savedNode, link: undefined } as Text, {
      match: (n) => !Editor.isEditor(n) && Text.isText(n) && !!n.link,
    });
    setEditModalVisibility(false);
  }, [editor]);

  const content = useMemo(
    () => (
      <Row align="middle">
        <Col>
          <span className={styles.goToLink}>
            {configProvider.formatMessage("ui.emailBody.linkModal.goToLink")}
          </span>
          <a className={styles.url} href={url} target="_blank">
            {url}
          </a>
        </Col>
        <Col>
          <Divider className={styles.divider} type="vertical" />
        </Col>
        <Col>
          <Button
            className={styles.button}
            onClick={() => {
              setPopoverVisibility(false);
              setEditModalVisibility(true);
            }}
            type="link"
          >
            {configProvider.formatMessage("ui.emailBody.linkModal.change")}
          </Button>
        </Col>
        <Col>
          <Divider className={styles.divider} type="vertical" />
        </Col>
        <Col>
          <Button
            danger
            className={styles.button}
            onClick={handleRemove}
            type="link"
          >
            {configProvider.formatMessage("ui.emailBody.linkModal.remove")}
          </Button>
        </Col>
      </Row>
    ),
    [configProvider, url, setPopoverVisibility, setEditModalVisibility]
  );

  return (
    <>
      <Popover
        content={content}
        onOpenChange={setPopoverVisibility}
        open={popoverVisible}
        overlayClassName={styles.popover}
        placement="bottom"
        showArrow={false}
        trigger={["click"]}
      >
        <span className={styles.link}>{children}</span>
      </Popover>
      {editModalVisible && (
        <LinkEditModal
          defaultLink={url}
          defaultText={text}
          onConfirm={handleUpdate}
          onHide={() => setEditModalVisibility(false)}
        />
      )}
    </>
  );
};

export default Link;
