import React from "react";
import styles from "./LockedEntityType.module.scss";
import cn from "classnames";
import EntityTypeShapedIcon from "../../../../EntityTypeIcon/EntityTypeShapedIcon";
import messages from "./messages";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { useIntl } from "react-intl";
import LockedArea from "./LockedArea";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

interface Props {
  entityName?: string;
  entityType: EntityTypesSupportedByEmailFeature;
}

const LockedEntityType: React.FC<Props> = ({ entityName, entityType }) => {
  const intl = useIntl();

  return (
    <LockedArea
      faded
      icon={
        <div className={cn(styles.iconWrapper, styles[entityType])}>
          <EntityTypeShapedIcon className={styles.entityTypeIcon} entityType={entityType} />
        </div>
      }
      title={
        entityType === EntityType.COMPANY
          ? intl.formatMessage(messages.selectCompany)
          : intl.formatMessage(messages.selectPerson)
      }
    >
      {entityName ?? intl.formatMessage(messages.autoFilledWithRecipient)}
    </LockedArea>
  );
};

export default LockedEntityType;
