import React, { ReactNode } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import Tooltip from "antd/es/tooltip";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { bem } from "@react-md/utils";

const block = bem("mmc-selectable-item");

interface Props {
  className?: string;
  label: ReactNode;
  onClick?: () => void;
  selected?: boolean;
  selectedClassName?: string;
  tooltip?: ReactNode;
}

const SelectableItem: React.FC<Props> = ({
  className,
  label,
  onClick,
  selected,
  selectedClassName,
  tooltip,
}) => {
  return (
    <div
      className={cn(block({ selected }), className, {
        ...(selectedClassName ? { [selectedClassName]: selected } : {}),
      })}
      onClick={onClick}
    >
      <div className={block("label")}>
        <Tooltip
          mouseEnterDelay={1}
          title={isDefined(tooltip) ? tooltip : label}
        >
          {label}
        </Tooltip>
      </div>
      <div className={block("icon")}>
        {selected && <FontAwesomeIcon icon={faCheck} />}
      </div>
    </div>
  );
};

export default SelectableItem;
