import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import ButtonLink from "component/ButtonLink";
import { RootState } from "../../store/rootReducer";
import { doesOrganizationSupportEmailService } from "store/iam";
import { connect } from "react-redux";
import type { ItemType } from "antd/es/menu/hooks/useItems";
import { useIntl } from "react-intl";
import Dropdown from "antd/es/dropdown";
import { checkEmailSupporting, showEmailCreationModal } from "store/email/actions";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import styles from "./Email.module.scss";
import sendEmailViaClient from "util/email/sendEmailViaClient";
import Modal from "antd/es/modal";
import useEmailSendVariants from "../../util/email/useEmailSendVariants";
import Tooltip from "antd/es/tooltip";
import useAnalytics from "../../util/contexts/useAnalytics";

interface Props {
  checkEmailSupporting: typeof checkEmailSupporting;
  className?: string;
  entity?: EntitiesSupportedByEmailFeature;
  onShowEmailCreationModal: typeof showEmailCreationModal;
  showIcon?: boolean;
  value: null | string | undefined;
}

const Email: React.FC<Props> = ({
  className,
  checkEmailSupporting,
  entity,
  onShowEmailCreationModal,
  showIcon = true,
  value,
}) => {
  const intl = useIntl();
  const analyticService = useAnalytics();
  const [modal, contextHolder] = Modal.useModal();

  const handleOpenLink = useCallback(() => {
    sendEmailViaClient(value ?? "", intl, entity?.name ?? "", modal, () => {});
    analyticService?.clicked(["Email from email client"], { email: value });
  }, [analyticService, intl, modal, entity, value]);

  const handleShowViaMmc = useCallback(() => {
    if (entity) {
      checkEmailSupporting({
        modal,
        successfulCallback: () => {
          onShowEmailCreationModal({ recipients: [entity] });
        },
      });
      analyticService?.clicked(["Email from MMC"], { email: value });
    }
  }, [analyticService, checkEmailSupporting, entity, modal, onShowEmailCreationModal, value]);

  const menuItems: ItemType[] = useEmailSendVariants(intl, handleShowViaMmc, handleOpenLink);

  if (!value) {
    return <>{""}</>;
  }

  return (
    <div className={className}>
      <Tooltip
        title={intl.formatMessage({
          id: "component.field.email.tooltip",
          defaultMessage: "Send email",
          description: "Tooltip for email field",
        })}
      >
        <Dropdown menu={{ items: menuItems }} trigger={["hover"]}>
          <ButtonLink>
            {value}
            {showIcon && (
              <FontAwesomeIcon className={styles.emailIcon} icon={faEnvelope} size="xs" />
            )}
          </ButtonLink>
        </Dropdown>
      </Tooltip>
      {contextHolder}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  organizationSupportEmailService: doesOrganizationSupportEmailService(state),
});

const mapDispatchToProps = {
  onShowEmailCreationModal: showEmailCreationModal,
  checkEmailSupporting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
