import React, { useMemo } from "react";
import AttachedFile from "@mapmycustomers/ui/src/EmailBody/type/AttachedFile";
import { getNewEmailAttachments } from "../../../../store/email";
import { RootState } from "../../../../store/rootReducer";
import { connect } from "react-redux";
import LockedFiles from "../../../createEditEntity/Activity/component/LockedFormFields/LockedFiles";
import { RawFile } from "@mapmycustomers/shared/types/File";

interface Props {
  attachments: AttachedFile[];
}

const Files: React.FC<Props> = ({ attachments }) => {
  const files = useMemo(
    () =>
      attachments.reduce<RawFile[]>((result, { file }) => [...result, ...(file ? [file] : [])], []),
    [attachments]
  );

  return <LockedFiles files={files} />;
};

const mapStateToProps = (state: RootState) => ({
  attachments: getNewEmailAttachments(state),
});

export default connect(mapStateToProps)(Files);
