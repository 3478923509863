const messages = {
  "ui.recipientList.waringText.tooltip.title":
    "Issues were detected with this recipient's email:",
  "ui.recipientList.waringText.tooltip.duplicate": "Duplicate email detected",
  "ui.recipientList.waringText.tooltip.duplicate.description":
    "This email already exists in the selection. Remove to avoid sending the same email twice.",
  "ui.recipientList.waringText.tooltip.missingFields":
    "Missing dynamic variable",
  "ui.recipientList.waringText.tooltip.missingFields.description":
    "This email doesn’t have a value for at least one dynamic variable that was used.",
  "ui.recipientList.waringText.tooltip.ok": "Remove",
} as const;

export default messages;
