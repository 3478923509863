import React, { useCallback, useMemo } from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import {
  Activity,
  AnyEntity,
  Company,
  Deal,
  EntityType,
  Person,
} from "@mapmycustomers/shared/types/entity";
import {
  getEditValues,
  getEntityTypesWithExternalPreview,
  getEntityViewElement,
  getEntityViewEntityId,
  getEntityViewEntityType,
  getPreviewPaneZIndex,
  shouldEditPreviewedEntity,
  shouldEditRequiredFieldsOnly,
} from "store/entityView";
import { CompanyRecordPane, DealRecordPane, PersonRecordPane } from "component/preview";
import { hideEntityView } from "store/entityView/actions";
import { LoadingSpinner } from "@mapmycustomers/ui";
import EditActivityModal from "../../createEditEntity/Activity/EditActivityModal";
import ActivityAnnotation from "../../activity/ActivityAnnotation";
import { PREVIEW_ENTITY_ATTR } from "@mapmycustomers/shared/util/browser";

interface Props {
  edit?: boolean;
  editValues?: Partial<AnyEntity>;
  elementId?: string;
  entityId?: AnyEntity["id"];
  entityType?: EntityType;
  entityTypesWithExternalPreview: EntityType[];
  onHide: () => void;
  requiredFieldsOnly: boolean;
  zIndex?: number;
}

const PreviewEditPanes: React.FC<Props> = ({
  edit,
  editValues,
  elementId,
  entityId,
  entityType,
  entityTypesWithExternalPreview,
  onHide,
  requiredFieldsOnly,
  zIndex,
}) => {
  const element = useMemo(() => {
    if (!elementId) {
      return undefined;
    }
    return (
      (document.querySelector(`[${PREVIEW_ENTITY_ATTR}="${elementId}"]`) as HTMLElement) ??
      undefined
    );
  }, [elementId]);

  const handleHide = useCallback(() => {
    onHide();
    if (element) {
      element.removeAttribute(PREVIEW_ENTITY_ATTR);
    }
  }, [element, onHide]);

  if (entityType && entityTypesWithExternalPreview.includes(entityType)) {
    return null;
  }

  return (
    <>
      {entityType === EntityType.ACTIVITY && !edit && (
        <React.Suspense fallback={<LoadingSpinner global />}>
          <ActivityAnnotation
            activityId={entityId as Activity["id"]}
            eventElement={element}
            onHide={handleHide}
          />
        </React.Suspense>
      )}
      {entityType === EntityType.ACTIVITY && edit && (
        <React.Suspense fallback={<LoadingSpinner global />}>
          <EditActivityModal
            activityId={entityId as Activity["id"]}
            onHide={handleHide}
            overrideValues={editValues as Partial<Activity>}
            requiredFieldsOnly={requiredFieldsOnly}
          />
        </React.Suspense>
      )}
      {entityType === EntityType.COMPANY && (
        <React.Suspense fallback={<LoadingSpinner global />}>
          <CompanyRecordPane
            companyId={entityId as Company["id"]}
            onHide={handleHide}
            zIndex={zIndex}
          />
        </React.Suspense>
      )}
      {entityType === EntityType.DEAL && (
        <React.Suspense fallback={<LoadingSpinner global />}>
          <DealRecordPane dealId={entityId as Deal["id"]} onHide={handleHide} zIndex={zIndex} />
        </React.Suspense>
      )}
      {entityType === EntityType.PERSON && (
        <React.Suspense fallback={<LoadingSpinner global />}>
          <PersonRecordPane
            personId={entityId as Person["id"]}
            onHide={handleHide}
            zIndex={zIndex}
          />
        </React.Suspense>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  edit: shouldEditPreviewedEntity(state),
  editValues: getEditValues(state),
  elementId: getEntityViewElement(state),
  entityId: getEntityViewEntityId(state),
  entityType: getEntityViewEntityType(state),
  entityTypesWithExternalPreview: getEntityTypesWithExternalPreview(state),
  requiredFieldsOnly: shouldEditRequiredFieldsOnly(state),
  zIndex: getPreviewPaneZIndex(state),
});

const mapDispatchToProps = {
  onHide: hideEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewEditPanes);
