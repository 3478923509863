import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { fetchActivitiesLoggedCardData } from "./actions";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import { getDatesRange } from "enum/dashboard/dateRanges";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import {
  applyActivitiesLoggedDrillDownListViewSettings,
  exportActivitiesLoggedCardDrillDownData,
  fetchActivitiesLoggedCardDrillDownData,
  showActivitiesLoggedDrillDown,
} from "scene/dashboard/store/cards/activitiesLogged/actions";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import loggingService from "util/logging";
import {
  getActivitiesLoggedDrillDownTotalFilteredRecords,
  getActivitiesLoggedDrillDownViewState,
} from "scene/dashboard/store/cards/activitiesLogged/selectors";
import localSettings from "config/LocalSettings";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import { exportEntities } from "store/exportEntities/actions";
import activityFieldModel, { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import { EntityType } from "@mapmycustomers/shared";
import { fetchActivitiesLoggedCardDataHelper } from "store/dashboard/cardDataFetchHelpers";
import expandPrimaryAssociationColumn from "scene/dashboard/utils/expandPrimaryAssociationColumn";

const defaultColumns: string[] = [
  ActivityFieldName.PRIMARY_ASSOCIATION,
  ActivityFieldName.ACTIVITY_TYPE,
  ActivityFieldName.ASSIGNEE,
  ActivityFieldName.START_AT,
  ActivityFieldName.COMPLETED_AT,
  ActivityFieldName.NOTE,
];

const defaultSortColumn = ActivityFieldName.COMPLETED_AT;

function* onFetchActivitiesLoggedCardData({
  payload,
}: ReturnType<typeof fetchActivitiesLoggedCardData>) {
  const { configuration, callback, failureCallback, scope } = payload;

  try {
    yield call(fetchActivitiesLoggedCardDataHelper, { configuration, callback, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showActivitiesLoggedDrillDown.request>) {
  const { configuration, customDateRange, scope } = payload;
  const { criteria, dateRange } = configuration;

  // TODO: read saved viewState from somewhere

  const viewState: DrillDownViewState = {
    columns: [
      ...defaultColumns
        .map((name) => activityFieldModel.getByName(name))
        .filter(isDefined)
        .map((field) => ({
          field,
          visible: true,
          pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
          width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
        })),
      ...activityFieldModel.sortedFields
        .filter((field) => !field.hasFeature(FieldFeature.NON_LIST_VIEW))
        .filter((field) => !defaultColumns.includes(field.name))
        .map((field) => ({
          field,
          visible: false,
          pinned: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? ("left" as const) : undefined,
          width: field.hasFeature(FieldFeature.ALWAYS_VISIBLE) ? 380 : undefined,
        })),
    ],
    range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
    filter: {},
    sort: [
      {
        field: activityFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  };

  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const range = customDateRange ?? getDatesRange(dateRange.range, dateRange.subRange);

  const filter: FilterModel = {
    completedAt: { operator: FilterOperator.IN_RANGE, value: [range.startDate, range.endDate] },
  };
  if (criteria.activityTypeIds.length) {
    filter.activityType = { operator: FilterOperator.IN_ANY, value: criteria.activityTypeIds };
  }
  if (isTeamScope) {
    filter.teams = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.assignee = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (criteria.sources.length) {
    filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
  }
  viewState.filter = filter;

  yield put(showActivitiesLoggedDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchActivitiesLoggedCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: activities logged card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyActivitiesLoggedDrillDownListViewSettings(payload.request));
    }

    const drillDownViewState: DrillDownViewState = yield select(
      getActivitiesLoggedDrillDownViewState
    );

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(drillDownViewState, `dashboard/activitiesLogged`);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          activityFieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $offset,
      $limit,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      orgId,
      requestPayload
    );
    payload.dataCallback && payload.dataCallback(response);
    yield put(
      fetchActivitiesLoggedCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchActivitiesLoggedCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport({ payload }: ReturnType<typeof exportActivitiesLoggedCardDrillDownData>) {
  const drillDownViewState: DrillDownViewState = yield select(
    getActivitiesLoggedDrillDownViewState
  );
  const total: number = yield select(getActivitiesLoggedDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      viewState: expandPrimaryAssociationColumn(drillDownViewState),
      total,
    })
  );
}

export function* activitiesLoggedSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchActivitiesLoggedCardData, onFetchActivitiesLoggedCardData);
  yield takeLatest(showActivitiesLoggedDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchActivitiesLoggedCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportActivitiesLoggedCardDrillDownData, onExport);
}
