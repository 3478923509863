@use "../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }

      .hiddenList {
        width: 0px;
        height: 0px;
        overflow: hidden;
      }

      .list {
        display: flex;
        overflow: hidden;
        gap: 0.5rem;
        width: 100%;
        min-width: 0;

        // list can work with any inner items, but just has special handling for antD tags
        // to simplify usage
        & :global(.ant-tag) {
          display: flex;
          overflow: initial;
        }

        & :global(.ant-tag):last-child {
          margin-right: 0;
        }
      }

      .visible {
        order: 0;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
      }

      .invisible {
        order: 100;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s ease-in-out;
      }
    }
  }
}
