import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Scope from "types/dashboard/Scope";
import NoContactInCardData from "./NoContactInCardData";
import NoContactInCardConfiguration from "types/dashboard/cards/noContactIn/NoContactInCardConfiguration";
import ViewMode from "enum/dashboard/ViewMode";
import MapViewportState from "types/map/MapViewportState";
import NoContactInCountCardConfiguration from "types/dashboard/cards/noContactInCount/NoContactInCountCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import { TabbedDrillDownListFetcherPayload } from "scene/dashboard/types/TabbedDrillDownListFetcher";
import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import EntityTypesSupportedInNoContactIn from "types/dashboard/cards/noContactIn/EntityTypesSupportedInNoContactIn";

export const fetchNoContactInCardData = createAction("@dashboard/fetchNoContactInCardData")<{
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
  viewMode: ViewMode;
  viewport?: MapViewportState; // used with Map view mode when user pans/zooms
  offset?: number; // used with List view mode when we need to load more items
}>();

export const fetchNoContactInCountCardData = createAction(
  "@dashboard/fetchNoContactInCountCardData"
)<{
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCountCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showNoContactInDrillDown = createAsyncAction(
  "@dashboard/showNoContactInCardDrillDownModal/request",
  "@dashboard/showNoContactInCardDrillDownModal/success",
  "@dashboard/showNoContactInCardDrillDownModal/failure"
)<
  {
    configuration: NoContactInCardConfiguration | NoContactInCountCardConfiguration;
    scope: Scope;
  },
  { viewStates: Record<EntityTypesSupportedInNoContactIn, DrillDownViewState> },
  unknown
>();

export const hideNoContactInDrillDown = createAction(
  "@dashboard/hideNoContactInCardDrillDownModal"
)<void>();

export const fetchNoContactInCardDrillDownData = createAsyncAction(
  "@dashboard/fetchNoContactInCardDrillDownData/request",
  "@dashboard/fetchNoContactInCardDrillDownData/success",
  "@dashboard/fetchNoContactInCardDrillDownData/failure"
)<
  TabbedDrillDownListFetcherPayload<AnyEntity, EntityTypesSupportedInNoContactIn>,
  {
    entityType: EntityTypesSupportedInNoContactIn;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyNoContactInDrillDownListViewSettings = createAction(
  "@dashboard/applyNoContactInDrillDownListViewSettings"
)<Partial<DrillDownViewState> & { entityType: EntityTypesSupportedInNoContactIn }>();

export const exportNoContactInCardDrillDownData = createAction(
  "@dashboard/exportNoContactInCardData"
)<{
  configuration: NoContactInCardConfiguration | NoContactInCountCardConfiguration;
  entityType: EntityTypesSupportedInNoContactIn;
  scope: Scope;
}>();

export type NoContactInCardActions = ActionType<
  | typeof fetchNoContactInCardData
  | typeof fetchNoContactInCountCardData
  | typeof showNoContactInDrillDown
  | typeof hideNoContactInDrillDown
  | typeof fetchNoContactInCardDrillDownData
  | typeof applyNoContactInDrillDownListViewSettings
  | typeof exportNoContactInCardDrillDownData
>;
