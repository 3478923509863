import AttachedFile from "../../type/AttachedFile";
import FileIcon, { FileType, getFileType } from "../../../FileIcon";
import { faDownload, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import styles from "./AttachedFiles.module.scss";
import { useConfigProvider } from "../../../ConfigProvider";
import Tooltip from "antd/es/tooltip";
import { Divider } from "antd";
import LoadingSpinner from "../../../LoadingSpinner";
import { formatFileSize } from "@mapmycustomers/shared/util/formatters";
import { RawFile } from "@mapmycustomers/shared/types/File";

const getFileNameParts = (attachedFile: AttachedFile) => {
  const name = attachedFile.file?.name ?? attachedFile.preparedFile.name;
  const indexOfDot = name.lastIndexOf(".");
  if (indexOfDot === -1) {
    return [name, ""];
  }

  return [name.substring(0, indexOfDot + 1), name.substring(indexOfDot + 1)];
};

interface Props {
  attachedFiles?: AttachedFile[];
  onDeleteFile?: (id: AttachedFile["id"]) => void;
  onDownloadFile?: (file: AttachedFile) => void;
  onPreviewFile?: (file: AttachedFile) => void;
}

const AttachedFiles: React.FC<Props> = ({
  attachedFiles,
  onDeleteFile,
  onDownloadFile,
  onPreviewFile,
}) => {
  const configProvider = useConfigProvider();

  if (!attachedFiles || attachedFiles.length === 0) {
    return null;
  }
  const handleDeleteFile = useCallback(
    (attachedFile: AttachedFile) => {
      if (attachedFile.file && onDeleteFile) {
        onDeleteFile(attachedFile.id);
      }
    },
    [onDeleteFile]
  );

  const isPreviewableFile = useCallback((file?: RawFile) => {
    return file && getFileType(file) !== FileType.OTHER;
  }, []);

  const handlePreview = useCallback(
    (attachedFile: AttachedFile) => {
      if (isPreviewableFile(attachedFile?.file)) {
        onPreviewFile?.(attachedFile);
      }
    },
    [isPreviewableFile, onPreviewFile]
  );

  if (attachedFiles.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {attachedFiles.map((attachedFile, i) => (
        <div className={styles.fileContainer} key={i}>
          {attachedFile.file && (
            <FileIcon
              className={styles.fileIcon}
              file={attachedFile.file}
              onClick={() => handlePreview(attachedFile)}
            />
          )}
          <div
            className={styles.fileName}
            onClick={() => handlePreview(attachedFile)}
          >
            <div className={styles.fileNamePure}>
              {getFileNameParts(attachedFile)[0]}
            </div>
            <div className={styles.fileNameExtra}>
              <span>{getFileNameParts(attachedFile)[1]}</span>
              <span>
                ({formatFileSize(attachedFile.preparedFile.size ?? 0)})
              </span>
            </div>
          </div>
          <div className={styles.actions}>
            {!attachedFile.uploading && (
              <Tooltip
                title={configProvider.formatMessage(
                  "ui.emailBody.attachedFiles.download"
                )}
              >
                <FontAwesomeIcon
                  className={styles.downloadIcon}
                  icon={faDownload}
                  onClick={() => onDownloadFile?.(attachedFile)}
                />
              </Tooltip>
            )}
            <span>
              {attachedFile.uploading ? (
                <LoadingSpinner mini />
              ) : (
                <Tooltip
                  title={configProvider.formatMessage(
                    "ui.emailBody.attachedFiles.remove"
                  )}
                >
                  <FontAwesomeIcon
                    className={styles.deleteIcon}
                    icon={faTimes}
                    onClick={() => handleDeleteFile(attachedFile)}
                  />
                </Tooltip>
              )}
            </span>
          </div>
        </div>
      ))}
      <Divider className={styles.divider} />
    </div>
  );
};

export default AttachedFiles;
