import React, { useCallback, useMemo } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import defaultFilterOption from "component/input/utils/defaultFilterOption";
import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import Group from "@mapmycustomers/shared/types/entity/Group";
import EntityBadge from "component/EntityBadge";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";
import styles from "./GroupsFilter.module.scss";

export const GROUPS_FILTER_OPERATORS = [
  FilterOperator.IN_ANY,
  FilterOperator.IN_ALL,
  FilterOperator.NOT_IN,
];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  GROUPS_FILTER_OPERATORS.includes(operator);

interface GroupFilterProps extends IFilterComponentProps {}

const GroupsFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_GROUPS,
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const groupsMap = new Map(
      ((options?.availableGroups ?? []) as Group[]).map(({ id, name }) => [id, name])
    );
    const selectedValues = value.value as Group["id"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map((groupId) => groupsMap.get(groupId) ?? String(groupId))
    );
  },
  getComponent:
    (): React.FC<GroupFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const intl = useIntl();

      const sortedGroups = useMemo(
        () => [...((options?.availableGroups as Group[]) ?? [])].sort(nameComparator),
        [options?.availableGroups]
      );

      const setRef = useCallback(
        (ref: RefSelectProps | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <Select<Array<Group["id"]>>
          className={cn(styles.container, className)}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={(groupIds: Group["id"][]) => onChange?.({ ...value, value: groupIds })}
          placeholder={intl.formatMessage({
            id: "filters.group.select.placeholder",
            defaultMessage: "Click or type to select groups",
            description: "Placeholder displayed in a GroupFilter's select field",
          })}
          ref={setRef}
          value={Array.isArray(value.value) ? value.value : []}
        >
          {sortedGroups.map((group) => (
            <Select.Option key={group.id} label={group.name} value={group.id}>
              <EntityBadge entity={group} />
            </Select.Option>
          ))}
        </Select>
      );
    },
};

export default GroupsFilter;
