import { useMemo } from "react";
import { IntlShape } from "react-intl";

const useEmailSendVariants = (
  intl: IntlShape,
  onSendViaMMCEmail: () => void,
  onEmail: () => void
) =>
  useMemo(() => {
    return [
      {
        key: "sendViaMMCEmail",
        label: intl.formatMessage({
          id: "utils.email.sendVariants.sendViaMMC",
          defaultMessage: "Send via MMC",
          description: "Send via MMC email",
        }),
        onClick: onSendViaMMCEmail,
      },
      {
        key: "sendInMyEmailClient",
        label: intl.formatMessage({
          id: "utils.email.sendVariants.sendInMyEmailClient",
          defaultMessage: "Send in my email client",
          description: "Send in my email client",
        }),
        onClick: onEmail,
      },
    ];
  }, [onEmail, intl, onSendViaMMCEmail]);

export default useEmailSendVariants;
