import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import styles from "./GlobalSearch.module.scss";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import {
  clickOnGlobalSearchItem,
  fetchUserRecents,
  hideGlobalSearch,
  performSearch,
} from "store/globalSearch/actions";
import { LoadingSpinner, TextField } from "@mapmycustomers/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import {
  getGlobalSearchResults,
  getUserRecents,
  isUserRecentsLoading,
} from "store/globalSearch/selectors";
import Item from "./components/Item";
import BottomInfo from "./components/BottomInfo";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import useStateWithDebouncedListener from "@mapmycustomers/shared/util/hook/useStateWithDebouncedListener";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import GlobalSearchItem from "../../types/globalSearch/GlobalSearchItem";
import GlobalSearchItemType from "../../enum/GlobalSearchItemType";
import TypeSelector from "./components/TypeSelector";
import NoResults from "./components/NoResults";
import localSettings from "../../config/LocalSettings";
import GlobalSearchFilter, { getGlobalSearchFilterValue } from "./enum/GlobalSearchFilter";
import { useHistory } from "react-router-dom";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import Path from "../../enum/Path";
import cn from "classnames";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import useAnalytics from "util/contexts/useAnalytics";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import useInterval from "util/hook/useInterval";
import { getLeadGenPlan, isCurrentUserOwner } from "store/iam";
import LeadFinderSubscriptionPlan from "@mapmycustomers/shared/enum/LeadFinderSubscriptionPlan";
import showLeadFinderUpsell from "util/leadFinder/showLeadFinderUpsell";

interface Props {
  isOwner: boolean;
  leadGenPlan?: LeadFinderSubscriptionPlan;
  loading: boolean;
  onClickOnGlobalSearchItem: (data: { entityId: Identified["id"]; entityType: EntityType }) => void;
  onFetchUserRecents: () => void;
  onHideGlobalSearch: () => void;
  onPerformSearch: (data: { entityTypes: EntityType[] | undefined; query: string }) => void;
  searchResults: GlobalSearchItem[];
  userRecents: GlobalSearchItem[];
}

const GlobalSearchModal: React.FC<Props> = ({
  isOwner,
  leadGenPlan,
  loading,
  onClickOnGlobalSearchItem,
  onFetchUserRecents,
  onHideGlobalSearch,
  onPerformSearch,
  searchResults,
  userRecents,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const analytics = useExtendedAnalytics("Universal Search", useAnalytics());

  useEffect(() => {
    analytics.clicked([]);
  }, [analytics]);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [direction, setDirection] = useState<-1 | 0 | 1>(0);
  const searchRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<GlobalSearchFilter>(GlobalSearchFilter.ALL);
  const handlePerformSearch = useDynamicCallback(
    (query: string, overrideFilter?: GlobalSearchFilter) => {
      if (query.trim().length >= 3) {
        onPerformSearch({
          entityTypes: getGlobalSearchFilterValue(overrideFilter ?? filter),
          query,
        });
      }
    }
  );

  useChangeTracking(() => {
    analytics.clicked(["Filter"], { filter });
  }, [analytics, filter]);

  const [query, setQuery, resetQuery] = useStateWithDebouncedListener(
    [handlePerformSearch, 500],
    ""
  );

  const searchActivated = query.length >= 3;

  useEffect(() => {
    if (query.length) {
      localSettings.setGlobalSearchQuery(query);
    }
  }, [query]);

  const handleSetFilter = useCallback(
    (filter: GlobalSearchFilter) => {
      setFilter(filter);
      handlePerformSearch(query, filter);
    },
    [handlePerformSearch, query, setFilter]
  );

  useEffect(() => {
    searchRef.current?.focus();
    onFetchUserRecents();
  }, [onFetchUserRecents, searchRef]);

  const handleSelect = useCallback(
    (item: GlobalSearchItem) => {
      if (GlobalSearchItemType.LEAD_FINDER === item.type) {
        analytics.clicked(["Find new leads based on search"]);
        if (leadGenPlan === LeadFinderSubscriptionPlan.NONE) {
          showLeadFinderUpsell(leadGenPlan, intl, isOwner);
        } else {
          history.push(`${Path.MAP}?findLeads&findLeadsQuery=${query}`);
          onHideGlobalSearch();
        }
      } else if (
        [GlobalSearchItemType.QUERY, GlobalSearchItemType.LOCAL_QUERY].includes(item.type)
      ) {
        analytics.clicked([" Clicked most recent search"]);
        handlePerformSearch(item.title);
        resetQuery(item.title);
      } else if (item.entity && item.entityType) {
        analytics.clicked([" Clicked row"]);
        onClickOnGlobalSearchItem({
          entityId: item.entity.id,
          entityType: item.entityType,
        });
        onHideGlobalSearch();
      }
    },
    [
      analytics,
      leadGenPlan,
      intl,
      isOwner,
      history,
      query,
      onHideGlobalSearch,
      handlePerformSearch,
      resetQuery,
      onClickOnGlobalSearchItem,
    ]
  );

  const noResults = (searchActivated ? searchResults : userRecents).length === 0;

  const items = useMemo(() => {
    const result = searchActivated
      ? [
          ...(filter === GlobalSearchFilter.ALL
            ? [{ title: "", type: GlobalSearchItemType.LEAD_FINDER }]
            : []),
          ...searchResults,
        ]
      : userRecents;
    const newIndex = result.findIndex(({ type }) => type !== GlobalSearchItemType.LEAD_FINDER);
    setSelectedIndex(newIndex === -1 ? 0 : newIndex);
    return result;
  }, [filter, searchActivated, searchResults, setSelectedIndex, userRecents]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        setDirection(e.code === "ArrowUp" ? -1 : 1);
      }
    },
    [setDirection]
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        setDirection(0);
      } else if (e.key === "Enter") {
        if (selectedIndex > -1) {
          handleSelect(items[selectedIndex]);
        }
      } else if (e.code === "Escape") {
        if (query.length) {
          setQuery(""); // first escape clears query
        } else {
          onHideGlobalSearch(); // second escape closes modal
        }
      }
    },
    [handleSelect, onHideGlobalSearch, query, selectedIndex, setQuery, setDirection, items]
  );

  const changeItemIndex = useCallback(() => {
    setSelectedIndex((index) => {
      const newIndex = (index + direction + items.length) % items.length;
      const element = document.querySelector(`[data-global-search-index='${newIndex}'`);
      if (element) {
        element.scrollIntoView({ behavior: "auto", block: "center" });
      }
      return newIndex;
    });
  }, [direction, items, setSelectedIndex]);

  useInterval(changeItemIndex, direction !== 0, 100, true, 400);

  useEffect(() => setSelectedIndex(-1), [query, setSelectedIndex]);

  useEffect(() => {
    document.body.addEventListener("keyup", handleKeyUp);
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keyup", handleKeyUp);
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, handleKeyUp]);

  return (
    <Modal
      centered
      className={styles.container}
      closeIcon={null}
      footer={null}
      keyboard={false}
      onCancel={onHideGlobalSearch}
      type="none"
      visible
      width="clamp(300px, 60vw, 750px)"
    >
      <TextField
        allowClear
        className={styles.search}
        onChange={setQuery}
        placeholder={intl.formatMessage({
          id: "component.globalSearch.search.placeholder",
          defaultMessage: "Type at least 3 characters to begin search",
          description: "Search text on global search modal",
        })}
        ref={searchRef}
        suffix={<FontAwesomeIcon icon={faSearch} />}
        value={query}
      />
      {searchActivated && <TypeSelector onSelect={handleSetFilter} value={filter} />}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.contentWrapper}>
          {!searchActivated && (
            <div className={styles.recentText}>
              <div>
                {intl.formatMessage({
                  id: "component.globalSearch.search.recent",
                  defaultMessage: "Recent",
                  description: "recent text on global search modal",
                })}
              </div>
            </div>
          )}

          {items.length ? (
            <div className={cn({ [styles.listContainer]: !noResults })}>
              {items.map((userRecent, i) => (
                <Item
                  index={i}
                  isSelected={selectedIndex === i}
                  item={userRecent}
                  key={i}
                  onClick={handleSelect}
                  onSetSelected={setSelectedIndex}
                />
              ))}
            </div>
          ) : null}
          {noResults ? <NoResults /> : <BottomInfo />}
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOwner: isCurrentUserOwner(state),
  leadGenPlan: getLeadGenPlan(state),
  loading: isUserRecentsLoading(state),
  searchResults: getGlobalSearchResults(state),
  userRecents: getUserRecents(state),
});

const mapDispatchToProps = {
  onClickOnGlobalSearchItem: clickOnGlobalSearchItem,
  onFetchUserRecents: fetchUserRecents.request,
  onHideGlobalSearch: hideGlobalSearch,
  onPerformSearch: performSearch.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchModal);
