import TimelineEventType from "scene/dashboard/enum/TimelineEventType";
import TimelineAction from "enum/TimelineAction";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import { isDefined } from "@mapmycustomers/shared/util/assert";

const simpleEventTypeMapToTimelineAction: Partial<Record<TimelineEventType, TimelineAction>> = {
  [TimelineEventType.ACTIVITIES_ASSIGNED]: TimelineAction.ACTIVITY_ASSIGNED,
  [TimelineEventType.DEAL_STAGE_CHANGE]: TimelineAction.DEAL_STAGE_UPDATED,
  [TimelineEventType.DEALS_LOST]: TimelineAction.DEAL_LOST,
  [TimelineEventType.DEALS_WON]: TimelineAction.DEAL_WON,
  [TimelineEventType.FILES_ADDED]: TimelineAction.FILE_ADDED,
  [TimelineEventType.MENTIONS]: TimelineAction.MENTION,
  [TimelineEventType.NEW_ROUTES]: TimelineAction.ROUTE_CREATED,
  [TimelineEventType.NOTES_LOGGED]: TimelineAction.NOTE_ADDED,
};

const getOrConditionByEventTypes = (eventTypes: TimelineEventType[]): PlatformFilterCondition[] => {
  const allowedActions: TimelineAction[] = [];
  const $or: PlatformFilterCondition[] = [];

  (Object.keys(simpleEventTypeMapToTimelineAction) as TimelineEventType[]).forEach((type) => {
    if (eventTypes.includes(type)) {
      allowedActions.push(simpleEventTypeMapToTimelineAction[type]!);
    }
  });

  const includeCompletedActivities = eventTypes.includes(TimelineEventType.ACTIVITIES_COMPLETED);
  const includeVerifiedCheckIns = eventTypes.includes(TimelineEventType.VERIFIED_CHECK_INS);
  const includeUnverifiedCheckIns = eventTypes.includes(TimelineEventType.UNVERIFIED_CHECK_INS);
  if (includeCompletedActivities || includeVerifiedCheckIns || includeUnverifiedCheckIns) {
    if (includeCompletedActivities && includeVerifiedCheckIns && includeUnverifiedCheckIns) {
      allowedActions.push(TimelineAction.ACTIVITY_COMPLETED);
    } else {
      const reliabilityFilter = includeCompletedActivities
        ? includeVerifiedCheckIns || includeUnverifiedCheckIns
          ? { $ne: !includeVerifiedCheckIns } // if comp+ver => $ne:false, comp + unver => $ne:true
          : null // if comp + !ver + !unver => eq:null
        : includeVerifiedCheckIns && includeUnverifiedCheckIns
        ? { $ne: null } // if !comp + ver + unver => $ne:null
        : includeVerifiedCheckIns; // if !comp+ver => true, !comp+unver => false,

      $or.push({
        $and: [
          { action: TimelineAction.ACTIVITY_COMPLETED },
          { "entity.reliability": reliabilityFilter },
        ],
      });
    }
  }

  const includeNewCompanies = eventTypes.includes(TimelineEventType.NEW_COMPANIES);
  const includeNewPeople = eventTypes.includes(TimelineEventType.NEW_PEOPLE);
  const includeNewDeals = eventTypes.includes(TimelineEventType.NEW_DEALS);
  const includeNewActivities = eventTypes.includes(TimelineEventType.ACTIVITIES_SCHEDULED);
  if (includeNewCompanies || includeNewPeople || includeNewDeals || includeNewActivities) {
    if (includeNewCompanies && includeNewPeople && includeNewDeals && includeNewActivities) {
      allowedActions.push(TimelineAction.RECORD_ADDED);
    } else {
      const entityTypes = [
        includeNewCompanies ? EntityType.COMPANY : undefined,
        includeNewPeople ? EntityType.PERSON : undefined,
        includeNewDeals ? EntityType.DEAL : undefined,
        includeNewActivities ? EntityType.ACTIVITY : undefined,
      ].filter(isDefined);
      $or.push({
        $and: [{ action: TimelineAction.RECORD_ADDED }, { "entity.type": { $in: entityTypes } }],
      });
    }
  }

  if (allowedActions.length) {
    $or.push({ action: { $in: allowedActions } });
  }

  return $or;
};

export default getOrConditionByEventTypes;
