import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import styles from "./LockedArea.module.scss";
import cn from "classnames";
import { Labeled } from "@mapmycustomers/ui";
import Tooltip from "antd/es/tooltip";

interface Props {
  children: React.ReactNode;
  faded?: boolean;
  grayIconBackground?: boolean;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  tooltip?: React.ReactNode;
}

const LockedArea: React.FC<Props> = ({
  children,
  faded,
  grayIconBackground = true,
  icon,
  title,
  tooltip,
}) => {
  return (
    <Labeled label={title}>
      <Row align="middle" className={styles.container} justify="space-between">
        <div className={styles.leftContainer}>
          {icon && (
            <div
              className={cn(styles.iconContainer, {
                [styles.customIconContainer]: grayIconBackground,
              })}
            >
              {icon}
            </div>
          )}
          <Col className={cn(styles.content, { [styles.faded]: faded })}>{children}</Col>
        </div>
        <div className={styles.iconContainer}>
          <Tooltip title={tooltip}>
            <FontAwesomeIcon className={styles.lockIcon} icon={faLock} />
          </Tooltip>
        </div>
      </Row>
    </Labeled>
  );
};

export default LockedArea;
