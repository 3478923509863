import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Person from "@mapmycustomers/shared/types/entity/Person";
import { Activity, EntityTypesSupportedByActivities } from "@mapmycustomers/shared/types/entity";
import { ActivityPayload } from "store/api/ApiService";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import { RecurringActionType } from "../enum/RecurringActionType";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FileListItem from "types/FileListItem";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";
import { NextAssociationsState } from "store/associations/getNextAssociationsState";

export const showGlobalCreateActivityModal = createAction(
  "@component/globalCreateActivityModal/show"
)<{ activity: Partial<Omit<Activity, "id">>; cloning?: boolean } | undefined>();
export const hideGlobalCreateActivityModal = createAction(
  "@component/globalCreateActivityModal/hide"
)<void>();

export const initializeActivityModal = createAsyncAction(
  "@component/activityModal/initialize/request",
  "@component/activityModal/initialize/success",
  "@component/activityModal/initialize/failure"
)<
  {
    fixedCompany?: Company;
    fixedDeal?: Deal;
    fixedPerson?: Person;
  },
  { associationsState: NextAssociationsState; fileGroupId: string },
  void
>();

export const initializeEditActivityModal = createAsyncAction(
  "@component/editActivityModal/initialize/request",
  "@component/editActivityModal/initialize/success",
  "@component/editActivityModal/initialize/failure"
)<
  { activityId: Activity["id"]; overrideValues?: Partial<Activity> },
  { activity: Activity; activityCompleted: boolean; files: RawFile[]; fileGroupId: string },
  boolean // whether failure was due to NoAccess (true) or something else (false)
>();

export const changeAssociatedEntities = createAsyncAction(
  "@component/createEditActivityModal/changeAssociatedEntities/request",
  "@component/createEditActivityModal/changeAssociatedEntities/success",
  "@component/createEditActivityModal/changeAssociatedEntities/failure"
)<Omit<ChangeAssociationsPayload, "entityType">, NextAssociationsState, void>();

export const createActivity = createAsyncAction(
  "@component/createEditActivityModal/createActivity/request",
  "@component/createEditActivityModal/createActivity/success",
  "@component/createEditActivityModal/createActivity/failure"
)<
  {
    activity: ActivityPayload;
    callback?: (updated?: boolean, newActivity?: Activity) => void;
    customFieldsValues: CustomFieldValue[];
    layoutId: FormLayout["id"];
  },
  void,
  void
>();

export const relateEntities = createAsyncAction(
  "@component/createEditActivityModal/relateEntities/request",
  "@component/createEditActivityModal/relateEntities/success",
  "@component/createEditActivityModal/relateEntities/failure"
)<
  {
    associatedCompany?: Company;
    associatedDeal?: Deal;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isDealCorrectlyRelatedToCompany: boolean;
    isDealCorrectlyRelatedToPerson: boolean;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  },
  { deal?: Deal; person?: Person },
  void
>();

export const updateActivity = createAsyncAction(
  "@component/createEditActivityModal/updateActivity/request",
  "@component/createEditActivityModal/updateActivity/success",
  "@component/createEditActivityModal/updateActivity/failure"
)<
  {
    activity: ActivityPayload & Identified;
    callback?: (updated?: boolean, updatedActivity?: Activity) => void;
    customFieldsValues: CustomFieldValue[];
    layoutId: FormLayout["id"];
    updateType?: RecurringActionType;
  },
  Activity,
  void
>();

export const deleteActivity = createAsyncAction(
  "@component/createEditActivityModal/deleteActivity/request",
  "@component/createEditActivityModal/deleteActivity/success",
  "@component/createEditActivityModal/deleteActivity/failure"
)<
  {
    activityId: Activity["id"];
    callback?: () => void;
    deleteType?: RecurringActionType;
  },
  void,
  void
>();

export const clearActivity = createAction(
  "@component/createEditActivityModal/clearActivity"
)<void>();

export const clearAssociations = createAction(
  "@component/createEditActivityModal/clearAssociations"
)<Record<EntityTypesSupportedByActivities, boolean> | void>();

export const uploadCreateEditActivityFiles = createAsyncAction(
  "@component/createEditActivityModal/uploadCreateEditActivityFiles/request",
  "@component/createEditActivityModal/uploadCreateEditActivityFiles/success",
  "@component/createEditActivityModal/uploadCreateEditActivityFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const removeCreateEditActivityFile = createAsyncAction(
  "@component/createEditActivityModal/removeCreateEditActivityFile/request",
  "@component/createEditActivityModal/removeCreateEditActivityFile/success",
  "@component/createEditActivityModal/removeCreateEditActivityFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedCreateEditActivityFiles = createAction(
  "@component/createEditActivityModal/clearAllUploadedCreateEditActivityFiles"
)<void>();

export const fetchFilePreview = createAsyncAction(
  "@component/createEditActivityModal/fetchFilePreview/request",
  "@component/createEditActivityModal/fetchFilePreview/success",
  "@component/createEditActivityModal/fetchFilePreview/failure"
)<RawFile["id"], Blob, void>();

export const downloadActivityFile = createAction(
  "@component/createEditActivityModal/downloadActivityFile"
)<RawFile>();

export type Actions = ActionType<
  | typeof changeAssociatedEntities
  | typeof clearActivity
  | typeof clearAllUploadedCreateEditActivityFiles
  | typeof clearAssociations
  | typeof createActivity
  | typeof deleteActivity
  | typeof downloadActivityFile
  | typeof fetchFilePreview
  | typeof hideGlobalCreateActivityModal
  | typeof initializeActivityModal
  | typeof initializeEditActivityModal
  | typeof relateEntities
  | typeof removeCreateEditActivityFile
  | typeof showGlobalCreateActivityModal
  | typeof updateActivity
  | typeof uploadCreateEditActivityFiles
>;
