import { Store } from "redux";
import type { RootState } from "./rootReducer";
import configureReduxStore from "./configureReduxStore";
import type { History } from "history";
import geoService from "@mapmycustomers/shared/util/geo/GeoService";
import { show as showUserLocationRequest } from "../component/Layout/components/UserLocationRequest/actions";
import localSettings from "config/LocalSettings";

/**
 * Holds Redux store to make it possible to use it in
 * regular functions outside of React components.
 */
class ReduxStore {
  // guaranteed to call createStore in /src/index.ts
  private _store!: Store<RootState>;
  private _history!: History;

  createStore(history: History) {
    this._history = history;
    this._store = configureReduxStore(history);

    geoService.registerErrorHandler("onGeolocationNotAvailable", (e) => {
      if (
        localSettings.isLocationReminderAllowed() &&
        e?.code === GeolocationPositionError.PERMISSION_DENIED
      ) {
        this._store.dispatch(showUserLocationRequest());
      }
    });
  }

  get history(): History {
    return this._history;
  }

  get store(): Store<RootState> {
    return this._store;
  }
}

const reduxStore = new ReduxStore();
export default reduxStore;
