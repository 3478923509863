import { put, select, takeEvery } from "redux-saga/effects";
import { exportEntities } from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getMe, getOrganizationId } from "../iam";
import { convertToPlatformFilterModel } from "../../util/viewModel/convertToPlatformFilterModel";
import { convertToPlatformSortModel } from "../../util/viewModel/convertSort";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { callApi } from "../api/callApi";
import { isCustomField } from "../../util/fieldModel/impl/assert";
import CustomField from "../../util/fieldModel/impl/CustomField";
import Report from "../../types/Report";
import {
  AnyEntity,
  EntityType,
  EntityTypesSupportingDownloading,
} from "@mapmycustomers/shared/types/entity";
import Iam from "../../types/Iam";
import { handleError } from "../errors/actions";
import downloadEntitiesAsCsv from "../../util/file/downloadEntitiesAsCsv";
import getFieldModelByEntityType from "../../util/fieldModel/getByEntityType";
import ApiService, { ApiMethodName } from "../api/ApiService";
import i18nService from "../../config/I18nService";
import { getEntityTypeDisplayName } from "util/ui";
import notification from "antd/es/notification";
import { MAX_ITEMS_TO_DOWNLOAD_FILE } from "./const";
import { formatDate } from "../../util/formatters";
import deepmerge from "deepmerge";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { DealFieldName } from "../../util/fieldModel/DealFieldModel";
import { ActivityFieldName } from "../../util/fieldModel/ActivityFieldModel";

const entityTypeToFetcher: Record<EntityTypesSupportingDownloading, ApiMethodName> = {
  [EntityType.ACTIVITY]: "fetchActivities",
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

export function* onExportEntities({
  payload: { entityType, exportCreationSuccessMessage, name, platformRequest, total, viewState },
}: ReturnType<typeof exportEntities.request>) {
  const $filters = {
    ...(viewState.filter && viewState.columns
      ? convertToPlatformFilterModel(
          viewState.filter,
          viewState.columns,
          getFieldModelByEntityType(entityType),
          true,
          viewState.viewAs
        )
      : {}),
    ...platformRequest?.$filters,
  };
  const orgId: Organization["id"] = yield select(getOrganizationId);

  if (total === undefined) {
    const requestPayload = {
      $limit: 0,
      $filters,
      ...(viewState?.sort ? { $order: convertToPlatformSortModel(viewState.sort) } : {}),
    };

    const response: ListResponse<AnyEntity> = yield callApi(
      entityTypeToFetcher[entityType] as keyof ApiService,
      orgId,
      requestPayload
    );
    total = response.total;
  }
  const intl = i18nService.getIntl();
  const exportName =
    name ??
    (intl
      ? getEntityTypeDisplayName(intl, entityType, {
          lowercase: false,
          plural: true,
        })
      : entityType);
  const visibleFields = viewState.columns?.filter(({ visible }) => visible) ?? [];
  const columns = new Set();
  visibleFields.forEach(({ field }) => {
    if (field.hasFeature(FieldFeature.DEAL_ADDRESS_FIELD)) {
      columns.add(DealFieldName.ACCOUNT);
      columns.add(DealFieldName.CONTACT);
    } else if (field.hasFeature(FieldFeature.ACTIVITY_TYPE_FIELD)) {
      columns.add(ActivityFieldName.COMPANY);
      columns.add(ActivityFieldName.PERSON);
    }
  });

  try {
    if (total <= MAX_ITEMS_TO_DOWNLOAD_FILE) {
      const requestPayload = deepmerge(
        {
          $limit: -1,
          $filters,
          ...(viewState.sort ? { $order: convertToPlatformSortModel(viewState.sort) } : {}),
        },
        platformRequest ?? {}
      );

      const response: ListResponse<AnyEntity> = yield callApi(
        entityTypeToFetcher[entityType],
        orgId,
        requestPayload
      );

      downloadEntitiesAsCsv(
        exportName,
        entityType,
        response.data,
        viewState.columns
          ? viewState.columns.filter(({ visible }) => visible).map(({ field }) => field)
          : undefined
      );
    } else {
      const customFieldIds = (viewState.columns ?? [])
        .filter(({ field, visible }) => visible && isCustomField(field))
        .map(({ field }) => (field as CustomField).customFieldData.id);

      const columnNames = (viewState.columns ?? [])
        .filter(({ field, visible }) => visible && !isCustomField(field))
        .map(({ field }) => field.name);

      const payload: Partial<Report> = {
        name: `${exportName} ${formatDate(new Date(), "PPpp")}`,
        description: "",
        selectedColumns: columnNames,
        customFields: customFieldIds,
        selectedFilters: $filters,
        tableName: entityType,
      };
      const iam: Iam = yield select(getMe);

      const report: Report = yield callApi("createReport", orgId, payload);
      yield callApi("generateReport", orgId, report.id, iam.username);
      if (exportCreationSuccessMessage) {
        notification.success({
          message: exportCreationSuccessMessage,
        });
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* exportEntitiesSaga() {
  yield takeEvery(exportEntities.request, onExportEntities);
}
