import React from "react";
import { useIntl } from "react-intl";
import LockedArea from "./LockedArea";
import { Avatar } from "@mapmycustomers/ui";
import { UserRef } from "@mapmycustomers/shared/types/User";
import { getUserDisplayNameWithInfo } from "../../../../../util/formatters";

interface Props {
  assignee: UserRef;
}

const LockedAssignee: React.FC<Props> = ({ assignee }) => {
  const intl = useIntl();

  return (
    <LockedArea
      grayIconBackground={false}
      icon={<Avatar size="small" user={assignee} />}
      title={intl.formatMessage({
        id: "createActivityModal.lockedAssignee.title",
        defaultMessage: "Assignee",
        description: "title for locked assignee",
      })}
      tooltip={intl.formatMessage({
        id: "createActivityModal.lockedAssignee.tooltip",
        defaultMessage: "Assignee cannot be changed",
        description: "Tooltip for locked assignee",
      })}
    >
      {getUserDisplayNameWithInfo(intl, assignee)}
    </LockedArea>
  );
};

export default LockedAssignee;
