import React, { useCallback, useEffect, useState } from "react";
import AnyColor, { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import { HexColorPicker } from "react-colorful";
import cn from "classnames";
import Color, { knownColors } from "@mapmycustomers/shared/enum/Color";
import anyColorToHex from "@mapmycustomers/shared/util/color/anyColorToHex";
import colorNameToHex from "@mapmycustomers/shared/util/color/colorNameToHex";
import Tooltip from "antd/es/tooltip";
import TextField from "../input/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/pro-solid-svg-icons/faHashtag";
import styles from "./ColorPanel.module.scss";
import { useConfigProvider } from "../ConfigProvider";
import SliderWithNumberField from "../SliderWithNumberField";

export interface ColorPanelProps {
  className?: string;
  getColorDisplayName?: (color: Color) => string;
  onChange?: (color: AnyColor) => void;
  onOpacityChange?: (opacity: number) => void;
  opacity?: number;
  showColorSpace?: boolean;
  showInput?: boolean;
  showNamedColors?: boolean;
  showOpacitySelector?: boolean;
  value?: AnyColor;
}

const ColorPanel: React.FC<ColorPanelProps> = ({
  className,
  getColorDisplayName,
  onChange,
  onOpacityChange,
  opacity,
  showColorSpace,
  showInput,
  showNamedColors,
  showOpacitySelector,
  value,
}) => {
  const configProvider = useConfigProvider();

  const color = value ? anyColorToHex(value) : undefined;

  const handleChange = useCallback(
    (value: string) => {
      const knownColor = knownColors.find(
        (color) => colorNameToHex(color) === value
      );
      onChange?.(knownColor ?? (value as HexColor));
    },
    [onChange]
  );

  const [inputValue, setInputValue] = useState<string>(color ?? "");
  useEffect(() => {
    setInputValue((color ?? "").trim().replace(/^#+/, "")); // remove any leading #
  }, [color]);

  const handleInputChange = useCallback(
    (value: string) => {
      // replace # in the beginning. This might happen when user pastes value
      value = value.trim().replace(/^#+/, "");
      setInputValue(value);

      const knownColor = knownColors.find(
        (color) => colorNameToHex(color) === `#${value}` || color === value
      );
      if (knownColor) {
        onChange?.(knownColor);
      }
      value = `#${value}`;
      if (/^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(value)) {
        onChange?.(value as HexColor);
      }
    },
    [onChange]
  );

  const handleOpacityChange = useCallback(
    (value: number) => onOpacityChange?.(value / 100),
    [onOpacityChange]
  );

  const opacityValueFormatter = useCallback(
    (value?: string | number) =>
      configProvider.formatNumber(+(value ?? 0) / 100, {
        style: "percent",
        maximumFractionDigits: 0,
      }),
    [configProvider]
  );

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div
      className={cn(styles.container, className)}
      onMouseDown={handleMouseDown}
    >
      {showColorSpace && (
        <HexColorPicker color={color} onChange={handleChange} />
      )}
      {showInput && (
        <div className={styles.inputContainer}>
          <TextField
            addonBefore={<FontAwesomeIcon icon={faHashtag} />}
            onChange={handleInputChange}
            value={inputValue}
          />
        </div>
      )}
      {showNamedColors && (
        <div className={styles.namedColors}>
          {knownColors.map((color) => (
            <Tooltip key={color} title={getColorDisplayName?.(color) ?? color}>
              <div
                className={styles.colorItem}
                onClick={() => onChange?.(colorNameToHex(color) ?? color)}
                style={{ backgroundColor: colorNameToHex(color) }}
              />
            </Tooltip>
          ))}
        </div>
      )}
      {showOpacitySelector && (
        <div className={styles.opacity}>
          <SliderWithNumberField
            min={0}
            max={100}
            step={5}
            onChange={handleOpacityChange}
            value={opacity === undefined ? opacity : opacity * 100}
            valueFormatter={opacityValueFormatter}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPanel;
