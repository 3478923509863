import React, { useCallback, useMemo } from "react";
import { DatePicker, Labeled, TimePicker } from "@mapmycustomers/ui";
import { SHOULD_USE_12_HOUR_FORMAT } from "util/consts";
import styles from "./SeparatedDateTimePicker.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import layout from "styles/layout";
import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isBefore,
  set,
  startOfDay,
} from "date-fns/esm";

interface Props {
  dateLabel?: string;
  minDate?: Date;
  onChange: (value: Date) => void;
  timeLabel?: string;
  value: Date;
}

const SeparatedDateTimePicker: React.FC<Props> = ({
  dateLabel,
  minDate,
  onChange,
  timeLabel,
  value,
}) => {
  const minDateForDatePicker = useMemo(() => {
    return minDate ? startOfDay(minDate) : undefined;
  }, [minDate]);

  const isDateDisabled = useCallback(
    (date: Date) => {
      return date && minDateForDatePicker ? isBefore(date, minDateForDatePicker) : false;
    },
    [minDateForDatePicker]
  );

  const isTimeDisabled = useCallback(
    (date: Date) => {
      return date && minDate ? isBefore(date, minDate) : false;
    },
    [minDate]
  );

  const handleDateChange = useCallback(
    (date: Date | undefined) => {
      if (date) {
        const newDate = set(value, {
          date: getDate(date),
          month: getMonth(date),
          year: getYear(date),
        });
        onChange(minDate && isBefore(newDate, minDate) ? minDate : newDate);
      }
    },
    [minDate, onChange, value]
  );

  const handleTimeChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onChange(set(value, { hours: getHours(date), minutes: getMinutes(date) }));
      }
    },
    [onChange, value]
  );

  return (
    <Row gutter={layout.spacerS}>
      <Col className={styles.noPadding} span={12}>
        <Labeled label={dateLabel}>
          <DatePicker
            allowClear={false}
            className={styles.fullWidth}
            disabledDate={isDateDisabled}
            format="PP"
            onChange={handleDateChange}
            value={value}
          />
        </Labeled>
      </Col>
      <Col span={12}>
        <Labeled label={timeLabel}>
          <TimePicker
            allowClear={false}
            className={styles.fullWidth}
            disabledDate={isTimeDisabled}
            format={`${SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm"}`}
            minuteStep={5}
            onChange={handleTimeChange}
            use12Hours={SHOULD_USE_12_HOUR_FORMAT}
            value={value}
          />
        </Labeled>
      </Col>
    </Row>
  );
};

export default SeparatedDateTimePicker;
