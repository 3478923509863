import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import { EventInput } from "@fullcalendar/common";
import { ResourceInput } from "@fullcalendar/resource-common";
import SchedulerViewState from "component/view/SchedulerView/types/SchedulerViewState";
import { Activity } from "@mapmycustomers/shared/types/entity";
import activityToEvent from "../util/activityToEvent";
import { getUsers } from "store/members";
import User from "@mapmycustomers/shared/types/User";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { isCurrentUserOwner } from "store/iam";
import { isOwner } from "store/iam/util";
import Team from "@mapmycustomers/shared/types/Team";

const schedulerState = (state: RootState) => state.scene.activityScheduler;

export const getSchedulerViewState = createSelector(
  schedulerState,
  ({ viewState }): SchedulerViewState => viewState
);
export const getSchedulerActivities = createSelector(
  schedulerState,
  ({ activities }) => activities
);

export const isSchedulerViewLoading = createSelector(schedulerState, ({ loading }) => loading);
export const getSchedulerViewTotalFilteredRecordsCount = createSelector(
  schedulerState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getSchedulerViewTotalRecordsCount = createSelector(
  schedulerState,
  ({ totalRecords }) => totalRecords
);

export const getSchedulerEvents = createSelector(
  getSchedulerActivities,
  (activities: Activity[]) => {
    let events: EventInput[] = activities.map((activity) => activityToEvent(activity));
    return events;
  }
);

export const getSchedulerResources = createSelector(
  isCurrentUserOwner,
  getSchedulerViewState,
  getUsers,
  (isCurrentUserOwner: boolean, viewState: SchedulerViewState, users: User[]) => {
    const assigneeFilter = viewState.filter.assignee as SimpleCondition | undefined;
    const teamFilter = viewState.filter.teams as SimpleCondition | undefined;

    const teamUsers = teamFilter?.value
      ? users.filter(({ teams }) =>
          (teamFilter.value as Team["id"][] | undefined)?.some((teamId) =>
            teams.map(({ id }) => id).includes(teamId)
          )
        )
      : users;

    const filteredUsers = (
      assigneeFilter?.value
        ? teamUsers.filter((user) => assigneeFilter.value.includes(user.id))
        : teamUsers
    ).filter((user) => (!isCurrentUserOwner ? !isOwner(user) : true));

    const resources: ResourceInput[] = filteredUsers.map((user) => ({
      id: String(user.id),
      title: user.fullName ?? user.username,
      extendedProps: { user },
    }));
    return resources;
  }
);
