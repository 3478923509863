import React from "react";
import { findCountry } from "./lookup";

const useRegionsListOptions = (
  countryCode?: string
): Array<{ label: string; value: string }> =>
  React.useMemo(() => {
    if (!countryCode) {
      return [];
    }

    const country = findCountry(countryCode);
    if (!country || country.regions?.length < 1) {
      return [];
    }

    return (
      country.regions.map((region) => ({
        label: region.name,
        value: region.regionCode,
      })) ?? []
    );
  }, [countryCode]);

export default useRegionsListOptions;
