import React, { SyntheticEvent, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import styles from "./Actions.module.scss";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import { hideNotifications } from "store/notification/actions";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import Button from "antd/es/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import Dropdown from "antd/es/dropdown";
import MmcNotification from "types/MmcNotification";
import optOutSetting from "./util/optOutSetting";
import { RootState } from "store/rootReducer";
import { getCurrentUserId, getOrganizationId, getUserSetting } from "store/iam";
import { updateSetting } from "store/iam/actions";
import User from "@mapmycustomers/shared/types/User";
import Organization from "@mapmycustomers/shared/types/Organization";
import useNotificationMenu from "./util/useNotificationMenu";
import Setting from "@mapmycustomers/shared/types/Setting";

interface Props {
  currentUserId: User["id"];
  hideNotifications: typeof hideNotifications;
  hovered: boolean;
  notification: MmcNotification;
  onGoToSettings: () => void;
  organizationId: Organization["id"];
  setting: Setting;
  updateSetting: typeof updateSetting.request;
}

const NotificationMenu: React.FC<Props> = ({
  currentUserId,
  hideNotifications,
  hovered,
  notification,
  onGoToSettings,
  organizationId,
  setting,
  updateSetting,
}) => {
  const intl = useIntl();
  const [menuVisible, setMenuVisibility] = useState<boolean>(false);

  const handleOptOut = useCallback(
    ({ domEvent }) => {
      stopEvents(domEvent);
      hideNotifications();
      setMenuVisibility(false);
      optOutSetting(
        intl,
        updateSetting,
        organizationId,
        currentUserId,
        setting,
        notification.action,
        onGoToSettings
      );
    },
    [
      hideNotifications,
      intl,
      currentUserId,
      notification.action,
      onGoToSettings,
      organizationId,
      setMenuVisibility,
      setting,
      updateSetting,
    ]
  );

  const handleGoToSettings = useCallback(
    ({ domEvent }) => {
      stopEvents(domEvent);
      setMenuVisibility(false);
      onGoToSettings();
    },
    [onGoToSettings, setMenuVisibility]
  );

  const notificationOptions = useNotificationMenu(
    intl,
    setting,
    notification.action,
    handleOptOut,
    handleGoToSettings
  );

  const handleClickMenuButton = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      stopEvents(event);
      setMenuVisibility((visibility) => !visibility);
    },
    [setMenuVisibility]
  );

  if (notification.readStatus) {
    return null;
  }

  return (
    <Dropdown
      onVisibleChange={setMenuVisibility}
      overlay={notificationOptions}
      placement="bottomRight"
      trigger={["click"]}
      visible={menuVisible}
    >
      <Tooltip
        title={intl.formatMessage({
          id: "navbar.notifications.list.notification.more",
          defaultMessage: "More",
          description: "Navbar Notifications - list - notification - more",
        })}
      >
        <Button
          className={cn({ [styles.moreButtonActive]: menuVisible })}
          hidden={!hovered}
          icon={<FontAwesomeIcon className={styles.moreIcon} icon={faEllipsisV} />}
          onClick={handleClickMenuButton}
          type="text"
        />
      </Tooltip>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUserId: getCurrentUserId(state)!,
  organizationId: getOrganizationId(state)!,
  setting: getUserSetting(state)("emailNotificationSettings")!,
});

const mapDispatchToProps = {
  hideNotifications,
  updateSetting: updateSetting.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);
