import React, { ComponentType } from "react";
import cn from "classnames";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import styles from "./ButtonLink.module.scss";

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tag?: ComponentType<any>;
}

// This component is needed to display button as a pure link with no margins and other paddings
// which make it look like a button. ButtonLink looks exactly like a link.
// It is basically needed to avoid jsx-a11y/anchor-is-valid linting error:
//     The href attribute is required for an anchor to be keyboard accessible. Provide a valid,
//     navigable address as the href value. If you cannot provide an href, but still need the
//     element to resemble a link, use a button and change it with appropriate styles.
//     Learn more: https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
const ButtonLink: React.FC<Props> = ({
  children,
  className,
  disabled,
  onClick,
  tag: Tag,
  ...props
}) => {
  if (Tag) {
    return (
      <Tag
        className={cn(styles.container, className)}
        data-disabled={disabled ? "disabled" : undefined}
        onClick={disabled ? stopEvents : onClick}
        {...props}
      >
        {children}
      </Tag>
    );
  }

  return (
    <a
      className={cn(styles.container, className)}
      data-disabled={disabled ? "disabled" : undefined}
      onClick={disabled ? stopEvents : onClick}
      {...props}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
