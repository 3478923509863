@use "../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 100%;
        min-height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 0.25rem;
        border: none;
        position: relative;
        &:focus-within {
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.06);
        }
      }
      .loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.6);
        opacity: 0.4;
      }
      .editorContainer {
        flex: 1;
        min-height: 0;
        //height: 100%;
        display: flex;
        flex-direction: column;
      }
      .editorWrapper {
        flex: 1;
        min-height: 0;
        //height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }

      .toolbar {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        left: 1rem;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.06);
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .sizeSmall {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }

      .sizeMedium {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .sizeLarge {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
      .editor {
        flex: 1;
        width: 100%;
        font-size: 1rem;
        border-radius: 0.25rem;
        min-height: 300px;
        line-height: 1rem;
        padding: 0.75rem 0 0.25rem;
        resize: none;

        p {
          margin-bottom: 0;
        }
        &[disabled],
        &:disabled {
          background: #f5f5f5; // mimic default "disabled" background for inputs
          color: colors.$mmc-disabled-gray;
          outline: none;

          & [data-slate-placeholder] {
            opacity: 1 !important;
          }
        }
      }

      .extraPadding {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      .toolbarWrapper {
        margin-top: 0.5rem;
        overflow: hidden;
      }
    }
  }
}
