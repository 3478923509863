import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { LabeledFieldProps, MonetaryField } from "@mapmycustomers/ui";
import Currency from "@mapmycustomers/shared/types/Currency";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getCurrencies } from "store/referenceData";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { getOrganizationSettingValue } from "store/iam";
import OrganizationSetting from "enum/OrganizationSetting";

interface Props extends Omit<LabeledFieldProps, "children"> {
  currencies: Currency[];
  defaultCurrencyCode?: string;
  disabled?: boolean;
  onChange?: (value: number) => void;
  placeholder?: string;
  value?: number;
}

const CurrencyInput = memo<Props>(
  ({
    className,
    currencies,
    defaultCurrencyCode,
    disabled,
    onChange,
    placeholder,
    value,
    ...labeledProps
  }) => {
    const intl = useIntl();

    const defaultCurrency = useMemo(
      () => currencies.find((currency) => currency.code === defaultCurrencyCode),
      [currencies, defaultCurrencyCode]
    );

    const [amount, setAmount] = useState<number | undefined>(value);
    useEffect(() => {
      setAmount(value);
    }, [value, defaultCurrency]);

    const handleChange = useCallback(
      (amount?: number) => {
        if (isDefined(amount) && onChange) {
          onChange(amount);
        }
      },
      [onChange]
    );

    const handleAmountChange = useCallback(
      (value: MonetaryValue) => {
        setAmount(value.value);
        handleChange(value.value);
      },
      [handleChange]
    );

    return (
      <MonetaryField
        {...labeledProps}
        disabled={disabled}
        onChange={handleAmountChange}
        placeholder={intl.formatMessage({
          id: "component.currencyInput.placeholder",
          defaultMessage: "Enter amount",
          description: "Placeholder for currency field",
        })}
        value={
          !defaultCurrency || amount === undefined
            ? undefined
            : { currencyId: defaultCurrency.id, value: amount }
        }
      />
    );
  }
);

const mapStateToProps = (state: RootState) => ({
  currencies: getCurrencies(state),
  defaultCurrencyCode: getOrganizationSettingValue(state)(OrganizationSetting.CURRENCY),
});

export default connect(mapStateToProps)(CurrencyInput);
