import React from "react";
import { UserRef } from "@mapmycustomers/shared/types/User";
import AntDAvatar, { AvatarProps } from "antd/es/avatar";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import getInitials from "@mapmycustomers/shared/util/ui/getInitials";
import { useConfigProvider } from "../ConfigProvider";
import colorStyles from "./AvatarColor.module.scss";
import styles from "./AvatarCommon.module.scss";

interface Props extends Omit<AvatarProps, "className"> {
  actionOnHover?: boolean;
  className?: string;
  tooltip?: boolean;
  user: UserRef;
}

const Avatar: React.FC<Props> = ({
  actionOnHover,
  className,
  tooltip = true,
  user,
  ...avatarProps
}) => {
  const configProvider = useConfigProvider();
  const profilePhotoUrl = configProvider.complementFileUrl(
    user.profilePhoto?.publicURI
  );

  const nameInitials = getInitials(user);

  let colorClassName;
  if (!profilePhotoUrl) {
    colorClassName =
      colorStyles[configProvider.getUserColorClassName(user) as string];
  }

  return (
    <Tooltip
      title={
        tooltip
          ? actionOnHover
            ? configProvider.formatMessage("ui.avatar.tooltip.open", {
                name: userDisplayName(user),
              })
            : userDisplayName(user)
          : undefined
      }
    >
      <AntDAvatar
        className={cn(styles.avatarIcon, colorClassName, className, {
          [styles.avatarHover]: actionOnHover,
        })}
        src={profilePhotoUrl}
        {...avatarProps}
      >
        {profilePhotoUrl ? " " : nameInitials}
      </AntDAvatar>
    </Tooltip>
  );
};

// Empty object preventing passing dispatch prop to the component
export default Avatar;
