import { SelectProps } from "antd/es/select";
import cn from "classnames";
import Tag from "antd/es/tag";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import React, { ReactNode, useCallback } from "react";
import { connect } from "react-redux";
import { getActivityTypes } from "store/activity";
import { RootState } from "store/rootReducer";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import useActivityTypeOptions from "util/ui/useActivityTypeOptions";
import styles from "./MultiSelectActivityTypeField.module.scss";
import defaultFilterOption from "./utils/defaultFilterOption";
import { SelectField } from "@mapmycustomers/ui";

interface Props extends Omit<SelectProps<Array<ActivityType["id"]>>, "onChange" | "value"> {
  activityTypes: ActivityType[];
  label?: ReactNode;
  onChange?: (value: ActivityType["id"][]) => void;
  specificActivityTypes?: ActivityType[];
  value?: ActivityType["id"][];
}

const MultiSelectActivityTypeField: React.FC<Props> = ({
  activityTypes,
  label,
  onChange,
  specificActivityTypes,
  value,
  ...props
}) => {
  const activityTypeOptions = useActivityTypeOptions(
    specificActivityTypes ?? activityTypes,
    value,
    useCallback((type) => {
      return !type.active;
    }, [])
  );
  const { size } = props;

  function tagRender(props: CustomTagProps) {
    const { label, onClose } = props;

    return (
      <Tag
        className={cn(styles.tag, { [styles.largeField]: size === "large" })}
        closable
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  }

  return (
    <SelectField<Array<ActivityType["id"]>>
      dropdownMatchSelectWidth={false}
      filterOption={defaultFilterOption}
      label={label}
      maxTagCount="responsive"
      mode="multiple"
      onChange={onChange}
      options={activityTypeOptions}
      showSearch
      tagRender={tagRender}
      value={value}
      {...props}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
});

export default connect(mapStateToProps, {})(MultiSelectActivityTypeField);
