import React, { ReactNode, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import { getMapSettings } from "store/iam";
import { EntityPin } from "@mapmycustomers/shared/types/map";
import MarkerEffect from "enum/MarkerEffect";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import getContrastColor from "util/colors/getContrastColor";
import defaultEntityStyleGetter from "util/map/markerStyles/defaultEntityStyleGetter";
import colors from "styles/_colors.module.scss";
import anyColorToHex from "util/colors/anyColorToHex";
import LocationMiniMap, { LocationMiniMapEntity } from "./index";

interface Props {
  before?: ReactNode;
  className?: string;
  draggable?: boolean;
  entity?: LocationMiniMapEntity;
  mapOptions?: google.maps.MapOptions;
  mapSettings: {
    markerSize: MarkerSize;
    showEntityIcons: boolean;
  };
  onDragPin?: (latLng: google.maps.LatLng) => void;
}

const RecordLocationMiniMap: React.FC<Props> = ({
  before,
  className,
  draggable,
  entity,
  mapOptions,
  mapSettings,
  onDragPin,
}) => {
  const handleMouseUp = useCallback(
    (_, event: google.maps.MapMouseEvent) => {
      if (draggable && event.latLng) {
        onDragPin?.(event.latLng);
      }
    },
    [draggable, onDragPin]
  );

  const entityPinStyleGetter = useMemo(() => {
    const styleGetter = defaultEntityStyleGetter({
      colorGetter: () => {
        const fillColor = entity?.color ? anyColorToHex(entity.color) : colors.slate;
        return {
          color: getContrastColor(fillColor) === "black" ? colors.slate : colors.white,
          fillColor,
        };
      },
      draggable,
      effect: MarkerEffect.SHADOW,
      icon: () => (mapSettings.showEntityIcons ? entity?.entity : undefined),
      markerSize: mapSettings.markerSize,
      shape: () => entity?.shape ?? MarkerShape.SQUARE,
    });
    // FIXME: generalize defaultEntityStyleGetter so that we can use it with other types in addition to EntityPin
    // For now this is a fake call to style getter. We do not use given argument inside that style getter completely
    return () => styleGetter({} as EntityPin);
  }, [draggable, entity, mapSettings]);

  if (!entity?.geoPoint?.coordinates) {
    return null;
  }

  return (
    <LocationMiniMap
      before={before}
      className={className}
      coordinates={entity.geoPoint.coordinates}
      mapOptions={mapOptions}
      onMouseUp={handleMouseUp}
      styleGetter={entityPinStyleGetter}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  mapSettings: getMapSettings(state),
});

export default connect(mapStateToProps)(RecordLocationMiniMap);
