import React, { ReactNode, useMemo } from "react";
import ButtonLink from "../../../ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./useGoogleAutoCompleteOptions.module.scss";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { useConfigProvider } from "../../../ConfigProvider";

export const NO_RESULT_VALUE = "no_result";
export const MANUAL_ADDRESS_VALUE = "manual_address";

const useGoogleAutoCompleteOptions = (
  suggestions: google.maps.places.AutocompletePrediction[],
  showManual?: boolean
) => {
  const configProvider = useConfigProvider();
  return useMemo(() => {
    const result: { label: ReactNode; value: string; disabled?: boolean }[] =
      suggestions.map((item) => ({
        label: (
          <div className={styles.placeOption}>
            <FontAwesomeIcon
              className={styles.markerIcon}
              icon={faMapMarkerAlt}
            />
            <span className={styles.placeDescription}>{item.description}</span>
          </div>
        ),
        value: item.place_id,
      }));

    if (!suggestions.length) {
      result.push({
        value: NO_RESULT_VALUE,
        label: (
          <div className={styles.noResults}>
            {configProvider.formatMessage("ui.autoComplete.address.noResult")}
          </div>
        ),
        disabled: true,
      });
    }

    if (showManual) {
      result.push({
        value: MANUAL_ADDRESS_VALUE,
        label: (
          <ButtonLink className={styles.enterManuallyButton}>
            {configProvider.formatMessage(
              "ui.autoComplete.address.manualButton"
            )}
          </ButtonLink>
        ),
      });
    }

    return result;
  }, [showManual, suggestions]);
};

export default useGoogleAutoCompleteOptions;
