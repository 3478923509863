import TimeZone from "@mapmycustomers/shared/enum/TimeZone";
import { defineMessages } from "react-intl";

const TimeZones = defineMessages<TimeZone>({
  "Africa/Abidjan": { id: "timeZone.abidjan", defaultMessage: "Abidjan" },
  "Africa/Algiers": { id: "timeZone.algiers", defaultMessage: "Algiers" },
  "Africa/Asmera": { id: "timeZone.asmera", defaultMessage: "Asmera" },
  "Africa/Bissau": { id: "timeZone.bissau", defaultMessage: "Bissau" },
  "Africa/Cairo": { id: "timeZone.cairo", defaultMessage: "Cairo" },
  "Africa/Casablanca": { id: "timeZone.casablanca", defaultMessage: "Casablanca" },
  "Africa/Ceuta": { id: "timeZone.ceuta", defaultMessage: "Ceuta" },
  "Africa/El_Aaiun": { id: "timeZone.el_aaiun", defaultMessage: "El Aaiun" },
  "Africa/Johannesburg": { id: "timeZone.johannesburg", defaultMessage: "Johannesburg" },
  "Africa/Juba": { id: "timeZone.juba", defaultMessage: "Juba" },
  "Africa/Khartoum": { id: "timeZone.khartoum", defaultMessage: "Khartoum" },
  "Africa/Lagos": { id: "timeZone.lagos", defaultMessage: "Lagos" },
  "Africa/Maputo": { id: "timeZone.maputo", defaultMessage: "Maputo" },
  "Africa/Monrovia": { id: "timeZone.monrovia", defaultMessage: "Monrovia" },
  "Africa/Nairobi": { id: "timeZone.nairobi", defaultMessage: "Nairobi" },
  "Africa/Ndjamena": { id: "timeZone.ndjamena", defaultMessage: "Ndjamena" },
  "Africa/Sao_Tome": { id: "timeZone.sao_tome", defaultMessage: "Sao Tome" },
  "Africa/Tripoli": { id: "timeZone.tripoli", defaultMessage: "Tripoli" },
  "Africa/Tunis": { id: "timeZone.tunis", defaultMessage: "Tunis" },
  "Africa/Windhoek": { id: "timeZone.windhoek", defaultMessage: "Windhoek" },
  "America/Adak": { id: "timeZone.adak", defaultMessage: "Adak" },
  "America/Anchorage": { id: "timeZone.anchorage", defaultMessage: "Anchorage" },
  "America/Araguaina": { id: "timeZone.araguaina", defaultMessage: "Araguaina" },
  "America/Argentina/Buenos_Aires": {
    id: "timeZone.buenos_aires",
    defaultMessage: "Buenos Aires",
  },
  "America/Argentina/Catamarca": { id: "timeZone.catamarca", defaultMessage: "Catamarca" },
  "America/Argentina/Cordoba": { id: "timeZone.cordoba", defaultMessage: "Cordoba" },
  "America/Argentina/Jujuy": { id: "timeZone.jujuy", defaultMessage: "Jujuy" },
  "America/Argentina/La_Rioja": { id: "timeZone.la_rioja", defaultMessage: "La Rioja" },
  "America/Argentina/Mendoza": { id: "timeZone.mendoza", defaultMessage: "Mendoza" },
  "America/Argentina/Rio_Gallegos": {
    id: "timeZone.rio_gallegos",
    defaultMessage: "Rio Gallegos",
  },
  "America/Argentina/Salta": { id: "timeZone.salta", defaultMessage: "Salta" },
  "America/Argentina/San_Juan": { id: "timeZone.san_juan", defaultMessage: "San Juan" },
  "America/Argentina/San_Luis": { id: "timeZone.san_luis", defaultMessage: "San Luis" },
  "America/Argentina/Tucuman": { id: "timeZone.tucuman", defaultMessage: "Tucuman" },
  "America/Argentina/Ushuaia": { id: "timeZone.argentina", defaultMessage: "Ushuaia" },
  "America/Asuncion": { id: "timeZone.asuncion", defaultMessage: "Asuncion" },
  "America/Bahia": { id: "timeZone.bahia", defaultMessage: "Bahia" },
  "America/Bahia_Banderas": { id: "timeZone.bahia_banderas", defaultMessage: "Bahia Banderas" },
  "America/Barbados": { id: "timeZone.barbados", defaultMessage: "Barbados" },
  "America/Belem": { id: "timeZone.belem", defaultMessage: "Belem" },
  "America/Belize": { id: "timeZone.belize", defaultMessage: "Belize" },
  "America/Boa_Vista": { id: "timeZone.boa_vista", defaultMessage: "Boa Vista" },
  "America/Bogota": { id: "timeZone.bogota", defaultMessage: "Bogota" },
  "America/Boise": { id: "timeZone.boise", defaultMessage: "Boise" },
  "America/Cambridge_Bay": { id: "timeZone.cambridge_bay", defaultMessage: "Cambridge Bay" },
  "America/Campo_Grande": { id: "timeZone.campo_grande", defaultMessage: "Campo Grande" },
  "America/Cancun": { id: "timeZone.cancun", defaultMessage: "Cancun" },
  "America/Caracas": { id: "timeZone.caracas", defaultMessage: "Caracas" },
  "America/Cayenne": { id: "timeZone.cayenne", defaultMessage: "Cayenne" },
  "America/Chicago": { id: "timeZone.chicago", defaultMessage: "Chicago" },
  "America/Chihuahua": { id: "timeZone.chihuahua", defaultMessage: "Chihuahua" },
  "America/Costa_Rica": { id: "timeZone.costa_rica", defaultMessage: "Costa Rica" },
  "America/Cuiaba": { id: "timeZone.cuiaba", defaultMessage: "Cuiaba" },
  "America/Danmarkshavn": { id: "timeZone.danmarkshavn", defaultMessage: "Danmarkshavn" },
  "America/Dawson": { id: "timeZone.dawson", defaultMessage: "Dawson" },
  "America/Dawson_Creek": { id: "timeZone.dawson_creek", defaultMessage: "Dawson Creek" },
  "America/Denver": { id: "timeZone.denver", defaultMessage: "Denver" },
  "America/Detroit": { id: "timeZone.detroit", defaultMessage: "Detroit" },
  "America/Edmonton": { id: "timeZone.edmonton", defaultMessage: "Edmonton" },
  "America/Eirunepe": { id: "timeZone.eirunepe", defaultMessage: "Eirunepe" },
  "America/El_Salvador": { id: "timeZone.el_salvador", defaultMessage: "El Salvador" },
  "America/Fort_Nelson": { id: "timeZone.fort_nelson", defaultMessage: "Fort Nelson" },
  "America/Fortaleza": { id: "timeZone.fortaleza", defaultMessage: "Fortaleza" },
  "America/Glace_Bay": { id: "timeZone.glace_bay", defaultMessage: "Glace Bay" },
  "America/Goose_Bay": { id: "timeZone.goose_bay", defaultMessage: "Goose Bay" },
  "America/Grand_Turk": { id: "timeZone.grand_turk", defaultMessage: "Grand Turk" },
  "America/Guatemala": { id: "timeZone.guatemala", defaultMessage: "Guatemala" },
  "America/Guayaquil": { id: "timeZone.guayaquil", defaultMessage: "Guayaquil" },
  "America/Guyana": { id: "timeZone.guyana", defaultMessage: "Guyana" },
  "America/Halifax": { id: "timeZone.halifax", defaultMessage: "Halifax" },
  "America/Havana": { id: "timeZone.havana", defaultMessage: "Havana" },
  "America/Hermosillo": { id: "timeZone.hermosillo", defaultMessage: "Hermosillo" },
  "America/Indiana/Indianapolis": { id: "timeZone.indianapolis", defaultMessage: "Indianapolis" },
  "America/Indiana/Knox": { id: "timeZone.knox", defaultMessage: "Knox" },
  "America/Indiana/Marengo": { id: "timeZone.marengo", defaultMessage: "Marengo" },
  "America/Indiana/Petersburg": { id: "timeZone.petersburg", defaultMessage: "Petersburg" },
  "America/Indiana/Tell_City": { id: "timeZone.tell_city", defaultMessage: "Tell City" },
  "America/Indiana/Vevay": { id: "timeZone.vevay", defaultMessage: "Vevay" },
  "America/Indiana/Vincennes": { id: "timeZone.vincennes", defaultMessage: "Vincennes" },
  "America/Indiana/Winamac": { id: "timeZone.winamac", defaultMessage: "Winamac" },
  "America/Inuvik": { id: "timeZone.inuvik", defaultMessage: "Inuvik" },
  "America/Iqaluit": { id: "timeZone.iqaluit", defaultMessage: "Iqaluit" },
  "America/Jamaica": { id: "timeZone.jamaica", defaultMessage: "Jamaica" },
  "America/Juneau": { id: "timeZone.juneau", defaultMessage: "Juneau" },
  "America/Kentucky/Louisville": { id: "timeZone.louisville", defaultMessage: "Louisville" },
  "America/Kentucky/Monticello": { id: "timeZone.monticello", defaultMessage: "Monticello" },
  "America/La_Paz": { id: "timeZone.la_paz", defaultMessage: "La Paz" },
  "America/Lima": { id: "timeZone.lima", defaultMessage: "Lima" },
  "America/Los_Angeles": { id: "timeZone.los_angeles", defaultMessage: "Los Angeles" },
  "America/Maceio": { id: "timeZone.maceio", defaultMessage: "Maceio" },
  "America/Managua": { id: "timeZone.managua", defaultMessage: "Managua" },
  "America/Manaus": { id: "timeZone.manaus", defaultMessage: "Manaus" },
  "America/Martinique": { id: "timeZone.martinique", defaultMessage: "Martinique" },
  "America/Matamoros": { id: "timeZone.matamoros", defaultMessage: "Matamoros" },
  "America/Mazatlan": { id: "timeZone.mazatlan", defaultMessage: "Mazatlan" },
  "America/Menominee": { id: "timeZone.menominee", defaultMessage: "Menominee" },
  "America/Merida": { id: "timeZone.merida", defaultMessage: "Merida" },
  "America/Metlakatla": { id: "timeZone.metlakatla", defaultMessage: "Metlakatla" },
  "America/Mexico_City": { id: "timeZone.mexico_city", defaultMessage: "Mexico City" },
  "America/Miquelon": { id: "timeZone.miquelon", defaultMessage: "Miquelon" },
  "America/Moncton": { id: "timeZone.moncton", defaultMessage: "Moncton" },
  "America/Monterrey": { id: "timeZone.monterrey", defaultMessage: "Monterrey" },
  "America/Montevideo": { id: "timeZone.montevideo", defaultMessage: "Montevideo" },
  "America/New_York": { id: "timeZone.new_york", defaultMessage: "New York" },
  "America/Nipigon": { id: "timeZone.nipigon", defaultMessage: "Nipigon" },
  "America/Nome": { id: "timeZone.nome", defaultMessage: "Nome" },
  "America/Noronha": { id: "timeZone.noronha", defaultMessage: "Noronha" },
  "America/North_Dakota/Beulah": { id: "timeZone.beulah", defaultMessage: "Beulah" },
  "America/North_Dakota/Center": { id: "timeZone.center", defaultMessage: "Center" },
  "America/North_Dakota/New_Salem": {
    id: "timeZone.north_dakota",
    defaultMessage: "North Dakota",
  },
  "America/Ojinaga": { id: "timeZone.ojinaga", defaultMessage: "Ojinaga" },
  "America/Panama": { id: "timeZone.panama", defaultMessage: "Panama" },
  "America/Pangnirtung": { id: "timeZone.pangnirtung", defaultMessage: "Pangnirtung" },
  "America/Paramaribo": { id: "timeZone.paramaribo", defaultMessage: "Paramaribo" },
  "America/Phoenix": { id: "timeZone.phoenix", defaultMessage: "Phoenix" },
  "America/Port-au-Prince": { id: "timeZone.port", defaultMessage: "Port" },
  "America/Porto_Velho": { id: "timeZone.porto_velho", defaultMessage: "Porto Velho" },
  "America/Puerto_Rico": { id: "timeZone.puerto_rico", defaultMessage: "Puerto Rico" },
  "America/Rainy_River": { id: "timeZone.rainy_river", defaultMessage: "Rainy River" },
  "America/Rankin_Inlet": { id: "timeZone.rankin_inlet", defaultMessage: "Rankin Inlet" },
  "America/Recife": { id: "timeZone.recife", defaultMessage: "Recife" },
  "America/Regina": { id: "timeZone.regina", defaultMessage: "Regina" },
  "America/Resolute": { id: "timeZone.resolute", defaultMessage: "Resolute" },
  "America/Rio_Branco": { id: "timeZone.rio_branco", defaultMessage: "Rio Branco" },
  "America/Santarem": { id: "timeZone.santarem", defaultMessage: "Santarem" },
  "America/Santiago": { id: "timeZone.santiago", defaultMessage: "Santiago" },
  "America/Santo_Domingo": { id: "timeZone.santo_domingo", defaultMessage: "Santo Domingo" },
  "America/Sao_Paulo": { id: "timeZone.sao_paulo", defaultMessage: "Sao Paulo" },
  "America/Scoresbysund": { id: "timeZone.scoresbysund", defaultMessage: "Scoresbysund" },
  "America/Sitka": { id: "timeZone.sitka", defaultMessage: "Sitka" },
  "America/St_Johns": { id: "timeZone.st_johns", defaultMessage: "St Johns" },
  "America/Swift_Current": { id: "timeZone.swift_current", defaultMessage: "Swift Current" },
  "America/Tegucigalpa": { id: "timeZone.tegucigalpa", defaultMessage: "Tegucigalpa" },
  "America/Thule": { id: "timeZone.thule", defaultMessage: "Thule" },
  "America/Thunder_Bay": { id: "timeZone.thunder_bay", defaultMessage: "Thunder Bay" },
  "America/Tijuana": { id: "timeZone.tijuana", defaultMessage: "Tijuana" },
  "America/Toronto": { id: "timeZone.toronto", defaultMessage: "Toronto" },
  "America/Vancouver": { id: "timeZone.vancouver", defaultMessage: "Vancouver" },
  "America/Whitehorse": { id: "timeZone.whitehorse", defaultMessage: "Whitehorse" },
  "America/Winnipeg": { id: "timeZone.winnipeg", defaultMessage: "Winnipeg" },
  "America/Yakutat": { id: "timeZone.yakutat", defaultMessage: "Yakutat" },
  "America/Yellowknife": { id: "timeZone.yellowknife", defaultMessage: "Yellowknife" },
  "Asia/Almaty": { id: "timeZone.almaty", defaultMessage: "Almaty" },
  "Asia/Amman": { id: "timeZone.amman", defaultMessage: "Amman" },
  "Asia/Anadyr": { id: "timeZone.anadyr", defaultMessage: "Anadyr" },
  "Asia/Aqtau": { id: "timeZone.aqtau", defaultMessage: "Aqtau" },
  "Asia/Aqtobe": { id: "timeZone.aqtobe", defaultMessage: "Aqtobe" },
  "Asia/Ashgabat": { id: "timeZone.ashgabat", defaultMessage: "Ashgabat" },
  "Asia/Baghdad": { id: "timeZone.baghdad", defaultMessage: "Baghdad" },
  "Asia/Baku": { id: "timeZone.baku", defaultMessage: "Baku" },
  "Asia/Bangkok": { id: "timeZone.bangkok", defaultMessage: "Bangkok" },
  "Asia/Beirut": { id: "timeZone.beirut", defaultMessage: "Beirut" },
  "Asia/Bishkek": { id: "timeZone.bishkek", defaultMessage: "Bishkek" },
  "Asia/Brunei": { id: "timeZone.brunei", defaultMessage: "Brunei" },
  "Asia/Chita": { id: "timeZone.chita", defaultMessage: "Chita" },
  "Asia/Choibalsan": { id: "timeZone.choibalsan", defaultMessage: "Choibalsan" },
  "Asia/Colombo": { id: "timeZone.colombo", defaultMessage: "Colombo" },
  "Asia/Damascus": { id: "timeZone.damascus", defaultMessage: "Damascus" },
  "Asia/Dhaka": { id: "timeZone.dhaka", defaultMessage: "Dhaka" },
  "Asia/Dili": { id: "timeZone.dili", defaultMessage: "Dili" },
  "Asia/Dubai": { id: "timeZone.dubai", defaultMessage: "Dubai" },
  "Asia/Dushanbe": { id: "timeZone.dushanbe", defaultMessage: "Dushanbe" },
  "Asia/Gaza": { id: "timeZone.gaza", defaultMessage: "Gaza" },
  "Asia/Hebron": { id: "timeZone.hebron", defaultMessage: "Hebron" },
  "Asia/Ho_Chi_Minh": { id: "timeZone.ho_chi_minh", defaultMessage: "Ho Chi Minh" },
  "Asia/Hong_Kong": { id: "timeZone.hong_kong", defaultMessage: "Hong Kong" },
  "Asia/Hovd": { id: "timeZone.hovd", defaultMessage: "Hovd" },
  "Asia/Irkutsk": { id: "timeZone.irkutsk", defaultMessage: "Irkutsk" },
  "Asia/Jakarta": { id: "timeZone.jakarta", defaultMessage: "Jakarta" },
  "Asia/Jayapura": { id: "timeZone.jayapura", defaultMessage: "Jayapura" },
  "Asia/Jerusalem": { id: "timeZone.jerusalem", defaultMessage: "Jerusalem" },
  "Asia/Kabul": { id: "timeZone.kabul", defaultMessage: "Kabul" },
  "Asia/Kamchatka": { id: "timeZone.kamchatka", defaultMessage: "Kamchatka" },
  "Asia/Karachi": { id: "timeZone.karachi", defaultMessage: "Karachi" },
  "Asia/Kathmandu": { id: "timeZone.kathmandu", defaultMessage: "Kathmandu" },
  "Asia/Khandyga": { id: "timeZone.khandyga", defaultMessage: "Khandyga" },
  "Asia/Kolkata": { id: "timeZone.kolkata", defaultMessage: "Kolkata" },
  "Asia/Krasnoyarsk": { id: "timeZone.krasnoyarsk", defaultMessage: "Krasnoyarsk" },
  "Asia/Kuala_Lumpur": { id: "timeZone.kuala_lumpur", defaultMessage: "Kuala Lumpur" },
  "Asia/Kuching": { id: "timeZone.kuching", defaultMessage: "Kuching" },
  "Asia/Macau": { id: "timeZone.macau", defaultMessage: "Macau" },
  "Asia/Magadan": { id: "timeZone.magadan", defaultMessage: "Magadan" },
  "Asia/Makassar": { id: "timeZone.makassar", defaultMessage: "Makassar" },
  "Asia/Manila": { id: "timeZone.manila", defaultMessage: "Manila" },
  "Asia/Nicosia": { id: "timeZone.nicosia", defaultMessage: "Nicosia" },
  "Asia/Novokuznetsk": { id: "timeZone.novokuznetsk", defaultMessage: "Novokuznetsk" },
  "Asia/Novosibirsk": { id: "timeZone.novosibirsk", defaultMessage: "Novosibirsk" },
  "Asia/Omsk": { id: "timeZone.omsk", defaultMessage: "Omsk" },
  "Asia/Oral": { id: "timeZone.oral", defaultMessage: "Oral" },
  "Asia/Pontianak": { id: "timeZone.pontianak", defaultMessage: "Pontianak" },
  "Asia/Pyongyang": { id: "timeZone.pyongyang", defaultMessage: "Pyongyang" },
  "Asia/Qatar": { id: "timeZone.qatar", defaultMessage: "Qatar" },
  "Asia/Qyzylorda": { id: "timeZone.qyzylorda", defaultMessage: "Qyzylorda" },
  "Asia/Riyadh": { id: "timeZone.riyadh", defaultMessage: "Riyadh" },
  "Asia/Riyadh87": { id: "timeZone.riyadh87", defaultMessage: "Riyadh87" },
  "Asia/Riyadh88": { id: "timeZone.riyadh88", defaultMessage: "Riyadh88" },
  "Asia/Riyadh89": { id: "timeZone.riyadh89", defaultMessage: "Riyadh89" },
  "Asia/Sakhalin": { id: "timeZone.sakhalin", defaultMessage: "Sakhalin" },
  "Asia/Samarkand": { id: "timeZone.samarkand", defaultMessage: "Samarkand" },
  "Asia/Seoul": { id: "timeZone.seoul", defaultMessage: "Seoul" },
  "Asia/Shanghai": { id: "timeZone.shanghai", defaultMessage: "Shanghai" },
  "Asia/Singapore": { id: "timeZone.singapore", defaultMessage: "Singapore" },
  "Asia/Srednekolymsk": { id: "timeZone.srednekolymsk", defaultMessage: "Srednekolymsk" },
  "Asia/Taipei": { id: "timeZone.taipei", defaultMessage: "Taipei" },
  "Asia/Tashkent": { id: "timeZone.tashkent", defaultMessage: "Tashkent" },
  "Asia/Tbilisi": { id: "timeZone.tbilisi", defaultMessage: "Tbilisi" },
  "Asia/Tehran": { id: "timeZone.tehran", defaultMessage: "Tehran" },
  "Asia/Thimphu": { id: "timeZone.thimphu", defaultMessage: "Thimphu" },
  "Asia/Tokyo": { id: "timeZone.tokyo", defaultMessage: "Tokyo" },
  "Asia/Ulaanbaatar": { id: "timeZone.ulaanbaatar", defaultMessage: "Ulaanbaatar" },
  "Asia/Urumqi": { id: "timeZone.urumqi", defaultMessage: "Urumqi" },
  "Asia/Vladivostok": { id: "timeZone.vladivostok", defaultMessage: "Vladivostok" },
  "Asia/Yakutsk": { id: "timeZone.yakutsk", defaultMessage: "Yakutsk" },
  "Asia/Yekaterinburg": { id: "timeZone.yekaterinburg", defaultMessage: "Yekaterinburg" },
  "Asia/Yerevan": { id: "timeZone.yerevan", defaultMessage: "Yerevan" },
  "Atlantic/Azores": { id: "timeZone.azores", defaultMessage: "Azores" },
  "Atlantic/Bermuda": { id: "timeZone.bermuda", defaultMessage: "Bermuda" },
  "Atlantic/Canary": { id: "timeZone.canary", defaultMessage: "Canary" },
  "Atlantic/Cape_Verde": { id: "timeZone.cape_verde", defaultMessage: "Cape Verde" },
  "Atlantic/Faroe": { id: "timeZone.faroe", defaultMessage: "Faroe" },
  "Atlantic/Madeira": { id: "timeZone.madeira", defaultMessage: "Madeira" },
  "Atlantic/Reykjavik": { id: "timeZone.reykjavik", defaultMessage: "Reykjavik" },
  "Atlantic/South_Georgia": { id: "timeZone.south_georgia", defaultMessage: "South Georgia" },
  "Atlantic/Stanley": { id: "timeZone.stanley", defaultMessage: "Stanley" },
  "Australia/Adelaide": { id: "timeZone.adelaide", defaultMessage: "Adelaide" },
  "Australia/Brisbane": { id: "timeZone.brisbane", defaultMessage: "Brisbane" },
  "Australia/Broken_Hill": { id: "timeZone.broken_hill", defaultMessage: "Broken Hill" },
  "Australia/Darwin": { id: "timeZone.darwin", defaultMessage: "Darwin" },
  "Australia/Eucla": { id: "timeZone.eucla", defaultMessage: "Eucla" },
  "Australia/Hobart": { id: "timeZone.hobart", defaultMessage: "Hobart" },
  "Australia/Lindeman": { id: "timeZone.lindeman", defaultMessage: "Lindeman" },
  "Australia/Lord_Howe": { id: "timeZone.lord_howe", defaultMessage: "Lord Howe" },
  "Australia/Melbourne": { id: "timeZone.melbourne", defaultMessage: "Melbourne" },
  "Australia/Perth": { id: "timeZone.perth", defaultMessage: "Perth" },
  "Australia/Sydney": { id: "timeZone.sydney", defaultMessage: "Sydney" },
  "Canada/East-Saskatchewan": { id: "timeZone.east", defaultMessage: "East" },
  "Etc/GMT": { id: "timeZone.UTC", defaultMessage: "UTC" },
  "Europe/Amsterdam": { id: "timeZone.amsterdam", defaultMessage: "Amsterdam" },
  "Europe/Andorra": { id: "timeZone.andorra", defaultMessage: "Andorra" },
  "Europe/Athens": { id: "timeZone.athens", defaultMessage: "Athens" },
  "Europe/Belgrade": { id: "timeZone.belgrade", defaultMessage: "Belgrade" },
  "Europe/Berlin": { id: "timeZone.berlin", defaultMessage: "Berlin" },
  "Europe/Brussels": { id: "timeZone.brussels", defaultMessage: "Brussels" },
  "Europe/Bucharest": { id: "timeZone.bucharest", defaultMessage: "Bucharest" },
  "Europe/Budapest": { id: "timeZone.budapest", defaultMessage: "Budapest" },
  "Europe/Chisinau": { id: "timeZone.chisinau", defaultMessage: "Chisinau" },
  "Europe/Copenhagen": { id: "timeZone.copenhagen", defaultMessage: "Copenhagen" },
  "Europe/Dublin": { id: "timeZone.dublin", defaultMessage: "Dublin" },
  "Europe/Gibraltar": { id: "timeZone.gibraltar", defaultMessage: "Gibraltar" },
  "Europe/Helsinki": { id: "timeZone.helsinki", defaultMessage: "Helsinki" },
  "Europe/Istanbul": { id: "timeZone.istanbul", defaultMessage: "Istanbul" },
  "Europe/Kaliningrad": { id: "timeZone.kaliningrad", defaultMessage: "Kaliningrad" },
  "Europe/Kiev": { id: "timeZone.kiev", defaultMessage: "Kiev" },
  "Europe/Lisbon": { id: "timeZone.lisbon", defaultMessage: "Lisbon" },
  "Europe/London": { id: "timeZone.london", defaultMessage: "London" },
  "Europe/Luxembourg": { id: "timeZone.luxembourg", defaultMessage: "Luxembourg" },
  "Europe/Madrid": { id: "timeZone.madrid", defaultMessage: "Madrid" },
  "Europe/Malta": { id: "timeZone.malta", defaultMessage: "Malta" },
  "Europe/Minsk": { id: "timeZone.minsk", defaultMessage: "Minsk" },
  "Europe/Monaco": { id: "timeZone.monaco", defaultMessage: "Monaco" },
  "Europe/Moscow": { id: "timeZone.moscow", defaultMessage: "Moscow" },
  "Europe/Oslo": { id: "timeZone.oslo", defaultMessage: "Oslo" },
  "Europe/Paris": { id: "timeZone.paris", defaultMessage: "Paris" },
  "Europe/Prague": { id: "timeZone.prague", defaultMessage: "Prague" },
  "Europe/Riga": { id: "timeZone.riga", defaultMessage: "Riga" },
  "Europe/Rome": { id: "timeZone.rome", defaultMessage: "Rome" },
  "Europe/Samara": { id: "timeZone.samara", defaultMessage: "Samara" },
  "Europe/Simferopol": { id: "timeZone.simferopol", defaultMessage: "Simferopol" },
  "Europe/Sofia": { id: "timeZone.sofia", defaultMessage: "Sofia" },
  "Europe/Stockholm": { id: "timeZone.stockholm", defaultMessage: "Stockholm" },
  "Europe/Tallinn": { id: "timeZone.tallinn", defaultMessage: "Tallinn" },
  "Europe/Tirane": { id: "timeZone.tirane", defaultMessage: "Tirane" },
  "Europe/Uzhgorod": { id: "timeZone.uzhgorod", defaultMessage: "Uzhgorod" },
  "Europe/Vienna": { id: "timeZone.vienna", defaultMessage: "Vienna" },
  "Europe/Vilnius": { id: "timeZone.vilnius", defaultMessage: "Vilnius" },
  "Europe/Volgograd": { id: "timeZone.volgograd", defaultMessage: "Volgograd" },
  "Europe/Warsaw": { id: "timeZone.warsaw", defaultMessage: "Warsaw" },
  "Europe/Zaporozhye": { id: "timeZone.zaporozhye", defaultMessage: "Zaporozhye" },
  "Europe/Zurich": { id: "timeZone.zurich", defaultMessage: "Zurich" },
  HST: { id: "timeZone.hst", defaultMessage: "HST" },
  "Indian/Chagos": { id: "timeZone.chagos", defaultMessage: "Chagos" },
  "Indian/Christmas": { id: "timeZone.christmas", defaultMessage: "Christmas" },
  "Indian/Cocos": { id: "timeZone.cocos", defaultMessage: "Cocos" },
  "Indian/Kerguelen": { id: "timeZone.kerguelen", defaultMessage: "Kerguelen" },
  "Indian/Mahe": { id: "timeZone.mahe", defaultMessage: "Mahe" },
  "Indian/Maldives": { id: "timeZone.maldives", defaultMessage: "Maldives" },
  "Indian/Mauritius": { id: "timeZone.mauritius", defaultMessage: "Mauritius" },
  "Indian/Reunion": { id: "timeZone.reunion", defaultMessage: "Reunion" },
  "Mideast/Riyadh87": { id: "timeZone.riyadh87", defaultMessage: "Riyadh87" },
  "Mideast/Riyadh88": { id: "timeZone.riyadh88", defaultMessage: "Riyadh88" },
  "Mideast/Riyadh89": { id: "timeZone.riyadh89", defaultMessage: "Riyadh89" },
  "Pacific/Apia": { id: "timeZone.apia", defaultMessage: "Apia" },
  "Pacific/Auckland": { id: "timeZone.auckland", defaultMessage: "Auckland" },
  "Pacific/Bougainville": { id: "timeZone.bougainville", defaultMessage: "Bougainville" },
  "Pacific/Chatham": { id: "timeZone.chatham", defaultMessage: "Chatham" },
  "Pacific/Chuuk": { id: "timeZone.chuuk", defaultMessage: "Chuuk" },
  "Pacific/Easter": { id: "timeZone.easter", defaultMessage: "Easter" },
  "Pacific/Efate": { id: "timeZone.efate", defaultMessage: "Efate" },
  "Pacific/Fakaofo": { id: "timeZone.fakaofo", defaultMessage: "Fakaofo" },
  "Pacific/Fiji": { id: "timeZone.fiji", defaultMessage: "Fiji" },
  "Pacific/Funafuti": { id: "timeZone.funafuti", defaultMessage: "Funafuti" },
  "Pacific/Galapagos": { id: "timeZone.galapagos", defaultMessage: "Galapagos" },
  "Pacific/Gambier": { id: "timeZone.gambier", defaultMessage: "Gambier" },
  "Pacific/Guadalcanal": { id: "timeZone.guadalcanal", defaultMessage: "Guadalcanal" },
  "Pacific/Guam": { id: "timeZone.guam", defaultMessage: "Guam" },
  "Pacific/Honolulu": { id: "timeZone.honolulu", defaultMessage: "Honolulu" },
  "Pacific/Kiritimati": { id: "timeZone.kiritimati", defaultMessage: "Kiritimati" },
  "Pacific/Kosrae": { id: "timeZone.kosrae", defaultMessage: "Kosrae" },
  "Pacific/Kwajalein": { id: "timeZone.kwajalein", defaultMessage: "Kwajalein" },
  "Pacific/Majuro": { id: "timeZone.majuro", defaultMessage: "Majuro" },
  "Pacific/Marquesas": { id: "timeZone.marquesas", defaultMessage: "Marquesas" },
  "Pacific/Nauru": { id: "timeZone.nauru", defaultMessage: "Nauru" },
  "Pacific/Niue": { id: "timeZone.niue", defaultMessage: "Niue" },
  "Pacific/Norfolk": { id: "timeZone.norfolk", defaultMessage: "Norfolk" },
  "Pacific/Noumea": { id: "timeZone.noumea", defaultMessage: "Noumea" },
  "Pacific/Pago_Pago": { id: "timeZone.pago_pago", defaultMessage: "Pago Pago" },
  "Pacific/Palau": { id: "timeZone.palau", defaultMessage: "Palau" },
  "Pacific/Pitcairn": { id: "timeZone.pitcairn", defaultMessage: "Pitcairn" },
  "Pacific/Port_Moresby": { id: "timeZone.port_moresby", defaultMessage: "Port Moresby" },
  "Pacific/Rarotonga": { id: "timeZone.rarotonga", defaultMessage: "Rarotonga" },
  "Pacific/Tahiti": { id: "timeZone.tahiti", defaultMessage: "Tahiti" },
  "Pacific/Tarawa": { id: "timeZone.tarawa", defaultMessage: "Tarawa" },
  "Pacific/Tongatapu": { id: "timeZone.tongatapu", defaultMessage: "Tongatapu" },
  "Pacific/Wake": { id: "timeZone.wake", defaultMessage: "Wake" },
  "Pacific/Wallis": { id: "timeZone.wallis", defaultMessage: "Wallis" },
});

export default TimeZones;
