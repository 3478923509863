import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import Input, { InputProps, InputRef } from "antd/es/input/Input";
import Labeled, { LabeledFieldProps } from "./Labeled";
import ErrorRow from "./ErrorRow";
import { useConfigProvider } from "../ConfigProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { bem } from "@react-md/utils";
import cn from "classnames";

export interface TextFieldProps
  extends Omit<InputProps, "onChange">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  className?: string;
  error?: ReactNode;
  locked?: boolean;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const block = bem("mmc-text-field");

const TextField = forwardRef<TextFieldComponent, TextFieldProps>(
  (
    {
      caption,
      className,
      disabled,
      error,
      label,
      labelClassName,
      labelPosition,
      locked,
      maxLength,
      onChange,
      required,
      rowProps,
      sideLabelSpan,
      size = "large",
      suffix,
      value,
      ...props
    },
    ref
  ) => {
    const configProvider = useConfigProvider();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(event.target.value, event),
      [onChange]
    );

    const formattedError = useMemo(
      () =>
        maxLength && (value ?? "").length >= maxLength
          ? configProvider.formatMessage("ui.textField.error.limit")
          : error,
      [configProvider, error, maxLength, value]
    );

    const inputRef = useRef<InputRef>(null);
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
      blur: () => inputRef.current?.blur(),
    }));

    return (
      <Labeled
        className={cn(block({ disabled: disabled || locked }), className)}
        label={label}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <div className={block("input")}>
          <Input
            disabled={disabled || locked}
            maxLength={maxLength}
            onChange={handleChange}
            ref={inputRef}
            suffix={
              locked ? (
                <div className={block("lock")}>
                  <FontAwesomeIcon icon={faLock} size="xs" />
                </div>
              ) : (
                suffix
              )
            }
            required={required}
            size={size}
            value={value}
            {...props}
          />
          {formattedError ? (
            <ErrorRow>{formattedError}</ErrorRow>
          ) : caption ? (
            <div className={block("caption")}>{caption}</div>
          ) : null}
        </div>
      </Labeled>
    );
  }
);

export interface TextFieldComponent {
  focus: () => void;
  blur(): void;
}

TextField.displayName = "TextField";

export default TextField;
