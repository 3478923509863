import React from "react";
import Section from "component/activity/ActivityAnnotation/Section";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { useIntl } from "react-intl";
import EmailSubject from "../../../email/parts/EmailSubject";
import EmailBody from "../../../email/parts/EmailBody";
import EmailRecipients from "../../../email/parts/EmailRecipients";

interface Props {
  activity: Activity;
}

const EmailSection: React.FC<Props> = ({ activity }) => {
  const intl = useIntl();

  if (!activity.emailLog) {
    return null;
  }

  return (
    <Section
      icon={faEnvelope}
      title={intl.formatMessage({
        id: "component.activityAnnotation.emailSection.title",
        defaultMessage: "Email",
        description: "Email section title",
      })}
    >
      <EmailRecipients recipients={activity.emailLog.recipientAddress} />
      <EmailSubject subject={activity.emailLog.subject} />
      <EmailBody body={activity.emailLog.body} />
    </Section>
  );
};

export default EmailSection;
