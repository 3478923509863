import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { AutoCompleteAddress, ManualAddress } from "@mapmycustomers/ui";
import { Labeled, NumberField } from "@mapmycustomers/ui";
import ButtonLink from "../ButtonLink";
import Address from "@mapmycustomers/shared/types/Address";
import GeoPoint from "@mapmycustomers/shared/types/shapes/GeoPoint";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { getFormattedAddressForUi } from "util/formatters";
import { COORDINATES_REGEX } from "util/regexp";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import layout from "styles/layout";
import styles from "./AddressField.module.scss";
import { connect } from "react-redux";
import { reverseGeocodeAddress } from "store/location/actions";

interface Props {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  onChange?: (value: string) => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  placeholder?: string;
  required?: boolean;
  value?: string;
}

const AddressField: React.FC<Props> = ({
  className,
  disabled,
  label,
  onChange,
  onReverseGeocodeAddress,
  placeholder,
  required,
  value,
}) => {
  const intl = useIntl();

  const [isCoordinatesMode, setCoordinatesMode, unSetCoordinatesMode, toggleCoordinatesMode] =
    useBoolean(COORDINATES_REGEX.test(value ?? ""));
  const [coordinates, setCoordinates] = useState<GeoPoint["coordinates"] | undefined>();
  const [isManualAddress, switchToManualAddress, , toggleManualAddress] = useBoolean();
  const [manualAddress, setManualAddress] = useState<Address>();

  useEffect(() => {
    if (COORDINATES_REGEX.test(value ?? "")) {
      setCoordinatesMode();
      const groups = (value ?? "").match(COORDINATES_REGEX)?.groups;
      if (groups) {
        setCoordinates([
          parseFloat(groups?.lon?.replace(",", ".")) ?? 0,
          parseFloat(groups?.lat?.replace(",", ".")) ?? 0,
        ]);
      }
    } else {
      unSetCoordinatesMode();
    }
  }, [setCoordinatesMode, setCoordinates, unSetCoordinatesMode, value]);

  const handleSetLatitude = useCallback(
    (value?: number) => {
      setCoordinates((coordinates) => {
        onChange?.(`${value ?? 0},${coordinates?.[0] ?? 0}`);
        return [coordinates?.[0] ?? 0, value ?? 0];
      });
    },
    [onChange, setCoordinates]
  );

  const handleSetLongitude = useCallback(
    (value?: number) => {
      setCoordinates((coordinates) => {
        onChange?.(`${coordinates?.[1] ?? 0},${value ?? 0}`);
        return [value ?? 0, coordinates?.[1] ?? 0];
      });
    },
    [onChange, setCoordinates]
  );

  const handleChangeAutoComplete = useCallback(
    (address?: Address) => {
      setManualAddress(address);
      onChange?.(getFormattedAddressForUi(address));
    },
    [onChange, setManualAddress]
  );

  const handleEnterManual = useDynamicCallback(() => switchToManualAddress());

  const handleManualChangeAddress = useCallback(
    (address?: Address) => {
      const updatedManualAddress = {
        ...manualAddress,
        ...address,
      };

      setManualAddress(updatedManualAddress);
      onChange?.(getFormattedAddressForUi(address));
    },
    [manualAddress, onChange, setManualAddress]
  );

  return (
    <div className={className}>
      {isCoordinatesMode ? (
        <Labeled label={label} required={required}>
          <div className={styles.coordinates}>
            <NumberField
              controls={false}
              disabled={disabled}
              fullWidth
              inputClassName={styles.coordinate}
              max={90}
              min={-90}
              onChange={handleSetLatitude}
              placeholder={intl.formatMessage({
                id: "component.customField.address.latitude",
                defaultMessage: "Latitude",
                description: "Latitude field label at address custom field",
              })}
              value={coordinates?.[1]}
            />

            <NumberField
              controls={false}
              disabled={disabled}
              fullWidth
              inputClassName={styles.coordinate}
              max={180}
              min={-180}
              onChange={handleSetLongitude}
              placeholder={intl.formatMessage({
                id: "component.customField.address.longitude",
                defaultMessage: "Longitude",
                description: "Longitude field label at address custom field",
              })}
              value={coordinates?.[0]}
            />
          </div>
        </Labeled>
      ) : isManualAddress ? (
        <ManualAddress
          label={label}
          onChange={handleManualChangeAddress}
          onReverseGeocodeAddress={onReverseGeocodeAddress}
          onToggleManualAddress={toggleManualAddress}
          required={required}
          value={manualAddress}
        />
      ) : (
        <AutoCompleteAddress
          allowClear
          disabled={disabled}
          label={label}
          onChange={handleChangeAutoComplete}
          onEnterManually={handleEnterManual}
          onReverseGeocodeAddress={onReverseGeocodeAddress}
          required={required}
          value={{ address: value ?? "" }}
        />
      )}

      <Row align="middle" gutter={layout.spacerM} justify="space-between">
        <Col>
          <ButtonLink className={styles.modeBtn} onClick={toggleCoordinatesMode}>
            {isCoordinatesMode
              ? intl.formatMessage({
                  id: "component.customField.address.switchToAddress",
                  defaultMessage: "Set location via street address",
                  description: "`Set location via street address` button at address custom field",
                })
              : intl.formatMessage({
                  id: "component.customField.address.switchToCoordinates",
                  defaultMessage: "Set location via coordinates",
                  description: "`Set location via coordinates` button at address custom field",
                })}
          </ButtonLink>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};
export default connect(null, mapDispatchToProps)(AddressField);
