import inputMessages from "../input/messages";
import attachedFilesMessages from "../EmailBody/component/AttachedFiles/messages";
import colorComponentsMessages from "../colorComponents/messages";
import emailBodyMessages from "../EmailBody/messages";
import subjectInputMessages from "../SubjectInput/messages";
import emailBodyToolbarMessages from "../EmailBody/component/toolbar/messages";
import recipientListMessages from "../RecipientList/messages";
import { messages as userDisplayNameWithInfoMessages } from "../Avatar/util/getUserDisplayNameWithInfo";
import { messages as userPickerMessages } from "../UserPicker";
import { messages as teamPickerMessages } from "../TeamPicker";
import commonMessages from "../common/messages";
import avatarMessages from "../Avatar/messages";

const messages = {
  ...avatarMessages,
  ...attachedFilesMessages,
  ...colorComponentsMessages,
  ...emailBodyMessages,
  ...emailBodyToolbarMessages,
  ...inputMessages,
  ...recipientListMessages,
  ...subjectInputMessages,
  ...userDisplayNameWithInfoMessages,
  ...userPickerMessages,
  ...teamPickerMessages,
  ...commonMessages,
} as const;

export type MessageKey = keyof typeof messages;

export default messages;
