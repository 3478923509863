import FieldModel from "./impl/FieldModel";
import DateTimeField from "./impl/DateTimeField";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import GroupsField from "./impl/GroupsField";
import NotesField from "./impl/NotesField";
import { createField } from "./impl/fieldModelUtil";
import { Deal, EntityType } from "@mapmycustomers/shared/types/entity";
import i18nService from "config/I18nService";
import NameField from "./impl/NameField";
import UserField from "./impl/UserField";
import UniqueIdField from "./impl/UniqueIdField";
import { csvNumberFormatter } from "../formatters";
import FunnelField from "./impl/FunnelField";
import FrequencyStatusField from "./impl/FrequencyStatusField";
import NoContactField from "./impl/NoContactField";
import DateField from "./impl/DateField";
import RottingDaysOutField from "./impl/RottingDaysOutField";
import MapLocationField from "./impl/MapLocationField";
import StageField from "./impl/StageField";
import FrequencyIntervalField from "./impl/FrequencyIntervalField";
import ViewAsField from "./impl/ViewAsField";
import SourceField from "./impl/SourceField";
import localSettings from "config/LocalSettings";
import getEntityFilter, { ENTITY_FILTER_OPERATORS } from "../filters/EntityFilter";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { parseApiDateWithUtcTz } from "util/parsers";
import TeamField from "util/fieldModel/impl/TeamField";
import { defineMessages } from "react-intl";
import LossReasonField from "./impl/LossReasonField";
import TerritoryField from "util/fieldModel/impl/TerritoryField";
import addressFieldsMessages from "./util/addressFieldsMessages";
import getDealAddressField from "./util/getDealAddressField";
import FrequencyDaysLeft from "./impl/FrequencyDaysLeft";
import ClosingDateField from "./impl/ClosingDateField";

export enum DealFieldName {
  NAME = "name",
  SCORE = "score",
  CRM_LINK = "crmLink",
  AMOUNT = "amount",
  ADJUSTED_AMOUNT = "adjustedAmount",
  SOURCE = "sourceCreated",
  CLOSING_DATE = "closingDate",
  FILES = "files",
  GROUPS = "groups",
  FREQUENCY_INTERVAL = "cadenceInterval",
  DAYS_LEFT_IN_FREQUENCY = "daysLeftInFrequency",
  CREATOR = "creator",
  CREATOR_NAME = "creatorName",
  CREATOR_EMAIL = "creator.username",
  USER = "user",
  OWNER_NAME = "ownerName",
  OWNER_EMAIL = "user.username",
  NEXT_ACTIVITY = "upcomingActivity.startAt",
  LAST_ACTIVITY = "lastCompletedActivity.completedAt",
  FREQUENCY_STATUS = "cadenceDaysOut",
  NO_CONTACT_IN = "noContactDaysOut",
  NOTES = "notes",
  UNIQUE_ID = "importId",
  ACCOUNT = "account",
  CONTACT = "contact",
  ADDRESS = "deal.address",
  GEOPOINT = "geoPoint",
  DEAL_LOSS_REASON = "dealLossReason",
  DEAL_LOSS_COMMENT = "dealLossComment",
  ROTTING_DAYS_OUT = "rottingDaysOut",
  DAYS_IN_STAGE = "daysInStage",
  FUNNEL = "funnel",
  STAGE = "stage",
  STAGE_TYPE = "stage.type",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  TEAM = "team",
  TERRITORIES = "territories",
  VIEW_AS = "viewAs",
  ACCOUNT_IMPORT_ID = "account.importId",
  CONTACT_IMPORT_ID = "contact.importId",
}

const displayNames = defineMessages<DealFieldName>({
  [DealFieldName.NAME]: {
    id: "deal.field.name",
    defaultMessage: "Name",
    description: "Name field of deal",
  },
  [DealFieldName.SCORE]: {
    id: "deal.field.score",
    defaultMessage: "Score",
    description: "Score field of deal",
  },
  [DealFieldName.CRM_LINK]: {
    id: "deal.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM Link field of deal",
  },
  [DealFieldName.AMOUNT]: {
    id: "deal.field.amount",
    defaultMessage: "Amount",
    description: "Amount field of deal",
  },
  [DealFieldName.ADJUSTED_AMOUNT]: {
    id: "deal.field.adjustedAmount",
    defaultMessage: "Adjusted Amount",
    description: "Adjusted Amount field of deal",
  },
  [DealFieldName.SOURCE]: {
    id: "deal.field.source",
    defaultMessage: "Source",
    description: "Source field of deal",
  },
  [DealFieldName.CLOSING_DATE]: {
    id: "deal.field.closingDate",
    defaultMessage: "Closing Date",
    description: "Closing Date field of deal",
  },
  [DealFieldName.FILES]: {
    id: "deal.field.files",
    defaultMessage: "Files",
    description: "Files field of deal",
  },
  [DealFieldName.GROUPS]: {
    id: "deal.field.groups",
    defaultMessage: "Group",
    description: "Groups field of deal",
  },
  [DealFieldName.FREQUENCY_INTERVAL]: {
    id: "deal.field.frequencyInterval",
    defaultMessage: "Frequency Interval",
    description: "Frequency Interval field of deal",
  },
  [DealFieldName.CREATOR]: {
    id: "deal.field.creator",
    defaultMessage: "Created by",
    description: "Creator field of deal",
  },
  [DealFieldName.CREATOR_NAME]: {
    id: "deal.field.creatorName",
    defaultMessage: "Creator Name",
    description: "Creator Name field of deal",
  },
  [DealFieldName.CREATOR_EMAIL]: {
    id: "deal.field.creatorEmail",
    defaultMessage: "Creator Email",
    description: "Creator Email field of deal",
  },
  [DealFieldName.USER]: {
    id: "deal.field.user",
    defaultMessage: "Owner",
    description: "Owner field of deal",
  },
  [DealFieldName.OWNER_NAME]: {
    id: "deal.field.ownerName",
    defaultMessage: "Owner Name",
    description: "Owner Name field of deal",
  },
  [DealFieldName.OWNER_EMAIL]: {
    id: "deal.field.ownerEmail",
    defaultMessage: "Owner Email",
    description: "Owner Email field of deal",
  },
  [DealFieldName.NEXT_ACTIVITY]: {
    id: "deal.field.nextActivity",
    defaultMessage: "Date of Next Activity",
    description: "Next Activity field of deal",
  },
  [DealFieldName.LAST_ACTIVITY]: {
    id: "deal.field.lastActivity",
    defaultMessage: "Date of Last Done Activity",
    description: "Date of last done activity field of deal",
  },
  [DealFieldName.FREQUENCY_STATUS]: {
    id: "deal.field.frequencyStatus",
    defaultMessage: "Frequency Status",
    description: "Frequency Status field of deal",
  },
  [DealFieldName.NO_CONTACT_IN]: {
    id: "deal.field.noContactIn",
    defaultMessage: "No Contact In",
    description: "No Contact In field of deal",
  },
  [DealFieldName.NOTES]: {
    id: "deal.field.notes",
    defaultMessage: "Notes",
    description: "Notes field of deal",
  },
  [DealFieldName.UNIQUE_ID]: {
    id: "deal.field.uniqueId",
    defaultMessage: "Unique ID",
    description: "Unique Id field of deal",
  },
  [DealFieldName.ACCOUNT]: {
    id: "deal.field.account",
    defaultMessage: "Company",
    description: "Company field of deal",
  },
  [DealFieldName.CONTACT]: {
    id: "deal.field.contact",
    defaultMessage: "Person",
    description: "Person field of deal",
  },
  [DealFieldName.ADDRESS]: {
    id: "deal.field.deal.address",
    defaultMessage: "Address",
    description: "Address field of deal",
  },
  [DealFieldName.GEOPOINT]: {
    id: "deal.field.geoPoint",
    defaultMessage: "Location",
    description: "Location field of deal",
  },
  [DealFieldName.DEAL_LOSS_REASON]: {
    id: "deal.field.dealLossReason",
    defaultMessage: "Loss Reason",
    description: "Loss Reason field of deal",
  },
  [DealFieldName.DEAL_LOSS_COMMENT]: {
    id: "deal.field.dealLossComment",
    defaultMessage: "Loss Comment",
    description: "Loss Comment field of deal",
  },
  [DealFieldName.ROTTING_DAYS_OUT]: {
    id: "deal.field.rottingDaysOut",
    defaultMessage: "Days Rotting",
    description: "Days Rotting field of deal",
  },
  [DealFieldName.DAYS_IN_STAGE]: {
    id: "deal.field.daysInStage",
    defaultMessage: "Days in Stage",
    description: "Days in Stage field of deal",
  },
  [DealFieldName.FUNNEL]: {
    id: "deal.field.funnel",
    defaultMessage: "Funnel",
    description: "Funnel field of deal",
  },
  [DealFieldName.STAGE]: {
    id: "deal.field.stage",
    defaultMessage: "Stage",
    description: "Stage field of deal",
  },
  [DealFieldName.STAGE_TYPE]: {
    id: "deal.field.stage.type",
    defaultMessage: "Stage Type",
    description: "Stage Type field of deal",
  },
  [DealFieldName.CREATED_AT]: {
    id: "deal.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created At field of deal",
  },
  [DealFieldName.UPDATED_AT]: {
    id: "deal.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of deal",
  },
  [DealFieldName.TEAM]: {
    id: "deal.field.team",
    defaultMessage: "Team",
    description: "Team field of deal",
  },
  [DealFieldName.TERRITORIES]: {
    id: "deal.field.territories",
    defaultMessage: "Territories",
    description: "Territories field of deal",
  },
  [DealFieldName.VIEW_AS]: {
    id: "deal.field.viewAs",
    defaultMessage: "View As",
    description: "View As field of deal",
  },
  [DealFieldName.ACCOUNT_IMPORT_ID]: {
    id: "deal.field.account.importId",
    defaultMessage: "Company ID",
    description: "CompanyId field of activity",
  },
  [DealFieldName.CONTACT_IMPORT_ID]: {
    id: "deal.field.person.importId",
    defaultMessage: "Person ID",
    description: "PersonId field of activity",
  },
  [DealFieldName.DAYS_LEFT_IN_FREQUENCY]: {
    id: "deal.field.daysLeftInFrequency",
    defaultMessage: "Days Left In Frequency",
    description: " Days Left In Frequency field of deal",
  },
});

const additionalDisplayNames = defineMessages({
  companyId: {
    id: "deal.field.account.importName",
    defaultMessage: "Company - ID",
    description: "Import name for the account field of deal",
  },
  personId: {
    id: "deal.field.contact.importName",
    defaultMessage: "Person - ID",
    description: "Import name for the contact field of deal",
  },
});

const dealFieldModel = new FieldModel(
  [
    new NameField({
      name: DealFieldName.NAME,
      displayName: displayNames[DealFieldName.NAME],
      formProperties: { fullWidth: false },
    }),
    {
      name: DealFieldName.SCORE,
      displayName: displayNames[DealFieldName.SCORE],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
      ],
      customGridProperties: {
        cellClass: ["cursor-pointer"],
      },
    },
    {
      name: DealFieldName.CRM_LINK,
      displayName: displayNames[DealFieldName.CRM_LINK],
      type: FieldType.STRING,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.SORTABLE,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DETAILS,
      ],
      customGridProperties: {
        cellRenderer: "crmLinkCellRenderer",
      },
    },
    {
      name: DealFieldName.AMOUNT,
      displayName: displayNames[DealFieldName.AMOUNT],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NUMERIC,
        FieldFeature.DATA_VIEW,
        FieldFeature.MONETARY_VALUE,
        FieldFeature.HISTORY_FIELD,
      ],
      valueFormatter: (entity: unknown, value: unknown) =>
        i18nService.getIntl()?.formatNumber(value as number, {
          style: "currency",
          currency: localSettings.getCurrencyCode(),
          minimumFractionDigits: 0,
        }) ?? "",
      customGridProperties: {
        cellClass: ["cursor-pointer"],
      },
      csvExportProperties: { valueFormatter: csvNumberFormatter },
      overridePhiSupport: false,
    },
    {
      name: DealFieldName.ADJUSTED_AMOUNT,
      displayName: displayNames[DealFieldName.ADJUSTED_AMOUNT],
      exportColumnName: "adjustedAmount",
      type: FieldType.NUMBER,
      features: [
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NUMERIC,
        FieldFeature.MONETARY_VALUE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      valueFormatter: (entity: unknown, value: unknown) =>
        i18nService.getIntl()?.formatNumber(value as number, {
          style: "currency",
          currency: localSettings.getCurrencyCode(),
          minimumFractionDigits: 0,
        }) ?? "",
      valueGetter: (entity: unknown) =>
        ((entity as Deal).amount ?? 0) * (entity as Deal).stage.weight,
      customGridProperties: {
        cellClass: ["cursor-pointer"],
      },
    },
    new SourceField({
      name: DealFieldName.SOURCE,
      displayName: displayNames[DealFieldName.SOURCE],
    }),
    new ClosingDateField({
      name: DealFieldName.CLOSING_DATE,
      displayName: displayNames[DealFieldName.CLOSING_DATE],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
      ],
      valueGetter: (entity: unknown, field: IField) =>
        (entity as Deal).closingDate
          ? parseApiDateWithUtcTz((entity as Deal).closingDate as string)
          : null,
      overridePhiSupport: false,
    }),
    {
      name: DealFieldName.FILES,
      displayName: displayNames[DealFieldName.FILES],
      type: FieldType.LIST,
      features: [
        FieldFeature.FILE_FIELD,
        // not visible anywhere, except in forms
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
      ],
      formProperties: {
        fullWidth: true,
      },
    },
    new GroupsField({
      name: DealFieldName.GROUPS,
      displayName: displayNames[DealFieldName.GROUPS],
    }),
    new FrequencyIntervalField({
      name: DealFieldName.FREQUENCY_INTERVAL,
      displayName: displayNames[DealFieldName.FREQUENCY_INTERVAL],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.ENGAGEMENT,
        FieldFeature.BULK_EDIT,
        FieldFeature.FREQUENCY_INTERVAL,
        FieldFeature.FILTERABLE_ON_MAP,
      ],
    }),
    new UserField({
      name: DealFieldName.CREATOR,
      displayName: displayNames[DealFieldName.CREATOR],
      platformFilterName: "createdBy",
      platformName: "createdBy",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DATA_VIEW,
      ],
    }),
    new UserField({
      name: DealFieldName.CREATOR_NAME,
      filterName: "creator",
      exportColumnName: "creator",
      displayName: displayNames[DealFieldName.CREATOR_NAME],
      platformFilterName: "createdBy",
      valueGetter: (entity: unknown) => (entity as Deal).creator,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    {
      name: DealFieldName.CREATOR_EMAIL,
      displayName: displayNames[DealFieldName.CREATOR_EMAIL],
      type: FieldType.STRING,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new UserField({
      name: DealFieldName.USER,
      displayName: displayNames[DealFieldName.USER],
      platformFilterName: "userId",
      platformName: "userId",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
      ],
    }),
    new UserField({
      name: DealFieldName.OWNER_NAME,
      filterName: "user",
      exportColumnName: "user",
      displayName: displayNames[DealFieldName.OWNER_NAME],
      platformFilterName: "userId",
      valueGetter: (entity: unknown) => (entity as Deal).user,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    {
      name: DealFieldName.OWNER_EMAIL,
      displayName: displayNames[DealFieldName.OWNER_EMAIL],
      type: FieldType.STRING,
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new DateField({
      name: DealFieldName.NEXT_ACTIVITY,
      displayName: displayNames[DealFieldName.NEXT_ACTIVITY],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
    }),
    new DateField({
      name: DealFieldName.LAST_ACTIVITY,
      displayName: displayNames[DealFieldName.LAST_ACTIVITY],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
    }),
    new FrequencyStatusField({
      name: DealFieldName.FREQUENCY_STATUS,
      displayName: displayNames[DealFieldName.FREQUENCY_STATUS],
    }),
    new NoContactField({
      name: DealFieldName.NO_CONTACT_IN,
      displayName: displayNames[DealFieldName.NO_CONTACT_IN],
    }),
    new NotesField({
      name: DealFieldName.NOTES,
      displayName: displayNames[DealFieldName.NOTES],
    }),
    new UniqueIdField({
      name: DealFieldName.UNIQUE_ID,
      displayName: displayNames[DealFieldName.UNIQUE_ID],
    }),
    {
      name: DealFieldName.ACCOUNT,
      displayName: displayNames[DealFieldName.ACCOUNT],
      platformFilterName: "account.id",
      platformName: "accountId",
      valueGetter: ["account", "name"],
      type: FieldType.STRING,
      features: [
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.RELATIONSHIPS,
      ],
      importProperties: {
        bottomPosition: true,
        displayName: additionalDisplayNames.companyId,
        required: false,
      },
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.COMPANY) },
        operators: ENTITY_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "relationshipCellRenderer",
      },
    },
    {
      name: DealFieldName.CONTACT,
      displayName: displayNames[DealFieldName.CONTACT],
      platformName: "contactId",
      valueGetter: ["contact", "name"],
      type: FieldType.STRING,
      features: [
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.RELATIONSHIPS,
        FieldFeature.HISTORY_FIELD,
      ],
      importProperties: {
        bottomPosition: true,
        displayName: additionalDisplayNames.personId,
        required: false,
      },
      platformFilterName: "contact.id",
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: { entity: getEntityFilter(EntityType.PERSON) },
        operators: ENTITY_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "relationshipCellRenderer",
      },
    },
    new MapLocationField({
      name: DealFieldName.GEOPOINT,
      displayName: displayNames[DealFieldName.GEOPOINT],
      valueGetter: (entity: unknown) =>
        (entity as Deal).account?.geoPoint?.coordinates ??
        (entity as Deal).contact?.geoPoint?.coordinates,
      features: [
        FieldFeature.STANDARD,
        FieldFeature.ADDRESS,
        FieldFeature.GEO_POINT_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.DEAL_ADDRESS_FIELD,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    new FunnelField({
      name: DealFieldName.FUNNEL,
      displayName: displayNames[DealFieldName.FUNNEL],
      platformName: "funnelId",
    }),
    new LossReasonField({
      name: DealFieldName.DEAL_LOSS_REASON,
      displayName: displayNames[DealFieldName.DEAL_LOSS_REASON],
      platformName: "dealLossReasonId",
    }),
    {
      name: DealFieldName.DEAL_LOSS_COMMENT,
      displayName: displayNames[DealFieldName.DEAL_LOSS_COMMENT],
      type: FieldType.STRING,
      importProperties: {
        name: "dealLossComment",
      },
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.DETAILS,
      ],
    },
    new RottingDaysOutField({
      name: DealFieldName.ROTTING_DAYS_OUT,
      displayName: displayNames[DealFieldName.ROTTING_DAYS_OUT],
    }),
    {
      name: DealFieldName.DAYS_IN_STAGE,
      displayName: displayNames[DealFieldName.DAYS_IN_STAGE],
      type: FieldType.NUMBER,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.ENGAGEMENT,
        FieldFeature.DETAILS,
      ],
    },
    new StageField({
      name: DealFieldName.STAGE,
      displayName: displayNames[DealFieldName.STAGE],
      platformName: "dealStageId",
    }),
    {
      name: DealFieldName.STAGE_TYPE,
      displayName: displayNames[DealFieldName.STAGE_TYPE],
      type: FieldType.STRING,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.SUPPORTS_SET_FILTER,
      ],
    },
    new DateTimeField({
      name: DealFieldName.CREATED_AT,
      displayName: displayNames[DealFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
    new DateTimeField({
      name: DealFieldName.UPDATED_AT,
      displayName: displayNames[DealFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
    new TeamField({
      name: DealFieldName.TEAM,
      displayName: displayNames[DealFieldName.TEAM],
      platformFilterName: "teamId",
    }),
    new TerritoryField({
      name: DealFieldName.TERRITORIES,
      displayName: displayNames[DealFieldName.TERRITORIES],
    }),
    // filter-only fields:
    new ViewAsField({
      name: DealFieldName.VIEW_AS,
      displayName: displayNames[DealFieldName.VIEW_AS],
    }),
    {
      name: "deal.address",
      displayName: addressFieldsMessages.dealStreet,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => getDealAddressField(entity as Deal, "address"),
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DEAL_ADDRESS_FIELD,
      ],
    },
    {
      name: "deal.city",
      displayName: addressFieldsMessages.dealCity,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => getDealAddressField(entity as Deal, "city"),
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DEAL_ADDRESS_FIELD,
      ],
    },
    {
      name: "deal.region",
      displayName: addressFieldsMessages.dealState,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => getDealAddressField(entity as Deal, "region"),
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DEAL_ADDRESS_FIELD,
      ],
    },
    {
      name: "deal.country",
      displayName: addressFieldsMessages.dealCountry,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => getDealAddressField(entity as Deal, "countryCode"),
      customGridProperties: {
        cellRenderer: "countryCellRenderer",
      },
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DEAL_ADDRESS_FIELD,
      ],
    },
    {
      name: "deal.postalCode",
      displayName: addressFieldsMessages.dealPostalCode,
      type: FieldType.STRING,
      valueGetter: (entity: unknown) => getDealAddressField(entity as Deal, "postalCode"),
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DEAL_ADDRESS_FIELD,
      ],
    },
    {
      name: "account.importId",
      displayName: displayNames[DealFieldName.ACCOUNT_IMPORT_ID],
      type: FieldType.STRING,
      valueGetter: ["account", "importId"],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    {
      name: "contact.importId",
      displayName: displayNames[DealFieldName.CONTACT_IMPORT_ID],
      type: FieldType.STRING,
      valueGetter: ["contact", "importId"],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    },
    new FrequencyDaysLeft({
      name: DealFieldName.DAYS_LEFT_IN_FREQUENCY,
      displayName: displayNames[DealFieldName.DAYS_LEFT_IN_FREQUENCY],
    }),
  ].map(createField)
);

export default dealFieldModel;
