import { useIntl } from "react-intl";
import React, { useCallback } from "react";
import { Modal } from "@mapmycustomers/ui";
import Checkbox from "component/input/Checkbox";
import styles from "./SelectDuplicateFieldsModal.module.scss";
import ToggleEntityCheckbox from "component/input/ToggleEntityCheckbox";
import useMutableSet from "util/hook/useMutableSet";
import localSettings from "config/LocalSettings";
import { ActivityFieldName, displayNames } from "util/fieldModel/ActivityFieldModel";

interface Props {
  onContinue: () => void;
  onHide: () => void;
  visible: boolean;
}

const SelectDuplicateFieldsModal: React.FC<Props> = ({ onContinue, onHide, visible }) => {
  const intl = useIntl();

  const [checkedEntities, updateCheckedEntities, handleToggleEntity] =
    useMutableSet<ActivityFieldName>(() => new Set(localSettings.getFollowUpFields()));

  const handleSelectAllCheckboxChange = useCallback(
    (checked) =>
      updateCheckedEntities((set) => {
        [ActivityFieldName.COMPANY, ActivityFieldName.PERSON, ActivityFieldName.DEAL].forEach(
          (entity) => {
            if (checked) {
              set.add(entity);
            } else {
              set.delete(entity);
            }
          }
        );
        return true;
      }),
    [updateCheckedEntities]
  );

  const handleConfirmClick = () => {
    localSettings.setFollowUpFields([...checkedEntities]);
    onContinue();
  };

  const allAssociationFieldsSelected = [
    ActivityFieldName.COMPANY,
    ActivityFieldName.PERSON,
    ActivityFieldName.DEAL,
  ].every((field) => checkedEntities.has(field));

  return (
    <Modal
      onCancel={onHide}
      onOk={handleConfirmClick}
      okText={intl.formatMessage({
        id: "createEditActivityModal.selectDuplicateFieldModal.default.okButton",
        defaultMessage: "Continue",
        description: "Placeholder for ok button text for select duplicate Field Modal",
      })}
      open={visible}
      title={intl.formatMessage({
        id: "createEditActivityModal.selectDuplicateFieldModal.title",
        defaultMessage: "Select fields to duplicate:",
        description: "Title for select duplicate Field Modal",
      })}
    >
      <div>
        <ToggleEntityCheckbox
          checked={checkedEntities.has(ActivityFieldName.NAME)}
          displayName={intl.formatMessage(displayNames[ActivityFieldName.NAME])}
          entity={ActivityFieldName.NAME}
          key={ActivityFieldName.NAME}
          onToggle={handleToggleEntity}
        />
        <ToggleEntityCheckbox
          checked={checkedEntities.has(ActivityFieldName.ACTIVITY_TYPE)}
          displayName={intl.formatMessage(displayNames[ActivityFieldName.ACTIVITY_TYPE])}
          entity={ActivityFieldName.ACTIVITY_TYPE}
          key={ActivityFieldName.ACTIVITY_TYPE}
          onToggle={handleToggleEntity}
        />
        <div>
          <Checkbox
            checked={allAssociationFieldsSelected}
            indeterminate={
              !allAssociationFieldsSelected &&
              [ActivityFieldName.COMPANY, ActivityFieldName.PERSON, ActivityFieldName.DEAL].some(
                (field) => checkedEntities.has(field)
              )
            }
            onChange={handleSelectAllCheckboxChange}
          >
            Associated Records
          </Checkbox>
          {[ActivityFieldName.COMPANY, ActivityFieldName.PERSON, ActivityFieldName.DEAL].map(
            (field) => (
              <ToggleEntityCheckbox
                checked={checkedEntities.has(field)}
                className={styles.nestedItem}
                displayName={intl.formatMessage(displayNames[field])}
                entity={field}
                key={field}
                onToggle={handleToggleEntity}
              />
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SelectDuplicateFieldsModal;
