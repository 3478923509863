import React, { useCallback } from "react";
import { faPaperclip } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Upload from "antd/es/upload/Upload";
import Modal from "antd/es/modal";
import Tooltip from "antd/es/tooltip";
import { useConfigProvider } from "../../../../ConfigProvider";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { notification } from "antd";
import styles from "./AttachFiles.module.scss";
import toolbarStyles from "../../Toolbar.module.scss";

const FILE_SIZE_LIMIT = 25 * 1024 * 1024;

interface Props {
  onAction?: (actionKey: string) => void;
  onFilesSelect?: (files: File) => void;
}

const AttachFiles: React.FC<Props> = ({ onAction, onFilesSelect }) => {
  const [modal, contextHolder] = Modal.useModal();
  const configProvider = useConfigProvider();

  const handleCustomRequest = useCallback(
    async ({ file }: UploadRequestOption) => {
      onFilesSelect?.(file as File);
    },
    [onFilesSelect]
  );

  const handleFilesSelect = useCallback(
    (file) => {
      if (file.size > FILE_SIZE_LIMIT) {
        notification.error({
          message: (
            <div>
              <div className={styles.errorTitle}>
                {configProvider.formatMessage(
                  "ui.emailBody.attachedFiles.largeFilesWarning.title"
                )}
              </div>
              <div className={styles.errorDescription}>
                {configProvider.formatMessage(
                  "ui.emailBody.attachedFiles.largeFilesWarning.description"
                )}
              </div>
            </div>
          ),
        });
        return false;
      }
      return true;
    },
    [modal, onFilesSelect]
  );

  const handleClick = useCallback(() => {
    onAction?.("Attach Files");
  }, [onAction]);

  return (
    <div>
      <Upload
        beforeUpload={handleFilesSelect}
        customRequest={handleCustomRequest}
        fileList={[]}
        multiple
      >
        <Tooltip
          title={configProvider.formatMessage(
            "ui.emailBody.toolbar.actions.attachFiles"
          )}
        >
          <span
            className={toolbarStyles.togglerButton}
            onClick={handleClick}
            onMouseDown={stopEvents}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </span>
        </Tooltip>
      </Upload>
      {contextHolder}
    </div>
  );
};

export default AttachFiles;
