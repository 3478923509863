import React, { useCallback } from "react";
import Dropdown from "antd/es/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import { Col, Row } from "antd";
import { useSlate } from "slate-react";
import ElementAlignment from "@mapmycustomers/shared/enum/textEditor/ElementAlignment";
import toggleBlock from "../../util/toggleBlock";
import Tooltip from "antd/es/tooltip";
import { useConfigProvider } from "../../../ConfigProvider";
import styles from "./ElementAlignmentToggler.module.scss";
import commonStyles from "../Toolbar.module.scss";
import { isMacOS, stopEvents } from "@mapmycustomers/shared/util/browser";

const ElementAlignmentToggler: React.FC = () => {
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const handleToggleBlock = useCallback(
    (align: ElementAlignment, e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleBlock(editor, undefined, { align });
    },
    [editor]
  );

  return (
    <Dropdown
      menu={{
        onMouseDown: stopEvents,
        onClick: (e) =>
          handleToggleBlock(e.key as ElementAlignment, e.domEvent),
        items: [
          {
            key: ElementAlignment.ALIGN_LEFT,
            label: (
              <Tooltip
                title={
                  isMacOS()
                    ? configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.left.mac"
                      )
                    : configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.left.win"
                      )
                }
              >
                <FontAwesomeIcon icon={faAlignLeft} />
              </Tooltip>
            ),
          },
          {
            key: ElementAlignment.ALIGN_CENTER,
            label: (
              <Tooltip
                title={
                  isMacOS()
                    ? configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.center.mac"
                      )
                    : configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.center.win"
                      )
                }
              >
                <FontAwesomeIcon icon={faAlignCenter} />
              </Tooltip>
            ),
          },
          {
            key: ElementAlignment.ALIGN_RIGHT,
            label: (
              <Tooltip
                title={
                  isMacOS()
                    ? configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.right.mac"
                      )
                    : configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.right.win"
                      )
                }
              >
                <FontAwesomeIcon icon={faAlignRight} />
              </Tooltip>
            ),
          },
          {
            key: ElementAlignment.ALIGN_JUSTIFY,
            label: (
              <Tooltip
                title={
                  isMacOS()
                    ? configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.justify.mac"
                      )
                    : configProvider.formatMessage(
                        "ui.emailBody.toolbar.align.justify.win"
                      )
                }
              >
                <FontAwesomeIcon icon={faAlignJustify} />
              </Tooltip>
            ),
          },
        ],
      }}
      overlayClassName={styles.container}
      placement="top"
    >
      <span className={commonStyles.togglerButton} onMouseDown={stopEvents}>
        <Row
          align="middle"
          className={commonStyles.togglerButtonContent}
          gutter={4}
        >
          <Col>
            <FontAwesomeIcon icon={faAlignLeft} />
          </Col>
          <Col>
            <FontAwesomeIcon
              className={commonStyles.dropDownChevron}
              icon={faChevronDown}
              size="xs"
            />
          </Col>
        </Row>
      </span>
    </Dropdown>
  );
};

export default ElementAlignmentToggler;
