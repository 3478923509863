import React, { ReactNode } from "react";
import cn from "classnames";
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
import styles from "./Modal.module.scss";

export interface ModalProps extends AntdModalProps {
  extra?: ReactNode;
  modalStyle?: "fancy";
  size?: "small" | "large";
  type?: "default" | "warning" | "error" | "success" | "none" | "disabled";
}

const Modal: React.FC<ModalProps> = ({
  className,
  extra,
  modalStyle,
  size = "large",
  title,
  type = "default",
  ...props
}) => (
  <AntdModal
    className={cn(
      className,
      { [styles.bordered]: type !== "none" },
      { [styles.error]: type === "error" },
      { [styles.warning]: type === "warning" },
      { [styles.disabled]: type === "disabled" },
      { [styles.success]: type === "success" },
      { [styles.fancyModalStyle]: modalStyle === "fancy" },
      { [styles.small]: size === "small" }
    )}
    centered
    title={
      title && (
        <div className={styles.header}>
          {title}
          {extra && <div>{extra}</div>}
        </div>
      )
    }
    {...props}
  />
);

export default Modal;
