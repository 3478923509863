import React from "react";
import styles from "./EmailMetrics.module.scss";
import cn from "classnames";
import { useIntl } from "react-intl";
import Email from "@mapmycustomers/shared/types/email/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "antd/es/tooltip";
import { RootState } from "../../../store/rootReducer";
import { connect } from "react-redux";
import { getUserSettingValue } from "../../../store/iam";

interface Props {
  email: Email | null | undefined;
  visible?: boolean;
}

const EmailMetrics: React.FC<Props> = ({ email, visible }) => {
  const intl = useIntl();
  const linksOpened = email?.linksOpened?.length ?? 0;

  if (!email || !visible) {
    return null;
  }
  return (
    <div className={styles.container}>
      {email.opened ? (
        <Tooltip
          title={intl.formatMessage({
            id: "email.parts.metrics.opened",
            defaultMessage: "Email has been opened",
            description: "Email opened status",
          })}
        >
          <div className={cn(styles.chip, styles.active)}>
            <FontAwesomeIcon className={cn(styles.icon, styles.activeIcon)} icon={faEnvelopeOpen} />
            <FontAwesomeIcon className={styles.icon} icon={faCheck} />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title={intl.formatMessage({
            id: "email.parts.metrics.notOpened",
            defaultMessage: "Email hasn't been opened yet",
            description: "Email not opened status",
          })}
        >
          <div className={styles.chip}>
            <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            <FontAwesomeIcon className={styles.icon} icon={faTimes} />
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={
          linksOpened > 0
            ? intl.formatMessage({
                id: "email.parts.metrics.linksOpenedTimes1",
                defaultMessage: "Link(s) have been opened",
                description: "Email opened status - clicked times",
              })
            : intl.formatMessage({
                id: "email.parts.metrics.noLinksOpened",
                defaultMessage: "Link(s) haven’t been opened yet",
                description: "Email opened status - no clicks",
              })
        }
      >
        <div className={cn(styles.chip, { [styles.active]: linksOpened })}>
          <FontAwesomeIcon
            className={cn(styles.icon, {
              [styles.activeIcon]: linksOpened,
            })}
            icon={faArrowPointer}
          />
          <span>
            {linksOpened > 0 ? (
              <FontAwesomeIcon className={styles.icon} icon={faCheck} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faTimes} />
            )}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: getUserSettingValue(state)("bulkEmail").trackEmails,
});

export default connect(mapStateToProps)(EmailMetrics);
