import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from "react";
import Select, {
  RefSelectProps,
  SelectProps,
  SelectValue,
} from "antd/es/select";
import Labeled, { LabeledFieldProps } from "../Labeled";
import ErrorRow from "../ErrorRow";
import cn from "classnames";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { Form } from "antd";
import { useConfigProvider } from "../../ConfigProvider";

const block = bem("mmc-select-field");

export interface SelectFieldProps<T extends SelectValue = SelectValue>
  extends Omit<SelectProps<T>, "className" | "ref">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  error?: ReactNode;
  locked?: boolean;
  prefixIcon?: ReactNode;
  selectClassName?: string;
}

const SelectField = <T extends SelectValue = SelectValue>(
  {
    caption,
    className,
    disabled,
    error,
    extra,
    label,
    labelClassName,
    labelPosition,
    locked,
    placeholder,
    prefixIcon,
    required,
    rowProps,
    selectClassName,
    sideLabelSpan,
    size = "large",
    ...selectProps
  }: SelectFieldProps<T>,
  ref: React.Ref<SelectFieldComponent>
) => {
  const configProvider = useConfigProvider();
  const selectorRef = useRef<RefSelectProps>(null);
  const { status } = Form.Item.useStatus();

  useImperativeHandle(ref, () => ({
    focus: () => selectorRef.current?.focus(),
    blur: () => selectorRef.current?.blur(),
  }));

  return (
    <Labeled
      className={cn(
        block({
          hasIcon: !!prefixIcon,
          disabled: disabled || locked,
          error: status === "error",
        }),
        className
      )}
      extra={extra}
      label={label}
      labelClassName={cn(block("label"), labelClassName)}
      labelPosition={labelPosition}
      required={required}
      rowProps={rowProps}
      sideLabelSpan={sideLabelSpan}
    >
      <div className={block("select")}>
        <div className={block("select-row")}>
          {prefixIcon && (
            <div className={block("icon-plate")}>{prefixIcon}</div>
          )}
          <Select
            className={cn(block("select-field"), selectClassName)}
            disabled={disabled || locked}
            placeholder={
              placeholder ??
              configProvider.formatMessage("ui.selectField.placeholder")
            }
            ref={selectorRef}
            size={size}
            showArrow={!locked}
            {...selectProps}
          />
          {locked && (
            <div className={block("lock")}>
              <FontAwesomeIcon icon={faLock} />
            </div>
          )}
        </div>
        {error ? (
          <ErrorRow>{error}</ErrorRow>
        ) : caption ? (
          <div className={block("caption")}>{caption}</div>
        ) : null}
      </div>
    </Labeled>
  );
};

export interface SelectFieldComponent {
  focus: () => void;
  blur(): void;
}

export default forwardRef(SelectField) as <T extends SelectValue = SelectValue>(
  props: SelectFieldProps<T> & { ref?: React.Ref<SelectFieldComponent> }
) => React.ReactElement;
