import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import AntDTextArea, {
  TextAreaProps as AntdTextAreaProps,
} from "antd/es/input/TextArea";
import { TextAreaRef } from "antd/es/input/TextArea";
import ErrorRow from "../ErrorRow";
import { LARGE_TEXT_LENGTH_LIMIT } from "@mapmycustomers/shared/util/consts";
import { bem } from "@react-md/utils";
import cn from "classnames";
import { useConfigProvider } from "../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../Labeled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

export const messages = {
  "ui.input.largeText.textLimitReached": "Character limit reached",
};

export interface TextAreaProps
  extends Omit<AntdTextAreaProps, "onChange" | "suffix" | "rows">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  error?: ReactNode;
  locked?: boolean;
  onChange?: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const block = bem("mmc-large-text-field");

const LargeTextField = forwardRef<TextAreaComponent, TextAreaProps>(
  (
    {
      allowClear,
      caption,
      className,
      disabled,
      error,
      label,
      labelClassName,
      labelPosition,
      locked,
      maxLength = LARGE_TEXT_LENGTH_LIMIT,
      onChange,
      required,
      rowProps,
      sideLabelSpan,
      size = "large",
      value,
      ...props
    },
    ref
  ) => {
    const configProvider = useConfigProvider();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event.target.value, event);
      },
      [onChange]
    );

    const formattedError = useMemo(
      () =>
        (value ?? "").length >= maxLength
          ? configProvider.formatMessage("ui.input.largeText.textLimitReached")
          : error,
      [configProvider, error, maxLength, value]
    );

    const inputRef = useRef<TextAreaRef>(null);
    useImperativeHandle(ref, () => ({
      blur: () => inputRef.current?.blur(),
      focus: () => inputRef.current?.focus(),
    }));

    return (
      <Labeled
        className={cn(block(), className)}
        label={label}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <div className={block("input")}>
          <AntDTextArea
            allowClear={allowClear && !(disabled || locked)}
            className={block("textArea")}
            disabled={disabled || locked}
            maxLength={maxLength}
            onChange={handleChange}
            ref={inputRef}
            required={required}
            size={size}
            value={value}
            {...props}
          />
          {locked && (
            <FontAwesomeIcon className={block("lock")} icon={faLock} />
          )}
          {formattedError ? (
            <ErrorRow>{formattedError}</ErrorRow>
          ) : caption ? (
            <div className={block("caption")}>{caption}</div>
          ) : null}
        </div>
      </Labeled>
    );
  }
);

export interface TextAreaComponent {
  blur(): void;
  focus: () => void;
}

LargeTextField.displayName = "LargeTextField";

export default LargeTextField;
