import Field, { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { listFormatter } from "util/fieldModel/impl/fieldUtil";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { getRegularFieldConditionValue } from "util/viewModel/convertFromPlatformFilterModel";
import GroupsFilter, { GROUPS_FILTER_OPERATORS } from "util/filters/GroupsFilter";

class GroupsField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "groups",
        filterInstances: {
          groups: GroupsFilter,
        },
        operators: [...GROUPS_FILTER_OPERATORS],
      },
      customGridProperties: {
        cellRenderer: "groupsCellRenderer",
      },
      extraPlatformFiltersGetter: () => ({ includeGroups: true }),
      features: [
        FieldFeature.GROUP_FIELD,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.DATA_VIEW,
        FieldFeature.RELATIONSHIPS,
        FieldFeature.HISTORY_FIELD,
      ],
      type: FieldType.LIST,
      valueFormatter: listFormatter("name"),
      ...data,
    });
  }

  get isDefaultValueAllowed() {
    return true;
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    // group field filter is applied as a meta-filter, thus it doesn't have a name
    // so we're adding a field name back for it.
    return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
  }
}

export default GroupsField;
