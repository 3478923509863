import React from "react";
import styles from "./FileUploader.module.scss";
import Col from "antd/es/col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-solid-svg-icons/faPaperclip";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { LoadingSpinner } from "@mapmycustomers/ui";
import Popconfirm from "antd/es/popconfirm";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import Row from "antd/es/row";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { useIntl } from "react-intl";

interface Props {
  className?: string;
  disabled?: boolean;
  errored?: boolean;
  file?: RawFile;
  fileName?: string;
  onClick?: (file: RawFile) => void;
  onRemove?: () => void;
  uploading?: boolean;
}

const FileItem: React.FC<Props> = ({
  className,
  disabled,
  errored,
  file,
  fileName,
  onClick,
  onRemove,
  uploading,
}) => {
  const intl = useIntl();

  return (
    <Row className={className}>
      <Col span={2}>
        <FontAwesomeIcon icon={errored ? faExclamationCircle : faPaperclip} />
      </Col>
      <Col span={20}>
        <li onClick={() => file && onClick?.(file)}>
          {fileName ??
            intl.formatMessage({
              id: "files.fileUploader.emptyFileName",
              defaultMessage: "File",
              description: "Name to display when file item has no given name",
            })}
        </li>
      </Col>
      {onRemove && (
        <Col span={2} className={styles.iconColumn}>
          {uploading ? (
            <LoadingSpinner mini />
          ) : (
            !errored &&
            !disabled && (
              <Popconfirm
                title={intl.formatMessage({
                  id: "files.fileUploader.deletePopConfirm.title",
                  defaultMessage: "Delete file?",
                  description: "Delete popconfirm title",
                })}
                okText={intl.formatMessage({
                  id: "files.fileUploader.deletePopConfirm.okText",
                  defaultMessage: "Yes",
                  description: "Delete popconfirm ok text",
                })}
                cancelText={intl.formatMessage({
                  id: "files.fileUploader.deletePopConfirm.cancelText",
                  defaultMessage: "Cancel",
                  description: "Delete popconfirm cancel text",
                })}
                onConfirm={onRemove}
              >
                <FontAwesomeIcon className={styles.deleteIcon} icon={faTrash} />
              </Popconfirm>
            )
          )}
        </Col>
      )}
    </Row>
  );
};

export default FileItem;
