import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { Editor } from "slate";
import insertImage from "./insertImage";
import FileUploadCallback from "../type/FileUploadCallback";

export const insertImageFiles = (
  editor: Editor,
  onLoadFile: FileUploadCallback,
  files?: FileList
): boolean => {
  if (files && files.length > 0) {
    for (const file of files) {
      const [mime] = file.type.split("/");
      if (mime === "image") {
        onLoadFile?.(file, (url: string) => {
          insertImage(editor, url.toString());
        });
      }
    }
    return true;
  } else {
    return false;
  }
};

const withImages = (editor: Editor, onLoadFile: FileUploadCallback) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === ElementType.IMAGE ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const { files } = data;
    if (!insertImageFiles(editor, onLoadFile, files)) {
      insertData(data);
    }
  };

  return editor;
};

export default withImages;
