import React from "react";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { useIntl } from "react-intl";
import EmailRecipients from "../../../../email/parts/EmailRecipients";
import EmailSubject from "../../../../email/parts/EmailSubject";
import EmailBody from "../../../../email/parts/EmailBody";
import Collapse, { CollapsePanel } from "../../../../Collapse/ModernCollapse/ModernCollapse";
import Row from "antd/es/row";
import Col from "antd/es/col";
import EmailMetrics from "../../../../email/parts/EmailMetrics";
import styles from "./EmailSection.module.scss";

interface Props {
  activity: Activity;
}

const EmailSection: React.FC<Props> = ({ activity }) => {
  const intl = useIntl();

  if (!activity.emailLog) {
    return null;
  }

  return (
    <Collapse accordion>
      <CollapsePanel
        className={styles.collapsePanel}
        key="email"
        header={
          <Row className={styles.collapseHeader} justify="space-between">
            <Col className={styles.title}>
              {intl.formatMessage({
                id: "component.activityAnnotation.emailSection.title",
                defaultMessage: "Email",
                description: "Email section title",
              })}
            </Col>
            <Col>
              <EmailMetrics email={activity.emailLog} />
            </Col>
          </Row>
        }
      >
        <EmailRecipients recipients={activity.emailLog.recipientAddress} />
        <EmailSubject subject={activity.emailLog.subject} />
        <EmailBody body={activity.emailLog.body} />
      </CollapsePanel>
    </Collapse>
  );
};

export default EmailSection;
