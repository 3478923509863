import { isDefined } from "@mapmycustomers/shared/util/assert";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { CombinedCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { isSimpleCondition } from "../assert";
import { getRegularFieldConditionValue } from "../convertFromPlatformFilterModel";

// implement handing legacy filters which could have two or more filterOperators per field
// there are some other special cases with more than two filters, but those are covered
// separately (see range condition or area condition parsers)
const parseLegacyCondition = (field: IField, platformCondition: Condition): CombinedCondition => {
  const filterOperators = Object.keys(platformCondition) as PlatformFilterOperator[];
  return {
    operator: "AND",
    conditions: filterOperators
      .map((operator) =>
        getRegularFieldConditionValue(field, {
          [field.platformFilterName]: { [operator]: platformCondition[operator] },
        })
      )
      .filter(isDefined)
      .filter(isSimpleCondition), // we're pretty sure all conditions will be simple
  };
};

export default parseLegacyCondition;
