import {
  Actions,
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  createDeal,
  hideGlobalCreateDealModal,
  initialize,
  relateEntities,
  removeDealFile,
  showGlobalCreateDealModal,
  uploadDealFiles,
} from "./actions";
import { createReducer } from "typesafe-actions";
import FileListItem from "types/FileListItem";
import AssociationsState from "store/associations/AssociationsState";
import { DEFAULT_ASSOCIATIONS_STATE } from "store/associations/const";

export interface CreateDealModalState {
  associations: AssociationsState;
  createError: Record<string, string> | undefined;
  createLoading: boolean;
  entityRelating: boolean;
  filesAdded: FileListItem[];
  fileGroupId: string;
  filesDeleting: boolean;
  filesUploading: boolean;
  initializeLoading: boolean;
  isGlobalAddDeal?: boolean;
  isWarningDismissed: boolean;
  visible: boolean;
}

const initialState: CreateDealModalState = {
  associations: DEFAULT_ASSOCIATIONS_STATE,
  createError: undefined,
  createLoading: false,
  entityRelating: false,
  filesAdded: [],
  fileGroupId: "",
  filesDeleting: false,
  filesUploading: false,
  initializeLoading: false,
  isWarningDismissed: false,
  visible: false,
};

const createDealModal = createReducer<CreateDealModalState, Actions>(initialState)
  .handleAction(showGlobalCreateDealModal, (state, action) => ({
    ...state,
    isGlobalAddDeal: action.payload?.showPostCreatePrompt,
    visible: true,
  }))
  .handleAction(hideGlobalCreateDealModal, (state) => ({
    ...state,
    isGlobalAddDeal: false,
    visible: false,
  }))
  .handleAction(initialize.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.fixedCompany,
      associatedPerson: payload.fixedPerson,
    },
    filesAdded: [],
    initializeError: undefined,
    initializeLoading: true,
  }))
  .handleAction(initialize.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload.associationsState },
    fileGroupId: payload.fileGroupId,
    initializeLoading: false,
  }))
  .handleAction(initialize.failure, (state) => ({
    ...state,
    initializeLoading: false,
  }))
  .handleAction(createDeal.request, (state) => ({
    ...state,
    createError: undefined,
    createLoading: true,
  }))
  .handleAction(createDeal.success, (state, action) => ({
    ...state,
    ...action.payload,
    createLoading: false,
  }))
  .handleAction(createDeal.failure, (state, action) => ({
    ...state,
    createError: action.payload,
    createLoading: false,
  }))
  .handleAction(changeAssociatedEntities.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.company,
      associatedPerson: payload.person,
      availableLoading: true,
    },
  }))
  .handleAction(changeAssociatedEntities.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload, availableLoading: false },
  }))
  .handleAction(changeAssociatedEntities.failure, (state) => ({
    ...state,
    associations: { ...state.associations, availableLoading: false },
  }))
  .handleAction(uploadDealFiles.request, (state, action) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadDealFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadDealFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removeDealFile.request, (state, action) => ({
    ...state,
    filesDeleting: true,
  }))
  .handleAction(removeDealFile.success, (state, action) => ({
    ...state,
    filesDeleting: false,
    //Removing the deleted file from store
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
  }))
  .handleAction(removeDealFile.failure, (state) => ({
    ...state,
    filesDeleting: false,
  }))
  .handleAction(clearAllUploadedDealFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(relateEntities.request, (state, action) => ({
    ...state,
    entityRelating: true,
  }))
  .handleAction(relateEntities.success, (state, { payload: { person } }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedPerson: person ?? state.associations.associatedPerson,
    },
    entityRelating: false,
  }))
  .handleAction(relateEntities.failure, (state) => ({
    ...state,
    entityRelating: false,
  }));

export * from "./selectors";
export type CreateDealModalActions = Actions;

export default createDealModal;
