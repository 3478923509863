import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./NextActivities.module.scss";
import FrequencyBox from "../../../../FrequencyBox";
import NextActivityState from "../../../../NextActivityState";
import getFrequencySequence from "../../../../util/getFrequencySequence";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { isNotEmpty } from "@mapmycustomers/shared/util/assert";
import FrequencyCriteria from "types/frequncy/FrequencyCriteria";
import { addDays, isSaturday, isWeekend, startOfToday } from "date-fns/esm";
import { formatDate, formatFriendlyRelativeTime } from "util/formatters";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import ButtonLink from "component/ButtonLink";
import { getFrequencyModalConfig, getLoggedActivities } from "../../../../../../store/frequency";
import LoggedActivityMap from "../../../../type/LoggedActivityMap";
import { fetchLoggedActivities, updateEntityFrequency } from "store/frequency/actions";
import FrequencyCollapsePanel from "../../../../FrequencyCollapsePanel";
import { Col, Row } from "antd/es/grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import layout from "styles/layout";
import getActivityDayKey from "../../../../util/getActivityDayKey";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import Tooltip from "antd/es/tooltip";
import FrequencyPreviewConfig from "../../../../type/FrequencyPreviewConfig";
import FrequencySelectorWrapper from "../../../../FrequencyPanel/component/FrequencySelectorWithConfig";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import AnalyticsContext from "util/contexts/AnalyticsContext";
import EntityTypes from "./EntityTypes";
import ActivityActions from "../../../../ActivityActions";
import ActivityScheduled from "../../../../ActivityScheduled";
import useAnalytics from "util/contexts/useAnalytics";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";

const ACTIVITIES_PORTION_SIZE = 3;

interface Props {
  config: FrequencyPreviewConfig;
  loggedActivities: LoggedActivityMap;
  onFetchLoggedActivities: typeof fetchLoggedActivities.request;
  updateEntityFrequency: typeof updateEntityFrequency.request;
}

const NextActivities: React.FC<Props> = ({
  config,
  loggedActivities,
  onFetchLoggedActivities,
  updateEntityFrequency,
}) => {
  const intl = useIntl();
  const [count, setCount] = useState<number>(ACTIVITIES_PORTION_SIZE - 1);
  const analytics = useExtendedAnalytics("To Do", useAnalytics());

  const { isEntityFrequencied, isSomeGroupFrequencied, entity, groups } = config;

  const suggestions: FrequencyCriteria[] = useMemo(() => {
    const frequencies = getFrequencySequence(entity, groups)
      .map((frequency) =>
        Array.from({ length: count }, (_, i) => ({
          ...frequency,
          cadenceInterval: frequency.cadenceInterval * (i + 1),
        }))
      )
      .flat()
      .sort((frequency1, frequency2) => frequency1.cadenceInterval - frequency2.cadenceInterval);
    const daysMap = new Map<number, ActivityType["id"][] | "all">();

    frequencies.forEach((frequency) => {
      const dayRecord = daysMap.get(frequency.cadenceInterval);
      if (dayRecord !== "all") {
        if (isNotEmpty(frequency.crmActivityTypeId)) {
          daysMap.set(
            frequency.cadenceInterval,
            Array.from(new Set([...frequency.crmActivityTypeId, ...(dayRecord ?? [])]))
          );
        } else {
          daysMap.set(frequency.cadenceInterval, "all");
        }
      }
    });

    return Array.from(daysMap)
      .slice(0, count)
      .map(([daysCount, types]) => {
        let dueDate = addDays(startOfToday(), daysCount);
        let days = daysCount;
        let dueDateReal;
        if (isWeekend(dueDate)) {
          dueDateReal = dueDate;
          const isDateSaturday = isSaturday(dueDate);
          dueDate = addDays(dueDate, isDateSaturday ? -1 : -2);
          days += isDateSaturday ? -1 : -2;
        }
        return {
          activityTypesIds: types === "all" ? undefined : types,
          dueDate,
          dueDateReal,
          days,
        };
      });
  }, [count, entity, groups]);

  const getDates = useCallback(
    (count: number) =>
      (ACTIVITIES_PORTION_SIZE < count
        ? suggestions.slice(count - ACTIVITIES_PORTION_SIZE, count)
        : suggestions
      ).map(({ dueDate }) => dueDate),
    [suggestions]
  );

  useEffect(() => {
    const dates = getDates(count);
    if (dates.length) {
      onFetchLoggedActivities({ dates });
    }
  }, [count, getDates, onFetchLoggedActivities]);

  const handleShowMore = useCallback(() => {
    setCount((count) => count + ACTIVITIES_PORTION_SIZE);
    analytics.clicked(["Show More Suggested Activities"]);
  }, [analytics, setCount]);

  const isSomeActivityScheduled = useCallback(
    (date: Date, types: ActivityType["id"][] | undefined) => {
      const record = loggedActivities[getActivityDayKey(date.toISOString())];
      return !!record && (types?.length ? types.some((type) => record.has(type)) : true);
    },
    [loggedActivities]
  );

  const handleUpdateFrequency = useCallback(
    (cadence: Partial<Cadenced>) => {
      if (entity) {
        updateEntityFrequency({
          entity: {
            ...entity,
            ...cadence,
          },
        });
      }
    },
    [entity, updateEntityFrequency]
  );

  const handleOpenPanel = useCallback(() => {
    analytics.clicked([]);
  }, [analytics]);

  return (
    <div className={styles.container}>
      <AnalyticsContext.Provider value={analytics}>
        {!isEntityFrequencied && !isSomeGroupFrequencied ? (
          <FrequencyBox className={styles.noFrequencyContainer}>
            <div className={styles.noFrequency}>
              {intl.formatMessage({
                id: "frequency.frequencyModal.activities.next.noFrequency",
                defaultMessage: "Set a Frequency",
                description:
                  "frequency component - frequency modal - next activities - Set a Frequency",
              })}
            </div>
            <FrequencySelectorWrapper onChange={handleUpdateFrequency} />
          </FrequencyBox>
        ) : (
          <FrequencyCollapsePanel
            header={intl.formatMessage({
              id: "frequency.frequencyModal.activities.next.todo",
              defaultMessage: "to do",
              description: "frequency component - frequency modal - next activities - to do",
            })}
            isOpenByDefault
            onOpen={handleOpenPanel}
          >
            <FrequencyBox className={styles.frequencyBox}>
              <NextActivityState config={config} />
              <div className={styles.frequencyBoxTypes}>
                <EntityTypes activityTypeIds={config.actualFrequencyCriteria?.activityTypesIds} />
              </div>
            </FrequencyBox>
            <div className={styles.nextUp}>
              {intl.formatMessage({
                id: "frequency.frequencyModal.activities.next.textUp",
                defaultMessage: "next up",
                description: "frequency component - frequency modal - next activities - next up ",
              })}
            </div>
            <div className={styles.suggestions}>
              {suggestions.map((criteria, i) => (
                <div className={styles.suggestion} key={`suggestion-${criteria.dueDate.valueOf()}`}>
                  <div className={styles.suggestionTitle}>
                    <Row align="middle" gutter={layout.spacerS}>
                      <Col>
                        <span>
                          {intl.formatMessage(
                            {
                              id: "frequency.frequencyModal.activities.next.suggestion.title",
                              defaultMessage:
                                "Due in {count} {count, plural, one {Day} other {Days}}",
                              description:
                                "frequency component - frequency modal - next activities - suggestion title",
                            },
                            { count: criteria.days }
                          )}
                        </span>
                      </Col>
                      <Col>
                        <span className={styles.suggestionDate}>
                          <span>{formatDate(criteria.dueDate, "PPPP")}</span>
                          {criteria.dueDateReal && (
                            <Tooltip
                              title={intl.formatMessage(
                                {
                                  id: "frequency.frequencyModal.activities.next.suggestion.changedTooltip",
                                  defaultMessage:
                                    "The original deadline for this activity was set for {date}. However, we have adjusted the deadline to a weekday to ensure the record remains current.",
                                  description:
                                    "frequency component - frequency modal - next activities - suggestion changed tooltip",
                                },
                                {
                                  date: formatFriendlyRelativeTime(
                                    intl,
                                    criteria.dueDateReal.toISOString()
                                  ),
                                }
                              )}
                            >
                              <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
                            </Tooltip>
                          )}
                        </span>
                      </Col>
                    </Row>
                    <div>
                      {isSomeActivityScheduled(criteria.dueDate, criteria.activityTypesIds) ? (
                        <ActivityScheduled />
                      ) : (
                        entity.cadenceStatus === FrequencyStatus.UP_TO_DATE && (
                          <ActivityActions entity={entity} criteria={criteria} />
                        )
                      )}
                    </div>
                  </div>
                  <EntityTypes activityTypeIds={criteria.activityTypesIds} />
                </div>
              ))}
            </div>
            <ButtonLink className={styles.showMore} onClick={handleShowMore}>
              {intl.formatMessage({
                id: "frequency.frequencyModal.activities.next.suggestion.showMore",
                defaultMessage: "Show more suggested activities",
                description:
                  "frequency component - frequency modal - next activities - Show more suggested activities",
              })}
            </ButtonLink>
          </FrequencyCollapsePanel>
        )}
      </AnalyticsContext.Provider>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyModalConfig(state),
  loggedActivities: getLoggedActivities(state),
});

const mapDispatchToProps = {
  onFetchLoggedActivities: fetchLoggedActivities.request,
  updateEntityFrequency: updateEntityFrequency.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextActivities);
