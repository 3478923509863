import React from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { isEmailCreationModalVisible } from "store/email";
import EmailCreationModal from "./Modal";
import { hideEmailCreationModal } from "store/email/actions";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import AnalyticsContext from "util/contexts/AnalyticsContext";

interface Props {
  modalVisible: boolean;
  onHideEmailCreationModal: typeof hideEmailCreationModal;
}

const EmailCreation: React.FC<Props> = ({ modalVisible, onHideEmailCreationModal }) => {
  const emailCreationAnalytics = useExtendedAnalytics(["Bulk Email Modal"]);
  return (
    <AnalyticsContext.Provider value={emailCreationAnalytics}>
      <div>{modalVisible && <EmailCreationModal onHide={onHideEmailCreationModal} />}</div>
    </AnalyticsContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  modalVisible: isEmailCreationModalVisible(state),
});

const mapDispatchToProps = {
  onHideEmailCreationModal: hideEmailCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCreation);
