import React from "react";
import { useIntl } from "react-intl";
import { Modal } from "@mapmycustomers/ui";
import { Col } from "antd/es/grid";
import Row from "antd/es/row";
import point1 from "assets/email/info-modal-point1.svg";
import point2 from "assets/email/info-modal-point2.svg";
import point3 from "assets/email/info-modal-point3.svg";
import point4 from "assets/email/info-modal-point4.svg";
import styles from "./EmailFeaturesModal.module.scss";
import { connect } from "react-redux";
import TotalSendLeft from "../TotalSendLeft";
import messages from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import ButtonLink from "../../ButtonLink";
import { hideEmailFeatures, showEmailQueue } from "store/email/actions";
import { RootState } from "store/rootReducer";
import { isEmailFeaturesVisible } from "store/email";

interface Props {
  onHideEmailFeatures: typeof hideEmailFeatures;
  onShowEmailQueue: typeof showEmailQueue;
  visible: boolean;
}

const EmailFeaturesModal: React.FC<Props> = ({
  onHideEmailFeatures,
  onShowEmailQueue,
  visible,
}) => {
  const intl = useIntl();
  return (
    <Modal
      cancelButtonProps={{ hidden: true }}
      className={styles.container}
      okText={intl.formatMessage(messages.featuresOk)}
      onCancel={() => onHideEmailFeatures()}
      onOk={() => onHideEmailFeatures()}
      open={visible}
      title={intl.formatMessage(messages.featuresTitle)}
      wrapClassName={styles.modalWrapper}
    >
      <Row>
        <Col span={2}>
          <img alt="point-1" src={point1} />
        </Col>
        <Col span={21}>
          <div className={styles.point}>{intl.formatMessage(messages.featuresPoint1Title)}</div>
          <div className={styles.description}>
            {intl.formatMessage(messages.featuresPoint1Description)}
          </div>
          <TotalSendLeft />
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <img alt="point-2" src={point2} />
        </Col>
        <Col span={21}>
          <div className={styles.point}>{intl.formatMessage(messages.featuresPoint2Title)}</div>
          <div className={styles.description}>
            {intl.formatMessage(messages.featuresPoint2Description)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <img alt="point-3" src={point3} />
        </Col>
        <Col span={21}>
          <div className={styles.point}>{intl.formatMessage(messages.featuresPoint3Title)}</div>
          <div className={styles.description}>
            {intl.formatMessage(messages.featuresPoint3Description)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <img alt="point-3" src={point4} />
        </Col>
        <Col span={21}>
          <div className={styles.point}>{intl.formatMessage(messages.featuresPoint4Title)}</div>
          <div className={styles.description}>
            {intl.formatMessage(messages.featuresPoint4Description)}
          </div>
          <div className={styles.description}>
            {intl.formatMessage(messages.featuresPoint4Description2)}
          </div>
          <ButtonLink className={styles.link} onClick={() => onShowEmailQueue()}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
            {intl.formatMessage(messages.openEmailQueue)}
          </ButtonLink>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: isEmailFeaturesVisible(state),
});

const mapDispatchToProps = {
  onHideEmailFeatures: hideEmailFeatures,
  onShowEmailQueue: showEmailQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailFeaturesModal);
