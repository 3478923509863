:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        overflow: hidden;
      }

      .flag {
        line-height: 1rem;
      }
    }
  }
}
