import React, { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import styles from "./DataCard.module.scss";
import { userDisplayName } from "util/formatters";
import Scope from "types/dashboard/Scope";
import { RootState } from "store/rootReducer";
import { getTeams, getUsers } from "store/members";
import { connect } from "react-redux";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/pro-solid-svg-icons";
import { fetchCardData, updateCards } from "../store/actions";
import { LoadingSpinner } from "@mapmycustomers/ui";
import Tooltip from "antd/es/tooltip";
import Popconfirm from "antd/es/popconfirm";
import { UserPreviewCard } from "types/dashboard/DashboardCard";
import getChartTitle from "../util/getChartTitle";
import { CardData } from "../store/saga";
import { isManager, isOwner } from "store/iam/util";
import Trend from "component/Trend";

const messages = defineMessages({
  subtitle: {
    id: "userPreviewCard.dataCard.subtitle",
    defaultMessage:
      "{entireOrg, select, true {All Users} other {{name}}}{showRank, select, true { • {rank, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} in {teamName}} other {}}",
    description: "Subtitle of the card",
  },
  organization: {
    id: "userPreviewCard.dataCard.subtitle.organization",
    defaultMessage: "Organization",
    description: "Organization title for the case when ranking a team for Subtitle of the card",
  },
  unknown: {
    id: "userPreviewCard.dataCard.subtitle.unknown",
    defaultMessage: "Unknown",
    description:
      "Unknown title for the case when ranking a team for Subtitle of the Activities Logged card",
  },
  removeMetric: {
    id: "userPreviewCard.dataCard.removeMetric",
    defaultMessage: "Remove Metric",
    description: "Remove Metric title",
  },
  cancelText: {
    id: "userPreviewCard.dataCard.cancelText",
    defaultMessage: "Cancel",
    description: "Remove Metric tooltip title",
  },
  removeMetricTitle: {
    id: "userPreviewCard.dataCard.removeMetric.title",
    defaultMessage: "Remove this metric for all Team Members in “{teamName}”?",
    description: "Remove Metric popconfirm title",
  },
});

interface Props {
  allowRemove?: boolean;
  card: UserPreviewCard;
  index?: number;
  onFetchData: typeof fetchCardData.request;
  onUpdateCards: typeof updateCards.request;
  scope: Scope;
  teams: Team[];
  users: User[];
}

const DataCard: React.FC<Props> = ({
  allowRemove = true,
  card,
  index,
  onFetchData,
  onUpdateCards,
  scope,
  teams,
  users,
}) => {
  const intl = useIntl();
  const [data, setData] = useState<CardData | undefined>();
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { teamId, userId } = scope;

  const user = useMemo(() => users.find(({ id }) => id === userId), [userId, users]);
  const team = useMemo(() => teams.find(({ id }) => id === teamId), [teamId, teams]);
  const isOrgScope = user && isOwner(user);
  const isTeamScope = user && isManager(user);
  const unknown = <i>{intl.formatMessage(messages.unknown)}</i>;

  const trend =
    data && data.previousCount ? (data.count - data.previousCount) / data.previousCount : undefined;

  const handleRemove = useCallback(() => {
    if (index !== undefined) {
      onUpdateCards({ scope, cardIds: { [index]: undefined } });
    }
  }, [index, onUpdateCards, scope]);

  const handleCancel = useCallback(() => setShowPopconfirm(false), []);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!!teamId) {
        setShowPopconfirm(open);
      }
      if (open) {
        setShowTooltip(false);
      }
    },
    [teamId]
  );

  const handleTooltipOpen = useCallback((open: boolean) => setShowTooltip(open), []);

  useEffect(() => {
    onFetchData({
      callback: setData,
      scope: {
        teamId: user && !isOwner(user) ? teamId : undefined,
        userId: user && !isManager(user) && !isOwner(user) ? userId : undefined,
      },
      card,
    });
  }, [card, onFetchData, teamId, user, userId]);

  useEffect(() => {
    setData(undefined);
  }, [card.id]);

  return !data ? (
    <LoadingSpinner />
  ) : (
    <div className={styles.container}>
      {allowRemove && (
        <Popconfirm
          cancelText={intl.formatMessage(messages.cancelText)}
          getPopupContainer={(trigger: HTMLElement) => trigger.parentElement?.parentElement!}
          okText={intl.formatMessage(messages.removeMetric)}
          onCancel={handleCancel}
          onConfirm={handleRemove}
          onOpenChange={handleOpenChange}
          open={showPopconfirm}
          overlayClassName={styles.popconfirm}
          title={intl.formatMessage(messages.removeMetricTitle, { teamName: team?.name })}
          trigger="click"
        >
          <Tooltip
            title={intl.formatMessage(messages.removeMetric)}
            onOpenChange={handleTooltipOpen}
            open={showTooltip}
          >
            <FontAwesomeIcon
              className={styles.removeIcon}
              icon={faCircleMinus}
              onClick={!teamId ? handleRemove : undefined}
              size="lg"
            />
          </Tooltip>
        </Popconfirm>
      )}
      <div className={styles.titleContainer}>
        <div className={styles.title}>{getChartTitle(intl, card)}</div>
        <div className={styles.subtitle}>
          {intl.formatMessage(messages.subtitle, {
            entireOrg: isOrgScope,
            name: (isTeamScope ? team?.name : user ? userDisplayName(user) : undefined) ?? unknown,
            showRank: !isOrgScope && isDefined(data.rank),
            rank: data.rank,
            teamName: isTeamScope
              ? intl.formatMessage(messages.organization)
              : team?.name ?? unknown,
          })}
        </div>
      </div>
      <div className={styles.content}>
        <span className={styles.count}>
          {intl.formatNumber(data.count, {
            notation: data.count >= 10000 ? "compact" : "standard",
            maximumFractionDigits: 2,
          })}
        </span>
        <Trend trend={trend} />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  onFetchData: fetchCardData.request,
  onUpdateCards: updateCards.request,
};

const mapStateToProps = (state: RootState) => ({
  teams: getTeams(state),
  users: getUsers(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataCard);
