import React, { useCallback, useEffect, useState } from "react";
import AddressFormItem from "component/createEditEntity/AddressFormItem";
import { useAddressFieldValidationRules } from "util/validation/address-validation";
import { useIntl } from "react-intl";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { Company } from "@mapmycustomers/shared/types/entity";
import styles from "./AddressField.module.scss";
import ButtonLink from "component/ButtonLink";
import Form from "antd/es/form";
import { inheritAddressFromCompany } from "../utils/inheritAddressFromCompany";

interface Props {
  disabled?: boolean;
  inheritAddressFrom?: Company;
  required?: boolean;
}

const AddressField: React.FC<Props> = ({ disabled, inheritAddressFrom, required }) => {
  const intl = useIntl();
  const form = useFormInstance();
  const [autoInheritedAddress, setAutoInheritedAddress] = useState<boolean>(false);

  const addressFieldValidation = useAddressFieldValidationRules(intl, form);

  useEffect(() => {
    if (inheritAddressFrom) {
      inheritAddressFromCompany(form, inheritAddressFrom);
      setAutoInheritedAddress(true);
    }
  }, [form, inheritAddressFrom]);

  const handleClearClick = useCallback(() => {
    form.setFieldsValue({
      addressDetails: undefined,
      longitude: undefined,
      latitude: undefined,
    });
    setAutoInheritedAddress(false);
  }, [form]);

  const handleInheritClick = useCallback(() => {
    if (inheritAddressFrom) {
      inheritAddressFromCompany(form, inheritAddressFrom);
      setAutoInheritedAddress(true);
    }
  }, [form, inheritAddressFrom]);

  return (
    <>
      <AddressFormItem
        disabled={disabled}
        required={required}
        rules={required ? addressFieldValidation : undefined}
      />
      <Form.Item dependencies={["accounts"]} noStyle>
        {() => {
          if (!inheritAddressFrom?.geoPoint || disabled) {
            return null;
          }
          return (
            <div className={styles.inheritAddress}>
              {autoInheritedAddress ? (
                <div className={styles.autoFilledAddressAlert}>
                  {intl.formatMessage(
                    {
                      id: "formFields.addressField.autoInheritedAddress",
                      defaultMessage: "Location auto-filled from {companyName}",
                      description: "inherited location by default",
                    },
                    { companyName: inheritAddressFrom.name }
                  )}
                  <ButtonLink onClick={handleClearClick}>
                    {intl.formatMessage({
                      id: "formFields.addressField.clearAddress",
                      defaultMessage: "Clear",
                      description: "clear address from address field",
                    })}
                  </ButtonLink>
                </div>
              ) : (
                <div className={styles.autoFill}>
                  <ButtonLink onClick={handleInheritClick}>
                    {intl.formatMessage(
                      {
                        id: "formFields.addressField.inheritFromCompany",
                        defaultMessage: "Auto-fill address from {companyName}",
                        description:
                          "Inherit location from Company button text on the Create Person modal",
                      },
                      { companyName: inheritAddressFrom.name }
                    )}
                  </ButtonLink>
                </div>
              )}
            </div>
          );
        }}
      </Form.Item>
    </>
  );
};

export default AddressField;
