import ApiError from "@mapmycustomers/shared/util/api/ApiError";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { Activity, Deal, EntityType } from "@mapmycustomers/shared/types/entity";
import Statistics from "../../../types/Statistics";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import DailyChangeData from "scene/reports/components/Activity/components/DailyChange/DailyChangeData";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import OpenDealsCardData from "../types/OpenDealsCardData";
import RecordsOutOfCadenceFilter from "../types/RecordsOutOfCadenceFilter";
import RecordsSourceFilter from "../types/RecordsSourceFilter";
import { GroupByType } from "scene/reports/components/Overview/components/Engagement/enum/GroupByType";
import { DisplayType } from "../enum/DisplayType";
import { DealsRottingFilter } from "../types/DealsRottingFilter";
import { createInitialDealsRotting } from "../util/createInitialDealsRotting";
import { DealsGroupByType } from "../enum/DealsGroupByType";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import SourceType from "enum/SourceType";
import SourceSummary from "types/source/SourceSummary";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import { RecordsStats } from "../types/RecordsStats";
import { MapEntity } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";
import { ActivityCount } from "scene/home/component/Cards/util";
import { SupportingGroupsRecordStats } from "../types/RecordsOutOfCadence";
import ActivityTypeWithCount from "@mapmycustomers/shared/types/entity/activities/ActivityTypeWithCount";
import { DealsGroupBy } from "../types/DealGroupBy";

export type HomeState = {
  activityByRepData: Record<User["id"], ActivityCount[]>;
  activityByRepDataError: ApiError | undefined;
  activityByRepDataLoading: boolean;
  actitivityTypesWithCount: ActivityTypeWithCount[];
  activityByTypesDataError: ApiError | undefined;
  activityByTypesDataLoading: boolean;
  activityOverview: Statistics["values"];
  activityOverviewError: ApiError | undefined;
  activityOverviewLoading: boolean;
  activityTypes: ActivityType[];
  dailyChangeData: DailyChangeData;
  dailyChangeDataError: ApiError | undefined;
  dailyChangeDataLoading: boolean;
  dealsCount: number;
  dealsRotting: Record<DealsGroupBy["id"], RecordsStats>;
  dealsRottingError: ApiError | undefined;
  dealsRottingFilter: DealsRottingFilter;
  dealsRottingLoading: boolean;
  dealsRottingStagesStats: Record<Funnel["id"], Record<Stage["name"], RecordsStats>>;
  engagementRecordsList: MapEntity[];
  engagementRecordsLoading: boolean;
  error: ApiError | undefined;
  funnels: Funnel[];
  funnelStages: Stage[];
  funnelSummary: StageSummary[];
  funnelSummaryError: ApiError | undefined;
  funnelSummaryLoading: boolean;
  hasActivities: boolean;
  hasCompanies: boolean;
  loading: boolean;
  openDealsData: OpenDealsCardData;
  openDealsDataError: ApiError | undefined;
  openDealsDataLoading: boolean;
  recentCompaniesData: Company[];
  recentCompaniesDataError: ApiError | undefined;
  recentCompaniesDataLoading: boolean;
  recordsBySourceList: MapEntity[];
  recordsBySourceListLoading: boolean;
  recordsBySourceSummary: SourceSummary[];
  recordsOutOfCadence: SupportingGroupsRecordStats;
  recordsOutOfCadenceError: ApiError | undefined;
  recordsOutOfCadenceFilter: RecordsOutOfCadenceFilter;
  recordsOutOfCadenceLoading: boolean;
  recordsSourceError: ApiError | undefined;
  recordsSourceFilter: RecordsSourceFilter;
  recordsSourceLoading: boolean;
  rottingDealsList: Deal[];
  rottingDealsLoading: boolean;
  rottingDealsTotal: number;
  selectedTypeData: Activity[];
  todayActivitiesData: Activity[];
  todayActivitiesDataError: ApiError | undefined;
  todayActivitiesDataForReps: Activity[];
  todayActivitiesDataForRepsError: ApiError | undefined;
  todayActivitiesDataForRepsLoading: boolean;
  todayActivitiesDataLoading: boolean;
  totalRecordsByActivityType: number;
  totalRecordsByEngagementList: number;
  totalRecordsBySourceList: number;
};

export const initialState: HomeState = {
  activityByRepData: [],
  activityByRepDataError: undefined,
  activityByRepDataLoading: false,
  actitivityTypesWithCount: [],
  activityByTypesDataError: undefined,
  activityByTypesDataLoading: false,
  activityOverview: {},
  activityOverviewError: undefined,
  activityOverviewLoading: false,
  activityTypes: [],
  dailyChangeData: {},
  dailyChangeDataLoading: false,
  dailyChangeDataError: undefined,
  dealsCount: 0,
  dealsRotting: createInitialDealsRotting(),
  dealsRottingError: undefined,
  dealsRottingLoading: false,
  dealsRottingFilter: {
    groupByType: DealsGroupByType.FUNNEL,
    displayType: DisplayType.PERCENT,
  },
  dealsRottingStagesStats: {},
  engagementRecordsList: [],
  engagementRecordsLoading: false,
  error: undefined,
  funnels: [],
  funnelStages: [],
  funnelSummary: [],
  funnelSummaryLoading: false,
  funnelSummaryError: undefined,
  hasActivities: false,
  hasCompanies: false,
  loading: false,
  openDealsData: {
    deals: [],
  },
  openDealsDataError: undefined,
  openDealsDataLoading: false,
  recentCompaniesData: [],
  recentCompaniesDataError: undefined,
  recentCompaniesDataLoading: false,
  recordsBySourceListLoading: false,
  recordsBySourceList: [],
  recordsOutOfCadence: {},
  recordsOutOfCadenceError: undefined,
  recordsOutOfCadenceLoading: false,
  recordsOutOfCadenceFilter: {
    groupByType: GroupByType.USER,
    recordTypes: [EntityType.COMPANY, EntityType.DEAL, EntityType.PERSON],
    displayType: DisplayType.PERCENT,
  },
  recordsBySourceSummary: [],
  recordsSourceError: undefined,
  recordsSourceFilter: {
    displayType: DisplayType.GROSS,
    recordTypes: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL],
    sourceTypes: [
      SourceType.PUBLIC_API,
      SourceType.WEB,
      SourceType.MOBILE,
      SourceType.IMPORTS,
      SourceType.INTEGRATIONS,
      SourceType.LEAD_GENERATION,
    ],
  },
  recordsSourceLoading: false,
  rottingDealsList: [],
  rottingDealsLoading: false,
  rottingDealsTotal: 0,
  selectedTypeData: [],
  todayActivitiesData: [],
  todayActivitiesDataError: undefined,
  todayActivitiesDataForReps: [],
  todayActivitiesDataForRepsError: undefined,
  todayActivitiesDataForRepsLoading: false,
  todayActivitiesDataLoading: false,
  totalRecordsByActivityType: 0,
  totalRecordsByEngagementList: 0,
  totalRecordsBySourceList: 0,
};
