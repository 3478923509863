import React, { useCallback } from "react";
import cn from "classnames";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import ActivityCompleted from "../ActivityCompleted";
import ActivityTime from "../ActivityTime";
import ActivityDate from "../ActivityDate";
import ActivityEndDate from "../ActivityEndDate";
import ActivityRepeat from "../ActivityRepeat";
import FormValues from "../../types/FormValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import Input from "antd/es/input";
import styles from "./ActivityFooter.module.scss";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { useIntl } from "react-intl";
import isEmailActivity from "../../../../../util/email/isEmailActivity";

interface Props {
  activity?: Activity;
  creating?: boolean;
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const ActivityFooter: React.FC<Props> = ({ activity, creating, form, onChange }) => {
  const intl = useIntl();

  const handleChange = useCallback(() => {
    onChange?.();
  }, [onChange]);

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const endDateValue = form.getFieldValue(ActivityFieldName.END_AT);
  const isValidDate = dateValue && Date.parse(dateValue.toString());
  const isValidEndDate = endDateValue && Date.parse(endDateValue.toString());

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={cn(styles.icon, styles.top)}>
          <FontAwesomeIcon icon={faClock} />
        </div>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.startAt !== curValues.startAt || prevValues.endAt !== curValues.endAt
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const dateValue = getFieldValue(ActivityFieldName.START_AT);
            const isValidDate = dateValue && Date.parse(dateValue.toString());
            const disabledTooltip = isEmailActivity(activity)
              ? intl.formatMessage({
                  id: "createActivityModal.footer.scheduleActivity.disabledTooltip",
                  defaultMessage:
                    "Auto Email time can't be changed because they are logged once an email is successfully sent.",
                  description: "Email activities cannot be scheduled",
                })
              : undefined;
            return (
              <div className={styles.group}>
                <ActivityDate
                  disabledTooltip={disabledTooltip}
                  form={form}
                  onChange={handleChange}
                />
                {isValidDate && (
                  <>
                    <ActivityTime
                      disabledTooltip={disabledTooltip}
                      form={form}
                      onChange={handleChange}
                    />
                    {isValidEndDate && (
                      <ActivityEndDate
                        disabledTooltip={disabledTooltip}
                        form={form}
                        onChange={handleChange}
                      />
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Form.Item>
      </div>

      {isValidDate && (
        <ActivityRepeat
          disableText={
            isEmailActivity(activity)
              ? intl.formatMessage({
                  id: "createActivityModal.footer.scheduleActivity.disabledTooltip.repeat",
                  defaultMessage:
                    "Auto Email activities can’t be changed they are logged once an email is successfully sent.",
                  description: "Email activities cannot be scheduled for repeat action",
                })
              : undefined
          }
          form={form}
          onChange={handleChange}
        />
      )}

      <ActivityCompleted
        creating={creating}
        disabled={isEmailActivity(activity)}
        tooltip={
          isEmailActivity(activity)
            ? intl.formatMessage({
                id: "createActivityModal.footer.scheduleActivity.disabledTooltip.done",
                defaultMessage:
                  "Auto Email activities can’t be undone because they are logged once an email is successfully sent.",
                description: "Email activities cannot be scheduled for done action",
              })
            : undefined
        }
        form={form}
        onChange={handleChange}
      />

      <Form.Item name="recurIntervalType" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="isRecurrent" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};

export default ActivityFooter;
