import React from "react";
import { useIntl } from "react-intl";
import styles from "./FrequencyPreview.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import layout from "../../../styles/layout";
import messages from "./messages";
import InputNumber from "antd/es/input-number";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";

const handleUpdateFrequency = (valueStr?: string) => parseInt(valueStr ?? "", 10);

interface Props {
  cadence: Cadenced["cadenceInterval"];
  onChange: (value: Cadenced["cadenceInterval"]) => void;
  readonly?: boolean;
}

const Interval: React.FC<Props> = ({ cadence, onChange, readonly }) => {
  const intl = useIntl();

  return (
    <Row align="middle" className={styles.line} justify="space-between">
      <Col>{intl.formatMessage(messages.activityInterval)}</Col>
      <Col>
        {readonly ? (
          <span className={styles.staticValue}>
            {intl.formatMessage(messages.daysCount, { cadence })}
          </span>
        ) : (
          <Row align="middle" gutter={layout.spacerS}>
            <Col>
              <InputNumber
                max={999}
                min={1}
                onChange={onChange}
                parser={handleUpdateFrequency}
                value={cadence}
              />
            </Col>
            <Col>{intl.formatMessage(messages.days)}</Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Interval;
