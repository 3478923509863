import React, { useCallback, useEffect, useMemo } from "react";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import EntityBadge from "component/EntityBadge";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import dealFieldModel, { DealFieldName } from "util/fieldModel/DealFieldModel";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getFunnels, getFunnelStages } from "store/deal";
import defaultSelectOptionMapper from "util/mappers/defaultSelectOptionMapper";
import { useWatch } from "antd/es/form/Form";
import { useIntl } from "react-intl";
import styles from "./FunnelStageField.module.scss";
import { FormItem, SelectField } from "@mapmycustomers/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import stageIcon from "assets/icons/deal-stage-dark.svg";

interface OwnProps {
  autoSelectFunnel?: boolean;
  disabled?: boolean;
  defaultFunnelId?: Funnel["id"];
  defaultStageId?: Stage["id"];
  required?: boolean;
}

interface Props extends OwnProps {
  funnels: Funnel[];
  funnelStages: Record<Funnel["id"], Stage[]>;
}

const FunnelStageField: React.FC<Props> = ({
  autoSelectFunnel = true,
  defaultFunnelId,
  defaultStageId,
  disabled,
  funnels,
  funnelStages,
  required,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const { funnelLabel, stageLabel } = useMemo(
    () => ({
      funnelLabel: dealFieldModel.getByName(DealFieldName.FUNNEL)!.displayName,
      stageLabel: dealFieldModel.getByName(DealFieldName.STAGE)!.displayName,
    }),
    []
  );

  const funnelId: Funnel["id"] | undefined = useWatch("funnelId", form);
  const funnelOptions = useMemo(() => funnels.map(defaultSelectOptionMapper), [funnels]);
  const stageOptions = useMemo(
    () =>
      (funnelId ? funnelStages[funnelId] ?? [] : []).map((stage) => ({
        value: stage.id,
        key: stage.id,
        label: <EntityBadge entity={stage} />,
      })),
    [funnelStages, funnelId]
  );

  const handleFunnelChange = useCallback(
    (funnelId?: Funnel["id"]) => form.setFieldsValue({ funnelId, stageId: undefined }),
    [form]
  );

  const handleStageChange = useCallback(
    (stageId: Stage["id"]) => form.setFieldsValue({ stageId }),
    [form]
  );

  // Setting Default Funnel if given or the first funnel otherwise
  useEffect(() => {
    if (funnels.length && !funnelId && autoSelectFunnel) {
      form.setFieldsValue({
        funnelId: defaultFunnelId,
        // only set stage when both default funnel and stage are specified
        stageId: defaultFunnelId && defaultStageId ? defaultStageId : undefined,
      });
    }
  }, [autoSelectFunnel, defaultFunnelId, defaultStageId, form, funnelId, funnels]);

  return (
    <div className={styles.container} data-id="js-funnel-staging-field">
      <div>
        <FormItem label={funnelLabel} name="funnelId" required={required} rules={[{ required }]}>
          <SelectField<Funnel["id"]>
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            label={funnelLabel}
            options={funnelOptions}
            onChange={handleFunnelChange}
            placeholder={intl.formatMessage({
              // TODO: fixme 2023-11-07 change message id
              id: "createDealModal.field.funnel.placeholder",
              defaultMessage: "Select",
              description: "Funnel field placeholder",
            })}
            prefixIcon={<FontAwesomeIcon icon={faFilter} />}
            required={required}
          />
        </FormItem>
      </div>

      <div>
        <FormItem label={stageLabel} name={"stageId"} required={required} rules={[{ required }]}>
          <SelectField<Stage["id"]>
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            label={stageLabel}
            placeholder={intl.formatMessage({
              id: "createDealModal.field.stage.placeholder",
              defaultMessage: "Select",
              description: "Stage field placeholder",
            })}
            onChange={handleStageChange}
            options={stageOptions}
            prefixIcon={<img alt="stage icon" className={styles.stageIcon} src={stageIcon} />}
            required={required}
          />
        </FormItem>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnels: getFunnels(state),
  funnelStages: getFunnelStages(state),
});

export default connect(mapStateToProps)(FunnelStageField);
