import React, { useCallback, useMemo } from "react";
import styles from "./Modal.module.scss";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendAndCreateButton from "./SendAndCreateButton";
import Button from "antd/es/button";
import messages from "./messages";
import Popconfirm from "antd/es/popconfirm";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import Tooltip from "antd/es/tooltip";
import { AnalyticsService } from "../../../util/analytic/AnalyticsService";

interface Props {
  analytics: AnalyticsService;
  bodyLimitReached: boolean;
  emailFilled: boolean;
  emailSending: boolean;
  filesSizeLimitReached: boolean;
  onClick: () => void;
  openActivityModal: boolean;
  recipientCount: number;
  templateChanged: boolean;
}

const ActionButton: React.FC<Props> = ({
  analytics,
  bodyLimitReached,
  emailFilled,
  emailSending,
  filesSizeLimitReached,
  onClick,
  openActivityModal,
  recipientCount,
  templateChanged,
}) => {
  const intl = useIntl();

  const disabled = emailSending || bodyLimitReached || !emailFilled || filesSizeLimitReached;

  const handleClick = useCallback(() => {
    const analyticPart = openActivityModal ? "Next" : "Send and Create";
    analytics.clicked([analyticPart]);
    onClick();
  }, [analytics, onClick, openActivityModal]);

  const button = useMemo(
    () =>
      openActivityModal ? (
        <Button
          disabled={disabled}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          loading={emailSending}
          onClick={templateChanged ? undefined : handleClick}
          type="primary"
        >
          {intl.formatMessage(messages.next)}
        </Button>
      ) : (
        <SendAndCreateButton
          disabled={disabled}
          loading={emailSending}
          onClick={templateChanged ? undefined : handleClick}
          recipientCount={recipientCount}
        />
      ),
    [disabled, handleClick, intl, emailSending, openActivityModal, recipientCount, templateChanged]
  );

  if (filesSizeLimitReached) {
    return (
      <Tooltip title={intl.formatMessage(messages.filesSizeLimitReached)}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  if (bodyLimitReached) {
    return (
      <Tooltip title={intl.formatMessage(messages.emailBodyLimitReached)}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  if (!emailFilled) {
    return (
      <Tooltip title={intl.formatMessage(messages.emailIsNotFilled)}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  if (templateChanged) {
    return (
      <Popconfirm
        cancelText={intl.formatMessage(messages.nextConfirmationNo)}
        okText={intl.formatMessage(messages.nextConfirmationYes)}
        onConfirm={handleClick}
        overlayClassName={styles.confirmation}
        title={intl.formatMessage(messages.nextConfirmationTitle)}
      >
        {button}
      </Popconfirm>
    );
  }

  return button;
};

export default ActionButton;
