import { Cluster } from "@mapmycustomers/shared/types/map";
import { BASE_CLUSTER_FILL_OPACITY } from "scene/map/utils/consts";
import { BASE_CLUSTER_ZINDEX } from "util/map/consts";
import svgToDataUrl from "util/map/svgToDataUrl";
import colors from "styles/_colors.module.scss";
import getScaledSize from "util/map/markerStyles/getScaledSize";
import getSvgForCluster from "util/map/markerStyles/getSvgForCluster";

const CLUSTER_SIZE_THRESHOLD = 100;

const defaultClusterStyleGetter = (cluster: Cluster) => {
  const fillColor = cluster.count >= CLUSTER_SIZE_THRESHOLD ? colors.red010 : colors.yellow010;
  const strokeColor = cluster.count >= CLUSTER_SIZE_THRESHOLD ? colors.red100 : colors.yellow050;
  const scale = cluster.count >= CLUSTER_SIZE_THRESHOLD ? 0.8 : 0.5;
  const fontSize = cluster.count >= CLUSTER_SIZE_THRESHOLD ? 20 : 16;

  const { svg, size, anchor } = getSvgForCluster(fillColor, strokeColor);

  return {
    clickable: true,
    icon: {
      url: svgToDataUrl(svg),
      scaledSize: new google.maps.Size(...getScaledSize(size, scale)),
      anchor: new google.maps.Point(...getScaledSize(anchor, scale)),
    },
    label: {
      text: String(cluster.count),
      color: colors.slate,
      fontSize: `${fontSize}px`,
    },
    opacity: BASE_CLUSTER_FILL_OPACITY,
    // adjust zIndex to be above other markers
    zIndex: BASE_CLUSTER_ZINDEX + cluster.count,
  };
};

export default defaultClusterStyleGetter;
