import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import { getOrganizationSettings, getUserMetaData, getUserSettings } from "../iam";
import Setting from "@mapmycustomers/shared/types/Setting";
import OrganizationSetting from "../../enum/OrganizationSetting";
import { utcToZonedTime } from "date-fns-tz";
import { isAfter, isBefore, setHours, setMinutes } from "date-fns/esm";
import { getActivityTypes } from "../activity";
import doesEmailActivityLayoutHaveRequiredFields from "../../util/email/doesEmailActivityLayoutHaveRequiredFields";
import { EMAIL_ACTIVITY_TYPE_NAME } from "../../util/email/const";

const emailState = (state: RootState) => state.email;

export const isEmailCreationModalVisible = createSelector(
  emailState,
  ({ creationModalVisible }) => creationModalVisible
);

export const getEmailQuota = createSelector(emailState, ({ emailQuota }) => emailQuota);

export const getEmailRecipients = createSelector(emailState, ({ recipients }) => recipients);

export const isNewEmailPreviewLoading = createSelector(
  emailState,
  ({ newEmailPreviewLoading }) => newEmailPreviewLoading
);

export const getNewEmailPreview = createSelector(
  emailState,
  ({ newEmailPreview }) => newEmailPreview
);

export const isTemplatePreviewLoading = createSelector(
  emailState,
  ({ templatePreviewLoading }) => templatePreviewLoading
);

export const getTemplatePreview = createSelector(
  emailState,
  ({ templatePreview }) => templatePreview
);

export const getNewEmailAttachments = createSelector(
  emailState,
  ({ newEmailAttachments }) => newEmailAttachments
);

// This selector is used to determine the entity type of the all recipients in the email creation modal
// We don't support sending emails to multiple entity types at once, so correct entity type is the first one
export const getEmailRecipientsEntityType = createSelector(
  getEmailRecipients,
  (recipients) => recipients[0]?.entity
);

export const getEmailTemplates = createSelector(
  emailState,
  getEmailRecipientsEntityType,
  ({ templates }, entityType) =>
    entityType ? templates.filter((template) => template.entityType === entityType) : []
);

export const areEmailTemplatesLoading = createSelector(
  emailState,
  ({ templatesLoading }) => templatesLoading
);

export const doesNeedToOpenActivityFormAfterSendingEmail = createSelector(
  getUserMetaData,
  (metaData) => {
    const userSettingValue = metaData.openActivityFormAfterSendingEmail;
    return !!userSettingValue || doesEmailActivityLayoutHaveRequiredFields();
  }
);

export const isNewEmailTemplateCreating = createSelector(
  emailState,
  ({ templateCreating }) => templateCreating
);

export const isEmailTemplateUpdating = createSelector(
  emailState,
  ({ templateUpdating }) => templateUpdating
);

export const isEmailTemplateDeleting = createSelector(
  emailState,
  ({ templateDeleting }) => templateDeleting
);

export const isEmailSending = createSelector(emailState, ({ emailSending }) => emailSending);

export const isEmailQueueVisible = createSelector(
  emailState,
  ({ emailQueueVisible }) => emailQueueVisible
);

export const isEmailFeaturesVisible = createSelector(
  emailState,
  ({ emailFeaturesVisible }) => emailFeaturesVisible
);

export const isOrgReachEmailDayLimit = createSelector(getEmailQuota, (quota) =>
  quota ? quota.total <= quota.sent + quota.submitted : false
);

export const isEmailQuotaUpdating = createSelector(
  emailState,
  ({ emailQuotaUpdating }) => emailQuotaUpdating
);

export const isOutOfEmailSendingHours = createSelector(
  getOrganizationSettings,
  getUserSettings,
  (orgSettings: Setting[] | undefined, userSettings: Setting[] | undefined) => {
    const timezone = orgSettings?.find(({ key }) => key === OrganizationSetting.TIMEZONE)?.value;
    const emailSetting = userSettings?.find(({ key }) => key === "bulkEmail")?.value;
    if (timezone && emailSetting) {
      const startTime = emailSetting.startTime.split(":");
      const endTime = emailSetting.endTime.split(":");
      const startHour = startTime[0];
      const startMinute = startTime[1] ?? 0;
      const endHour = endTime[0];
      const endMinute = endTime[1] ?? 0;
      const dateInTimeZone = utcToZonedTime(new Date(), timezone);

      return (
        isBefore(dateInTimeZone, setMinutes(setHours(dateInTimeZone, startHour), startMinute)) ||
        isAfter(dateInTimeZone, setMinutes(setHours(dateInTimeZone, endHour), endMinute)) ||
        // we consider then excludedDays can contain only weekend
        (Array.isArray(emailSetting.excludedDays) &&
          emailSetting.excludedDays.length &&
          [0, 6].includes(dateInTimeZone.getDay()))
      );
    }
    return false;
  }
);

export const getEmailActivityType = createSelector(getActivityTypes, (activityTypes) =>
  activityTypes.find((type) => type.name === EMAIL_ACTIVITY_TYPE_NAME)
);
