import React, { useMemo } from "react";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import CountryWithFlag from "../../../CountryWithFlag";
import { useConfigProvider } from "../../../ConfigProvider";

const useCountryListOptions = () =>
  useMemo(() => {
    const configProvider = useConfigProvider();
    return Object.values(CountryCode).map((countryCode) => ({
      label: <CountryWithFlag countryCode={countryCode} />,
      text: configProvider.formatCountryName(countryCode),
      value: countryCode,
    }));
  }, []);

export default useCountryListOptions;
