import React, { useCallback } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import type { TextFieldComponent } from "@mapmycustomers/ui";
import { TextField } from "@mapmycustomers/ui";

export const TEXT_FILTER_OPERATORS = [
  FilterOperator.CONTAINS,
  FilterOperator.NOT_CONTAINS,
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUAL,
  FilterOperator.STARTS_WITH,
  FilterOperator.ENDS_WITH,
];

interface TextFilterProps extends IFilterComponentProps {}

const TextFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "string" && TEXT_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<TextFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const setRef = useCallback(
        (ref: TextFieldComponent | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <TextField
          className={className}
          onChange={(text) => onChange?.({ ...value, value: text })}
          ref={setRef}
          size="middle"
          value={value?.value}
        />
      );
    },
};

export default TextFilter;
