import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import cn from "classnames";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { useConfigProvider } from "../../../ConfigProvider";
import Footer from "../Footer";
import Select, {
  DefaultOptionType,
  RefSelectProps,
  SelectProps,
  SelectValue,
} from "antd/es/select";
import Tag from "antd/es/tag";

export const messages = {
  "ui.inlineInput.selectField.unknown": "Unknown",
};

export interface InlineSelectFieldProps<T extends SelectValue = SelectValue>
  extends Omit<
      SelectProps<T>,
      "className" | "ref" | "tagRender" | "defaultValue" | "onChange" | "value"
    >,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  disabled?: boolean;
  error?: ReactNode;
  onChange?:
    | ((
        value: T | undefined,
        option: DefaultOptionType | DefaultOptionType[]
      ) => void)
    | undefined;
  prefixIcon?: ReactNode;
  showFooterButtons?: boolean;
  tagRender?: (value: SelectValue) => ReactNode;
  value?: T;
}

const block = bem("mmc-inline-select-field");
const SelectField = <T extends SelectValue = SelectValue>(
  {
    caption,
    className,
    disabled,
    label,
    labelClassName,
    labelPosition = "side",
    onChange,
    options,
    placeholder,
    prefixIcon,
    required,
    showFooterButtons = true,
    tagRender,
    value,
    ...selectProps
  }: InlineSelectFieldProps<T>,
  ref: React.Ref<SelectFieldComponent>
) => {
  const configProvider = useConfigProvider();
  const [inlineValue, setInlineValue] = useState<T | undefined>(value);
  const [editing, startEditing, cancelEditing] = useBoolean();
  const selectorRef = useRef<RefSelectProps>(null);

  const handleChange = useCallback(
    (value: T) => {
      if (!showFooterButtons && options && onChange) {
        cancelEditing();
        onChange(value, options);
      } else {
        setInlineValue(value);
      }
    },
    [cancelEditing, onChange, options]
  );

  const handleSave = useCallback(() => {
    if (options && inlineValue !== null) {
      cancelEditing();
      onChange && onChange(inlineValue, options);
    }
  }, [inlineValue, cancelEditing, onChange, options]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const inputRef = useRef<RefSelectProps>(null);
  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value);
    startEditing();
    // can't focus hidden field, need to wait until it will be displayed
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [disabled, setInlineValue, startEditing, value]);

  const formattedValue = useMemo(() => {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return tagRender
          ? tagRender(value)
          : value.map((item, index) => (
              <Tag key={index}>
                {typeof item === "object"
                  ? item.label
                  : options?.find((option) => item === option.value)?.label}
              </Tag>
            ));
      } else {
        return value?.label;
      }
    } else {
      return options?.find((option) => value === option.value)?.label;
    }
  }, [options, tagRender, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")
      ) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [handleSave]
  );

  useImperativeHandle(ref, () => ({
    focus: () => {
      selectorRef.current?.focus();
    },
    blur: () => {
      selectorRef.current?.blur();
    },
  }));

  const content =
    editing && !disabled ? (
      <Select
        allowClear={false}
        autoFocus
        className={block("select")}
        defaultOpen
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        options={options}
        ref={selectorRef}
        size="large"
        value={inlineValue}
        {...selectProps}
      />
    ) : (
      <div className={block("value")} onClick={handleStartEditing}>
        {formattedValue ?? (
          <span className={block("placeholder")}>
            {placeholder ??
              configProvider.formatMessage("ui.inlineInput.unknown")}
          </span>
        )}
      </div>
    );

  return (
    <div className={cn(block({ disabled: !!disabled }), className)}>
      {label ? (
        <Labeled
          label={
            <div className={block("label-item")}>
              {prefixIcon}
              {label}
            </div>
          }
          labelClassName={cn(block("label"), labelClassName)}
          labelPosition={labelPosition}
          extra={
            disabled ? (
              <FontAwesomeIcon className={block("lock")} icon={faLock} />
            ) : undefined
          }
          required={required}
        >
          {content}
        </Labeled>
      ) : (
        content
      )}
      {showFooterButtons && (
        <Footer
          caption={caption}
          disabled={disabled}
          editing={editing}
          onCancel={cancelEditing}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export interface SelectFieldComponent {
  focus: () => void;
  blur(): void;
}

export default forwardRef(SelectField) as <T extends SelectValue = SelectValue>(
  props: InlineSelectFieldProps<T> & { ref?: React.Ref<SelectFieldComponent> }
) => React.ReactElement;
