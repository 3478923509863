:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        position: relative;
      }

      .image {
        display: block;
        max-width: 600px;
      }

      .delete {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
      }
    }
  }
}
