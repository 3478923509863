import React from "react";
import { Modal } from "@mapmycustomers/ui";
import styles from "./ConfirmationMessageModal.module.scss";
import { useIntl } from "react-intl";
import emailSent from "assets/email/email-sent.svg";
import messages from "../messages";
import EmailPeriod from "../../EmailPeriod";
import EmailSummaryInfo from "../../EmailSummaryInfo";
import HelpText from "../../../typography/HelpText";
import { RootState } from "store/rootReducer";
import { isOrgReachEmailDayLimit, isOutOfEmailSendingHours } from "store/email";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Path from "enum/Path";
import SettingPath from "enum/settings/SettingPath";
import { showEmailFeatures } from "store/email/actions";

interface Props {
  isOrgReachEmailDayLimit: boolean;
  isOutOfEmailSendingHours: boolean;
  onHide: () => void;
  onShowEmailFeatures: typeof showEmailFeatures;
  open: boolean;
}

const ConfirmationMessageModal: React.FC<Props> = ({
  isOrgReachEmailDayLimit,
  isOutOfEmailSendingHours,
  onHide,
  onShowEmailFeatures,
  open,
}) => {
  const intl = useIntl();
  return (
    <Modal
      className={styles.modal}
      footer={null}
      onCancel={onHide}
      open={open}
      title={null}
      width="27rem"
    >
      <div className={styles.container}>
        <img alt="email-sent" className={styles.image} src={emailSent} />
        <div className={styles.title}>
          {isOrgReachEmailDayLimit || isOutOfEmailSendingHours
            ? intl.formatMessage(messages.emailIsQueued)
            : intl.formatMessage(messages.emailIsSent)}
        </div>
        <EmailPeriod onSettings={onHide} showIcon={isOutOfEmailSendingHours} />
        <div className={styles.subTitle}>
          {isOrgReachEmailDayLimit
            ? intl.formatMessage(messages.reachSendLimit)
            : isOutOfEmailSendingHours
            ? intl.formatMessage(messages.outSideSchedule, {
                a: (text) => (
                  <Link
                    onClick={onHide}
                    to={`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`}
                  >
                    {text}
                  </Link>
                ),
              })
            : intl.formatMessage(messages.emailIsSentDescription)}
        </div>
        <EmailSummaryInfo />
        <div className={styles.bottom}>
          <HelpText
            onClick={onShowEmailFeatures}
            text={intl.formatMessage(messages.emailIsSentAboutEmails)}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOrgReachEmailDayLimit: isOrgReachEmailDayLimit(state),
  isOutOfEmailSendingHours: isOutOfEmailSendingHours(state),
});

const mapDispatchToProps = {
  onShowEmailFeatures: showEmailFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationMessageModal);
