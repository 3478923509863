import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

const countryNames = {
  [CountryCode["AFGHANISTAN"]]: "Afghanistan",
  [CountryCode["ÅLAND ISLANDS"]]: "Åland Islands",
  [CountryCode["ALBANIA"]]: "Albania",
  [CountryCode["ALGERIA"]]: "Algeria",
  [CountryCode["AMERICAN SAMOA"]]: "American Samoa",
  [CountryCode["ANDORRA"]]: "Andorra",
  [CountryCode["ANGOLA"]]: "Angola",
  [CountryCode["ANGUILLA"]]: "Anguilla",
  [CountryCode["ANTARCTICA"]]: "Antarctica",
  [CountryCode["ANTIGUA AND BARBUDA"]]: "Antigua and Barbuda",
  [CountryCode["ARGENTINA"]]: "Argentina",
  [CountryCode["ARMENIA"]]: "Armenia",
  [CountryCode["ARUBA"]]: "Aruba",
  [CountryCode["AUSTRALIA"]]: "Australia",
  [CountryCode["AUSTRIA"]]: "Austria",
  [CountryCode["AZERBAIJAN"]]: "Azerbaijan",
  [CountryCode["BAHAMAS"]]: "Bahamas",
  [CountryCode["BAHRAIN"]]: "Bahrain",
  [CountryCode["BANGLADESH"]]: "Bangladesh",
  [CountryCode["BARBADOS"]]: "Barbados",
  [CountryCode["BELARUS"]]: "Belarus",
  [CountryCode["BELGIUM"]]: "Belgium",
  [CountryCode["BELIZE"]]: "Belize",
  [CountryCode["BENIN"]]: "Benin",
  [CountryCode["BERMUDA"]]: "Bermuda",
  [CountryCode["BHUTAN"]]: "Bhutan",
  [CountryCode["BOLIVIA (PLURINATIONAL STATE OF)"]]:
    "Bolivia (Plurinational State of)",
  [CountryCode["BONAIRE, SINT EUSTATIUS AND SABA"]]:
    "Bonaire, Sint Eustatius and Saba",
  [CountryCode["BOSNIA AND HERZEGOVINA"]]: "Bosnia and Herzegovina",
  [CountryCode["BOTSWANA"]]: "Botswana",
  [CountryCode["BOUVET ISLAND"]]: "Bouvet Island",
  [CountryCode["BRAZIL"]]: "Brazil",
  [CountryCode["BRITISH INDIAN OCEAN TERRITORY"]]:
    "British Indian Ocean Territory",
  [CountryCode["BRUNEI DARUSSALAM"]]: "Brunei Darussalam",
  [CountryCode["BULGARIA"]]: "Bulgaria",
  [CountryCode["BURKINA FASO"]]: "Burkina Faso",
  [CountryCode["BURUNDI"]]: "Burundi",
  [CountryCode["CABO VERDE"]]: "Cabo Verde",
  [CountryCode["CAMBODIA"]]: "Cambodia",
  [CountryCode["CAMEROON"]]: "Cameroon",
  [CountryCode["CANADA"]]: "Canada",
  [CountryCode["CAYMAN ISLANDS"]]: "Cayman Islands",
  [CountryCode["CENTRAL AFRICAN REPUBLIC"]]: "Central African Republic",
  [CountryCode["CHAD"]]: "Chad",
  [CountryCode["CHILE"]]: "Chile",
  [CountryCode["CHINA"]]: "China",
  [CountryCode["CHRISTMAS ISLAND"]]: "Christmas Island",
  [CountryCode["COCOS (KEELING) ISLANDS"]]: "Cocos (Keeling) Islands",
  [CountryCode["COLOMBIA"]]: "Colombia",
  [CountryCode["COMOROS"]]: "Comoros",
  [CountryCode["CONGO"]]: "Congo",
  [CountryCode["CONGO, DEMOCRATIC REPUBLIC OF THE"]]:
    "Congo, Democratic Republic of the",
  [CountryCode["COOK ISLANDS"]]: "Cook Islands",
  [CountryCode["COSTA RICA"]]: "Costa Rica",
  [CountryCode["CÔTE D'IVOIRE"]]: "Côte d'Ivoire",
  [CountryCode["CROATIA"]]: "Croatia",
  [CountryCode["CUBA"]]: "Cuba",
  [CountryCode["CURAÇAO"]]: "Curaçao",
  [CountryCode["CYPRUS"]]: "Cyprus",
  [CountryCode["CZECHIA"]]: "Czechia",
  [CountryCode["DENMARK"]]: "Denmark",
  [CountryCode["DJIBOUTI"]]: "Djibouti",
  [CountryCode["DOMINICA"]]: "Dominica",
  [CountryCode["DOMINICAN REPUBLIC"]]: "Dominican Republic",
  [CountryCode["ECUADOR"]]: "Ecuador",
  [CountryCode["EGYPT"]]: "Egypt",
  [CountryCode["EL SALVADOR"]]: "El Salvador",
  [CountryCode["EQUATORIAL GUINEA"]]: "Equatorial Guinea",
  [CountryCode["ERITREA"]]: "Eritrea",
  [CountryCode["ESTONIA"]]: "Estonia",
  [CountryCode["ESWATINI"]]: "Eswatini",
  [CountryCode["ETHIOPIA"]]: "Ethiopia",
  [CountryCode["FALKLAND ISLANDS (MALVINAS)"]]: "Falkland Islands (Malvinas)",
  [CountryCode["FAROE ISLANDS"]]: "Faroe Islands",
  [CountryCode["FIJI"]]: "Fiji",
  [CountryCode["FINLAND"]]: "Finland",
  [CountryCode["FRANCE"]]: "France",
  [CountryCode["FRENCH GUIANA"]]: "French Guiana",
  [CountryCode["FRENCH POLYNESIA"]]: "French Polynesia",
  [CountryCode["FRENCH SOUTHERN TERRITORIES"]]: "French Southern Territories",
  [CountryCode["GABON"]]: "Gabon",
  [CountryCode["GAMBIA"]]: "Gambia",
  [CountryCode["GEORGIA"]]: "Georgia",
  [CountryCode["GERMANY"]]: "Germany",
  [CountryCode["GHANA"]]: "Ghana",
  [CountryCode["GIBRALTAR"]]: "Gibraltar",
  [CountryCode["GREECE"]]: "Greece",
  [CountryCode["GREENLAND"]]: "Greenland",
  [CountryCode["GRENADA"]]: "Grenada",
  [CountryCode["GUADELOUPE"]]: "Guadeloupe",
  [CountryCode["GUAM"]]: "Guam",
  [CountryCode["GUATEMALA"]]: "Guatemala",
  [CountryCode["GUERNSEY"]]: "Guernsey",
  [CountryCode["GUINEA"]]: "Guinea",
  [CountryCode["GUINEA-BISSAU"]]: "Guinea-Bissau",
  [CountryCode["GUYANA"]]: "Guyana",
  [CountryCode["HAITI"]]: "Haiti",
  [CountryCode["HEARD ISLAND AND MCDONALD ISLANDS"]]:
    "Heard Island and McDonald Islands",
  [CountryCode["HOLY SEE"]]: "Holy See",
  [CountryCode["HONDURAS"]]: "Honduras",
  [CountryCode["HONG KONG"]]: "Hong Kong",
  [CountryCode["HUNGARY"]]: "Hungary",
  [CountryCode["ICELAND"]]: "Iceland",
  [CountryCode["INDIA"]]: "India",
  [CountryCode["INDONESIA"]]: "Indonesia",
  [CountryCode["IRAN (ISLAMIC REPUBLIC OF)"]]: "Iran (Islamic Republic of)",
  [CountryCode["IRAQ"]]: "Iraq",
  [CountryCode["IRELAND"]]: "Ireland",
  [CountryCode["ISLE OF MAN"]]: "Isle of Man",
  [CountryCode["ISRAEL"]]: "Israel",
  [CountryCode["ITALY"]]: "Italy",
  [CountryCode["JAMAICA"]]: "Jamaica",
  [CountryCode["JAPAN"]]: "Japan",
  [CountryCode["JERSEY"]]: "Jersey",
  [CountryCode["JORDAN"]]: "Jordan",
  [CountryCode["KAZAKHSTAN"]]: "Kazakhstan",
  [CountryCode["KENYA"]]: "Kenya",
  [CountryCode["KIRIBATI"]]: "Kiribati",
  [CountryCode["KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)"]]:
    "Korea (Democratic People's Republic of)",
  [CountryCode["KOREA, REPUBLIC OF"]]: "Korea, Republic of",
  [CountryCode["KUWAIT"]]: "Kuwait",
  [CountryCode["KYRGYZSTAN"]]: "Kyrgyzstan",
  [CountryCode["LAO PEOPLE'S DEMOCRATIC REPUBLIC"]]:
    "Lao People's Democratic Republic",
  [CountryCode["LATVIA"]]: "Latvia",
  [CountryCode["LEBANON"]]: "Lebanon",
  [CountryCode["LESOTHO"]]: "Lesotho",
  [CountryCode["LIBERIA"]]: "Liberia",
  [CountryCode["LIBYA"]]: "Libya",
  [CountryCode["LIECHTENSTEIN"]]: "Liechtenstein",
  [CountryCode["LITHUANIA"]]: "Lithuania",
  [CountryCode["LUXEMBOURG"]]: "Luxembourg",
  [CountryCode["MACAO"]]: "Macao",
  [CountryCode["MADAGASCAR"]]: "Madagascar",
  [CountryCode["MALAWI"]]: "Malawi",
  [CountryCode["MALAYSIA"]]: "Malaysia",
  [CountryCode["MALDIVES"]]: "Maldives",
  [CountryCode["MALI"]]: "Mali",
  [CountryCode["MALTA"]]: "Malta",
  [CountryCode["MARSHALL ISLANDS"]]: "Marshall Islands",
  [CountryCode["MARTINIQUE"]]: "Martinique",
  [CountryCode["MAURITANIA"]]: "Mauritania",
  [CountryCode["MAURITIUS"]]: "Mauritius",
  [CountryCode["MAYOTTE"]]: "Mayotte",
  [CountryCode["MEXICO"]]: "Mexico",
  [CountryCode["MICRONESIA (FEDERATED STATES OF)"]]:
    "Micronesia (Federated States of)",
  [CountryCode["MOLDOVA, REPUBLIC OF"]]: "Moldova, Republic of",
  [CountryCode["MONACO"]]: "Monaco",
  [CountryCode["MONGOLIA"]]: "Mongolia",
  [CountryCode["MONTENEGRO"]]: "Montenegro",
  [CountryCode["MONTSERRAT"]]: "Montserrat",
  [CountryCode["MOROCCO"]]: "Morocco",
  [CountryCode["MOZAMBIQUE"]]: "Mozambique",
  [CountryCode["MYANMAR"]]: "Myanmar",
  [CountryCode["NAMIBIA"]]: "Namibia",
  [CountryCode["NAURU"]]: "Nauru",
  [CountryCode["NEPAL"]]: "Nepal",
  [CountryCode["NETHERLANDS"]]: "Netherlands",
  [CountryCode["NEW CALEDONIA"]]: "New Caledonia",
  [CountryCode["NEW ZEALAND"]]: "New Zealand",
  [CountryCode["NICARAGUA"]]: "Nicaragua",
  [CountryCode["NIGER"]]: "Niger",
  [CountryCode["NIGERIA"]]: "Nigeria",
  [CountryCode["NIUE"]]: "Niue",
  [CountryCode["NORFOLK ISLAND"]]: "Norfolk Island",
  [CountryCode["NORTH MACEDONIA"]]: "North Macedonia",
  [CountryCode["NORTHERN MARIANA ISLANDS"]]: "Northern Mariana Islands",
  [CountryCode["NORWAY"]]: "Norway",
  [CountryCode["OMAN"]]: "Oman",
  [CountryCode["PAKISTAN"]]: "Pakistan",
  [CountryCode["PALAU"]]: "Palau",
  [CountryCode["PALESTINE, STATE OF"]]: "Palestine, State of",
  [CountryCode["PANAMA"]]: "Panama",
  [CountryCode["PAPUA NEW GUINEA"]]: "Papua New Guinea",
  [CountryCode["PARAGUAY"]]: "Paraguay",
  [CountryCode["PERU"]]: "Peru",
  [CountryCode["PHILIPPINES"]]: "Philippines",
  [CountryCode["PITCAIRN"]]: "Pitcairn",
  [CountryCode["POLAND"]]: "Poland",
  [CountryCode["PORTUGAL"]]: "Portugal",
  [CountryCode["PUERTO RICO"]]: "Puerto Rico",
  [CountryCode["QATAR"]]: "Qatar",
  [CountryCode["RÉUNION"]]: "Réunion",
  [CountryCode["ROMANIA"]]: "Romania",
  [CountryCode["RUSSIAN FEDERATION"]]: "Russian Federation",
  [CountryCode["RWANDA"]]: "Rwanda",
  [CountryCode["SAINT BARTHÉLEMY"]]: "Saint Barthélemy",
  [CountryCode["SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"]]:
    "Saint Helena, Ascension and Tristan da Cunha",
  [CountryCode["SAINT KITTS AND NEVIS"]]: "Saint Kitts and Nevis",
  [CountryCode["SAINT LUCIA"]]: "Saint Lucia",
  [CountryCode["SAINT MARTIN (FRENCH PART)"]]: "Saint Martin (French part)",
  [CountryCode["SAINT PIERRE AND MIQUELON"]]: "Saint Pierre and Miquelon",
  [CountryCode["SAINT VINCENT AND THE GRENADINES"]]:
    "Saint Vincent and the Grenadines",
  [CountryCode["SAMOA"]]: "Samoa",
  [CountryCode["SAN MARINO"]]: "San Marino",
  [CountryCode["SAO TOME AND PRINCIPE"]]: "Sao Tome and Principe",
  [CountryCode["SAUDI ARABIA"]]: "Saudi Arabia",
  [CountryCode["SENEGAL"]]: "Senegal",
  [CountryCode["SERBIA"]]: "Serbia",
  [CountryCode["SEYCHELLES"]]: "Seychelles",
  [CountryCode["SIERRA LEONE"]]: "Sierra Leone",
  [CountryCode["SINGAPORE"]]: "Singapore",
  [CountryCode["SINT MAARTEN (DUTCH PART)"]]: "Sint Maarten (Dutch part)",
  [CountryCode["SLOVAKIA"]]: "Slovakia",
  [CountryCode["SLOVENIA"]]: "Slovenia",
  [CountryCode["SOLOMON ISLANDS"]]: "Solomon Islands",
  [CountryCode["SOMALIA"]]: "Somalia",
  [CountryCode["SOUTH AFRICA"]]: "South Africa",
  [CountryCode["SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"]]:
    "South Georgia and the South Sandwich Islands",
  [CountryCode["SOUTH SUDAN"]]: "South Sudan",
  [CountryCode["SPAIN"]]: "Spain",
  [CountryCode["SRI LANKA"]]: "Sri Lanka",
  [CountryCode["SUDAN"]]: "Sudan",
  [CountryCode["SURINAME"]]: "Suriname",
  [CountryCode["SVALBARD AND JAN MAYEN"]]: "Svalbard and Jan Mayen",
  [CountryCode["SWEDEN"]]: "Sweden",
  [CountryCode["SWITZERLAND"]]: "Switzerland",
  [CountryCode["SYRIAN ARAB REPUBLIC"]]: "Syrian Arab Republic",
  [CountryCode["TAIWAN, PROVINCE OF CHINA"]]: "Taiwan, Province of China",
  [CountryCode["TAJIKISTAN"]]: "Tajikistan",
  [CountryCode["TANZANIA, UNITED REPUBLIC OF"]]: "Tanzania, United Republic of",
  [CountryCode["THAILAND"]]: "Thailand",
  [CountryCode["TIMOR-LESTE"]]: "Timor-Leste",
  [CountryCode["TOGO"]]: "Togo",
  [CountryCode["TOKELAU"]]: "Tokelau",
  [CountryCode["TONGA"]]: "Tonga",
  [CountryCode["TRINIDAD AND TOBAGO"]]: "Trinidad and Tobago",
  [CountryCode["TUNISIA"]]: "Tunisia",
  [CountryCode["TURKEY"]]: "Turkey",
  [CountryCode["TURKMENISTAN"]]: "Turkmenistan",
  [CountryCode["TURKS AND CAICOS ISLANDS"]]: "Turks and Caicos Islands",
  [CountryCode["TUVALU"]]: "Tuvalu",
  [CountryCode["UGANDA"]]: "Uganda",
  [CountryCode["UKRAINE"]]: "Ukraine",
  [CountryCode["UNITED ARAB EMIRATES"]]: "United Arab Emirates",
  [CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]]:
    "United Kingdom of Great Britain and Northern Ireland",
  [CountryCode["UNITED STATES OF AMERICA"]]: "United States",
  [CountryCode["UNITED STATES MINOR OUTLYING ISLANDS"]]:
    "United States Minor Outlying Islands",
  [CountryCode["URUGUAY"]]: "Uruguay",
  [CountryCode["UZBEKISTAN"]]: "Uzbekistan",
  [CountryCode["VANUATU"]]: "Vanuatu",
  [CountryCode["VENEZUELA (BOLIVARIAN REPUBLIC OF)"]]:
    "Venezuela (Bolivarian Republic of)",
  [CountryCode["VIET NAM"]]: "Viet Nam",
  [CountryCode["VIRGIN ISLANDS (BRITISH)"]]: "Virgin Islands (British)",
  [CountryCode["VIRGIN ISLANDS (U.S.)"]]: "Virgin Islands (U.S.)",
  [CountryCode["WALLIS AND FUTUNA"]]: "Wallis and Futuna",
  [CountryCode["WESTERN SAHARA"]]: "Western Sahara",
  [CountryCode["YEMEN"]]: "Yemen",
  [CountryCode["ZAMBIA"]]: "Zambia",
  [CountryCode["ZIMBABWE"]]: "Zimbabwe",
};

const defaultCountryNameFormatter = (countryCode: CountryCode): string =>
  countryNames[countryCode];

export default defaultCountryNameFormatter;
