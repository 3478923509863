import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import RegionField from "./RegionField";
import Address from "@mapmycustomers/shared/types/Address";
import Region from "@mapmycustomers/shared/types/Region";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import useCountryListOptions from "../utils/useCountryListOptions";
import { isEmptyString } from "@mapmycustomers/shared/util/stringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/pro-solid-svg-icons";
import layout from "../../../styles/layout";
import Button from "antd/es/button";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { useConfigProvider } from "../../../ConfigProvider";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import TextField from "../../TextField";
import LoadingSpinner from "../../../LoadingSpinner";
import SelectField from "../../SelectField";
import { bem } from "@react-md/utils";

const block = bem("mmc-manual-address");

export interface ManualAddressProps
  extends Omit<LabeledFieldProps, "children"> {
  disabled?: boolean;
  editMode?: boolean;
  onChange?: (address?: Address) => void;
  onReverseGeocodeAddress: (payload: {
    coordinates: LongLat;
    callback: (result: GeocodeResult) => void;
    failureCallback?: () => void;
  }) => void;
  onToggleManualAddress?: () => void;
  value?: Address;
}

const ManualAddress: React.FC<ManualAddressProps> = ({
  disabled,
  onChange,
  onReverseGeocodeAddress,
  onToggleManualAddress,
  required,
  value,
  ...labelProps
}) => {
  const { formatMessage, useFindMyLocation } = useConfigProvider();

  const handleAddressChange = useCallback(
    (address) => onChange?.({ ...value, address }),
    [onChange, value]
  );

  const handleCountryCodeChange = useCallback(
    (countryCode = null, row) => {
      onChange?.({
        ...value,
        country: row ? row.text : null,
        countryCode,
        region: undefined,
        regionCode: undefined,
      });
    },
    [onChange, value]
  );

  const handleCityChange = useCallback(
    (city) => onChange?.({ ...value, city }),
    [onChange, value]
  );

  const handleRegionChange = useCallback(
    (region: Region) => onChange?.({ ...value, ...region }),
    [onChange, value]
  );

  const handlePostalCodeChange = useCallback(
    (postalCode) => onChange?.({ ...value, postalCode }),
    [onChange, value]
  );

  // Find Me functionality:
  const [
    ,
    ,
    findMeLoading,
    findMeError,
    handleFindMyLocation,
    geocodingResult,
    resetError,
  ] = useFindMyLocation(onReverseGeocodeAddress);
  const lastGeocodingResult = useRef<GeocodeResult>();

  const canClearCountry = useMemo(
    () =>
      !isEmptyString(value?.countryCode) &&
      isEmptyString(value?.address) &&
      isEmptyString(value?.city) &&
      isEmptyString(value?.region) &&
      isEmptyString(value?.postalCode),
    [value]
  );

  useEffect(() => {
    resetError();
  }, [value, resetError]);

  useEffect(() => {
    if (lastGeocodingResult.current !== geocodingResult) {
      lastGeocodingResult.current = geocodingResult;
      onChange?.(geocodingResult?.address);
    }
  }, [geocodingResult, onChange]);

  const countryListOptions = useCountryListOptions();

  return (
    <Labeled required={required} {...labelProps}>
      <Row gutter={[layout.spacerS, layout.spacerS]}>
        <Col span={24}>
          <TextField
            error={findMeError}
            locked={disabled}
            onChange={handleAddressChange}
            placeholder={formatMessage("ui.manualAddress.street")}
            suffix={
              findMeLoading ? (
                <LoadingSpinner mini />
              ) : !disabled ? (
                <Tooltip title={formatMessage("ui.address.findMe.tooltip")}>
                  <FontAwesomeIcon
                    className={block("icon")}
                    icon={faLocationArrow}
                    onClick={handleFindMyLocation}
                    size="lg"
                  />
                </Tooltip>
              ) : undefined
            }
            value={value?.address}
          />
        </Col>
        <Col span={24}>
          <SelectField
            allowClear={canClearCountry}
            className={block("country-dropdown")}
            dropdownMatchSelectWidth
            locked={disabled}
            onChange={handleCountryCodeChange}
            optionFilterProp="text"
            options={countryListOptions}
            placeholder={formatMessage("ui.manualAddress.country")}
            showSearch
            value={value?.countryCode}
          />
        </Col>
        <Col span={12}>
          <TextField
            locked={disabled}
            onChange={handleCityChange}
            placeholder={formatMessage("ui.manualAddress.city")}
            value={value?.city}
          />
        </Col>
        <Col span={12}>
          <RegionField
            countryCode={value?.countryCode}
            disabled={disabled}
            onChange={handleRegionChange}
            value={value}
          />
        </Col>
        <Col span={24}>
          <TextField
            locked={disabled}
            onChange={handlePostalCodeChange}
            placeholder={formatMessage("ui.manualAddress.postalCode")}
            value={value?.postalCode}
          />
        </Col>
        {!!onToggleManualAddress && !disabled && (
          <Col span={24}>
            <Button
              className={block("search-manual-button")}
              onClick={onToggleManualAddress}
              type="link"
            >
              {formatMessage("ui.manualAddress.searchGoogle")}
            </Button>
          </Col>
        )}
      </Row>
    </Labeled>
  );
};

export default ManualAddress;
