import ReportType from "../../../../enum/ReportType";
import MapFilter from "../../enums/MapFilter";
import { getExportTypeByReportTypeAndMapFilter } from "./ExportType";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import getHeaderRow from "./getHeaderRow";
import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import getDataRowFromEntity from "./getDataRowFromEntity";
import User from "@mapmycustomers/shared/types/User";
import formatUserTeamsById from "./formatUserTemsById";

const downloadEntitiesAsCsv = (
  entities: AnyEntity[],
  reportType: ReportType,
  filter: MapFilter,
  users?: User[]
) => {
  if (!entities.length) {
    return; // nothing to export
  }
  const userTeamsById = formatUserTeamsById(users);

  const exportType = getExportTypeByReportTypeAndMapFilter(reportType, filter);

  const dataRows = entities
    .map((entity) => getDataRowFromEntity(exportType, entity, userTeamsById))
    .filter(isDefined);
  const headerRow = getHeaderRow(exportType);
  const csvText = [headerRow.join(","), ...dataRows.map((row) => row.join(","))].join("\n");

  const blob = new Blob([csvText], { type: "text/plain" });
  const fileName = `${reportType}_${filter ? filter : ""}.csv`;

  const hiddenElement = window.document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = fileName;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  document.body.removeChild(hiddenElement);
};

export default downloadEntitiesAsCsv;
