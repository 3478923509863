import { put, select, takeLatest } from "redux-saga/effects";
import { fetchLocations, initSideBar, selectUser, selectUserById } from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import Location from "@mapmycustomers/shared/types/Location";
import { callReadonlyApi } from "store/api/callApi";
import { getLocalTimeZoneFormattedOffset } from "util/dates";
import { handleError } from "store/errors/actions";
import { addDays, startOfDay } from "date-fns/esm";
import { getSelectedUser } from "./selectors";
import User from "@mapmycustomers/shared/types/User";
import { Action } from "redux";
import BaseLayerData from "scene/map/store/layers/BaseLayerData";
import { getUserLocationsData } from "scene/map/store/layers/selectors";
import { isActionOf } from "typesafe-actions";
import {
  toggleLayerLegendExpandedState,
  toggleLayerVisibility,
} from "scene/map/store/layers/actions";
import { updateMetadata } from "../../../../../store/iam/actions";
import { convertLongLatToLatLngLiteral } from "@mapmycustomers/shared/util/geo/GeoService";
import locationsToLocationGroup from "../../../components/layers/UserLocation/component/SideBar/util/locationsToLocationGroup";
import { getUsers } from "store/members";

export function* onFetchLocations({
  payload: { daysOffset },
}: ReturnType<typeof fetchLocations.request>) {
  try {
    const user: User | undefined = yield select(getSelectedUser);
    if (user) {
      const organizationId: Organization["id"] = yield select(getOrganizationId);

      const locationsResponse: ListResponse<Location> = yield callReadonlyApi(
        "fetchLocations",
        organizationId,
        {
          $filters: {
            $and: [
              {
                userId: user.id,
              },
              {
                createdAt: {
                  $gte: startOfDay(addDays(new Date(), -daysOffset)).toISOString(),
                  $offset: getLocalTimeZoneFormattedOffset(),
                },
              },
            ],
          },
          $order: "-createdAt",
          $limit: 999,
        }
      );
      const locations: Location[][] = [];
      locationsResponse.data.forEach((location, i) => {
        if (
          i === 0 ||
          google.maps.geometry.spherical.computeDistanceBetween(
            convertLongLatToLatLngLiteral(location.geoPoint.coordinates),
            convertLongLatToLatLngLiteral(locationsResponse.data[i - 1].geoPoint.coordinates)
          ) > 100
        ) {
          locations.push([]);
        }
        locations[locations.length - 1].push(location);
      });

      yield put(
        fetchLocations.success({
          firstLocationId: locationsResponse.data[0]?.id,
          locationGroups: locationsToLocationGroup(locations),
        })
      );
    } else {
      yield put(fetchLocations.success({ locationGroups: [] }));
    }
  } catch (error) {
    yield put(fetchLocations.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onInitSideBar() {
  try {
    const user: User | undefined = yield select(getSelectedUser);
    if (user) {
      const organizationId: Organization["id"] = yield select(getOrganizationId);
      const locationsResponse: ListResponse<Location> = yield callReadonlyApi(
        "fetchLocations",
        organizationId,
        {
          $filters: {
            $and: [
              {
                userId: user.id,
              },
            ],
          },
          $order: "-createdAt",
          $limit: 1,
        }
      );
      yield put(
        initSideBar.success({
          lastLocationWithoutPeriod: locationsResponse.data[0],
        })
      );
    } else {
      yield put(initSideBar.success({}));
    }
  } catch (error) {
    yield put(initSideBar.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onSelectUserById({ payload }: ReturnType<typeof selectUserById>) {
  const users: User[] = yield select(getUsers);
  const user = users.find(({ id }) => id === payload);
  yield put(selectUser(user));
}

export function* onPersistUserLocationsSettings() {
  const layerData: BaseLayerData = yield select(getUserLocationsData);
  yield put(updateMetadata.request({ userLocationsSettings: layerData }));
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

export function* userLocationsSagas() {
  yield takeLatest(fetchLocations.request, onFetchLocations);
  yield takeLatest(initSideBar.request, onInitSideBar);
  yield takeLatest(selectUserById, onSelectUserById);
  yield takeLatest(
    (action: Action) =>
      isToggleVisibilityOrLegendAction(action) && action.payload.name === "userLocations",
    onPersistUserLocationsSettings
  );
}
