import React, { useCallback } from "react";
import SelectableItem from "./SelectableItem";
import User from "@mapmycustomers/shared/types/User";
import AvatarWithName from "../Avatar/AvatarWithName";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import { bem } from "@react-md/utils";

const block = bem("mmc-user-item");

interface Props {
  onClick?: (userId: User["id"]) => void;
  selected?: boolean;
  showAvatar?: boolean;
  user: User;
}

const UserItem: React.FC<Props> = ({
  onClick,
  selected,
  showAvatar = true,
  user,
}) => {
  const handleClick = useCallback(() => {
    onClick?.(user.id);
  }, [onClick, user.id]);

  const teamNames = user.teams.map((team) => team.name).join(", ");

  return (
    <SelectableItem
      label={
        <div className={block({ selected })}>
          {showAvatar ? (
            <AvatarWithName
              avatarClassName={block("avatar")}
              className={cn(block("avatar-container"))}
              user={user}
            />
          ) : (
            <Tooltip title={userDisplayName(user)}>
              <span className={block("user-name")}>
                {userDisplayName(user)}
              </span>
            </Tooltip>
          )}
          <div className={block("teams")}>
            <Tooltip title={teamNames}>{teamNames}</Tooltip>
          </div>
        </div>
      }
      onClick={handleClick}
      selected={selected}
      tooltip={null}
    />
  );
};

export default UserItem;
