import { ShortcutService } from "@mapmycustomers/shared";
import { useEffect } from "react";
import { Editor } from "slate";
import toggleTextType from "./toggleTextType";
import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";
import toggleBlock from "./toggleBlock";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { REMOVABLE_TEXT_TYPES } from "./const";
import TextSize from "@mapmycustomers/shared/enum/textEditor/TextSize";
import FileUploadCallback from "../type/FileUploadCallback";
import selectImage from "./selectImage";

const changeFontSize = (
  event: KeyboardEvent,
  editor: Editor,
  increase: boolean
) => {
  event.preventDefault();
  const marks = Editor.marks(editor);
  const currentSize = marks?.["size"] ?? TextSize.SMALL;
  let targetSize;
  if (
    (currentSize === TextSize.SMALL && increase) ||
    (currentSize === TextSize.LARGE && !increase)
  ) {
    targetSize = TextSize.MEDIUM;
  } else if (currentSize === TextSize.MEDIUM && increase) {
    targetSize = TextSize.LARGE;
  } else if (currentSize === TextSize.MEDIUM && !increase) {
    targetSize = TextSize.SMALL;
  }
  if (targetSize) {
    Editor.addMark(editor, "size", targetSize);
  }
};

const useShortcuts = (editor: Editor, onLoadFile: FileUploadCallback) =>
  useEffect(() => {
    const shortcutService = new ShortcutService(document.body);
    shortcutService.addKeyListener(
      { code: "NumpadAdd", metaKey: true, shiftKey: true, type: "keydown" },
      (e) => changeFontSize(e, editor, true)
    );
    shortcutService.addKeyListener(
      { code: "Equal", metaKey: true, shiftKey: true, type: "keydown" },
      (e) => changeFontSize(e, editor, true)
    );
    shortcutService.addKeyListener(
      { code: "Minus", metaKey: true, shiftKey: true, type: "keydown" },
      (e) => changeFontSize(e, editor, false)
    );
    shortcutService.addKeyListener(
      {
        code: "NumpadSubtract",
        metaKey: true,
        shiftKey: true,
        type: "keydown",
      },
      (e) => changeFontSize(e, editor, false)
    );

    shortcutService.addKeyListener(
      { code: "KeyB", metaKey: true, type: "keydown" },
      () => toggleTextType(editor, TextType.BOLD)
    );
    shortcutService.addKeyListener(
      { code: "KeyI", metaKey: true, type: "keydown" },
      () => toggleTextType(editor, TextType.ITALIC)
    );
    shortcutService.addKeyListener(
      { code: "KeyU", metaKey: true, type: "keydown" },
      () => toggleTextType(editor, TextType.UNDERLINE)
    );
    shortcutService.addKeyListener(
      { code: "KeyX", metaKey: true, shiftKey: true, type: "keydown" },
      () => toggleTextType(editor, TextType.STRIKE)
    );

    shortcutService.addKeyListener(
      { code: "Digit8", metaKey: true, shiftKey: true, type: "keydown" },
      () => toggleBlock(editor, ElementType.BULLETED_LIST)
    );
    shortcutService.addKeyListener(
      { code: "Digit7", metaKey: true, shiftKey: true, type: "keydown" },
      () => toggleBlock(editor, ElementType.NUMBERED_LIST)
    );
    shortcutService.addKeyListener(
      { code: "BracketLeft", metaKey: true, type: "keydown" },
      () =>
        toggleBlock(editor, ElementType.INDENT, {
          indentIncrement: -1,
        })
    );
    shortcutService.addKeyListener(
      { code: "BracketRight", metaKey: true, type: "keydown" },
      () =>
        toggleBlock(editor, ElementType.INDENT, {
          indentIncrement: 1,
        })
    );
    shortcutService.addKeyListener(
      { code: "Backslash", metaKey: true, type: "keydown" },
      () => {
        REMOVABLE_TEXT_TYPES.forEach((type) => {
          Editor.removeMark(editor, type);
        });
      }
    );
    shortcutService.addKeyListener(
      { code: "KeyZ", metaKey: true, type: "keydown" },
      editor.undo
    );
    shortcutService.addKeyListener(
      { code: "KeyZ", metaKey: true, shiftKey: true, type: "keydown" },
      editor.redo
    );
    shortcutService.addKeyListener(
      { code: "KeyI", metaKey: true, shiftKey: true, type: "keydown" },
      () => selectImage(editor, onLoadFile)
    );

    return () => shortcutService.destroy();
  }, [editor, onLoadFile]);

export default useShortcuts;
