import React, { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import cn from "classnames";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Tooltip from "antd/es/tooltip";
import { ClearableDatePicker } from "component/input/DatePicker";
import ButtonLink from "component/ButtonLink";
import FormValues from "../../types/FormValues";
import styles from "./ActivityDate.module.scss";
import { isThisYear } from "date-fns/esm";
import isValidDate from "util/isValidDate";
import { formatDate } from "util/formatters";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  disabledTooltip?: string;
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const messages = defineMessages({
  changeDate: {
    id: "createActivityModal.footer.scheduleActivity.changeDate",
    defaultMessage: "Change Date",
    description: "Change Date",
  },
  noDate: {
    id: "createActivityModal.footer.scheduleActivity.noDate",
    defaultMessage: "No date",
    description: "No date",
  },
  removeDateTime: {
    id: "createActivityModal.footer.scheduleActivity.removeDateTime",
    defaultMessage: "Remove Date and Time",
    description: "Remove Date and Time",
  },
});

const ActivityDate: React.FC<Props> = ({ disabledTooltip, form, onChange }) => {
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        setTooltipVisible(false);
      }
      setOpen(open);
    },
    [setOpen, setTooltipVisible]
  );

  const handleClearDateClick = useCallback(() => {
    form.setFieldsValue({
      startAt: undefined,
      endAt: undefined,
    });

    setTooltipVisible(false);
    setOpen(false);

    onChange?.();
  }, [form, onChange, setOpen, setTooltipVisible]);

  const handleClick = useCallback(() => {
    setTooltipVisible(false);
    setOpen(true);
  }, [setTooltipVisible, setOpen]);

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const currentDate = isValidDate(dateValue) ? dateValue : undefined;
  const isCurrentYear = currentDate && isThisYear(currentDate);
  const dateFormat = intl.formatMessage(
    {
      id: "createEditEntity.activity.date.format",
      defaultMessage: "{currentYear, select, true {eeee, MMM d} other {eeee, MMM d, yyyy}}",
      description: "Date format for current date",
    },
    {
      currentYear: isCurrentYear,
    }
  );

  return (
    <div className={styles.container}>
      <Tooltip
        destroyTooltipOnHide
        mouseLeaveDelay={0}
        onVisibleChange={setTooltipVisible}
        overlayClassName={styles.tooltip}
        placement="top"
        title={disabledTooltip ?? intl.formatMessage(messages.changeDate)}
        trigger={open ? [] : ["hover"]}
        visible={tooltipVisible}
      >
        <div>
          <Form.Item name={ActivityFieldName.START_AT} noStyle>
            <ClearableDatePicker
              allowClear={false}
              bordered={false}
              disabled={!!disabledTooltip}
              dropdownClassName={styles.panel}
              inputReadOnly
              onOpenChange={handleOpenChange}
              open={open}
              placeholder={intl.formatMessage(messages.noDate)}
              renderExtraFooter={() => (
                <ButtonLink className={styles.clear} onClick={handleClearDateClick}>
                  {intl.formatMessage(messages.removeDateTime)}
                </ButtonLink>
              )}
              showTime={false}
              suffixIcon={<></>}
            />
          </Form.Item>

          <Button
            className={cn(styles.btn, { [styles.active]: open })}
            disabled={!!disabledTooltip}
            onClick={handleClick}
          >
            {currentDate
              ? formatDate(currentDate, dateFormat)
              : intl.formatMessage(messages.noDate)}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default ActivityDate;
