import React, { ReactNode, useCallback, useEffect } from "react";
import { Modal } from "@mapmycustomers/ui";
import authService from "store/auth/AuthService";
import { getLegacyAppUrl } from "util/appUrl";
import configService from "config/ConfigService";
import { useIntl } from "react-intl";
import styles from "./PreviewDealsListModal.module.scss";
import { Deal } from "@mapmycustomers/shared/types/entity";
import Table from "component/Table";
import Button from "antd/es/button";
import ReportType from "enum/ReportType";
import downloadEntitiesAsCsv from "scene/reports/utils/csv/downloadEntitiesAsCsv";
import MapFilter from "scene/reports/enums/MapFilter";
import { DownloadConfirmation } from "./DownloadConfirmation";
import useVersion from "util/hook/useVersion";
import Path from "enum/Path";
import ViewMode from "scene/deal/enum/ViewMode";
import { ColumnsType } from "antd/es/table/Table";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { connect } from "react-redux";
import { fetchDeals } from "./store/actions";
import { PREVIEW_MODAL_LIMIT } from "util/consts";
import { DealListModalTitle } from "component/DealListModalForCharts/DealListModalTitle";
import { getDeals, getLoading, getTotal } from "./store";
import { RootState } from "store/rootReducer";

export const DEFAULT_PAGE_SIZE = 5;

interface Props {
  columns: ColumnsType<Deal>;
  deals: Deal[];
  fetchDeals: typeof fetchDeals.request;
  filter: FilterModel;
  isLoading: boolean;
  onDealClick: (deal: Deal) => void;
  onHide: () => void;
  stageName?: string;
  subtitle?: string | ReactNode;
  title?: string;
  total: number;
}

const PreviewDealsListModal: React.FC<Props> = ({
  columns,
  deals,
  fetchDeals,
  filter,
  isLoading,
  onDealClick,
  onHide,
  stageName,
  subtitle,
  title,
  total,
}) => {
  const handlePageChange = useCallback(
    (page: number) => {
      fetchDeals({
        request: {
          filter,
          range: {
            startRow: (page - 1) * DEFAULT_PAGE_SIZE,
            endRow: page * DEFAULT_PAGE_SIZE,
          },
        },
      });
    },
    [fetchDeals, filter]
  );

  useEffect(() => {
    fetchDeals({ request: { filter } });
  }, [fetchDeals, filter]);

  const intl = useIntl();
  const token = authService.getToken();
  const { isVersion2Beta } = useVersion();

  const handleRowEvents = useCallback(
    (deal: Deal) => ({
      onClick: () => onDealClick?.(deal),
    }),
    [onDealClick]
  );

  if (!token) {
    return null;
  }

  return (
    <Modal
      destroyOnClose
      footer={[
        <Button
          className={styles.viewAllDealsLink}
          href={
            isVersion2Beta
              ? `${Path.DEAL}/${ViewMode.LIST}`
              : getLegacyAppUrl(configService.getBaseOldAppUrl(), token, "/deals/list")
          }
          key="viewAll"
          target="_blank"
          type="link"
        >
          {intl.formatMessage({
            id: "dealListModal.viewAllDeals",
            defaultMessage: "View all Deals",
            description: "View all Deals link on the Deal list modal",
          })}
        </Button>,
        <DownloadConfirmation
          key="download"
          onConfirm={() => downloadEntitiesAsCsv(deals, ReportType.DEALS, MapFilter.ALL_DEALS)}
        >
          <Button type="primary">
            {intl.formatMessage({
              id: "dealListModal.downloadDealsButton",
              defaultMessage: "Quick export",
              description: "Download Deals button on the list modal",
            })}
          </Button>
        </DownloadConfirmation>,
      ]}
      onCancel={onHide}
      title={
        <DealListModalTitle
          dealsTotal={total}
          stageName={stageName}
          title={title}
          subTitle={subtitle}
        />
      }
      visible
      width="clamp(300px, 70vw, 780px)"
    >
      <Table<Deal>
        clickableRows
        columns={columns}
        dataSource={deals}
        loading={isLoading}
        noHover
        onRow={handleRowEvents}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          onChange: handlePageChange,
          total: total < PREVIEW_MODAL_LIMIT ? total : PREVIEW_MODAL_LIMIT,
        }}
        rowKey="id"
        scrollable
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  deals: getDeals(state),
  total: getTotal(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = {
  fetchDeals: fetchDeals.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDealsListModal);
