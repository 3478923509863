import React, { useCallback, useState } from "react";
import { Modal } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import messages from "./messages";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import RecipientSelector from "../RecipientSelector";
import styles from "./PreviewAsModal.module.scss";

interface Props {
  onHide: () => void;
  onSelectRecipientId: (id: EntitiesSupportedByEmailFeature["id"]) => void;
  recipients: EntitiesSupportedByEmailFeature[];
  selectedRecipientId?: EntitiesSupportedByEmailFeature["id"];
}

const PreviewAsModal: React.FC<Props> = ({
  onHide,
  onSelectRecipientId,
  recipients,
  selectedRecipientId,
}) => {
  const intl = useIntl();
  const [recipientId, setRecipientId] = useState<EntitiesSupportedByEmailFeature["id"] | undefined>(
    selectedRecipientId
  );

  const handleOk = useCallback(() => {
    if (recipientId) {
      onSelectRecipientId(recipientId);
      onHide();
    }
  }, [recipientId, onSelectRecipientId, onHide]);

  return (
    <Modal
      cancelText={intl.formatMessage(messages.cancel)}
      okButtonProps={{ disabled: !recipientId }}
      okText={intl.formatMessage(messages.okay)}
      onCancel={() => onHide()}
      onOk={handleOk}
      open
      title={intl.formatMessage(messages.previewAs)}
    >
      <RecipientSelector
        onSelectRecipientId={setRecipientId}
        recipients={recipients}
        selectedRecipientId={recipientId}
      />
      <div className={styles.description}>
        {intl.formatMessage(messages.previewTemplateDescription)}
      </div>
    </Modal>
  );
};

export default PreviewAsModal;
