import React, { useCallback, useEffect, useRef, useState } from "react";
import { BaseEditor, createEditor, Descendant } from "slate";
import styles from "./EmailBody.module.scss";
import type { ReactEditor } from "slate-react";
import { Editable, Slate, withReact } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import { TextAreaProps } from "antd/es/input/TextArea";
import cn from "classnames";
import EditorElement from "./component/EditorElement";
import Leaf from "./component/Leaf";
import decorator from "./util/decorator";
import serializeToHTML from "./util/serializeToHTML";
import deserializeFromHTML from "./util/deserializeFromHTML";
import Actions from "./component/actions";
import AttachedFile from "./type/AttachedFile";
import Toolbar from "./component/Toolbar";
import withImages from "./util/withImages";
import { useConfigProvider } from "../ConfigProvider";
import DynamicVarVariant from "./type/DynamicVarVariant";
import withDynamicVars from "./util/withDynamicVars";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import resetEditorValue from "./util/resetEditorValue";
import useShortcuts from "./util/useShortcuts";
import AttachedFiles from "./component/AttachedFiles";
import FileUploadCallback from "./type/FileUploadCallback";
import isEqual from "lodash-es/isEqual";
import LoadingSpinner from "../LoadingSpinner";

const BODY_MAX_LENGTH = 384000;

// Not sure can we provide all text area props.
interface Props
  extends Pick<
    TextAreaProps,
    | "autoFocus"
    | "className"
    | "disabled"
    | "placeholder"
    | "rows"
    | "maxLength"
  > {
  action?: React.ReactNode;
  appliedTemplate?: EmailTemplate;
  attachedFiles?: AttachedFile[];
  containerClassName?: string;
  dynamicVarVariants?: DynamicVarVariant[];
  editorId?: string;
  entityType?: EntityTypesSupportedByEmailFeature;
  loading?: boolean;
  maxLength?: number;
  onAction?: (actionKey: string) => void;
  onChange?: (
    text: string,
    limitReached: boolean,
    appliedTemplate?: EmailTemplate
  ) => void;
  onDeleteFile?: (id: AttachedFile["id"]) => void;
  onDownloadFile?: (file: AttachedFile) => void;
  onFilesSelect?: (files: File) => void;
  onLoadFile: FileUploadCallback;
  onPreview?: () => void;
  onPreviewFile?: (file: AttachedFile) => void;
  placeholder?: string;
  previewDisableText?: string;
  showActions?: boolean;
  value?: string;
}

const EmailBody: React.FC<Props> = ({
  action,
  appliedTemplate,
  attachedFiles,
  className,
  containerClassName,
  dynamicVarVariants,
  editorId,
  entityType,
  loading,
  maxLength = BODY_MAX_LENGTH,
  onAction,
  onChange,
  onDeleteFile,
  onDownloadFile,
  onFilesSelect,
  onLoadFile,
  onPreview,
  onPreviewFile,
  placeholder,
  previewDisableText,
  showActions,
  value,
  ...props
}) => {
  const [parsedValue, setParsedValue] = useState<Descendant[]>(() =>
    deserializeFromHTML(value ?? "")
  );
  const [savedAppliedTemplate, setSavedAppliedTemplate] = useState<
    EmailTemplate | undefined
  >();
  const configProvider = useConfigProvider();

  const editorRef = useRef<ReactEditor & BaseEditor & HistoryEditor>(
    withDynamicVars(
      withImages(
        withReact(
          withHistory(
            createEditor() as ReactEditor & BaseEditor & HistoryEditor
          )
        ),
        onLoadFile
      )
    )
  );

  const renderElement = useCallback(
    (props) => (
      <EditorElement
        dynamicVarVariants={dynamicVarVariants}
        entityType={entityType}
        {...props}
      />
    ),
    [dynamicVarVariants]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = editorRef.current!;

  const handleChange = useCallback(
    (elements: Descendant[]) => {
      if (!isEqual(parsedValue, elements)) {
        const text = serializeToHTML(elements);
        onChange?.(text, text.length >= maxLength, appliedTemplate);
        setParsedValue(elements);
      }
    },
    [appliedTemplate, editor, parsedValue, parsedValue, setParsedValue]
  );

  useEffect(() => {
    if (appliedTemplate) {
      if (appliedTemplate.id !== savedAppliedTemplate?.id) {
        resetEditorValue(editor, appliedTemplate.body);
        setSavedAppliedTemplate(appliedTemplate);
      }
    }
  }, [appliedTemplate, editor, setParsedValue, value]);

  useShortcuts(editor, onLoadFile);

  return (
    <div className={cn(styles.container, containerClassName)}>
      <Slate
        editor={editor}
        onChange={props.disabled ? undefined : handleChange}
        value={parsedValue}
      >
        <div className={styles.editorContainer}>
          <div className={styles.editorWrapper}>
            <AttachedFiles
              attachedFiles={attachedFiles}
              onDeleteFile={onDeleteFile}
              onDownloadFile={onDownloadFile}
              onPreviewFile={onPreviewFile}
            />
            <Editable
              className={cn(
                styles.editor,
                { [styles.extraPadding]: !showActions },
                className
              )}
              decorate={decorator}
              id={editorId}
              placeholder={placeholder}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              renderPlaceholder={() => (
                <span>
                  {configProvider.formatMessage("ui.emailBody.placeholder")}
                </span>
              )}
              {...props}
            />
          </div>
          <div className={styles.toolbarWrapper}>
            {!showActions && (
              <Toolbar extraMargin onLoadFile={onLoadFile} showInsertLink />
            )}
            {showActions && (
              <Actions
                action={action}
                dynamicVarVariants={dynamicVarVariants}
                editorId={editorId}
                entityType={entityType}
                onAction={onAction}
                onFilesSelect={onFilesSelect}
                onLoadFile={onLoadFile}
                onPreview={onPreview}
                previewDisableText={previewDisableText}
              />
            )}
          </div>
        </div>
      </Slate>
      {loading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default EmailBody;
