@use "../styles/colors";
@use "../styles/layout";
@use "sass:color";

$fancy-modal-border-radius: 0.75rem !default;

:global {
  body[class*="theme-"].theme {
    :local {
      .bordered {
        border-top: 0.5rem solid colors.$mmc-primary-100;
        border-radius: 0.5rem;
      }

      .warning {
        border-top: 0.5rem solid colors.$mmc-yellow-100;
      }

      .error {
        border-top: 0.5rem solid colors.$mmc-red-100;
      }

      .success {
        border-top: 0.5rem solid colors.$mmc-green-100;
      }

      .disabled {
        border-top: 0.5rem solid colors.$mmc-accent-gray;
      }

      // defined separately, so that it can be exported separately
      .fancyHeader {
        box-shadow: 0 0.25rem 0.75rem #{color.change(
            colors.$mmc-black,
            $alpha: 0.06
          )};
        position: relative;
        // we need to define z-index to let header drop shadow on all elements bellow.
        z-index: 1;
        border-top-left-radius: $fancy-modal-border-radius;
        border-top-right-radius: $fancy-modal-border-radius;
      }

      .fancyFooter {
        border-bottom-left-radius: $fancy-modal-border-radius;
        border-bottom-right-radius: $fancy-modal-border-radius;
      }

      .fancyModalStyle {
        & :global(.ant-modal-content) {
          border-radius: $fancy-modal-border-radius;
        }

        & :global(.ant-modal-header) {
          @extend .fancyHeader;
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      .small {
        border-width: layout.$spacer-xs;
      }
    }
  }
}
